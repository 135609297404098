.hero-section-alignment {
    padding: 78px 0 40px 0;
    position: relative;
}

.hero-section-alignment .logo-center-alignment {
    display: flex;
    justify-content: center;
    padding: 0 0 50px 0;
}

.slick-prev {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: 1px solid #F337FF;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: absolute;
    font-size: 0px;
    background-color: #fff !important;
    z-index: 999;
    top: 50%;
    transform: translateY(-50%);
    left: -25px;
    background-position: 10px 16px !important;
}

.slick-next {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: #fff !important;
    border: 1px solid #F337FF;
    display: flex;
    z-index: 999;

    align-items: center;
    justify-content: center;
    font-size: 0px;
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: -25px;
}

.hero-section-alignment .hero-text h1 {
    color: #22212C;
    text-align: center;
    font-size: 42px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    margin: 0 0 16px 0;
    letter-spacing: -1.68px;
}

.hero-section-alignment h1 span {
    background: linear-gradient(270deg, #F337FF 44.95%, #5347EB 72.19%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: Manrope;
    font-size: 42px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    letter-spacing: -1.68px;
}

.hero-section-alignment .hero-text p {
    color: #22212C;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    max-width: 781px;
    margin: 0 auto 36px auto;
    letter-spacing: -0.8px;
}

.hero-section-alignment .hero-text .gradient-text {
    background-color: #F337FF;
    background-image: linear-gradient(91deg, #F337FF 6.54%, #5347EB 90.41%);
    background-size: 100%;
    background-repeat: repeat;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;
}

.hero-section-alignment .hero-text .btn-center {
    display: flex;
    justify-content: center;
    padding: 0 0 28px 0;
    text-decoration: none;
}

.hero-section-alignment .hero-text .btn-center button {
    border-radius: 60px;
    background: linear-gradient(91deg, #F337FF 6.54%, #5347EB 90.41%);
    padding: 12px 40px;
    color: #FFF;
    font-size: 18px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    border: none;
    cursor: pointer;
}

.hero-section-alignment .hero-text .center-text p {
    color: #000;
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
}

.hero-section-alignment .web-template {
    position: absolute;
    top: 100px;
    right: 100px;
}

.hero-section-alignment .ui-kit {
    position: absolute;
    top: 100px;
    left: 140px;
}

.hero-section-alignment .web-template img {
    max-width: 180px;
}

.hero-section-alignment .icon-text {
    position: absolute;
    top: 60px;
    right: 0;
}

.hero-section-alignment .mobile-text {
    position: absolute;
    top: 60px;
    left: 0;
}

.hero-section-alignment .ui-sec-kit {
    position: absolute;
    top: 260px;
    left: 0;
}

.hero-section-alignment .ui-sec-kit img {
    max-width: 100px;
}

.hero-section-alignment .mobile-text img {
    max-width: 120px;
}

.hero-section-alignment .light-image {
    position: absolute;
    right: 0;
    top: 300px;
}

.text-icon-all-content-alignment .grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 60px;
}

.text-icon-all-content-alignment .box {
    border-radius: 99px;
    background: #FAFAFA;
    padding: 8px 40px 8px 8px;
    width: fit-content;
    display: grid;
    grid-template-columns: 55px 1fr;
    gap: 20px;
    align-items: center;
    margin: 0 0 24px 0;
}

.text-icon-all-content-alignment .icon {
    width: 55px;
    height: 55px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 50%;
    box-shadow: 0px 20px 38px rgba(38, 6, 74, 0.14);
}

.text-icon-all-content-alignment .icon img {
    max-width: 25px;
}

.text-icon-all-content-alignment span {
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    background: #22212C;
    /* background: var(--linear, linear-gradient(270deg, #F337FF -20%, #5347EB 120%)); */
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.text-icon-all-content-alignment .alignment {
    display: flex;
    justify-content: flex-end;
}

.text-icon-all-content-alignment .left-alignment {
    margin-left: 60px;
}

.text-icon-all-content-alignment .right-content-alignment {
    margin-right: 60px;
}

.text-icon-all-content-alignment {
    padding: 0 0 120px 0;
}

.card-content-alignment .heading h2 {
    color: #22212C;
    text-align: center;
    font-size: 32px;
    text-align: center;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
}

.card-content-alignment .heading span {
    background: linear-gradient(270deg, #F337FF 1.89%, #5347EB 37.53%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-style: normal;
    line-height: normal;
}

.card-content-alignment .heading {
    padding: 0 0 50px 0;
}

.card-content-alignment .grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    padding: 0 0 55px 0;
}

.card-content-alignment .grid-items {
    border-radius: 24px;
    background: #FFF;
    padding: 14px;
    border-bottom: 1px solid #E1E1E1;
}

.card-content-alignment .grid-items a{
    text-decoration: none;
}

.card-content-alignment .card-image .img {
    width: 100%;
    height: 180px;
    object-fit: cover;
    border-radius: 20px;
}

.card-content-alignment .card-image {
    position: relative;
}

.card-content-alignment .button-alignment {
    position: absolute;
    bottom: 0;
    left: 23px;
}

.card-content-alignment .button-alignment button {
    color: #22212C;
    text-align: center;
    background-color: #FEDA03;
    padding: 4px 16px;
    font-size: 9px;
    font-style: normal;
    font-weight: 800;
    border: none;
    border-radius: 999px;
    line-height: 14px;
    cursor: pointer;
    letter-spacing: -0.36px;
}

.card-content-alignment .heart-alignmet {
    position: absolute;
    right: -25px;
    top: 0;
}

.card-content-alignment .heart-alignmet button {
    background-color: transparent;
    border-radius: 17px;
    border: 1px solid #E1E1E1;
    background: #FFF;
    padding: 10px 14px;
    color: #252831;
    display: flex;
    align-items: center;
    gap: 6px;
    font-size: 12px;
    font-style: normal;
    font-weight: 800;
    line-height: 14px;
    /* 116.667% */
    letter-spacing: -0.48px;
}

.card-content-alignment .heart-alignmet button img {}

.card-content-alignment .main-items {
    border-radius: 25px;
    border: 1px solid #E1E1E1;
    background: #F9F9F9;
}

.card-content-alignment .card-text {
    padding: 25px 0 0 8px;
}

.card-content-alignment .card-text h6 {
    color: #22212C;
    font-size: 16px;
    margin: 0 0 10px 0;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    letter-spacing: -0.64px;
    text-decoration: none;
}

.card-content-alignment .sec-card {
    padding: 20px 25px;
}

.card-content-alignment .profile-card {
    display: flex;
    align-items: center;
    gap: 6px;
    text-decoration: none;
}

.card-content-alignment .profile-card span {
    display: block;
    color: #22212C;
    font-size: 14px;
    font-style: normal;
    font-weight: 800;
    line-height: 1.04;
    letter-spacing: -0.56px;
}

.card-content-alignment .profile-card h6 {
    color: #22212C;
    text-align: right;
    font-size: 14px;
    font-style: normal;
    font-weight: 800;
    line-height: 22px;
    letter-spacing: -0.56px;
}

.card-content-alignment .sec-card {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.card-content-alignment .btn-center {
    display: flex;
    justify-content: center;
    text-decoration: none;
}

.card-content-alignment .btn-center button {
    color: #FFF;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 800;
    line-height: 14px;
    /* 100% */
    border-radius: 12px;
    background: #22212C;
    padding: 18px 40px;
    cursor: pointer;
    border: none;
    letter-spacing: -0.56px;
}

.card-content-alignment {
    padding: 0 0 120px 0;
}

footer {
    background: #14161B;
    padding: 70px 0 0 0;
}

footer .footer-alignment {
    display: flex;
    justify-content: space-between;
    padding: 0 0 120px 0;
    border-bottom: 1px solid #31333B;
}

footer .footer-logo img {
    cursor: pointer;
}

footer .footer-logo {
    padding: 0 0 28px 0;
}

footer span {
    display: block;
    color: #FFF;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    max-width: 427px;
    padding: 0 0 30px 0;
    line-height: 26px;
    letter-spacing: -0.52px;
}

footer img {
    cursor: pointer;
}

footer .two-content-alignment {
    display: flex;
    gap: 120px;
}

footer .two-content-alignment h6 {
    color: #FFF;
    margin: 0 0 30px 0;
    font-size: 13.495px;
    font-style: normal;
    font-weight: 800;
    line-height: 13.495px;
    /* 100% */
    letter-spacing: -0.54px;
}

footer a {
    display: block;
    color: #FFF;
    font-size: 13px;
    cursor: pointer;
    font-style: normal;
    font-weight: 700;
    transition: .3s ease-in-out;
    text-decoration: none;
    line-height: 32px;
    letter-spacing: -0.52px;
}

footer .two {
    display: flex;
    gap: 60px;
}

footer a:hover {
    color: #C23FFF;
    transition: .3s ease-in-out;
}

footer .copy {
    display: flex;
    align-items: center;
    padding: 30px 0;
    justify-content: space-between;
}

footer .copy span {
    display: block;
    color: #FFF;
    font-size: 12.531px;
    font-style: normal;
    font-weight: 400;
    padding: 0;
    line-height: 13.495px;
    letter-spacing: -0.501px;
}

footer .sec {
    display: flex;
    gap: 30px;
    align-items: center;
}


.price .text p {
    color: #22212C;
    text-align: center;
    font-size: 32px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
}

.price .text p span {
    background: linear-gradient(270deg, #F337FF 1.89%, #5347EB 37.53%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-style: normal;
    line-height: normal;
}

.price {
    padding: 0 0 100px 0;
}

.price .text {
    padding: 0 0 60px 0;
}

.price-box {
    border: 1px solid #F337FF;
    border-radius: 999px;
    padding: 74px 165px;
}

.price .cout {
    padding: 0 0 30px 0;
}

.price .text-style p {
    color: #22212C;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 170.1%;
    /* 34.02px */
    letter-spacing: -0.8px;
}

.price .text-style {
    padding: 0 0 35px 0;
}

.price .star-alignment {
    display: flex;
    align-items: center;
    gap: 9px;
    cursor: pointer;
}

.price .lasttext p {
    color: #22212C;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    margin: 20px 0 0 0;
    line-height: 170.1%;
    /* 27.216px */
    letter-spacing: -0.64px;
}

.price .lasttext p b {
    font-weight: 800;
}

.plan .heading h6 {
    color: #22212C;
    text-align: center;
    font-size: 32px;
    font-style: normal;
    font-weight: 800;
    margin: 0 0 20px 0;
    line-height: normal;
}

.plan .heading h6 span {
    background: linear-gradient(270deg, #F337FF 1.89%, #5347EB 37.53%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-style: normal;
    line-height: normal;
}

.plan {
    padding: 0 0 120px 0;
}

.plan .heading p {
    color: #252831;
    max-width: 474px;
    margin: 0 auto;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    line-height: 28px;
    /* 175% */
    letter-spacing: -0.64px;
}

.plan .heading p span {
    background: linear-gradient(270deg, #F337FF 1.89%, #5347EB 37.53%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-style: normal;
    line-height: normal;
}

.plan .heading {
    padding: 0 0 80px 0;
}

.plan .grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 32px;
}

.plan .grid-items:nth-child(2) {
    background-image: url("../../../public/Image/images/black.png");
    border-radius: 24px;
    padding: 50px 40px;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
}

.plan,
.grid-items:nth-child(2) .icon-grid p {
    color: #fff;
}

.plan,
.grid-items:nth-child(2) .icon-grid p span {
    color: rgba(255, 255, 255, 0.50);

}

.btn-align-plan button {
    border-radius: 60px;
    background: linear-gradient(91deg, #F337FF 6.54%, #5347EB 90.41%);
    padding: 12px 40px;
    color: #FFF;
    font-size: 18px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    border: none;
    cursor: pointer;
}

.btn-align-plan {
    position: absolute;
    top: -30px;
    left: 50%;
    transform: translateX(-50%);
}

.plan .grid-items {
    position: relative;
}

.plan .grid-items:nth-child(1),
.plan .grid-items:nth-child(3) {
    background-image: url("../../../public/Image/images/white.png");
    border-radius: 24px;
    padding: 50px 40px;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
}

.plan .card-two-content-alignment .left-content img {
    max-width: 45px;
}

.plan .card-two-content-alignment .left-content p {
    color: #060606;
    font-size: 18px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
}

.plan .card-two-content-alignment .left-content h6 {
    color: rgba(0, 0, 0, 0.50);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.plan .card-two-content-alignment .left-content h6 span {
    color: #060606;
    font-weight: 800;
}

.plan .card-two-content-alignment .left-content {
    display: flex;
    align-items: center;
    gap: 12px;
}

.plan .card-two-content-alignment h5 {
    color: #060606;
    font-size: 26px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
}

.plan .card-two-content-alignment {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0 50px 0;
}

.plan .icon-grid {
    display: grid;
    grid-template-columns: 18px 1fr;
    gap: 16px;
    padding: 0 0 18px 0;
}

.plan .icon-grid p {
    color: #060606;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.plan .icon-grid p span {
    color: rgba(0, 0, 0, 0.50);

}

.plan .btn-align {
    padding: 40px 0 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
}

.plan .btn-align button {
    padding: 16px;
    color: #FFF;
    border: none;
    cursor: pointer;
    width: 100%;
    font-size: 14px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    border-radius: 60px;
    background: linear-gradient(91deg, #F337FF 6.54%, #5347EB 90.41%);
}

.faq .new-text p {
    background: linear-gradient(270deg, #F337FF -20%, #5347EB 120%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 32px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    letter-spacing: -1.68px;
    margin: 0 0 16px 0;
}

.faq .new-text span {
    color: #22212C;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: -0.8px;
}

.faq .new-text {
    padding: 0 0 60px 0;
}

.faq {
    padding: 0 0 120px 0;
}

.faq .grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 40px;
}

.faq .grid .grid-items {
    background: #FAFAFA;
    border-radius: 20px;

}

.faq .header-box {
    padding: 30px;

}

.faq .header-box p {
    font-size: 15px;
    font-style: normal;
    background: #22212C;
    /* background: linear-gradient(270deg, #F337FF -20%, #5347EB 120%); */
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 700;
    line-height: normal;
}

.faq .header-box {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.bg .relative {
    position: relative;
}

.bg .relative img {
    width: 100%;
}

.bg .content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.bg .content p {
    color: #22212C;
    text-align: center;
    font-size: 32px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    margin: 0 0 40px 0;
    letter-spacing: -1.68px;
}

.bg .content p span {
    background: linear-gradient(270deg, #F337FF 34.38%, #5347EB 70.62%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    letter-spacing: -1.68px;
}

.bg .btn-center {
    display: flex;
    justify-content: center;
    padding: 0 0 25px 0;
    text-decoration: none;
}

.bg .btn-center button {
    border-radius: 60px;
    background: linear-gradient(91deg, #F337FF 6.54%, #5347EB 90.41%);
    padding: 12px 40px;
    color: #FFF;
    font-size: 18px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    border: none;
    cursor: pointer;
}

.bg h5 {
    color: #000;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
}


.sub-footer {
    margin: 100px 0 0 0;
}

.sub-bg {
    background-image: url("../../../public/Image/images/sub-footer.png");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    padding: 140px 0;
    background-position: top;
}

.sub-bg p {
    color: #FFF;
    margin: 0 0 12px 0;
    font-size: 21.952px;
    font-style: normal;
    font-weight: 800;
    line-height: 21.952px;
    /* 100% */
    letter-spacing: -0.878px;
}

.sub-bg h2 {
    color: #FFF;
    font-size: 50px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    letter-spacing: -2.8px;
}

.collapse-content {
    display: none;
}

.sub-bg {}

.sub-bg {}

.sub-bg {}

.sub-bg {}

.hero-section-alignment .ui-kit {
    max-width: 180px;
}

@media (max-width:1700px) {

    .hero-section-alignment .web-template img,
    .hero-section-alignment .ui-kit img {
        max-width: 180px;
    }

    .container-md {
        max-width: 600px;
    }

    .price-box {
        padding: 50px 100px;
    }

    .price .text-style p {
        font-size: 18px;
        line-height: 28px;
    }

    .hero-section-alignment .hero-text h1,
    .hero-section-alignment h1 span,
    .card-content-alignment .heading h2 {
        font-size: 30px;
    }

    .hero-section-alignment .hero-text p {
        font-size: 16px;
    }

    .hero-section-alignment .hero-text .center-text p {
        font-size: 20px;
    }
}

.m-header {
    display: none;
    position: relative;
}

.m-round {
    position: absolute;
    left: 0;
    bottom: -5px;
}

.header-design {
    background-image: url("../../../public/Image/images/m-header.png");
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 30px 20px;
}

.m-time {
    background: url('../../../public/Image/images/m-timer.png');
    background-size: cover;
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    gap: 20px;
    justify-content: center;
}

.m-button {
    display: flex;
    justify-content: center;
    padding: 20px 0 0 0;
}

.m-button button {
    color: #35006D;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    padding: 8px 24px;
    background-color: #fff;
    border-radius: 6px;
    border: none;
    line-height: normal;
}

.m-time h2 {
    color: #35006D;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    letter-spacing: -0.8px;
}

.m-time span {
    display: block;
    color: #FFF;
    text-align: center;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.4px;
}

.n-text p {
    color: #FFF;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    letter-spacing: -0.8px;
}

.n-text {
    padding: 0 0 20px 0;
}

.faq-body p {
    color: #515151;
    font-size: 15px;
    font-style: normal;
    padding: 30px;
    font-weight: 500;
    line-height: 151.6%;
    /* 24.256px */
}

.m-block {
    display: none;
}

@media (max-width:820px) {
    .hide-all-image {
        display: none;
    }

    .m-header {
        display: block;
    }

    .card-content-alignment .grid {
        grid-template-columns: repeat(2, 1fr);
    }

    footer .footer-alignment {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 40px;
    }

    .plan .grid {
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
    }

    .price-box {
        padding: 40px 100px;
        border-radius: 24px;
    }

    .text-icon-all-content-alignment .grid {
        grid-template-columns: repeat(1, 1fr);
        gap: 0;
    }

    .sub-bg {
        padding: 75px 0;
    }

    .sub-bg h2 {
        font-size: 32px;
    }

    .sub-bg img {
        max-width: 346px;
    }

    .slick-next {
        right: 0;
    }

    .slick-prev {
        left: 0;
    }
}

@media (max-width:576px) {
    .hide-all-image {
        display: none;
    }

    .m-hide,
    .sub-bg p {
        display: none;
    }

    .m-block {
        display: block;
    }

    .hero-section-alignment {
        padding: 60px 0;
    }

    .hero-section-alignment .logo-center-alignment img {
        max-width: 180px;
    }

    .container-md {
        padding: 0 20px;
    }

    .hero-section-alignment .hero-text h1,
    .hero-section-alignment h1 span,
    .card-content-alignment .heading h2,
    .price .text p,
    .bg .content p,
    .faq .new-text p,
    .plan .heading h6,
    .bg .content p span {
        font-size: 20px;
        line-height: 30px;
    }

    .plan .heading p {
        font-size: 14px;
    }

    .faq .new-text span {
        font-size: 16px;
        line-height: 24px;
    }

    .faq .new-text {
        padding: 0 0 40px 0;
    }

    .price {
        padding: 0 0 60px 0;
    }

    .plan .heading {
        padding: 0 0 40px 0;
    }

    .bg .content p {
        margin: 0 0 20px 0;
    }

    .card-content-alignment .heading,
    .price .text {
        padding: 0 0 30px 0;
    }

    .price-box {
        padding: 40px;
        border-radius: 12px;
    }

    .card-content-alignment .grid {
        grid-template-columns: repeat(1, 1fr);
        padding: 0 0 30px 0;
    }

    .card-content-alignment .btn-center button {
        padding: 12px 24px;
    }

    .card-content-alignment {
        padding: 0 0 60px 0;
    }

    .hero-section-alignment .hero-text p {
        font-size: 14px;
        line-height: 22px;
    }

    .hero-section-alignment .hero-text p {
        margin-bottom: 20px;
    }

    .hero-section-alignment .hero-text .btn-center button,
    .bg .btn-center button {
        font-size: 14px;
        padding: 10px 30px;
    }

    .bg h5 {
        font-size: 16px;
    }

    .faq {
        padding: 0 0 80px 0;
    }

    .plan {
        padding: 0 0 60px 0;
    }

    .hero-section-alignment .hero-text .btn-center {
        padding: 0 0 20px 0;
    }

    .hero-section-alignment .hero-text .center-text p {
        font-size: 16px;
    }

    .text-icon-all-content-alignment {
        padding: 0 0 60px 0;
    }

    .plan .grid {
        grid-template-columns: repeat(1, 1fr);
    }

    .faq .grid {
        grid-template-columns: repeat(1, 1fr);
        gap: 20px;
    }

    .text-icon-all-content-alignment .grid {
        grid-template-columns: repeat(1, 1fr);
        gap: 0;
    }

    .text-icon-all-content-alignment .right-content-alignment {
        margin-right: 0;
    }

    .text-icon-all-content-alignment .alignment {
        display: block;
    }

    .price .text-style p {
        font-size: 16px;
    }

    .text-icon-all-content-alignment span {
        font-size: 13px;
    }

    .text-icon-all-content-alignment .box {
        width: 100%;
    }

    .text-icon-all-content-alignment .box {
        margin: 0 0 20px 0;
    }

    .text-icon-all-content-alignment .left-alignment {
        margin-left: 0;
    }

    .sub-bg {
        height: 997px;
        background-image: url("../../../public/Image/images/mobile-bg.png");
    }

    footer .two-content-alignment,
    footer .footer-alignment {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 40px;
    }

    footer .copy {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 20px;
    }

    footer .copy span {
        text-align: center;
    }

    footer .sec {
        justify-content: center;
    }

    footer .copy {
        padding: 20px 0;
    }

    footer .footer-alignment {
        padding: 0 0 40px 0;
    }

    .faq .header-box {
        padding: 14px;
        border-radius: 8px;
    }

    .faq .header-box p {
        font-size: 11px;
    }

    .slick-prev {
        display: none !important;
    }

    .slick-next {
        display: none !important;
    }

    .faq-body p {
        padding: 14px;
        font-size: 10px;
    }

    .plan .grid {
        gap: 60px;
    }

    .btn-align-plan button {
        padding: 10px 20px;
        font-size: 12px;
    }
    .plan .btn-align button {
        padding: 9px;
        font-size: 12px;
    }
    .plan .grid-items:nth-child(1), .plan .grid-items:nth-child(3) {
        background-size: contain;
    }
    .plan .grid-items:nth-child(2) {
        background-size: contain;
    }
    .btn-align-plan {
        top: -16px;
        left: 50%;
    }

    .plan .btn-align {
        padding: 5px 0 0 0;
    }

    .plan .card-two-content-alignment {
        padding: 0 0 30px 0;
    }
    .plan .icon-grid p {
        font-size: 12px;
    }
    .plan .icon-grid {
        padding: 0 0 10px 0;
    }
    .plan .grid-items:nth-child(1), .plan .grid-items:nth-child(3) {
        padding: 30px 30px 20px 30px;
    }
}

.slick-prev {
    background: url("https://img.icons8.com/ios/50/000000/long-arrow-left.png") 0 0 / 100% no-repeat;
    background-size: 37px;
    background-position: 10px 11px !important;
}

.slick-next {
    background: url("https://img.icons8.com/ios/50/000000/long-arrow-right.png") 0 0 / 100% no-repeat;
    background-size: 37px;
    background-position: 10px 11px !important;
}

.all-social-icons svg {
    fill: #fff;
    cursor: pointer;
    width: 22px;
    height: 22px;
    transition: .3s ease-in-out;

}

.all-social-icons svg:hover {
    fill: #c23fff;
    transition: .3s ease-in-out;
}

.all-social-icons {
    display: flex;
    align-items: center;
    gap: 20px;
}



header {
    background: linear-gradient(91deg, #F337FF 6.54%, #5347EB 90.41%);
    background-repeat: no-repeat;
    background-size: cover;
    background-size: cover;
    width: 100%;
    height: 100px;
    /* padding: 0 40px; */
    position: sticky;
    top: 0;
    left: 0;
    z-index: 99999999;
}

.header-alignment {
    display: grid;
    grid-template-columns: auto 628px auto;
    width: 100%;
    height: 100%;
}

header .center-content {
    background-image: url("../../../public/Image/images/new-header.png");
    background-size: cover;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
    height: 100%;
}

header .left-content p {
    color: #FFF;
    font-size: 20px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
}

header .left-content {
    display: flex;
    align-items: center;
    gap: 60px;
}

header .right-content button {
    color: #35006D;
    text-align: center;
    border: none;
    font-size: 18px;
    cursor: pointer;
    border-radius: 24px;
    background: linear-gradient(91deg, #EFDFFF 11.43%, #EBB1FF 52.46%, #EFDFFF 96.41%);
    padding: 12px 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    height: 48px;
}

header .right-content {
    display: flex;
    justify-content: flex-end;
    height: 100%;
    align-items: center;
    text-decoration: none;
}

.center-content h2 {
    color: #35006D;
    text-align: center;
    font-size: 32px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    letter-spacing: -1.68px;
    margin: 0 0 5px 0;
}

.center-content span {
    display: block;
    color: #FFF;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.64px;
}

@media (max-width:1500px) {
    /* header {
        padding: 0 20px;
    } */

    .header-alignment {
        grid-template-columns: auto 567px auto;
    }

    header .left-content {
        gap: 40px;
    }
}


@media (max-width:820px) {
    header {
        display: block;
    }
}

body {
    margin: 0;
    padding: 0;
    font-family: 'Manrope', sans-serif;
    overflow-x: hidden;
}

* {
    box-sizing: border-box;
}

img {
    max-width: 100%;
}

p:last-child {
    margin: 0;
}

h1,h2,h3,h4,h5,h6,p {
    margin: 0;
}

button {
    font-family: 'Manrope', sans-serif;
}



.container-md {
    max-width: 881px;
    margin: 0 auto;
}

.container-lg {
    max-width: 1390px;
    margin: 0 auto;
    padding: 0 20px;
}

@media (max-width:1700px) {
    .container-md {
        max-width: 681px;
    }
}