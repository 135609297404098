/* manrope-200 - latin */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-display: swap;
  font-weight: 200;
  src: local('Manrope');
  /* url('../fonts/manrope-v4-latin-200.woff2') format('woff2'), */
  /* Chrome 26+, Opera 23+, Firefox 39+ */
  /* url('../fonts/manrope-v4-latin-200.woff') format('woff'); */
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* manrope-300 - latin */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: local('Manrope');
  /* url('../fonts/manrope-v4-latin-300.woff2') format('woff2'), */
  /* Chrome 26+, Opera 23+, Firefox 39+ */
  /* url('../fonts/manrope-v4-latin-300.woff') format('woff'); */
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* manrope-200 - latin */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-display: swap;
  font-weight: 200;
  src: local('');
  /* url('../fonts/manrope-v4-latin-200.woff2') format('woff2'), Chrome 26+, Opera 23+, Firefox 39+ */
  /* url('../fonts/manrope-v4-latin-200.woff') format('woff'); Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* manrope-300 - latin */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: local('');
  /* url('../fonts/manrope-v4-latin-300.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  /* url('../fonts/manrope-v4-latin-300.woff') format('woff'); Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* manrope-regular - latin */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: local('');
  /* url('../fonts/manrope-v4-latin-regular.woff2') format('woff2'), Chrome 26+, Opera 23+, Firefox 39+ */
  /* url('../fonts/manrope-v4-latin-regular.woff') format('woff'); Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* manrope-500 - latin */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-display: block;
  font-weight: 500;
  src: local('');
  /* url('../fonts/manrope-v4-latin-500.woff2') format('woff2'), Chrome 26+, Opera 23+, Firefox 39+ */
  /* url('../fonts/manrope-v4-latin-500.woff') format('woff'); Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* manrope-600 - latin */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: local('');
  /* url('../fonts/manrope-v4-latin-600.woff2') format('woff2'), Chrome 26+, Opera 23+, Firefox 39+ */
  /* url('../fonts/manrope-v4-latin-600.woff') format('woff'); Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* manrope-700 - latin */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: local('');
  /* url('../fonts/manrope-v4-latin-700.woff2') format('woff2'), Chrome 26+, Opera 23+, Firefox 39+ */
  /* url('../fonts/manrope-v4-latin-700.woff') format('woff'); Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* manrope-800 - latin */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-display: block;
  font-weight: 800;
  src: local('');
  /* url('../fonts/manrope-v4-latin-800.woff2') format('woff2'), Chrome 26+, Opera 23+, Firefox 39+ */
  /* url('../fonts/manrope-v4-latin-800.woff') format('woff'); Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* manrope-regular - latin */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: local('Manrope');
  /* url('../fonts/manrope-v4-latin-regular.woff2') format('woff2'), */
  /* Chrome 26+, Opera 23+, Firefox 39+ */
  /* url('../fonts/manrope-v4-latin-regular.woff') format('woff'); */
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* manrope-500 - latin */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-display: block;
  font-weight: 500;
  src: local('Manrope');
  /* url('../fonts/manrope-v4-latin-500.woff2') format('woff2'), */
  /* Chrome 26+, Opera 23+, Firefox 39+ */
  /* url('../fonts/manrope-v4-latin-500.woff') format('woff'); */
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* manrope-600 - latin */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: local('Manrope');
  /* url('../fonts/manrope-v4-latin-600.woff2') format('woff2'), */
  /* Chrome 26+, Opera 23+, Firefox 39+ */
  /* url('../fonts/manrope-v4-latin-600.woff') format('woff'); */
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* manrope-700 - latin */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: local('Manrope');
  /* url('../fonts/manrope-v4-latin-700.woff2') format('woff2'), */
  /* Chrome 26+, Opera 23+, Firefox 39+ */
  /* url('../fonts/manrope-v4-latin-700.woff') format('woff'); */
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* manrope-800 - latin */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-display: block;
  font-weight: 800;
  src: local('Manrope');
  /* url('../fonts/manrope-v4-latin-800.woff2') format('woff2'), */
  /* Chrome 26+, Opera 23+, Firefox 39+ */
  /* url('../fonts/manrope-v4-latin-800.woff') format('woff'); */
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
