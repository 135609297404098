/* ==========================
   1. GLOBAL VARIABLES
   ========================== */

:root {
  /* ============================
       1.1. Primary template colors
       ============================ */
  --primary-accent-color: #c23fff;
  --secondary-accent-color: #feda03;
  --primary-gradient-color: #6345ed;
  --secondary-gradient-color: #e139fd;
  --cryptoki-gradient: linear-gradient(to right,
      var(--primary-gradient-color) 20%,
      var(--secondary-gradient-color));
  /* ===============================
       1.2. Body background and font colors
       =============================== */
  --body-background: #fff;
  --border-color: #e1e1e1;
  --main-font-color: #252831;
  --unchangable-font-color: #252831;
  --contrast-text-color: #fff;
  --inactive-text-color: #a8a9ad;
  /* ====================================
       1.3. Footer background nand font colors
      ===================================== */
  --footer-background: #14161b;
  --footer-text-color: #fff;
  /* ==============================
       1.4. Additional colors
       ============================== */
  --light-gray: #e3e3e3;
  --lighter-gray: #f3f3f3;
  --extra-light-gray: #f9f9f9;
  --pure-black: #000;
  --fuchsia-color: #ff2654;
  --unrated-color: #c8c7d5;
  --cryptoki-blue: #1852ff;
  --cryptoki-salmon: #ff3d54;
  --ds-purple: #c23fff;
  --ds-yellow: #feda03;
  --cryptoki-box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2),
    0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
  --ds-red: #f32a63;
  --ds-green: #00bf8b;
  --btn-dark: #252831;
  --ui-templates: var(--cryptoki-blue);
  --social-graphics: var(--secondary-accent-color);
  --coded-templates: var(--primary-accent-color);
  --video-tutorials: var(--cryptoki-salmon);
  /* ===========================
       1.5. Typography sizes
       ============================*/
  --h1-heading: clamp(16px, calc(1rem + 3vw), 52px);
  --h2-heading: clamp(16px, calc(1rem + 2vw), 42px);
  --h3-heading: clamp(16px, calc(1rem + 1.5vw), 36px);
  --h4-heading: clamp(16px, calc(1rem + 1.2vw), 28px);
  --h5-heading: clamp(16px, calc(1rem + 0.75vw), 24px);
  --h6-heading: clamp(14px, calc(1rem + 0.75vw), 20px);
  --body-font-size: 14px;
  --article-font-size: 16px;
  --heading-font-weight: 800;
  --body-font-weight: 500;
  --letter-spacing: -0.04em;
  /* =========================== 
       1.6. Form authorization colors
       ============================*/
  --toggle-background-color-on: var(--cryptoki-gradient);
  --toggle-background-color-off: var(--main-font-color);
  --toggle-control-color: #fff;
  --toggle-control-border: #343c4a;
  /* ===========================
       1.7. Border-radius
       ============================*/
  --btn-border-radius: 10px;
  --input-border-radius: 12px;
}

/* .scroolbarhide{
        overflow: hidden;
        height: 100vh;
} */

.modal-content {
  background: var(--body-background);
}

.sidebarbtn {
  color: var(--main-font-color) !important;
}

/* ===========================
       1.8. Dark Mode
       ============================*/

body.dark-mode {
  --body-background: #121419;
  --main-font-color: #fff;
  --border-color: #353946;
  --light-gray: #353946;
  --lighter-gray: #20232b;
  --extra-light-gray: #20232b;
  --primary-accent-color: #00ffcc;
  --primary-gradient-color: #177afa;
  --secondary-gradient-color: #03f0d1;
  --cryptoki-gradient: linear-gradient(to right,
      var(--primary-gradient-color) 20%,
      var(--secondary-gradient-color));
  --toggle-background-color-on: var(--cryptoki-gradient);
  --cryptoki-box-shadow: 0 3px 5px -1px rgba(255, 255, 255, 0.2),
    0 6px 10px 0 rgba(255, 255, 255, 0.14),
    0 1px 18px 0 rgba(255, 255, 255, 0.12);
}

/* ===========================
       2. DEFAULTS
       ============================*/

*,
*::after,
*::before {
  box-sizing: border-box;
}

html {
  color: var(--main-font-color);
  font-size: 1em;
  line-height: 1.4;
  background-color: var(--body-background);
  /* scroll-behavior: smooth; */
  /* content-visibility: auto; */
}

::-moz-selection {
  background: var(--secondary-gradient-color);
  color: var(--contrast-text-color);
  text-shadow: none;
}

::selection {
  background: var(--secondary-gradient-color);
  color: var(--contrast-text-color);
  text-shadow: none;
}

.cryptoki-scrollbar::-webkit-scrollbar {
  width: 6px;
}

.cryptoki-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: var(--main-font-color);
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.cryptoki-scrollbar::-webkit-scrollbar-track {
  background: transparent;
}

.cryptoki-scrollbar::-webkit-scrollbar-thumb {
  background-color: var(--light-gray);
  border-radius: 6px;
}

body {
  background-color: var(--body-background);
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

img {
  max-width: 100%;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

textarea {
  resize: vertical;
}

figure {
  margin: 0;
}

a {
  text-decoration: none !important;
  color: inherit;
}

a:hover,
a:active {
  color: var(--primary-accent-color);
  fill: var(--primary-accent-color);
}

input:focus,
select:focus {
  outline: none;
}

ul,
ol {
  margin: 0;
  padding: 0;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    margin-top: 20px;
  }

  to {
    opacity: 1;
    margin-top: 0;
  }
}

/* ==============================
       3. HELPER CLASSES
       ============================== */

/* =========================
       3.1. General classes
       ========================= */

.hidden,
[hidden] {
  display: none !important;
}

.sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.sr-only.focusable:active,
.sr-only.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  white-space: inherit;
  width: auto;
}

.invisible {
  visibility: hidden;
}

.overflow-hidden {
  overflow: hidden;
}

.clearfix::before,
.clearfix::after {
  content: ' ';
  display: table;
}

.clearfix::after {
  clear: both;
}

.highlighted-item {
  font-size: 10px;
  line-height: 1;
  color: var(--contrast-text-color);
  border-radius: 30px;
  padding: 4px 7px;
  margin-left: 10px;
}

/* =========================
       3.2. Text classes
       ========================= */

.colored {
  color: var(--primary-accent-color);
}

.text-center {
  text-align: center;
}

.bold {
  font-weight: var(--heading-font-weight);
}

.truncate {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

/* =========================
       3.3. Padding classes
       ========================= */

.content-padding {
  padding-top: clamp(2rem, 4.3vmax, 70px);
  padding-bottom: clamp(2rem, 4.3vmax, 70px);
}

.section-padding {
  padding-top: clamp(1rem, 2.8vmax, 45px);
  padding-bottom: clamp(1rem, 2.8vmax, 45px);
}

.content-padding>.section-padding:first-child {
  padding-top: 0;
}

.section-padding-medium {
  padding-top: clamp(2rem, 4.5vmax, 70px);
  padding-bottom: clamp(2rem, 4.5vmax, 70px);
}

.bottom-padding-50 {
  padding-bottom: clamp(2rem, 3.12vmax, 50px);
}

.bottom-padding-70 {
  padding-bottom: clamp(2rem, 4.3vmax, 70px);
}

.no-top-padding {
  padding-top: 0 !important;
}

.padding-top-bottom {
  padding: 20px 0;
}

/* =========================
       3.4. Margin classes
       ========================= */

.margin-bottom {
  margin-bottom: 75px;
}

.negative-margin {
  margin-right: -18px;
  margin-left: -22px;
}

/* =========================
       3.5. Border classes
       ========================= */

.border-bottom {
  border-bottom: 1px solid var(--border-color);
}

.icon-border a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 34px;
  background-color: var(--body-background);
  border: 1px solid var(--border-color);
  border-radius: 30px;
  line-height: 1;
}

.icon-border a svg {
  fill: var(--main-font-color);
}

/* =========================
       3.6. Flex layout classes
       ========================= */

.flex-space-between {
  display: flex;
  justify-content: space-between;
}

.flex-space-around {
  display: flex;
  justify-content: space-around;
}

.flex-center {
  display: flex;
  justify-content: center;
}

.flex-right {
  display: flex;
  justify-content: flex-end;
}

.flex-left {
  display: flex;
  justify-content: flex-start;
}

.flex-wrap {
  flex-wrap: wrap;
}

.align-items-center {
  align-items: center;
}

/* =========================
       3.7. Grid layout classes
       ========================= */

.grid-aside-main-4-col {
  display: grid;
  grid-template-areas: 'aside main-content-area';
  grid-template-columns: 1fr 3fr;
}

.grid-left-sidebar {
  display: grid;
  grid-template-columns: 340px 3fr;
  grid-column-gap: clamp(1rem, 8vw, 140px);
}

.grid-right-sidebar {
  display: grid;
  grid-template-columns: 3fr 340px;
  grid-column-gap: clamp(1rem, 8vw, 140px);
}

.grid-right-sidebar-large {
  display: grid;
  grid-template-columns: 3fr minmax(320px, 1.5fr);
  grid-column-gap: 20px;
}

.grid-2-columns {
  display: grid;
  grid-template-columns: minmax(340px, 1fr) minmax(340px, 1fr);
  grid-gap: 20px;
}

.grid-3-columns {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  grid-gap: 20px;
}

.grid-aside-main-4-col .grid-3-columns {
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
}

.grid-4-columns {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
  grid-gap: 20px;
}

.grid-5-columns {
  display: grid;
  grid-template-columns: minmax(340px, 0fr) minmax(340px, 0fr);
  grid-gap: 20px;
}

.grid-1-columns {
  display: grid;
  grid-template-columns: minmax(300px, 0fr) minmax(300px, 0fr);
  grid-gap: 20px;
}

.grid-6-columns {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(218px, 1fr));
  grid-gap: 20px;
}

.grid-small-sidebar-left {
  display: grid;
  grid-template-columns: 260px minmax(550px, 3fr);
  grid-gap: clamp(1rem, 6.25vw, 100px);
}

@media (max-width: 1199px) {
  .grid-aside-main-4-col {
    grid-template-columns: 1fr 2fr;
  }

  .grid-1-columns {
    grid-template-columns: minmax(270px, 1fr) minmax(270px, 1fr);
  }
}

@media (max-width: 1024px) {
  .grid-aside-main-4-col .grid-4-columns {
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  }
}

@media (max-width: 800px) {
  .grid-aside-main-4-col {
    grid-template-columns: 1fr 1fr;
  }

  .grid-right-sidebar-large {
    grid-template-columns: 1fr;
    grid-column-gap: 0;
  }

  .grid-left-sidebar,
  .grid-right-sidebar {
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: clamp(1rem, 8vw, 140px);
    grid-column-gap: 0;
  }
}

@media (max-width: 600px) {
  .grid-aside-main-4-col {
    grid-template-columns: 1fr;
    grid-template-areas: none;
  }

  .grid-1-columns {
    grid-template-columns: 1fr;
    grid-column-gap: 0;
  }
}

@media (max-width: 414px) {
  .grid-3-columns {
    grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));
    grid-column-gap: 0;
  }
}

@media (max-width: 320px) {
  .grid-aside-main-4-col .grid-3-columns {
    grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));
  }

  .grid-2-columns,
  .grid-left-sidebar,
  .grid-right-sidebar {
    grid-template-columns: 290px;
  }
}

/* ==================
       3.8. Gradients
       =================== */

.gradient-border_white-bg {
  display: inline-flex;
  align-self: center;
  border-radius: var(--btn-border-radius);
  border: solid 2px transparent;
  background-image: linear-gradient(var(--body-background),
      var(--body-background)),
    linear-gradient(to right,
      var(--primary-gradient-color),
      var(--secondary-gradient-color));
  background-origin: border-box;
  background-clip: content-box, border-box;
  position: relative;
}

.gradient-border_white-bg .btn,
.gradient-border_white-bg button,
.gradient-border_white-bg .btn:hover,
.gradient-border_white-bg button:hover {
  color: var(--main-font-color);
}

.gradient-border_white-bg:after {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  background: var(--cryptoki-gradient);
  filter: blur(5px);
  border-radius: var(--btn-border-radius);
  opacity: 0;
  visibility: hidden;
  will-change: opacity;
  transition: opacity 0.3s linear;
}

.gradient-border_white-bg:hover:after {
  opacity: 1;
  visibility: visible;
}

.gradient-border_dark-bg {
  display: inline-flex;
  align-self: center;
  border-radius: var(--btn-border-radius);
  border: solid 2px transparent;
  background-image: linear-gradient(var(--footer-background),
      var(--footer-background)),
    linear-gradient(to right,
      var(--primary-gradient-color),
      var(--secondary-gradient-color));
  background-origin: border-box;
  background-clip: content-box, border-box;
  position: relative;
}

.gradient-border_dark-bg .btn,
.gradient-border_dark-bg button,
.gradient-border_dark-bg .btn:hover,
.gradient-border_dark-bg button:hover {
  color: var(--contrast-text-color);
}

.gradient-border_dark-bg:after {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  background: var(--primary-accent-color);
  filter: blur(0);
  border-radius: var(--btn-border-radius);
  will-change: filter;
  transition: all 0.2s linear;
}

.gradient-border_dark-bg:hover:after {
  filter: blur(5px);
}

.gradient-border-purple-bg {
  display: inline-flex;
  align-self: center;
  border-radius: var(--btn-border-radius);
  border: solid 2px transparent;
  background-image: linear-gradient(#473495, #51399b),
    linear-gradient(to right,
      var(--primary-gradient-color),
      var(--secondary-gradient-color));
  background-origin: border-box;
  background-clip: content-box, border-box;
  position: relative;
}

.gradient-border-purple-bg .btn,
.gradient-border-purple-bg button,
.gradient-border-purple-bg .btn:hover,
.gradient-border-purple-bg button:hover {
  color: var(--contrast-text-color);
}

.gradient-border_purple-bg:after {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  background: var(--primary-accent-color);
  filter: blur(0);
  border-radius: var(--btn-border-radius);
  will-change: filter;
  transition: all 0.2s linear;
}

.gradient-border_purple-bg:hover:after {
  filter: blur(5px);
}

.gradient-text {
  background-color: var(--main-font-color);
  background-image: var(--cryptoki-gradient);
  background-size: 100%;
  background-clip: text;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
}

.gradient-text::selection {
  color: var(--contrast-text-color);
  -webkit-text-fill-color: var(--contrast-text-color);
}

.gradient-background {
  background: var(--cryptoki-gradient);
  color: var(--contrast-text-color);
}

.gradient-background:hover {
  color: var(--contrast-text-color);
}

.orange-gradient {
  background: linear-gradient(to right, #ff7b0c, #ffed01);
}

.turquoise-gradient {
  background: linear-gradient(to right, #38abf6, #06f5b1);
}

.purple-gradient {
  background: linear-gradient(to right, #2628b1, #bc62f6);
}

.green-gradient {
  background: linear-gradient(to right, #0075a2, #00ef68);
}

.turquoise-gradient-diagonal {
  background: linear-gradient(to top right, #00f4cb, #0062fe);
}

.purple-gradient-diagonal {
  background: linear-gradient(to top right, #f137ff, #5447eb);
}

.orange-gradient-diagonal {
  background: linear-gradient(to top right, #ffe022, #ff2853);
}

.blue-gradient-diagonal {
  background: linear-gradient(to top right, #0062fe, #00f4cb);
}

.fuchsia-gradient-diagonal {
  background: linear-gradient(to top right, #5447eb, #f137ff);
}

.yellow-gradient-diagonal {
  background: linear-gradient(to top right, #ff2853, #ffe022);
}

/* =======================
       4. PRINT STYLES
       ======================= */

@media print {

  *,
  *::before,
  *::after {
    background: var(--body-background) !important;
    color: #000 !important;
    /* Black prints faster */
    box-shadow: none !important;
    text-shadow: none !important;
  }

  a,
  a:visited {
    text-decoration: underline;
  }

  a[href]::after {
    content: ' (' attr(href) ')';
  }

  abbr[title]::after {
    content: ' (' attr(title) ')';
  }

  a[href^='#']::after,
  a[href^='javascript:']::after {
    content: '';
  }

  pre {
    white-space: pre-wrap !important;
  }

  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }

  thead {
    display: table-header-group;
  }

  tr,
  img {
    page-break-inside: avoid;
  }

  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
  h3 {
    page-break-after: avoid;
  }
}

/* ==================
       5. TYPOGRAPHY
       =================== */

body {
  font-family: 'Manrope VF', Manrope, -apple-system, BlinkMacSystemFont, Roboto,
    'Helvetica Neue', sans-serif;
  font-size: var(--body-font-size);
  color: var(--main-font-color);
  line-height: 2;
  letter-spacing: var(--letter-spacing);
}

body,
input {
  font-weight: var(--body-font-weight);
  letter-spacing: var(--letter-spacing);
}

h1,
.h1 {
  font-size: var(--h1-heading);
  font-weight: var(--heading-font-weight);
  letter-spacing: var(--letter-spacing);
  margin: 0;
  line-height: 1.15em;
}

h2,
.h2 {
  font-size: var(--h2-heading);
  font-weight: var(--heading-font-weight);
  line-height: 1.15em;
  letter-spacing: var(--letter-spacing);
  margin: 0 0 0.25em;
}

h3,
.h3 {
  font-size: var(--h3-heading);
  font-weight: var(--heading-font-weight);
  line-height: 1.5em;
  letter-spacing: var(--letter-spacing);
  margin: 0 0 0.25em;
}

h4,
.h4 {
  font-size: var(--h4-heading);
  font-weight: var(--heading-font-weight);
  line-height: 1.5;
  letter-spacing: var(--letter-spacing);
  margin: 0 0 0.25em;
}

h5,
.h5 {
  font-size: var(--h5-heading);
  font-weight: var(--heading-font-weight);
  line-height: 1.5;
  letter-spacing: var(--letter-spacing);
  margin: 0 0 0.25em;
}

h6,
.h6 {
  font-size: var(--h6-heading);
  font-weight: var(--heading-font-weight);
  line-height: 1.5;
  letter-spacing: var(--letter-spacing);
  margin: 0 0 0.5em;
}

/* ==================
       6. BUTTONS
       =================== */

button {
  font-size: var(--body-font-size);
  font-weight: var(--heading-font-weight);
  border: none;
  cursor: pointer;
  background-color: transparent;
  padding: 0;
  line-height: 1;
}

.btn {
  font-size: var(--body-font-size);
  font-weight: var(--heading-font-weight);
  line-height: 1;
  border-radius: var(--btn-border-radius);
  white-space: nowrap;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  will-change: background, color, box-shadow;
  transition-property: background, color, box-shadow;
  transition-duration: 300ms;
}

.btn:hover {
  color: white;
}

.digital-store .btn:hover {
  color: var(--main-font-color);
}

.btn.gradient-background:hover {
  color: var(--contrast-text-color);
}

.btn-normal {
  padding: max(0.87vw, 12px) max(2.5vw, 30px);
}

.btn-wide {
  padding: max(0.87vw, 12px) max(4vw, 50px);
}

.btn-fullwidth {
  padding: max(0.87vw, 12px) max(2.5vw, 30px);
  width: 100%;
  text-align: center;
}

.btn-small {
  padding: 10px 14px;
  font-size: 12px;
}

.btn-small-wide {
  padding: 12px 30px;
  font-size: 12px;
}

.btn-small-fullwidth {
  padding: 12px 30px;
  font-size: 12px;
  width: 100%;
  display: block;
  text-align: center;
}

.btn-square {
  border-radius: 10px;
}

.btn-follow {
  border-radius: 30px;
}

.btn-dark {
  background-color: var(--btn-dark);
  color: white;
  border: none;
}

.btn-dark:hover {
  color: white !important;
  background: #0084ff;
  border: none;
}

.digital-store .btn-dark:hover {
  color: white;
  font-weight: var(--heading-font-weight);
}

body.dark-mode .btn-dark {
  background: var(--secondary-accent-color) var(--cryptoki-gradient);
  color: var(--contrast-text-color);
}

body.dark-mode .btn-dark:hover {
  background-image: none;
}

.btn-fuchsia {
  background-color: var(--fuchsia-color);
  color: var(--contrast-text-color);
}

/* ===========================
     6.1 Buttons Material effect
     ============================ */

.waves-effect {
  position: relative;
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  -webkit-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

.waves-effect .waves-ripple {
  position: absolute;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  margin-top: -50px;
  margin-left: -50px;
  opacity: 0;
  background: rgba(0, 0, 0, 0.2);
  background: radial-gradient(rgba(0, 0, 0, 0.2) 0,
      rgba(0, 0, 0, 0.3) 40%,
      rgba(0, 0, 0, 0.4) 50%,
      rgba(0, 0, 0, 0.5) 60%,
      rgba(255, 255, 255, 0) 70%);
  transition: all 0.5s ease-out;
  transition-property: transform, opacity;
  transform: scale(0) translate(0, 0);
  pointer-events: none;
}

.waves-effect.waves-light .waves-ripple {
  background: rgba(255, 255, 255, 0.4);
  background: radial-gradient(rgba(255, 255, 255, 0.2) 0,
      rgba(255, 255, 255, 0.3) 40%,
      rgba(255, 255, 255, 0.4) 50%,
      rgba(255, 255, 255, 0.5) 60%,
      rgba(255, 255, 255, 0) 70%);
}

.waves-float {
  mask-image: none;
  -webkit-mask-image: none;
}

.waves-float:active {
  box-shadow: var(--cryptoki-box-shadow);
}

.waves-block {
  display: block;
}

/* ==================
    7. SVG ICONS
    =================== */

svg {
  width: 16px;
  height: 16px;
}

.crumina-icon {
  fill: var(--main-font-color);
}

#cryptoki-svg-gradient {
  --color-stop-1: var(--primary-gradient-color);
  --color-stop-2: var(--secondary-gradient-color);
}

.errormsg {
  /* text-align: right; */
  margin-top: -17px;
}

.modal-body {
  padding: 0px;
}

.tk-lp-form-item .otpinput div {
  display: flex;
  justify-content: space-between;
}

.otpinput input {
  width: 3rem !important;
  height: 3rem;
  border-radius: 7px;
  caret-color: var(--tk-lp-caret-color);
  color: var(--tk-lp-input-color);
  border: 1px solid var(--border-color);
}

.otpinput input:focus {
  border-color: var(--primary-accent-color);
}

a {
  cursor: pointer;
}

.resendotp {
  cursor: pointer;
}

.facebook button div {
  background: none !important;
  background-color: none !important;
  margin: 0px !important;
  padding: 0px !important;
}

.facebook button span {
  display: none !important;
}

.facebook button {
  background: none !important;
  background-color: none !important;
  display: inline-flex;
  align-items: center;
  color: none !important;
  box-shadow: none !important;
  padding: 0px;
  border-radius: 2px;
  border: 1px solid transparent;
  font-size: 14px;
  font-weight: 500;
  font-family: Roboto, sans-serif;
  opacity: 1;
}

/* ==================
       8. GENERAL
       =================== */

/* ==================
       8.1. Body, containers
       =================== */

body::before {
  content: '';
  width: 0;
  height: 0;
  opacity: 0;
  visibility: hidden;
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  transition: visibility 0.3s linear, opacity 0.3s linear;
}

body.body-overlay::before {
  width: 100%;
  height: 100%;
  opacity: 1;
  visibility: visible;
  z-index: 99;
  transition: visibility 0.3s linear, opacity 0.3s linear;
}

.main {
  display: flex;
  flex-direction: column;
}

.container {
  width: 1480px;
  padding-left: 30px;
  padding-right: 30px;
  max-width: 100%;
  margin: 0 auto;
}

.container-1300 {
  width: 1360px;
  padding-left: 30px;
  padding-right: 30px;
  max-width: 100%;
  margin: 0 auto;
}

@media (max-width: 600px) {

  .container,
  .container-1300 {
    margin-bottom: 0px;
    padding: 0px 8px 0px 10px;
  }
}

/* ==================
       8.2. Sections
       =================== */

.section-title-wrapper,
.page-title-section {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: max(2vw, 1rem);
}

.section-large {
  width: 1200px;
  padding-right: 30px;
  padding-left: 30px;
  max-width: 100%;
  margin: 0 auto;
}

.section-medium {
  width: 1000px;
  padding-right: 30px;
  padding-left: 30px;
  max-width: 100%;
  margin: 0 auto;
}

.section-small {
  width: 760px;
  padding-right: 30px;
  padding-left: 30px;
  max-width: 100%;
  margin: 0 auto;
}

.extra-small-section {
  width: 525px;
  padding-right: 30px;
  padding-left: 30px;
  max-width: 100%;
  margin: 0 auto;
}

.section-title {
  font-size: var(--h2-heading);
  font-weight: var(--heading-font-weight);
  line-height: 1.15em;
  letter-spacing: var(--letter-spacing);
  margin: 0 0 0.25em;
}

.mySwiper2 {
  display: none;
}

.mySwiper1 {
  display: block;
}

.ulswiper1 {
  display: block;
}

.ulswiper2 {
  display: none;
}

.mySwiper123 {
  /* width: 82% !important; */
  margin-left: 0px !important;
}

@media only screen and (max-width: 1200px) {
  .mySwiper2 {
    display: block;
  }

  .mySwiper1 {
    display: none;
  }
}

@media only screen and (max-width: 600px) {
  .ulswiper2 {
    display: block !important;
  }

  .ulswiper1 {
    display: none !important;
  }
}

.page-title {
  margin-bottom: 40px;
}

.small-title {
  font-size: 16px;
  font-weight: var(--heading-font-weight);
  margin-bottom: clamp(1rem, 2vmax, 20px);
  line-height: 1.4;
  letter-spacing: var(--letter-spacing);
}

.display-mode-links svg {
  fill: var(--main-font-color);
}

@media (max-width: 600px) {

  .section-medium,
  .section-small,
  .extra-small-section {
    padding-right: 15px;
    padding-left: 15px;
  }
}

/* ================================
       8.2.1. Sections title navigation
       ================================ */

.title-navigation {
  width: 55px;
  display: flex;
  justify-content: space-between;
  position: relative;
}

.title-navigation svg {
  width: 15px;
  height: 20px;
}

.title-navigation a:hover svg {
  fill: var(--primary-accent-color);
}

.display-mode-links a {
  margin-left: 15px;
}

.display-mode-links a:hover svg {
  fill: var(--primary-accent-color);
}

.all-items-link {
  font-size: 16px;
  font-weight: var(--heading-font-weight);
  line-height: 1.6;
}

/* ================================
       8.3. Avatars
       ================================ */

/* ================================
        8.3.1. Avatar block
       ================================ */

.avatar-block {
  display: flex;
  align-items: center;
}

.avatar-block .avatar-title {
  font-size: 16px;
  font-weight: var(--heading-font-weight);
  line-height: 1.4;
  margin-bottom: 0.25em;
}

.avatar-block .avatar-title a:hover {
  color: inherit;
}

.avatar-block .avatar-meta {
  font-size: 13px;
  line-height: 1;
}

.avatar-block .avatar+.avatar-meta {
  margin-left: 10px;
}

/* ================================
       8.3.2. Avatar sizes
       ================================ */

.avatar {
  position: relative;
}

.avatar,
.avatar img {
  border-radius: 50%;
}

.avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--cryptoki-gradient);
}

.avatar a {
  line-height: 1;
}

.avatar img {
  background: var(--body-background);
}
.box-20 {
  width: 20px;
  height: 20px;
  min-width: 20px;
}

.box-26 {
  width: 26px;
  height: 26px;
  min-width: 26px;
}

.box-26 img {
  width: 22px;
  height: 22px;
  border: 1px solid var(--body-background);
}

.avatar.box-26 .verified {
  position: absolute;
  bottom: -8px;
  right: -4px;
}

.avatar.box-26 .verified .svg {
  width: 15px;
  height: 15px;
}

.box-42 {
  width: 42px;
  height: 42px;
  min-width: 42px;
}

.box-42 img {
  width: 38px;
  height: 38px;
  border: 2px solid var(--body-background);
}

.avatar.box-42 .verified {
  position: absolute;
  bottom: -2px;
  right: -4px;
  line-height: 1;
}

.avatar.box-42 .verified svg {
  width: 20px;
  height: 20px;
  padding: 2px;
}

.box-64 {
  width: 64px;
  height: 64px;
  min-width: 64px;
}

.box-64 img {
  width: 58px;
  height: 58px;
  border: 3px solid var(--body-background);
}

.avatar.box-64 .verified {
  position: absolute;
  right: -4px;
  bottom: -2px;
}

.avatar.box-64 svg {
  width: 24px;
  height: 24px;
  border: 3px solid var(--body-background);
  padding: 2px;
}

.box-152 {
  width: 152px;
  height: 152px;
  min-width: 152px;
}

.box-152 img {
  width: 112px;
  height: 112px;
  border: 6px solid var(--body-background);
}

.avatar.box-152 {
  border: 14px solid var(--body-background);
  margin-right: 0;
}

.avatar.box-152 .verified {
  position: absolute;
  bottom: -4px;
  right: -4px;
}

.avatar.box-152 svg {
  width: 40px;
  height: 40px;
  border: 6px solid var(--body-background);
  padding: 6px;
}

/* ================================
       8.4. Lists
       ================================ */

/* ================================
       8.4.1. Styled list
       ================================ */

ul {
  list-style: none;
}

ul li .bullet-icon {
  width: 8px;
  height: 8px;
  margin-right: 8px;
}

ol li::marker {
  font-weight: bold;
}

.details-list {
  list-style: none;
}

.details-list li {
  line-height: 2.75;
}

.details-list li .detail-label {
  display: inline-block;
  width: 30%;
  min-width: 105px;
}

/* ================================
       8.4.2. Social styled list
       ================================ */

.social-styled-list {
  list-style: none;
}

.social-styled-list li {
  line-height: 2.75;
}

.social-styled-list li a {
  font-weight: var(--heading-font-weight);
}

.social-styled-list li>svg {
  fill: var(--main-font-color);
}

.social-styled-list .marker {
  margin-right: 10px;
}

.social-styled-list .verified {
  margin-left: 5px;
}

.social-styled-list .verified svg {
  padding: 2px;
}

/* ================================
       9. HEADER
       ================================ */

/* ================================
        9.1. Header General
        ================================ */

.site-header {
  background-color: var(--body-background);
  position: sticky;
  top: 0;
  z-index: 20;
  background: var(--body-background);
}

@media (max-width: 800px) {
  .main:not(.digital-store) .site-header {
    position: sticky;
    top: 0;
    z-index: 100;
    background-color: var(--body-background);
  }
}

/* ================================
        9.2. Topbar
        ================================ */

.digital-store .topbar {
  background: var(--extra-light-gray);
}

.digital-store .topbar .nice-select {
  background-color: transparent;
}

.topbar-wrapper {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.topbar-wrapper>* {
  margin-right: 10px;
}

.topbar-wrapper>div:last-child {
  margin-right: 0;
}

.topbar-47 {
  height: 47px;
}

.topbar-92 {
  height: 92px;
}

@media (max-width: 1200px) {
  .digital-store .topbar {
    display: none;
  }
}

@media (min-width: 1200px) {
  .topbar-wrapper>* {
    margin-bottom: 0;
  }
}

/* ================================
        9.2.1. Topbar switchers
        ================================ */

.topbar-switchers {
  display: flex;
}

.topbar-switchers>* {
  margin-left: 15px;
}

.topbar-switchers>div:first-child {
  margin-left: 0;
}

/* ================================
        9.3. Logotype
        ================================ */

.logo .logo-link:hover {
  color: inherit;
}

.logo .logo-link,
.collection-box,
.header-user-profile {
  display: flex;
  align-items: center;
}

.new-logo {
  width: 180px !important;
  height: auto !important;
  margin-right: 10px;
}
.new-logo1 {
  width: 180px !important;
  height: auto !important;
  margin-right: 10px;
}

.logo .logo-img,
.logo .logo-img svg {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.logo .logo-text {
  font-size: 20px;
  font-weight: var(--heading-font-weight);
}

@media (max-width: 1024px) {
  .site-header .logo {
    flex-grow: 1;
  }
}

@media (max-width: 414px) {

  .topbar .logo .logo-text,
  .nav-ds .logo .logo-text {
    display: none;
  }
}

/* ================================
        9.4. Collection box
        ================================ */

.collection-box .collection-title {
  font-weight: var(--heading-font-weight);
  white-space: nowrap;
}

.collection-box>* {
  margin-right: 20px;
}

.collection-box>*:last-child {
  margin-right: 10px;
}

.collection-box .btn {
  padding: 18px 27px;
}

.count {
  margin-left: 5px;
}

/* ================================
        9.5. User activity buttons
        ================================ */

.user-activity-buttons {
  display: flex;
}

.user-activity-buttons>* {
  margin-right: 20px;
  position: relative;
}

@media (max-width: 568px) {
  .user-activity-buttons>* {
    position: static;
  }
}

.cart-button svg,
.favourites-button svg,
.notifications-button svg,
#mobile-search svg {
  fill: var(--main-font-color);
}

.cart-button:hover svg,
.favourites-button:hover svg,
.notifications-button:hover svg,
#mobile-search:hover svg {
  fill: var(--primary-accent-color);
}

/* ================================
        9.5.1. Notifications dropdown
        ================================ */

#notifications-dropdown {
  position: absolute;
  top: 40px;
  left: 50%;
  transform: translate(-50%, 0);
  padding: 25px 30px;
  background-color: var(--body-background);
  border: 1px solid var(--border-color);
  color: var(--main-font-color);
  border-radius: 25px;
  z-index: 100;
  max-width: 100vw;
  width: 360px;
}

#notifications-dropdown .title {
  font-size: var(--body-font-size);
  letter-spacing: var(--letter-spacing);
  font-weight: var(--heading-font-weight);
  text-align: left;
  margin-bottom: 30px;
}

.notifications-wrapper {
  margin-bottom: 40px;
  max-height: 250px;
}

.notifications-wrapper .notification {
  display: flex;
  /* justify-content: space-between; */
  font-size: 12px;
  letter-spacing: var(--letter-spacing);
  margin-bottom: 30px;
}

.notification:last-child {
  margin-bottom: 0;
}

.notification .thumb-box {
  width: 50px;
  height: 50px;
  min-width: 50px;
  border: 1px solid var(--border-color);
  border-radius: 15px;
  position: relative;
  margin-right: 10px;
}

.notification .thumb-box span {
  position: absolute;
  bottom: 0;
  right: 0;
  border-radius: 5px;
  font-size: 0;
}

.notification .thumb-box img {
  object-fit: cover;
  border-radius: 15px;
}

.bid-type {
  background-color: #6a52ff;
}

.purchase-type {
  background-color: #ffc000;
}

.like-type {
  background-color: #ff3a4e;
}

.review-type {
  background-color: #c23fff;
}

.comment-type {
  background-color: #ff394f;
}

.notification .notification-info {
  text-align: left;
}

.notification .notification-info .message {
  line-height: 1.4;
  margin-bottom: 5px;
}

.cryptoki-notif-target {
  display: none;
  animation: fadeInUp 0.2s;
}

.cryptoki-notif-target.active {
  display: block;
}

.cryptoki-notif-bttn {
  position: relative;
}

.cryptoki-notif-bttn.active>.crumina-icon {
  fill: var(--primary-accent-color);
}

/* ================================
        9.5.1.1. Notifications page
        ================================ */

.widget-filter-panel.notifications {
  max-width: 260px;
}

.notifications-list+.load-more_bars {
  margin-top: 75px;
}

.notifications-list .notification {
  padding: 25px 30px;
  border: 1px solid var(--border-color);
  border-radius: 25px;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  letter-spacing: var(--letter-spacing);
}

.notifications-list .notification-body {
  display: flex;
  align-items: center;
}

.notification-body .notification-info {
  font-size: 14px;
}

.notification-body .publish-date {
  font-size: 12px;
}

.notification-body .thumb-box {
  width: 82px;
  height: auto;
  min-width: 82px;
}

.notification-body .avatar+.notification-info {
  margin-left: 28px;
}

.notification .action-buttons {
  display: flex;
  align-items: center;
}

.notification .action-buttons input[type='checkbox']+label::before {
  width: 16px;
  height: 16px;
  margin-right: 0;
}

.notification .action-buttons input[type='checkbox']:checked+label::after {
  left: 4px;
  top: 4px;
}

.notification .action-buttons .cryptoki-form.checkbox {
  position: relative;
  top: 4px;
}

.notification .action-buttons button.close+.cryptoki-form.checkbox {
  margin-left: 15px;
}

.notifications-list .command-line {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 30px;
}

.notifications-list .command-line>* {
  margin-left: 20px;
  font-size: var(--body-font-size);
  font-weight: var(--heading-font-weight);
  letter-spacing: var(--letter-spacing);
}

.notifications-list .command-line>.command:first-child {
  margin-left: 0;
}

.notifications-list .command-line .command:hover,
.notifications-list .command-line .command:active {
  color: var(--primary-accent-color);
}

@media (max-width: 600px) {
  .widget-filter-panel.notifications {
    max-width: 100%;
  }

  .widget-filter-panel.notifications #filter-form {
    margin-right: 0;
  }

  .widget-filter-panel.notifications #filter-form .form-group {
    flex-direction: row;
    justify-content: flex-start;
  }

  .widget-filter-panel.notifications #filter-form .form-group .small-title {
    flex-basis: 100%;
  }

  .widget-filter-panel.notifications #filter-form .form-group .form-list-elem {
    margin-right: 10px;
  }
}

@media (max-width: 414px) {
  .notification .action-buttons {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin-left: 10px;
  }

  .notification .action-buttons button.close+.cryptoki-form.checkbox {
    margin-left: 0;
    margin-top: 10px;
  }
}

@media (max-width: 320px) {
  .notification-body .thumb-box {
    display: none;
  }
}

/* ================================
        9.5.2. Cart dropdown
        ================================ */

.cart-button {
  position: relative;
}

#cart-dropdown {
  position: absolute;
  top: 40px;
  left: 50%;
  transform: translate(-50%, 0);
  padding: 25px 30px;
  background-color: var(--body-background);
  border: 1px solid var(--border-color);
  color: var(--main-font-color);
  border-radius: 25px;
  z-index: 100;
  width: 360px;
  max-width: 100vw;
}

#cart-dropdown .cart-wrapper {
  border-bottom: 1px solid var(--border-color);
}

#cart-dropdown .title {
  font-size: var(--body-font-size);
  letter-spacing: var(--letter-spacing);
  font-weight: var(--heading-font-weight);
  text-align: left;
  margin-bottom: 30px;
}

.cart-product {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.purchase-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.purchase-info .product-info {
  display: flex;
  justify-content: space-between;
  text-align: left;
  margin-bottom: 5px;
}

.product-info .product-title,
.product-info .product-price {
  font-size: 12px;
  line-height: 1.2;
  font-weight: var(--heading-font-weight);
}

.product-info .product-price {
  margin-left: 20px;
}

.purchase-info .product-type {
  font-size: 9px;
  line-height: 1;
  text-transform: uppercase;
  font-weight: var(--heading-font-weight);
  padding: 4px 8px;
  border-radius: 20px;
  color: var(--contrast-text-color);
  letter-spacing: var(--letter-spacing);
}

.product-type.ui-templates {
  background-color: var(--ui-templates);
}

.product-type.social-graphics {
  background-color: var(--social-graphics);
  color: var(--main-font-color);
}

.digital-store .product-type.social-graphics {
  color: var(--main-font-color);
}

.product-type.coded-templates {
  background-color: var(--coded-templates);
}

.cart-product .thumb-box {
  width: 50px;
  height: 50px;
  min-width: 50px;
  border: 1px solid var(--border-color);
  border-radius: 15px;
  position: relative;
  margin-right: 10px;
}

.cart-product .thumb-box img {
  object-fit: cover;
  border-radius: 15px;
}

#cart-dropdown .total-price {
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
  border-bottom: 1px solid var(--border-color);
}

.total-price .heading {
  font-size: 12px;
}

.total-price .price {
  font-size: 12px;
  font-weight: var(--heading-font-weight);
}

.cart-buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 30px;
}

.cart-buttons a {
  line-height: 1;
}

.cart-buttons a+a {
  margin-left: 10px;
}

@media (max-width: 568px) {

  #notifications-dropdown,
  #cart-dropdown {
    top: 90px;
  }
}

@media (max-width: 320px) {

  #notifications-dropdown,
  #cart-dropdown {
    width: 300px;
  }
}

/* ================================
        9.6. Header search
        ================================ */

.searchbox {
  width: 100%;
  flex: 0 3 700px;
}

.main-search {
  position: relative;
  display: flex;
  justify-content: flex-start;
  border: 1px solid var(--border-color);
  border-radius: var(--input-border-radius);
}

.main-search .search-input,
.main-search .search-params,
.main-search .search-button {
  border: none;
  padding: 18px 20px;
  white-space: nowrap;
}

.main-search .search-button svg {
  fill: var(--main-font-color);
}

.main-search .search-input {
  flex: 0 1 100%;
  background-color: var(--body-background);
  color: var(--main-font-color);
  border-radius: 0;
}

.main-search .search-params {
  border-radius: var(--input-border-radius) 0 0 var(--input-border-radius);
  border-right: 1px solid var(--border-color);
  background-color: var(--extra-light-gray);
  color: var(--main-font-color);
}

.main-search .search-params svg {
  margin-left: 5px;
  fill: var(--main-font-color);
  transition: all ease 0.1s;
}

.main-search .search-dropdown {
  position: absolute;
  background-color: var(--extra-light-gray);
  border: 1px solid var(--border-color);
  top: calc(100% + 10px);
  left: -1px;
  width: 200px;
  z-index: 60;
  padding: 10px 0;
  border-radius: var(--input-border-radius);
  text-align: left;
  display: none;
  animation: fadeInUp 0.25s;
}

.main-search .search-dropdown li {
  white-space: initial;
  padding: 10px 20px;
  font-size: var(--body-font-size);
  line-height: 1.4;
  font-weight: var(--heading-font-weight);
}

.main-search .search-dropdown li:hover {
  background-color: var(--main-font-color);
  background-image: var(--cryptoki-gradient);
  background-size: 100%;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  cursor: pointer;
}

.main-search .search-params.active svg {
  transform: rotate(180deg);
}

.main-search .search-params.active~.search-dropdown {
  display: block;
}

@media (max-width: 1024px) {
  .searchbox {
    display: none;
  }
}

/* ================================
        9.6.1. Mobile search
        ================================ */

#mobile-search {
  display: none;
  position: relative;
}

#mobile-search-block {
  height: 0;
  position: relative;
  transition: height 0.3s linear;
  border-bottom: 0px solid var(--border-color);
}

#mobile-search-block>#mobile-search-form {
  width: 1430px;
  max-width: 100%;
  margin: 0 auto;
  position: relative;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.3s linear, opacity 0s linear;
}

#mobile-search-form input {
  width: 100%;
  outline: none;
  border: none;
  background-color: var(--body-background);
  padding: 15px 40px 15px 20px;
}

#mobile-search-form input::placeholder {
  color: var(--inactive-text-color);
}

#mobile-search-form .search-button {
  position: absolute;
  right: 20px;
  top: 15px;
  visibility: hidden;
  opacity: 0;
  transform: scale(0);
  transition: visibility 0.3s linear, opacity 0.3s linear, transform 0.3s linear;
}

#mobile-search-block.is-visible {
  height: 50px;
  border-bottom-width: 1px;
}

.nav-ds #mobile-search-block.is-visible {
  border-top: 1px solid var(--border-color);
}

#mobile-search-block.is-visible>#mobile-search-form {
  visibility: visible;
  opacity: 1;
  transition: visibility 0.3s linear 0.3s, opacity 0.3s linear 0.3s;
}

#mobile-search-block>#mobile-search-form>.search-button svg {
  fill: var(--main-font-color);
}

#mobile-search-block.is-visible>#mobile-search-form>.search-button {
  visibility: visible;
  opacity: 1;
  transform: scale(1);
  transition: visibility 0.3s linear, opacity 0.3s linear, transform 0.3s linear;
}

#mobile-search svg {
  will-change: transform;
  transform: rotate(0deg);
  transition: transform 0.3s linear;
}

#mobile-search.clicked svg {
  transform: rotate(90deg);
  transition: transform 0.3s linear;
}

@media (max-width: 1024px) {
  #mobile-search {
    display: block;
    margin-right: 20px;
  }
}

/* ================================
        9.7. Header User Profile
        ================================ */

.header-user-profile {
  position: relative;
  cursor: pointer;
}

.header-user-profile .user-meta {
  border-left: 1px solid var(--border-color);
  padding-left: 20px;
  text-align: right;
  white-space: nowrap;
}

.header-user-profile .user_name {
  font-weight: var(--heading-font-weight);
}

.header-user-profile .user_score {
  font-size: 12px;
  font-weight: 600;
  line-height: 1;
}

.header-user-profile .avatar {
  margin-left: 10px;
  position: relative;
  transform: scale(1);
  will-change: transform;
  transition: transform 0.3s linear;
}

.hidetext a {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.verified svg {
  color: white;
  background-color: #0084ff;
  border: 2px solid var(--body-background);
  border-radius: 30px;
}

.digital-store .verified svg {
  fill: var(--unchangable-font-color);
}

@media (max-width: 414px) {
  .header-user-profile .user-meta {
    display: none;
  }
}

/* ================================
        9.7.1. Profile dropdown
        ================================ */

#profile-dropdown {
  width: 240px;
  border-radius: 25px;
  border: 1px solid var(--border-color);
  position: absolute;
  right: -20px;
  top: 60px;
  background-color: var(--extra-light-gray);
  will-change: opacity, visibility;
  z-index: 100;
}

.profile-dropdown-header {
  height: 40px;
}

.profile-cover-image {
  /* background: url(../img/content/profile/profile-cover-1.png) no-repeat 0 0; */
  background-size: cover;
  border-radius: 25px 25px 0 0;
}

.profile-dropdown-body {
  padding: 30px 0;
  background-color: var(--body-background);
  border-bottom: 1px solid var(--border-color);
  border-radius: 0 0 20px 20px;
}

.profile-heading {
  padding-right: 30px;
  padding-left: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  margin-top: -45px;
}

.profile-heading .profile-avatar {
  border: 3px solid var(--body-background);
  position: relative;
  width: 32px;
  height: 32px;
}

.profile-heading .profile-avatar .verified {
  position: absolute;
  right: -2px;
  top: 8px;
}

.profile-heading .profile-avatar .verified svg {
  width: 14px;
  height: 14px;
  padding: 2px;
  border: 1px solid var(--body-background);
}

.profile-heading .profile-link a {
  font-size: 10px;
}

.profile-heading .profile-link:hover a {
  color: var(--contrast-text-color);
}

.profile-menu li svg {
  width: 25px;
  height: 14px;
  margin-right: 10px;
}

.profile-menu li {
  display: flex;
  align-items: center;
}

.profile-menu li a {
  padding: 10px 30px;
  font-size: 13px;
  font-weight: var(--heading-font-weight);
  transition: all 0.2s ease-in;
}

.profile-menu li svg {
  fill: var(--main-font-color);
}

.profile-menu li:hover {
  background-color: var(--extra-light-gray);
}

.profile-menu li:hover svg {
  fill: url('#cryptoki-svg-gradient') var(--primary-accent-color);
}

.profile-menu li:hover a {
  color: inherit;
  transform: translateX(8px);
}

.profile-dropdown-footer {
  padding: 25px 30px;
}

.mode-toggle {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mode-toggle .mode-title {
  font-size: 13px;
  font-weight: var(--heading-font-weight);
}

.mode-toggle .mode-title svg {
  width: 25px;
  height: 14px;
  margin-right: 0px;
  fill: var(--main-font-color);
}

.toggle-control {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 12px;
  user-select: none;
}

.toggle-control input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.toggle-control input:checked~.control {
  background: var(--toggle-background-color-on);
}

.toggle-control input:checked~.control::after {
  left: 16px;
  /* border: 1px solid var(--toggle-control-border); */
}

.toggle-control .control {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 30px;
  border-radius: 30px;
  background-color: var(--toggle-background-color-off);
  transition: background-color 0.15s ease-in;
}

.toggle-control .control::after {
  content: '';
  position: absolute;
  left: 2px;
  top: 2px;
  width: 12px;
  height: 12px;
  border-radius: 30px;
  background-color: var(--toggle-control-color);
  transition: left 0.15s ease-in;
}

.header-user-profile .closing-icon .closeiconshow {
  color: white;
}

.header-user-profile .closing-icon {
  color: var(--contrast-text-color);
  visibility: hidden;
  opacity: 0;
  position: absolute;
  right: 0;
  background: var(--cryptoki-gradient);
  width: 42px;
  height: 42px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: scale(0);
  transition: transform 0.3s linear;
}

.header-user-profile .closing-icon svg {
  fill: var(--contrast-text-color);
}

/* ================================
        9.7.2. Digital Store User Profile
        ================================ */

.nav-ds .header-user-profile .user_score {
  font-weight: var(--heading-font-weight);
  color: var(--primary-accent-color);
}

/* ================================
        10. PRIMARY NAVIGATION
        ================================ */

/* nav {
    position: sticky;
    top: 0;
    z-index: 20;
    background: var(--body-background);
} */

.navigation-menu {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: baseline;
  list-style: none;
  position: relative;
}

.navigation-menu>.menu-item {
  margin-right: 30px;
  display: flex;
  align-items: center;
}

.navigation-menu .menu-item:last-child {
  margin-right: 0;
}

.navigation-menu .hidden-menu svg {
  width: 14px;
  height: 8px;
  fill: var(--main-font-color);
}

.navigation-menu .hidden-menu .menu-link:hover svg {
  fill: var(--primary-accent-color);
}

.menu-item .menu-link {
  display: block;
  font-size: var(--body-font-size);
  font-weight: var(--heading-font-weight);
  line-height: 1;
  cursor: pointer;
}

.navigation-menu .menu-item .menu-link {
  padding: 25px 0;
}

.navigation-menu .menu-item .menu-link:hover,
.navigation-menu .menu-item .menu-link:active {
  background-color: var(--main-font-color);
  background-image: var(--cryptoki-gradient);
  background-size: 100%;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.navigation-menu .menu-item .menu-link:visited {
  background-color: var(--main-font-color);
  background-image: var(--cryptoki-gradient);
  background-size: 100%;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: var(--main-font-color);
}

.menu-item-has-children {
  position: relative;
}

.menu-item-has-children .menu-link .indicator {
  display: inline-flex;
}

.menu-item-has-children .menu-link .indicator svg {
  width: 8px;
  height: 8px;
  margin-left: 5px;
}

.navigation-menu .menu-item-has-children:hover>.menu-link,
.navigation-menu .menu-item-has-children:active>.menu-link,
.navigation-menu .menu-item-has-children:visited>.menu-link {
  background-color: var(--main-font-color);
  background-image: var(--cryptoki-gradient);
  background-size: 100%;
  /*noinspection CssInvalidPropertyValue*/
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.menu-item-has-children:hover>.menu-link>.indicator svg {
  fill: var(--primary-accent-color);
}

.navigation-menu>li.menu-item-has-children>.menu-link>.indicator svg {
  transition: transform 0.2s linear;
}

.navigation-menu>li.menu-item-has-children:hover>.menu-link>.indicator svg {
  transform: rotate(180deg);
}

/* ================================
        10.1. Submenu
        ================================ */

.navigation-menu .menu-item-has-children ul.submenu,
.navigation-menu ul.submenu li ul.submenu {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  left: 0;
  top: 80%;
  margin: 0;
  padding: 20px 0;
  min-width: 270px;
  list-style: none;
  background-color: var(--body-background);
  border: 1px solid var(--border-color);
  border-radius: 25px;
  z-index: 100;
  will-change: opacity, visibility;
  transition: visibility 0s linear, opacity 0.3s linear;
}

.navigation-menu ul.submenu li ul.submenu {
  left: 92%;
  top: -16px;
}

.navigation-menu .menu-item-has-children:hover>ul.submenu,
.navigation-menu ul.submenu>.menu-item-has-children:hover>ul.submenu {
  visibility: visible;
  opacity: 1;
}

.navigation-menu .submenu .menu-item .menu-link {
  font-size: var(--body-font-size);
  line-height: 1.4;
  font-weight: var(--heading-font-weight);
  padding: 10px 30px;
}

.navigation-menu ul.submenu>.menu-item-has-children>.menu-link>.indicator svg {
  transition: transform 0.2s linear;
}

.navigation-menu ul.submenu>.menu-item-has-children:hover>.menu-link>.indicator svg {
  transform: translateX(5px);
}

.submenu>.menu-item-has-children .menu-link .indicator {
  opacity: 0;
  transition: opacity 0.3s linear;
}

.submenu>.menu-item-has-children:hover>.menu-link>.indicator {
  opacity: 1;
}

/* ================================
        10.2. Megamenu
        ================================ */

.navigation-menu .megamenu {
  visibility: hidden;
  opacity: 0;
  width: 770px;
  max-width: 80vw;
  position: absolute;
  top: 80%;
  left: auto;
  right: 50%;
  transform: translate(50%, 0);
  z-index: 100;
  will-change: opacity, visibility;
  transition: visibility 0s linear, opacity 0.3s linear;
}

.navigation-menu .menu-item-has-megamenu:hover .megamenu {
  visibility: visible;
  opacity: 1;
}

.navigation-menu .megamenu .megamenu-wrapper {
  display: flex;
  justify-content: space-between;
  background-color: var(--body-background);
  border: 1px solid var(--border-color);
  border-radius: 25px;
  padding: 30px 50px;
}

.navigation-menu .megamenu .megamenu-column {
  display: flex;
  flex-direction: column;
  flex-basis: 50%;
  margin-right: clamp(2rem, 5.6vmax, 90px);
}

.navigation-menu .megamenu .megamenu-column:last-child {
  margin-right: 0;
}

.navigation-menu .megamenu-column .megamenu-title {
  font-size: var(--body-font-size);
  font-weight: var(--heading-font-weight);
  margin-bottom: 20px;
}

.navigation-menu .megamenu .menu-item .menu-link {
  font-size: 13px;
  padding: 10px 0;
}

/* ================================
        10.3. Hidden menu dropdown
        ================================ */

.hidden-menu-dropdown {
  visibility: hidden;
  opacity: 0;
  width: 270px;
  padding: 20px 0;
  background-color: var(--body-background);
  border: 1px solid var(--border-color);
  border-radius: 25px;
  position: absolute;
  z-index: 10;
  right: -200px;
  top: 50px;
  will-change: opacity, visibility;
  transition: visibility 0s linear 0.3s, opacity linear 0.3s;
}

.navigation-menu .hidden-menu:hover .hidden-menu-dropdown {
  visibility: visible;
  opacity: 1;
  transition-delay: 0s;
}

.loading .hidden-menu-dropdown {
  display: none;
}

.hidden-menu-dropdown a {
  font-size: var(--body-font-size);
  font-weight: var(--heading-font-weight);
  display: block;
  padding: 6px 30px;
}

/* ================================
        10.4. Social icons list
        ================================ */

.social-icons-list {
  list-style: none;
  display: flex;
  flex-wrap: nowrap;
}

.social-icons-list .social-icon {
  margin-right: 15px;
}

.social-icons-list .social-icon:last-child {
  margin-right: 0;
}

.social-icons-list .social-icon svg {
  fill: var(--main-font-color);
}

.social-icons-list .social-icon svg:hover,
.social-icons-list .social-icon svg:active {
  fill: var(--primary-accent-color);
}

/* ================================
        10.5. Mobile menu
        ================================ */

.burger-icon {
  display: none;
  cursor: pointer;
}

.burger-icon:hover svg {
  fill: var(--primary-accent-color);
}

.mobile-nav-panel {
  position: fixed;
  top: 0;
  left: 0;
  transform: translateX(-100%);
  background: var(--body-background);
  min-width: 240px;
  z-index: 100;
  overflow: auto;
  transition: transform 0.3s ease-in;
}

.mobile-nav-panel.open {
  transform: translateX(0);
}

.loading .mobile-nav-panel {
  display: none;
}

.mobile-nav-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 20px;
}

.mobile-nav-header .close-icon {
  visibility: hidden;
  opacity: 0;
  width: 14px;
  height: 14px;
  transform: scale(0);
  transition: transform 0.3s linear 0.3s;
}

.mobile-menu {
  background: var(--body-background);
  position: relative;
  z-index: 10;
}

.mobile-menu-logo img {
  width: 40px;
  height: 40px;
}

.mobile-nav-panel.open .mobile-nav-header .close-icon {
  visibility: visible;
  opacity: 1;
  transform: scale(1);
  transition: transform 0.3s linear 0.3s;
  cursor: pointer;
}

.mobile-nav-panel.open .mobile-nav-header .close-icon:hover {
  fill: var(--primary-accent-color);
}

.mobile-menu .menu-item-has-children>.submenu,
.mobile-menu .menu-item-has-children>.megamenu {
  width: 100%;
  background-color: var(--body-background);
  position: relative;
  max-height: 0;
  opacity: 0;
  z-index: -1;
  transition: opacity 0.2s linear, max-height 0.3s linear 0.1s;
  overflow: hidden;
}

.mobile-menu .menu-item-has-children>.submenu.show,
.mobile-menu .menu-item-has-children>.megamenu.show {
  max-height: 2500px;
  opacity: 1;
  z-index: 100;
  pointer-events: all;
  transition: opacity 0.3s linear, max-height 0.3s linear;
}

.mobile-menu .menu-item .menu-link {
  line-height: 1.4;
  padding: 8px 20px;
}

.mobile-menu .menu-item .menu-link .count {
  color: var(--primary-accent-color);
}

.mobile-menu .menu-item-has-children>ul.submenu {
  padding-left: 10px;
}

.mobile-menu .megamenu-title {
  font-weight: var(--heading-font-weight);
  padding-left: 20px;
}

.mobile-menu .megamenu .menu-item .menu-link {
  font-size: 13px;
  padding-left: 30px;
}

.mobile-nav-footer {
  position: fixed;
  top: 95vh;
  padding: 20px;
}

.mobile-nav-footer .topbar-switchers {
  margin-bottom: 25px;
}

@media (max-width: 800px) {
  nav {
    display: none;
  }

  .burger-icon {
    display: block;
  }
}

/* ================================
        10.6 Digital Store navigation
        ================================ */

.nav-ds .navigation-wrapper {
  height: 93px;
}

.nav-ds .navigation-wrapper>* {
  display: flex;
  align-items: center;
}

.nav-ds .nav-left-hand-part {
  justify-content: flex-start;
  flex-basis: 60%;
}

.nav-ds .logo {
  margin-right: 30px;
}

.nav-ds #mobile-search {
  display: block;
}

.nav-ds #mobile-search:hover svg {
  fill: var(--primary-accent-color);
}

@media (max-width: 1200px) {
  nav.nav-ds {
    display: block;
  }

  .nav-ds .navigation-menu {
    display: none;
  }

  .nav-ds .burger-icon {
    display: block;
    margin-right: 10px;
  }
}

/* ================================
        11. ELEMENTS
        ================================ */

/* ================================
       11.1.Call to action
       ================================ */

.call-to-action-box {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 40px;
  background: linear-gradient(50deg,
      var(--primary-gradient-color),
      var(--secondary-gradient-color));
  border-radius: 25px;
  padding: 66px 0 68px;
  position: relative;
  overflow: hidden;
}

.animated-img {
  position: absolute;
  top: -28px;
  left: 0;
}

.call-to-action-text {
  color: #fff;
}

.call-to-action-text .h1 {
  line-height: 1;
}

.call-to-action-text .subtitle {
  font-weight: var(--heading-font-weight);
}

.call-to-action-button {
  position: relative;
  z-index: 10;
}

@media (max-width: 1419px) {
  .call-to-action-box .animated-img {
    display: none;
  }
}

@media (max-width: 600px) {
  .call-to-action-box {
    flex-direction: column;
    text-align: center;
    padding: 36px 0 38px;
  }

  .call-to-action-box .call-to-action-text {
    margin-bottom: 10px;
  }

  .call-to-action-button {
    margin-top: 20px;
  }
}

/* ================================
       11.2. Filterable bar
       ================================ */

.filterable-bar {
  padding: 22px 30px;
  border: 1px solid var(--border-color);
  border-radius: 15px;
  margin-bottom: 30px;
}

.filterable-bar .filter-item {
  margin-right: 15px;
}

.filterable-bar select {
  margin-right: 15px;
}

.filter-item button {
  padding: 18px 24px;
}

.filter-item svg {
  width: 8px;
  height: 5px;
  margin-left: 60px;
}

@media (max-width: 320px) {
  .filterable-bar {
    padding: 20px;
  }
}

/* ================================
      11.3. Info Box
      ================================ */

.info-box {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: clamp(1rem, 4vmax, 100px);
}

.info-item .title {
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .info-box {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 414px) {
  .info-box {
    grid-template-columns: 1fr;
    grid-column-gap: 0;
  }
}

/* ================================
       11.4. Tabs
       ================================ */

.tabs-list {
  list-style: none;
  display: flex;
  border-bottom: 1px solid var(--border-color);
}

.tabs-list li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 35px;
  opacity: 0.5;
}

.tabs-list li .count {
  color: var(--primary-accent-color);
  font-weight: var(--heading-font-weight);
}

.tabs-list li a {
  font-size: 16px;
  font-weight: var(--heading-font-weight);
  letter-spacing: var(--letter-spacing);
  display: block;
  padding: 14px 0;
  white-space: nowrap;
  border-bottom: 2px solid transparent;
  cursor: pointer;
  }
  
  .tabs-list li div {
    font-size: 16px;
    font-weight: var(--heading-font-weight);
    letter-spacing: var(--letter-spacing);
    display: block;
    padding: 14px 0;
    white-space: nowrap;
    border-bottom: 2px solid transparent;
}

.tabs-list li.active {
  color: var(--main-font-color);
  opacity: 1;
}

.tabs-list li.active a {
  border-bottom: 2px solid var(--main-font-color);
}
.tabs-list li.active div {
  border-bottom: 2px solid var(--main-font-color);
}

.tab-content {
  padding-top: 40px;
}

.tab {
  display: none;
}

.tab.active {
  display: block;
}

.tabs-block.swiper-container {
  position: relative;
}

.tabs-block li.swiper-slide {
  width: auto;
}

.tabs-block .swiper-nav,
.tabs-block .swiper-nav .swiper-button-next,
.tabs-block .swiper-nav .swiper-button-prev {
  display: none;
}

.tabs-block .swiper-nav .swiper-button-next .next-button,
.tabs-block .swiper-nav .swiper-button-prev .prev-button {
  width: 8px;
  height: 12px;
  fill: var(--main-font-color);
}

.tabs-block .swipper-nav .swiper-button-next:hover svg,
.tabs-block .swipper-nav .swiper-button-prev:hover svg {
  fill: var(--primary-accent-color);
}

.tabs-block .swiper-nav .swiper-button-prev::before,
.tabs-block .swiper-nav .swiper-button-next::before,
.tabs-block .swiper-nav .swiper-button-prev::after,
.tabs-block .swiper-nav .swiper-button-next::after {
  content: '';
}

@media (max-width: 800px) {
  .tabs-block {
    position: relative;
  }

  .tabs-block.swiper-container .swiper-nav {
    display: flex;
    justify-content: center;
    position: absolute;
    width: 800px;
    max-width: 100%;
  }

  .tabs-block.swiper-container .swiper-nav>* {
    background-color: var(--body-background);
    z-index: 10;
  }

  .tabs-list {
    border-bottom: none;
  }

  .tabs-list li {
    justify-content: center;
    border-bottom: 1px solid var(--border-color);
  }

  .tabs-block.swiper-container .tabs-list li.swiper-slide {
    width: auto;
    opacity: 0;
    visibility: hidden;
    transition: visibility 0s linear 0s, opacity 0.3s linear;
  }

  .tabs-block.swiper-container .tabs-list li.swiper-slide-visible {
    opacity: 1;
    visibility: visible;
  }

  .tabs-block.swiper-container .tabs-list li.swiper-slide {
    margin-right: 0;
  }

  .tab-content {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (max-width: 1024px) {
  .product-version-1 .product-media {
    padding-top: 0;
  }
}

/* ================================
       11.5. Accordion
       ================================ */

.accordion-card {
  padding: clamp(1rem, 2vmax, 30px);
  border: 1px solid var(--border-color);
  border-radius: 25px;
  margin-bottom: 15px;
}

.card-header .card-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.card-header .card-title {
  font-size: 16px;
  font-weight: var(--heading-font-weight);
  line-height: 1.4;
}

.accordion-card .card-info {
  margin-top: 45px;
}

.card-header .icon-box {
  position: relative;
}

.icon-box .plus-icon.show {
  opacity: 1;
  visibility: visible;
  transform: rotate(90deg);
  transition: transform 0.3s linear;
}

.icon-box .plus-icon {
  transform: rotate(0deg);
  transition: transform 0.3s linear;
}

.icon-box .minus-icon {
  opacity: 1;
  visibility: visible;
  transform: rotate(0deg);
  transition: transform 0.3s linear;
}

.card-body {
  overflow: hidden;
  opacity: 1;
  visibility: hidden;
  line-height: 1.6;
  font-size: 1rem;
  font-weight: 500;
  max-height: 0;
  transition: visibility 0.5s, padding 0.5s, max-height 0.5s;
}

.card-body.open {
  visibility: visible;
}

/* ================================
       11.6. Pagination
       ================================ */

.pagination-section {
  padding-top: clamp(2rem, 3.1vmax, 80px);
  padding-bottom: clamp(1rem, 2.5vmax, 40px);
}

.pagination-list {
  display: flex;
  list-style: none;
  font-weight: var(--heading-font-weight);
}

.pagination-list .page-nav-prev {
  display: none;
  margin-right: 22px;
}

.pagination-list .page-item {
  margin-right: 22px;
}

.pagination-list .page-item:last-child {
  margin-right: 0;
}

/* ================================
       11.7. Before footer
       ================================ */

.before-footer-block {
  display: flex;
  padding-top: clamp(1rem, 4vmax, 100px);
}

.before-footer-image {
  flex: 0 1 240px;
}

.before-footer-image img {
  object-fit: cover;
  width: 100%;
  height: auto;
}

@media (max-width: 767px) {
  .before-footer-block {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  }
}

/* ================================
       11.8. Wallet
       ================================ */

.wallet-wrapper .section-title {
  margin-bottom: 80px;
}

.wallet-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 40px;
  margin-bottom: 40px;
}

.wallet-item {
  border: 1px solid var(--border-color);
  border-radius: 25px;
  position: relative;
  padding-top: 60px;
}

.wallet-icon {
  position: absolute;
  top: -20px;
  left: 50%;
  transform: translate(-50%, 0);
  width: 90px;
  height: 60px;
  background: var(--body-background);
  border: 1px solid var(--border-color);
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.wallet-item .wallet-info {
  padding: 35px;
  text-align: center;
  background: var(--body-background);
  border-top: 1px solid var(--border-color);
  border-radius: 23px;
}

.wallet-item .wallet-info .title {
  margin-bottom: 10px;
}

.wallet-item .wallet-info a {
  color: var(--primary-accent-color);
}

.notification-text {
  font-size: 16px;
  font-weight: var(--heading-font-weight);
}

.notification-text a {
  color: var(--primary-accent-color);
}

@media (max-width: 567px) {
  .wallet-grid {
    grid-template-columns: 1fr;
    grid-column-gap: 0;
  }
}

/* ================================
       11.9. Become seller block
       ================================ */

.become-seller-block {
  display: flex;
  justify-content: center;
  align-items: center;
}

.become-seller-block img {
  margin-right: max(3.75vw, 30px);
  width: 170px;
  height: auto;
}

.become-seller-block .subtitle {
  font-size: var(--body-font-size);
  font-weight: var(--heading-font-weight);
  letter-spacing: var(--letter-spacing);
  text-transform: uppercase;
}

.become-seller-block .title {
  font-size: var(--h2-heading);
  font-weight: var(--heading-font-weight);
  letter-spacing: var(--letter-spacing);
  line-height: 1;
}

@media (max-width: 375px) {
  .become-seller-block {
    flex-direction: column;
  }

  .become-seller-block img {
    margin-right: 0;
    margin-bottom: 20px;
  }
}

/* ================================
       11.10. Fullwidth banner
       ================================ */

.fullwidth-banner {
  /* background: var(--cryptoki-gradient); */
  height: 540px;
  max-height: 70vw;
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 70px;
}

.banner-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top left;
}

.search-form-with-text {
  z-index: 1;
}

.search-form-with-text .text-content {
  text-align: center;
  margin-bottom: max(3.75vw, 30px);
}

.search-form-with-text {
  padding: 20px;
}

.search-form-with-text .subtitle {
  font-size: var(--body-font-size);
  font-weight: var(--heading-font-weight);
  letter-spacing: var(--letter-spacing);
  color: var(--contrast-text-color);
}

.search-form-with-text .title {
  font-size: clamp(2rem, 3.8vmax, 62px);
  font-weight: var(--heading-font-weight);
  letter-spacing: var(--letter-spacing);
  color: var(--contrast-text-color);
  line-height: 1;
}

.search-form-with-text .main-search {
  border-radius: 10px;
  border: none;
}

.search-form-with-text .main-search .search-params {
  display: flex;
  align-items: center;
  border-radius: 10px 0 0 10px;
}

.search-form-with-text .main-search .search-params .search-dropdown {
  border-radius: 10px;
}

.search-form-with-text .main-search .search-button {
  background-color: var(--main-font-color);
  border-radius: 0 10px 10px 0;
}

body.dark-mode .search-form-with-text .main-search .search-button {
  background-color: var(--btn-dark);
}

.search-form-with-text .main-search .search-button svg {
  fill: var(--contrast-text-color);
}

@media (max-width: 767px) {
  .search-form-with-text .main-search .search-params .button-text {
    display: none;
  }
}

@media (max-width: 320px) {
  .search-form-with-text .main-search .search-input {
    width: 100px;
  }
}

/* ================================
       11.11. Load More Bars
       ================================ */

.load-more_bars {
  display: flex;
  justify-content: center;
  height: 40px;
}

.load-more_bars .load-more_bar {
  width: 4px;
  height: 100%;
  margin-right: 4px;
  border-radius: 200px;
  -webkit-transform: scaleY(0.2);
  transform: scaleY(0.2);
  -webkit-animation: load-more_bars 0.5s infinite alternate ease-in;
  animation: load-more_bars 0.5s infinite alternate ease-in;
}

.load-more_bars .load-more_bar:last-child {
  margin-right: 0;
}

.load-more_bars .load-more_bar:nth-child(1) {
  background-color: #5346eb;
}

.digital-store .load-more_bars .load-more_bar:nth-child(1) {
  background-color: #5346eb;
}

.load-more_bars .load-more_bar:nth-child(2) {
  background-color: #6545ee;
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}

.digital-store .load-more_bars .load-more_bar:nth-child(2) {
  background-color: #6545ee;
}

.load-more_bars .load-more_bar:nth-child(3) {
  background-color: #8242f1;
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

.digital-store .load-more_bars .load-more_bar:nth-child(3) {
  background-color: #8242f1;
}

.load-more_bars .load-more_bar:nth-child(4) {
  background-color: #ac3ef5;
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

.digital-store .load-more_bars .load-more_bar:nth-child(4) {
  background-color: #ac3ef5;
}

.load-more_bars .load-more_bar:nth-child(5) {
  background-color: #b43df7;
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.digital-store .load-more_bars .load-more_bar:nth-child(5) {
  background-color: #b43df7;
}

.load-more_bars .load-more_bar:nth-child(6) {
  background-color: #cd3afa;
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

.digital-store .load-more_bars .load-more_bar:nth-child(6) {
  background-color: #cd3afa;
}

.load-more_bars .load-more_bar:nth-child(7) {
  background-color: #dd39fc;
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

.digital-store .load-more_bars .load-more_bar:nth-child(7) {
  background-color: #dd39fc;
}

.load-more_bars .load-more_bar:nth-child(8) {
  background-color: #f337fe;
  -webkit-animation-delay: 0.7s;
  animation-delay: 0.7s;
}

.digital-store .load-more_bars .load-more_bar:nth-child(8) {
  background-color: #f337fe;
}

@-webkit-keyframes load-more_bars {
  0% {
    -webkit-transform: scaleY(0.2);
    transform: scaleY(0.2);
  }

  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }
}

@keyframes load-more_bars {
  0% {
    -webkit-transform: scaleY(0.2);
    transform: scaleY(0.2);
  }

  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }
}

/* ================================
       12. CRYPTOKI FORM
       ================================ */

.form-title {
  font-size: 24px;
  line-height: 1.2;
  font-weight: var(--heading-font-weight);
  margin-bottom: 20px;
}

.form-list-wrapper {
  display: inline-flex;
  flex-flow: column nowrap;
}

.form-group {
  margin-bottom: 40px;
}

.form-group:last-child {
  margin-bottom: 0;
}

.form-list-elem {
  font-weight: var(--heading-font-weight);
  line-height: 1;
  margin-bottom: 20px;
}

.form-list-elem:last-child {
  margin-bottom: 0;
}

.cryptoki-form input,
.cryptoki-form textarea {
  font-size: var(--body-font-size);
  font-weight: var(--heading-font-weight);
  background-color: var(--body-background);
  color: var(--main-font-color);
}

.cryptoki-form input[type='text'],
.cryptoki-form input[type='number'],
.cryptoki-form input[type='email'],
.cryptoki-form input[type='button'],
.cryptoki-form input[type='password'],
.cryptoki-form input[type='url'],
.cryptoki-form input[type='submit'],
.cryptoki-form input[type='reset'],
.cryptoki-form select,
.cryptoki-form .select-nice {
  padding: max(0.87vw, 12px) max(1.5vw, 18px);
  border: 1px solid var(--border-color);
  background-color: var(--body-background);
  color: var(--main-font-color);
  border-radius: var(--input-border-radius);
  margin-bottom: 20px;
  position: relative;
}

.cryptoki-form input[type='button'],
.cryptoki-form input[type='submit'] {
  cursor: pointer;
}

.cryptoki-form input:focus,
.cryptoki-form select:focus,
.cryptoki-form textarea:focus {
  outline: none;
  border: 1px solid var(--primary-accent-color);
}

.cryptoki-form .price-range {
  display: flex;
  justify-content: space-between;
}

.cryptoki-form .price-range input {
  float: left;
  width: 48%;
  margin-right: 15px;
}

.cryptoki-form .price-range input:last-child {
  margin-right: 0;
}

.cryptoki-form input[type='button'],
.cryptoki-form button {
  background-color: var(--main-font-color);
  color: var(--contrast-text-color);
}

.cryptoki-form input[type='reset'] {
  border: none;
  color: var(--inactive-text-color);
  padding: 0;
  margin: 0;
}

.cryptoki-form textarea {
  padding: max(1vw, 15px) max(1.5vw, 18px);
  border: 1px solid var(--border-color);
  border-radius: 10px;
}

.digital-store .cryptoki-form textarea {
  border-radius: 15px;
}

.cryptoki-form .form-group {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.cryptoki-form .form-field {
  display: flex;
  flex-direction: column;
  /* width: 100%; */
  margin-bottom: 10px;
}

.cryptoki-form .form-group>.form-field {
  margin-bottom: 0;
}

.cryptoki-form .form-group:last-of-type>.form-field input {
  margin-bottom: 0;
}

.cryptoki-form .form-group:last-of-type>.form-field label {
  margin-top: 20px;
}

.cryptoki-form input::placeholder {
  color: var(--inactive-text-color);
}

.form-field:only-child,
.form-field:last-child {
  margin-right: 0;
}

.cryptoki-form .form-field label,
.cryptoki-form .label {
  font-size: var(--body-font-size);
  font-weight: var(--heading-font-weight);
  text-transform: capitalize;
  margin-bottom: 15px;
  line-height: 1;
}

.cryptoki-form .form-field+button,
.cryptoki-form .form-group+button,
.cryptoki-form .form-field+input[type='button'],
.cryptoki-form .form-group+input[type='button'],
.cryptoki-form .form-field+input[type='submit'],
.cryptoki-form .form-group+input[type='submit'] {
  margin-top: 30px;
}

.cryptoki-form .register-notice {
  margin-top: 10px;
}

@media (max-width: 1024px) {
  .is-two-col-element .form-list-wrapper {
    margin-bottom: 20px;
  }

  .cryptoki-form .form-group {
    flex-wrap: wrap;
  }

  .form-group>.form-field {
    margin-right: 0;
  }
}

/* ================================
       12.1. Cryptoki select
       ================================ */

select {
  appearance: none;
  display: inline-block;
  /* background-image: url(../svg/arrow-down.svg); */
  background-position: calc(100% - 20px) center;
  background-repeat: no-repeat;
  background-size: 8px 8px;
  border: 1px solid var(--border-color);
  border-radius: var(--btn-border-radius);
  padding: max(1vw, 12px) max(1.5vw, 40px) max(1vw, 12px) max(1.5vw, 20px);
  font-weight: var(--heading-font-weight);
  line-height: 1.2;
}

select::before {
  /* content: url(../svg/arrow-down.svg); */
  width: 8px;
  height: 8px;
}

.cryptoki-form select {
  position: relative;
  width: 100%;
  cursor: pointer;
  font-weight: var(--heading-font-weight);
}

/* ================================
       12.2. Cryptoki checkbox
       ================================ */

.cryptoki-form input[type='checkbox'] {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.cryptoki-form input[type='checkbox']+label {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  position: relative;
}

.cryptoki-form input[type='checkbox']+label::before {
  content: '';
  width: 1em;
  height: 1em;
  flex-grow: 0;
  flex-shrink: 0;
  background-color: var(--lighter-gray);
  border: 1px solid var(--border-color);
  border-radius: 0.4em;
  margin-right: 0.5em;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
}

.cryptoki-form input[type='checkbox']:checked+label::before {
  background: var(--cryptoki-gradient);
  border: none;
}

.cryptoki-form input[type='checkbox']:checked+label::after {
  content: '';
  width: 8px;
  height: 8px;
  position: absolute;
  left: 3px;
  top: 3px;
  /* background-image: url(../svg/cross.svg); */
}

/* ================================
       12.3. Cryptoki radio
       ================================ */

.cryptoki-form input[type='radio'] {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.cryptoki-form input[type='radio']+label {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  position: relative;
}

.cryptoki-form input[type='radio']+label::before {
  content: '';
  width: 14px;
  height: 14px;
  flex-grow: 0;
  flex-shrink: 0;
  background-color: var(--lighter-gray);
  border: 1px solid var(--border-color);
  border-radius: 1em;
  margin-right: 0.5em;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
}

.cryptoki-form input[type='radio']:checked+label::before {
  background: var(--cryptoki-gradient);
  border: none;
}

.cryptoki-form input[type='radio']:checked+label::after {
  content: '';
  width: 6px;
  height: 6px;
  position: absolute;
  left: 4px;
  /* background-image: url(../svg/dot.svg); */
}

/* ================================
       12.4. Cryptoki input file
       ================================ */

.cryptoki-form .file-uploader {
  width: 100%;
  height: 180px;
  border: 1px dashed var(--border-color);
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.cryptoki-form .file-uploader>* {
  margin-bottom: 15px;
}

.cryptoki-form .file-uploader label {
  font-size: var(--body-font-size);
  font-weight: var(--heading-font-weight);
  line-height: 1;
  border-radius: var(--input-border-radius);
  padding: max(0.87vw, 12px) max(2.5vw, 30px);
  background-color: var(--main-font-color);
  color: var(--contrast-text-color);
  cursor: pointer;
}

.digital-store .file-uploader label:hover {
  color: var(--contrast-text-color);
}

.cryptoki-form #upload-media {
  position: absolute;
  opacity: 0;
  z-index: -1;
}

/* ================================
       12.5. Comment form
       ================================ */

#comment-form .btn {
  float: right;
}

/* ================================
       12.6. Contact form
       ================================ */

#contact-form .btn {
  padding: max(0.87vw, 12px) max(4.3vw, 50px);
  float: right;
}

/* Hide recaptcha v3 Bage */

.grecaptcha-badge {
  visibility: hidden;
}

/* ================================
       12.7. Filter forms
       ================================ */

#filter-form {
  padding: 30px;
  border: 1px solid var(--border-color);
  border-radius: 20px;
  margin-right: 20px;
}

#filter-form input[type='submit'],
#filter-form input[type='reset'] {
  width: 100%;
  font-weight: var(--heading-font-weight);
}

#filter-form input[type='submit'] {
  background: var(--main-font-color);
  color: var(--contrast-text-color);
  margin-top: 0;
}

#filter-form input[type='submit']:active,
#filter-form input[type='submit']:visited,
#filter-form input[type='submit']:focus {
  border: none;
}

body.dark-mode #filter-form input[type='submit'] {
  background: var(--cryptoki-gradient);
  color: var(--contrast-text-color);
}

#filter-form .form-group {
  flex-direction: column;
  margin-bottom: 40px;
}

#filter-form input[type='number'] {
  margin-bottom: 0;
}

@media (max-width: 1024px) {
  #filter-form .is-two-col-element {
    flex-wrap: wrap;
  }

  #filter-form .form-group {
    margin-bottom: 20px;
  }
}

/* ================================
       12.7.1. Creators form
       ================================ */

#creators-filter-form {
  display: flex;
  justify-content: space-between;
}

#creators-filter-form .filter-options>* {
  margin-right: 15px;
  margin-left: 0;
}

#creators-filter-form .filter-options>div:last-child {
  margin-right: 0;
}

@media (max-width: 800px) {
  #creators-filter-form {
    flex-direction: column;
  }

  #creators-filter-form .filter-options>* {
    margin-bottom: 10px;
  }
}

/* ================================
       12.7.2. Artworks form
       ================================ */

#artworks-filter-form {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media (max-width: 1024px) {
  #artworks-filter-form {
    justify-content: flex-start;
  }

  #artworks-filter-form .filter-item {
    margin-bottom: 10px;
  }
}

/* ================================
       13. SLIDERS
       ================================ */

/* ================================
       13.1. Home slider
       ================================ */

.swiper {
  width: 100%;
  height: 100%;
}

#homeSlider {
  max-height: 640px;
  margin-bottom: 20px;
}

#homeSlider .swiper-slide {
  background: var(--body-background);
  background-size: cover;
  border-radius: 30px;
  position: relative;
  width: 940px;
  height: 642px !important;
  max-width: 100vw;
}

#homeSlider .swiper-slide img {
  display: block;
  max-width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 30px;
}

#homeSlider .dark-overlay {
  width: 100%;
  height: 100%;
  /* background: url(../img/main-slider_dark-gradient.html) no-repeat 0 100%; */
  border-radius: 30px;
  position: absolute;
}

.slider-title {
  font-size: clamp(2rem, 3.8vmax, 62px);
  line-height: 1;
  font-weight: var(--heading-font-weight);
  color: var(--contrast-text-color);
  letter-spacing: var(--letter-spacing);
  padding-bottom: clamp(1rem, 2.8vmax, 45px);
  width: 90%;
}

.slider-overlay {
  width: 99.2%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgb(0, 0, 1));
  /* background: linear-gradient(90deg, rgba(2, 0, 36, 1) 0%, rgba(9, 9, 121, 1) 35%); */
  border-radius: 30px;
  position: absolute;
}

.slider-description {
  font-size: var(--body-font-size);
  text-transform: uppercase;
  font-weight: var(--heading-font-weight);
  color: var(--contrast-text-color);
  letter-spacing: var(--letter-spacing);
}

#homeSlider .slide-content .slide-title {
  font-size: clamp(2rem, 3.8vmax, 62px);
  line-height: 1;
  font-weight: var(--heading-font-weight);
  color: var(--contrast-text-color);
  letter-spacing: var(--letter-spacing);
}

#homeSlider .slide-content .slide-subtitle {
  font-size: var(--body-font-size);
  text-transform: uppercase;
  font-weight: var(--heading-font-weight);
  color: var(--contrast-text-color);
  letter-spacing: var(--letter-spacing);
}

#homeSlider .slide-content .slide-title+button,
#homeSlider .slide-content .slide-title+.slide-button {
  margin-top: 30px;
}

#homeSlider .gradient-border_white-bg.slide-button:hover,
#homeSlider .gradient-border_dark-bg.slide-button:hover,
#homeSlider .gradient-border-purple-bg.slide-button:hover {
  background: var(--cryptoki-gradient);
  background-origin: border-box;
}

#homeSlider .gradient-border_white-bg.slide-button:hover a {
  color: var(--contrast-text-color);
}

#homeSlider .swiper-slide {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
}

#homeSlider .swiper-slide>img {
  position: relative;
}

#homeSlider .swiper-slide .slide-content {
  /* position: absolute;
    bottom: 0;
    left: 0; */
  padding: clamp(2rem, 4.3vmax, 70px);
  width: 100%;
  height: auto;
}

#homeSlider .slide-content-align-right .slide-content {
  width: 60%;
}

#homeSlider .swiper-slide .item-bid-section {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  color: var(--contrast-text-color);
  margin-top: 20px;
}

#homeSlider .swiper-slide .item-bid-section>* {
  margin-right: clamp(1rem, 2.5vmax, 40px);
}

#homeSlider .swiper-slide .item-bid-section>div:last-child {
  margin-right: 0;
}

.item-bid-section .value {
  font-size: clamp(1rem, 2vmax, 32px);
  font-weight: var(--heading-font-weight);
  line-height: 1.2;
  letter-spacing: var(--letter-spacing);
}

.item-bid-section .label {
  text-transform: uppercase;
}

.item-info .item-meta {
  display: inline-flex;
  background-color: var(--body-background);
  padding: 3px 6px;
  border-radius: 30px;
  margin-bottom: 20px;
}

.homeslider-nav {
  width: 940px;
  height: 50px;
  max-width: 100vw;
  position: absolute;
  left: 50%;
  bottom: 50%;
  transform: translate(-50%, 50%);
  z-index: 10;
}

.homeslider-nav .swiper-button-next,
.homeslider-nav .swiper-button-prev {
  background-color: var(--body-background);
  width: 46px;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--border-color);
  border-radius: var(--btn-border-radius);
  cursor: pointer;
  position: absolute;
  top: 50%;
}

.homeslider-nav .swiper-button-prev {
  left: -12px;
  right: auto;
}

.homeslider-nav .swiper-button-next {
  right: -12px;
  left: auto;
}

.homeslider-nav .swiper-button-next::after,
.homeslider-nav .swiper-button-prev::after {
  content: '';
}

.homeslider-nav .swiper-button-next .next-button,
.homeslider-nav .swiper-button-prev .prev-button {
  width: 4px;
  height: 8px;
}

.homeslider-nav .swiper-button-next svg,
.homeslider-nav .swiper-button-prev svg {
  fill: var(--main-font-color);
}

.homeslider-nav .swiper-button-next:hover svg,
.homeslider-nav .swiper-button-prev:hover svg {
  fill: var(--primary-accent-color);
}

@media (max-width: 996px) {
  .homeslider-nav .swiper-button-prev {
    left: 10px;
    right: auto;
  }

  .homeslider-nav .swiper-button-next {
    right: 10px;
    left: auto;
  }
}

@media (max-width: 600px) {
  #homeSlider .homeslider-nav {
    display: none;
  }
}

@media (max-width: 414px) {
  #homeSlider .slide-content-align-right .slide-content {
    width: 100%;
  }

  #homeSlider .swiper-slide .item-bid-section>div:last-child {
    margin-top: 10px;
  }

  #homeSlider .swiper-slide .item-bid-section {
    margin-top: 10px;
  }
}

@media (max-width: 320px) {
  #homeSlider .item-info .item-meta {
    display: none;
  }
}

/* ================================
       13.2. Featured items carousel
       ================================ */

.featured-carousel {
  position: relative;
  width: 1420px;
  max-width: 100%;
  height: 40vh;
  margin: 0 auto;
  overflow: hidden;
}

@media only screen and (max-width: 600px) {
  .featured-carousel {
    height: 60vh;
  }

  .footer-wrapper {
    margin-top: 100px !important;
  }
}

.title-navigation .swiper-button-prev {
  left: auto;
  right: 40px;
  top: -10px;
}

.title-navigation .swiper-button-next {
  right: 0;
  left: auto;
  top: -10px;
}

.title-navigation .swiper-button-prev svg,
.title-navigation .swiper-button-next svg {
  fill: var(--main-font-color);
}

.title-navigation .swiper-button-prev:hover svg,
.title-navigation .swiper-button-next:hover svg {
  fill: var(--primary-accent-color);
}

.title-navigation .swiper-button-prev::after,
.title-navigation .swiper-button-next::after {
  content: '';
}

/* ================================
       13.3. Categories bar carousel
       ================================ */

.categories-nav .swiper-button-prev::after,
.categories-nav .swiper-button-next::after {
  content: '';
}

.categories-nav .swiper-button-prev {
  left: 0;
}

.categories-nav .swiper-button-next {
  right: 0;
}

.categories-nav .arrow {
  display: flex;
  align-items: center;
  width: 10px;
  height: 42px;
  background: var(--body-background);
  z-index: 10;
  fill: var(--main-font-color);
}

.categories-nav .arrow:hover svg {
  fill: var(--primary-accent-color);
}

.categories-nav .arrow-left {
  justify-content: flex-start;
  box-shadow: var(--body-background) 5px 0px 10px 15px;
}

.categories-nav .arrow-right {
  justify-content: flex-end;
  box-shadow: var(--body-background) -5px 0px 10px 15px;
}

.categories-nav svg {
  width: 7px;
  height: 12px;
}

.categories-bar {
  position: relative;
  overflow: hidden;
  margin-bottom: 30px;
}

.category-item {
  display: inline-flex;
  font-size: 13px;
  font-weight: var(--heading-font-weight);
  border: 1px solid var(--border-color);
  border-radius: var(--btn-border-radius);
  margin-right: 14px;
  white-space: nowrap;
  flex-basis: 160px;
}

.category-item a {
  display: flex;
  align-items: center;
  padding: 9px 16px;
}

.category-item svg {
  margin-right: 7px;
}

.category-item:hover {
  display: inline-flex;
  align-self: center;
  border-radius: var(--btn-border-radius);
  border: solid 1px transparent;
  background-image: linear-gradient(var(--body-background),
      var(--body-background)),
    linear-gradient(to right,
      var(--primary-gradient-color),
      var(--secondary-gradient-color));
  background-origin: border-box;
  background-clip: content-box, border-box;
}

.category-item:hover a {
  color: inherit;
}

/* ================================
       13.4. Gallery carousel
       ================================ */

.gallery-carousel {
  overflow: hidden;
}

.gallery-carousel .swiper-button-prev {
  left: 0;
  right: auto;
}

.gallery-carousel .swiper-button-next {
  right: 0;
  left: auto;
}

.gallery-carousel .swiper-button-prev:hover svg,
.gallery-carousel .swiper-button-next:hover svg {
  fill: var(--primary-accent-color);
}

.gallery-carousel .swiper-button-prev::after,
.gallery-carousel .swiper-button-next::after {
  content: '';
}

/* ================================
       13.5. Social icons carousel
       ================================ */

.social-icons-carousel {
  position: relative;
  overflow: hidden;
}

.social-icons-carousel .swiper-wrapper {
  margin: 0 auto 30px;
}

.social-icons-carousel .swiper-slide {
  width: auto;
  opacity: 0;
  visibility: hidden;
  transition: visibility 0s linear 0s, opacity 0.3s linear;
}

.social-icons-carousel .swiper-slide-visible {
  opacity: 1;
  visibility: visible;
}

.social-swiper-nav {
  position: relative;
}

.social-swiper-nav .swiper-button-prev svg,
.social-swiper-nav .swiper-button-next svg {
  width: 4px;
  height: 8px;
}

.social-swiper-nav .swiper-button-prev:hover svg,
.social-swiper-nav .swiper-button-next:hover svg {
  fill: var(--primary-accent-color);
}

.social-swiper-nav .swiper-button-prev::after,
.social-swiper-nav .swiper-button-next::after {
  content: '';
}

.social-swiper-nav .swiper-button-next {
  right: -26px;
  left: auto;
  top: 16px;
}

.social-swiper-nav .swiper-button-prev {
  right: auto;
  left: -28px;
  top: 16px;
}

/* ================================
       13.6. Items Categories carousel
       ================================ */

.category-card {
  border: 1px solid var(--border-color);
  border-radius: 25px;
  position: relative;
  overflow: hidden;
  margin: 0px 5px !important;
  cursor: pointer;
}

.category-card .category-wrapper {
  background-size: cover;
  display: flex;
  flex-basis: 460px;
  height: 197px;
  padding: 40px;
}

.category-card .screens-overlay {
  position: absolute;
  top: 0;
  right: 0;
}

.category-card .screens-overlay img {
  object-fit: cover;
}

.category-card .category-content {
  color: var(--contrast-text-color);
}

.category-card .category-title {
  font-size: var(--h3-heading);
  font-weight: var(--heading-font-weight);
  letter-spacing: var(--letter-spacing);
  line-height: 1;
  margin-bottom: 20px;
}

.category-card .category-meta {
  font-size: 16px;
  font-weight: var(--body-font-weight);
  letter-spacing: var(--letter-spacing);
}

/* ================================
       13.8. Featured Sellers slider
       ================================ */

.seller-card {
  padding: 30px;
  border: 1px solid var(--border-color);
  border-radius: 25px;
}

.seller-card .about-seller {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.seller-info {
  display: flex;
  align-items: center;
}

.seller-info .avatar {
  margin-right: 20px;
}

.seller-info .title,
.seller-score .title {
  font-size: 20px;
  font-weight: var(--heading-font-weight);
  letter-spacing: var(--letter-spacing);
  line-height: 1;
}

.seller-info .meta,
.seller-score label {
  font-size: var(--body-font-size);
  font-weight: var(--body-font-weight);
  letter-spacing: var(--letter-spacing);
}

.seller-stats {
  display: flex;
  align-items: center;
}

.seller-stats>* {
  margin-left: clamp(10px, 3.5vmax, 50px);
}

.seller-stats>div:first-child {
  margin-left: 0;
}

.seller-score {
  text-align: right;
  white-space: nowrap;
}

.seller-products {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.products-previews {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.products-previews .preview-box {
  width: clamp(100px, 8vmax, 128px);
  height: clamp(62px, 4.8vmax, 80px);
  border: 1px solid var(--border-color);
  border-radius: 15px;
}

.seller-products .more-link {
  width: auto;
  height: auto;
  margin-right: 0;
}

.seller-products .more-link a {
  width: 46px;
  height: 34px;
}

.propdot {
  display: none;
}

.swiper-button-prev {
  position: absolute;
  top: 5%;
  left: 95%;
  color: var(--main-font-color);
}

.swiper-button-next {
  position: absolute;
  top: 5%;
  right: -0.5%;
  color: var(--main-font-color);
}

.swiper-button-prev:after,
.swiper-button-next:after {
  font-size: 15px;
  font-weight: 600;
}

@media (max-width: 800px) {
  .section-title {
    margin-top: -50px !important;
  }

  .swiper-button-prev {
    position: absolute;
    top: 8%;
    left: 80%;
    color: black;
  }

  .swiper-button-next {
    position: absolute;
    top: 8%;
    right: -0.5%;
    color: black;
  }

  .propdot {
    display: block;
  }

  .seller-card .about-seller {
    margin-bottom: 10px;
    flex-wrap: wrap;
  }

  .seller-info {
    margin-bottom: 20px;
  }
}

@media (max-width: 320px) {
  .products-previews .preview-box {
    width: 60px;
    height: auto;
    border-radius: 12px;
  }

  .products-previews .preview-box img {
    border-radius: 12px;
  }

  .seller-info .seller-box .avatar {
    display: none;
  }

  .product-additional-info .product-score .score-item {
    flex-basis: 105px;
  }
}

/* ================================
       14. ASIDE
       ================================ */

.aside-wrapper {
  padding-top: 15px;
}

.widget {
  margin-bottom: 50px;
}

.widget:last-child {
  margin-bottom: 0;
}

.widget-title {
  margin-bottom: 20px;
}

.widget-contact .email {
  font-size: 16px;
  font-weight: var(--heading-font-weight);
}

.widget-2 {
  padding: 30px;
  border: 1px solid var(--border-color);
  border-radius: 25px;
  margin-bottom: 20px;
}

.widget-2 .widget-title.h5 {
  font-size: 16px;
  font-weight: var(--heading-font-weight);
  margin-bottom: 30px;
}

@media (max-width: 600px) {
  aside {
    margin-bottom: 30px;
  }
}

@media (max-width: 414px) {

  .widget-2,
  .news-item-classic .news-content {
    padding: 15px;
  }
}

/* ================================
       14.1. Recent posts widget
       ================================ */

.recent-posts-widget-item {
  display: flex;
  margin-bottom: 20px;
}

.recent-posts-widget-item:last-child {
  margin-bottom: 0;
}

.recent-posts-widget-item-img {
  width: 80px;
  height: 80px;
  min-width: 80px;
  border-radius: 20px;
  border: 1px solid var(--border-color);
  margin-right: 15px;
}

.recent-posts-widget-item-img img {
  width: 100%;
  height: auto;
  border-radius: 20px;
  object-fit: cover;
}

.recent-posts-widget-item-tags {
  margin-bottom: 8px;
}

.recent-posts-widget-item-tags .tag-item {
  font-size: 10px;
  line-height: 1;
  font-weight: var(--heading-font-weight);
  padding: 5px 8px;
  border: 1px solid var(--border-color);
  border-radius: 30px;
}

.recent-posts-widget-item-tags .tag-item+.tag-item {
  margin-left: 4px;
}

.recent-posts-widget-item-title {
  font-size: var(--body-font-size);
  font-weight: var(--heading-font-weight);
  line-height: 1.4;
  margin-bottom: 10px;
}

.recent-posts-widget-item-title a:hover {
  color: inherit;
}

.recent-posts-widget-item-meta {
  font-size: 12px;
  font-weight: var(--body-font-weight);
  line-height: 1;
}

.recent-posts-widget-item-meta a {
  color: var(--primary-accent-color);
}
.recent-posts-widget-item-meta span {
  color: #9b32cc;
}

/* ================================
       14.2. Widget Search
       ================================ */

.widget-search .search {
  position: relative;
  border: 1px solid var(--border-color);
  border-radius: var(--input-border-radius);
  display: flex;
}

.widget-search .search-input,
.widget-search .search-button {
  border: none;
  padding: 18px 20px;
  white-space: nowrap;
}

.widget-search .search-input {
  flex: 0 1 100%;
  background-color: var(--body-background);
  color: var(--main-font-color);
  border-radius: var(--input-border-radius);
}

@media (max-width: 320px) {

  .widget-search .search-input,
  .widget-search .search-button {
    padding: 15px 17px;
  }
}

/* ================================
       14.2. Categories Widget
       ================================ */

.categories-widget-item {
  display: inline-block;
  font-size: 12px;
  font-weight: var(--heading-font-weight);
  line-height: 1;
  padding: 10px 14px;
  border: 1px solid var(--border-color);
  border-radius: var(--btn-border-radius);
  margin-right: 5px;
  margin-bottom: 10px;
}

.categories-widget-item:hover {
  color: var(--contrast-text-color);
  background: var(--cryptoki-gradient);
  border: none;
  padding: 11px 15px;
}

/* ================================
       14.3. Archives Widget
       ================================ */

.archives-widget-list .archive-item {
  font-size: var(--body-font-size);
  font-weight: var(--heading-font-weight);
  line-height: 2.75;
}

.archives-widget-list .archive-item a+span {
  margin-left: 4px;
}

/* ================================
       14.4. Latest Comments Widget
       ================================ */

.latest-comments-widget-list .comment-item {
  font-size: var(--body-font-size);
  font-weight: var(--body-font-weight);
  line-height: 1.4;
  padding: 0;
  border: none;
  border-radius: 0;
  margin-bottom: 20px;
}

.latest-comments-widget-list .comment-link {
  font-weight: var(--heading-font-weight);
}

.latest-comments-widget-list .comment-link:hover {
  color: inherit;
}

/* ================================
       14.5. Calendar Widget
       ================================ */

.calendar-table {
  border-spacing: 10px;
  width: 100%;
}

.calendar-table caption {
  font-size: 16px;
  font-weight: var(--heading-font-weight);
}

.calendar-table th {
  font-size: 10px;
  font-weight: var(--heading-font-weight);
  text-transform: uppercase;
}

.calendar-table td {
  font-size: var(--body-font-size);
  font-weight: var(--heading-font-weight);
  line-height: 1;
  background: var(--body-background);
  border: 1px solid var(--border-color);
  border-radius: var(--btn-border-radius);
  text-align: center;
}

.calendar-table td a {
  display: block;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 14px 13px;
}

.calendar-table td:hover {
  border: solid 1px transparent;
  background-image: linear-gradient(var(--body-background),
      var(--body-background)),
    linear-gradient(to right,
      var(--primary-gradient-color),
      var(--secondary-gradient-color));
  background-origin: border-box;
  background-clip: content-box, border-box;
  position: relative;
}

.calendar-table td.inactive a {
  color: var(--inactive-text-color);
  border-color: var(--inactive-text-color);
}

.calendar-table td.inactive:hover {
  color: inherit;
  border-color: var(--inactive-text-color);
  background-image: none;
}

@media (max-width: 1200px) {
  .calendar-table {
    border-spacing: 5px;
  }

  .calendar-table td {
    font-size: 12px;
  }

  .calendar-table td a {
    padding: 8px 7px;
  }
}

@media (max-width: 800px) {
  .calendar-table td a {
    padding: 10px 9px;
  }
}

@media (max-width: 320px) {
  .calendar-table td {
    font-size: 12px;
  }

  .calendar-table td a {
    padding: 8px 7px;
  }
}

/* ================================
       15. FOOTER
       ================================ */

.footer {
  margin-top: auto;
  font-size: 13px;
  background: var(--footer-background);
  position: relative;
  color: var(--footer-text-color);
}

.footer a {
  fill: var(--footer-text-color);
}

.footer p {
  margin: 0;
}

.footer input {
  color: var(--footer-text-color);
}

.footer::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 8px;
  z-index: 1;
  background: var(--cryptoki-gradient);
}

.footer-content {
  padding-bottom: 70px;
}

.footer-column-large {
  flex-basis: 500px;
}

.footer-column {
  margin-right: 20px;
}

.footer-column:last-child {
  margin-right: 0;
}

.footer-row+.footer-row {
  margin-top: 75px;
}

.footer-margin {
  margin-top: clamp(4rem, 12.25vmax, 150px);
}

.footer .social-icons-list .social-icon svg {
  fill: var(--footer-text-color);
}

.footer .social-icons-list .social-icon:hover svg {
  fill: var(--primary-accent-color);
}

@media (max-width: 1024px) {
  .footer-content {
    flex-wrap: wrap;
  }

  .footer-column-large {
    flex-basis: min(300px, 30vw);
  }
}

@media (max-width: 800px) {
  .footer-column-large {
    flex-basis: 100%;
  }
}

@media (max-width: 414px) {
  .footer-content {
    justify-content: flex-start;
  }

  .footer-column {
    flex-basis: 100%;
    margin-right: 0;
  }

  .footer-column .flex-space-between {
    flex-wrap: wrap;
  }

  .footer-column .is-two-col-element {
    flex-wrap: nowrap;
    flex-basis: 100%;
  }

  .footer-column .is-two-col-element>* {
    flex-basis: 50%;
  }

  .footer-column .is-two-col-element .footer-menu {
    margin-right: 0;
  }

  .main:not(.digital-store) .footer-column:nth-child(3),
  .main:not(.digital-store) .footer-column:last-child {
    flex-basis: 50%;
  }
}

/* ================================
       15.1. Footer menu
       ================================ */

.footer-menu {
  list-style: none;
}

.footer-menu .menu-item {
  margin-bottom: 6px;
}

.footer-menu .menu-link {
  display: block;
  font-weight: 600;
  line-height: 2.3;
}

/* ================================
       15.2. Footer widgets
       ================================ */

.footer-widget>* {
  margin-bottom: 30px;
}

.footer .widget-title {
  font-size: var(--body-font-size);
  font-weight: var(--heading-font-weight);
  margin-bottom: clamp(1rem, 3vmax, 40px);
}

.is-two-col-element {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}

.is-two-col-element .footer-menu {
  margin-right: 55px;
}

@media (max-width: 414px) {
  .is-two-col-element {
    flex-wrap: wrap;
  }
}

/* ================================
       15.3. Footer copyright
       ================================ */

.footer-copyright {
  padding: 30px 0;
  border-top: 1px solid #31333b;
  align-items: center;
}

.footer-copyright .copyright-text {
  line-height: 1.2;
}

.sub-footer-menu {
  list-style: none;
  display: flex;
}

.sub-footer-menu .menu-item {
  font-weight: var(--body-font-weight);
}

.sub-footer-menu .menu-item+.menu-item {
  margin-left: 30px;
}

@media (max-width: 414px) {
  .sub-footer-menu .menu-item+.menu-item {
    margin-left: 10px;
  }
}

/* ================================
       15.4. Subscribe widget
       ================================ */

.subscribe-form-content {
  display: flex;
  width: max(250px, 20vw);
  max-width: 100%;
  border: solid 2px transparent;
  border-radius: var(--input-border-radius);
  background-image: linear-gradient(var(--footer-background),
      var(--footer-background)),
    linear-gradient(to right,
      var(--primary-gradient-color),
      var(--secondary-gradient-color));
  background-origin: border-box;
  background-clip: content-box, border-box;
  position: relative;
}

.subscribe-form .subscribe-input {
  flex: 0 1 100%;
  padding: 20px;
  background: transparent;
  border-radius: var(--input-border-radius);
  border: none;
  font-size: var(--body-font-size);
  font-weight: var(--body-font-weight);
}

.subscribe-widget .subscribe-submit {
  position: absolute;
  width: 24px;
  height: 24px;
  top: calc(50% - 12px);
  right: 20px;
}

.subscribe-widget .subscribe-submit svg {
  fill: var(--footer-text-color);
}

.subscribe-form .subscribe-form-message {
  margin: 5px 0 0 5px;
}

/* ================================
      15.5. Subscribe widget v2
      ================================ */

.footer .subscribe-widget-v2 {
  position: relative;
  margin-top: -60px;
}

.subscribe-widget-v2 {
  display: flex;
  justify-content: space-around;
  align-items: center;
  /* margin-top: 40px; */
  background: linear-gradient(50deg,
      var(--primary-gradient-color),
      var(--secondary-gradient-color));
  border-radius: 25px;
  padding: clamp(1.8rem, 4vmax, 64px) 0 clamp(2rem, 4.1vmax, 66px);
  position: relative;
}

.subscribe-text {
  color: #fff;
  z-index: 1;
}

.subscribe-text .h2 {
  line-height: 1;
}

.subscribe-text .subtitle {
  font-weight: var(--heading-font-weight);
}

.subscribe-form-v2 form {
  position: relative;
  width: 500px;
  max-width: 100%;
}

.subscribe-form-v2 input {
  padding: 18px;
  width: 100%;
  border-radius: 10px;
  border: 0;
  color: var(--main-font-color);
  font-size: var(--body-font-size);
}

.subscribe-form-v2 input::placeholder {
  font-size: var(--body-font-size);
  color: var(--inactive-text-color);
}

.subscribe-form-v2 .subscribe-submit {
  height: 100%;
  padding: 18px 34px;
  position: absolute;
  right: 0;
  top: 0;
  border-radius: 0 10px 10px 0;
}

.subscribe-form-v2 .subscribe-submit #send {
  display: none;
}

@media (max-width: 1024px) {
  .subscribe-widget-v2 {
    flex-direction: column;
    text-align: center;
  }

  .subscribe-widget-v2 .subscribe-text {
    margin-bottom: 20px;
    padding: 20px;
  }
}

@media (max-width: 600px) {
  .footer .subscribe-widget-v2 {
    margin-top: -30px;
  }

  .subscribe-form-v2 form {
    width: 300px;
  }

  .subscribe-form-v2 .subscribe-submit {
    padding: 18px;
  }

  .subscribe-form-v2 .subscribe-submit #send {
    display: block;
    fill: var(--contrast-text-color);
  }

  .subscribe-form-v2 .subscribe-submit .button-text {
    display: none;
  }
}

@media (max-width: 320px) {
  .subscribe-form-v2 form {
    width: 260px;
  }
}

/* ================================
       16. BLOG
       ================================ */

/* ================================
       16.1. News feed
       ================================ */

.news-item {
  border: 1px solid var(--border-color);
  border-radius: 25px;
  transition: transform 0.3s ease-in-out;
}

.news-item:hover {
  transform: translateY(-10px);
}

.news-thumb {
  border-radius: 25px;
}

.news-thumb img {
  width: 100%;
  height: auto;
  border-radius: 25px;
}

.news-content {
  padding: 30px;
  position: relative;
}

.news-content>* {
  margin-bottom: 20px;
}

.news-content>div:last-child {
  margin-bottom: 0;
}

.news-meta>a {
  color: var(--primary-accent-color);
}
.news-meta>span {
  color: #9b32cc;
}

/* .news-tags {
    position: absolute;
    top: -15px;
    left: 25px;
    z-index: 10;
} */

.no-thumb .news-tags {
  position: relative;
  top: 0;
  left: 0;
  margin-bottom: 20px;
}

.news-tags .tag-item {
  padding: 9px 14px;
  background-color: var(--body-background);
  border: 1px solid var(--border-color);
  border-radius: 30px;
  margin-right: 5px;
  font-size: 12px;
  font-weight: var(--heading-font-weight);
  cursor: pointer;
}

.news-tags .tag-item:last-child {
  margin-right: 0;
}

.news-title.h5 {
  margin-bottom: 20px;
}

.news-title.h5 a:hover {
  color: inherit;
}

.read-more-link {
  display: inline-block;
  vertical-align: middle;
}

.read-more-link a {
  font-weight: var(--heading-font-weight);
}

.read-more-link a:hover {
  background-color: var(--main-font-color);
  background-image: var(--cryptoki-gradient);
  background-size: 100%;
  background-clip: text;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
}

.read-more-link a:hover svg {
  fill: var(--primary-gradient-color);
}

@media (max-width: 320px) {
  .news-item .news-content {
    padding: 20px 15px;
  }
}

/* ================================
       16.2. Post formats
       ================================ */

.video-post-format,
.gallery-post-format,
.audio-post-format {
  position: relative;
}

.video-post-format .post-format-icon,
.audio-post-format .post-format-icon {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: var(--cryptoki-gradient);
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}

.video-post-format .post-format-icon svg {
  width: 20px;
  height: 22px;
  fill: #fff;
  position: relative;
  top: calc(50% - 15px);
  left: calc(50% - 8px);
}

.audio-post-format .post-format-icon svg {
  width: 18px;
  height: 22px;
  fill: #fff;
  position: relative;
  top: calc(50% - 15px);
  left: calc(50% - 8px);
}

.gallery-post-format .post-format-icon {
  width: 55px;
  height: 34px;
  z-index: 10;
  background: var(--body-background);
  border: 1px solid var(--border-color);
  border-radius: 30px;
  position: absolute;
  top: 18px;
  right: -14px;
}

.gallery-post-format .post-format-icon svg {
  width: 4px;
  height: 8px;
}

.gallery-post-format .post-format-icon a {
  padding: 4px 7px;
}

.gallery-post-format .post-format-icon a:hover {
  fill: var(--primary-accent-color);
}

/* ================================
       16.3. Single post
       ================================ */

.single-post.medium-section {
  width: 940px;
  max-width: 100%;
  margin: 0 auto;
}

.post-featured-image {
  margin-bottom: clamp(2rem, 4.5vmax, 70px);
}

.single-post .post-content {
  font-size: var(--article-font-size);
}

.single-post .post-content,
.single-post .comments-section {
  width: 700px;
  max-width: 100%;
  margin: 0 auto clamp(1rem, 6vmax, 100px);
}

.single-post .post-content>* {
  margin-bottom: clamp(2rem, 4.3vmax, 70px);
}

.single-post .post-heading {
  text-align: center;
  margin-bottom: clamp(2rem, 4.5vmax, 70px);
}

.single-post .post-heading h1 {
  margin-bottom: 40px;
}

.single-post .news-meta {
  display: flex;
  justify-content: center;
  align-items: center;
}

.post-author a {
  color: var(--primary-accent-color);
}
.post-author span {
  color: #9b32cc;
}

.single-post .news-meta .social-icons-list {
  margin-left: 28px;
}

.single-post .news-tags {
  position: relative;
  top: 0;
  left: 0;
  margin-bottom: 30px;
}

.single-post .content-heading {
  margin-bottom: 1.5em;
}

.single-post .list-block {
  display: flex;
  justify-content: flex-start;
}

.single-post .list-block>* {
  flex-basis: 50%;
}

.single-post .list-block ol {
  padding-left: 1rem;
}

@media (max-width: 414px) {
  .single-post .list-block {
    flex-wrap: wrap;
  }

  .single-post .list-block>* {
    flex-basis: 100%;
  }
}

/* ================================
       16.3.1. Image caption
       ================================ */

figure figcaption {
  font-size: var(--body-font-size);
  text-align: center;
  margin-top: 10px;
}

figure figcaption a {
  color: var(--primary-accent-color);
}

/* ================================
       16.3.2. Quote
       ================================ */

blockquote {
  text-align: center;
  background: var(--extra-light-gray);
  border: 1px solid var(--border-color);
  border-radius: 25px;
  margin-left: 0;
  margin-right: 0;
  padding: clamp(1rem, 2vmax, 35px) clamp(2rem, 4vmax, 65px) clamp(1.5rem, 3vmax, 45px);
}

blockquote p {
  font-size: var(--h4-heading);
  letter-spacing: var(--letter-spacing);
  line-height: 1.3;
  margin: 20px;
}

/* ================================
       16.3.3. Post author box
       ================================ */

.post-author-box {
  padding: 30px;
  background: var(--extra-light-gray);
  border-radius: 25px;
  border: 1px solid var(--border-color);
}

.post-author-box .author-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.post-author-box p {
  font-size: var(--body-font-size);
}

@media (max-width: 414px) {
  .post-author-box .author-heading {
    flex-wrap: wrap;
  }

  .post-author-box .avatar-block {
    margin-bottom: 20px;
  }
}

/* ================================
       16.3.4. Comments
       ================================ */

.comments-section .comments-list {
  margin-bottom: clamp(1rem, 4vmax, 100px);
}

.comment-item {
  margin-bottom: 20px;
}

.comment-item .comment-item-wrapper {
  padding: 30px;
  border: 1px solid var(--border-color);
  border-radius: 25px;
}

.comment-item .avatar-block {
  margin-bottom: 30px;
}

.comment-item .comment-body {
  font-size: var(--body-font-size);
  margin-bottom: 30px;
}

.comment-item .comment-meta {
  display: flex;
  font-size: 12px;
}

.comment-item .comment-meta .publish-date {
  margin-right: 15px;
}

.comment-item .comment-meta .reply a {
  font-weight: var(--heading-font-weight);
  color: inherit;
}

/* ================================
     16.4. Blog Classic
    ================================ */

.news-item-classic {
  display: flex;
  border: 1px solid var(--border-color);
  border-radius: 25px;
  margin-bottom: 20px;
}

.news-item-classic:last-child {
  margin-bottom: 0;
}

.news-item-classic .news-thumb {
  display: flex;
  border-radius: 25px 0 0 25px;
  position: relative;
  flex: 0 0 45%;
}

.news-item-classic .news-thumb a:not(.post-format-icon) {
  display: flex;
}

.news-item-classic .news-thumb img {
  border-radius: 25px 0 0 25px;
  object-fit: cover;
  flex: 1;
}

.news-item-classic .news-content {
  flex: 0 0 55%;
}

.news-item-classic .news-tags {
  position: relative;
  top: auto;
  left: auto;
  z-index: 1;
}

.news-item-classic.no-thumb .news-content {
  flex-basis: 100%;
}

@media (max-width: 800px) {
  .blog-classic-area.grid-right-sidebar-large {
    grid-template-columns: 1fr;
    grid-column-gap: 0;
    grid-row-gap: 40px;
  }
}

@media (max-width: 414px) {
  .news-item-classic {
    flex-direction: column;
  }

  .news-item-classic .news-thumb,
  .news-item-classic .news-thumb img {
    border-radius: 25px 25px 0 0;
  }
}

/* ================================
     16.5. Single post sidebar
    ================================ */

.single-post-sidebar.grid-right-sidebar-large {
  grid-template-columns: 2.5fr minmax(320px, 1.5fr);
  grid-column-gap: clamp(1rem, 8vw, 140px);
}

.single-post-sidebar .post-heading {
  text-align: left;
}

.single-post-sidebar .post-heading h1 {
  margin-bottom: 30px;
}

.single-post-sidebar .news-meta {
  justify-content: flex-start;
}

.single-post-sidebar .post-content,
.single-post-sidebar .comments-section {
  width: 100%;
  max-width: 100%;
  margin: 0;
}

@media (max-width: 800px) {
  .single-post-sidebar.grid-right-sidebar-large {
    grid-template-columns: 1fr;
    grid-column-gap: 0;
    grid-row-gap: 40px;
  }
}

@media (max-width: 320px) {
  .single-post-sidebar.grid-right-sidebar-large {
    grid-template-columns: 290px;
  }
}

/* ================================
       17. FEATURED ITEMS
       ================================ */

/* ================================
       17.1. Featured items v1
       ================================ */

.featured-box-wrapper {
  margin-bottom: clamp(2rem, 3.75vmax, 60px);
}

.featured-item .featured-item-wrapper {
  background-color: var(--extra-light-gray);
  border: 1px solid var(--border-color);
  border-radius: 25px;
}

.featured-item .featured-item-content {
  background-color: var(--body-background);
  border-bottom: 1px solid var(--border-color);
  border-radius: 25px;
  padding: 16px;
  position: relative;
  z-index: 1;
}

.fav-counter {
  display: flex;
  align-items: center;
  background: var(--body-background);
  border: 1px solid var(--border-color);
  border-radius: 30px;
  padding: 9px 14px;
  line-height: 1;
  position: absolute;
  right: -12px;
  top: 30px;
  z-index: 1;
  cursor: pointer;
}

.fav-counter svg {
  fill: var(--main-font-color);
}

.fav-counter .count {
  font-size: 12px;
  font-weight: var(--heading-font-weight);
}

.fav-counter:hover {
  background: var(--cryptoki-gradient);
  background-origin: border-box;
  background-clip: padding-box, border-box;
  border: 1px solid transparent;
}

.fav-counter:hover>* {
  color: var(--contrast-text-color);
  fill: var(--contrast-text-color);
}

.featured-item-image {
  max-height: 340px;
  overflow: hidden;
  border-radius: 20px;
  border: 1px solid var(--border-color) ;

}

.featured-item-image img {
  object-fit: cover;
}

.featured-item-image>a>img {
  /* height: 198px !important; */
  border-radius: 20px;
  width: 100%;
  height: auto;
}

.featured-item-info {
  padding: 26px 10px 10px;
  position: relative;
}

.featured-item .featured-item-info .title,
.featured-item.v3 .featured-item-wrapper .title,
.featured-item.v4 .featured-item-author .title {
  font-size: 20px;
  font-weight: var(--heading-font-weight);
  line-height: 1.2;
  margin-bottom: 10px;
  letter-spacing: var(--letter-spacing);
}

.featured-item .featured-item-info .title a:hover {
  color: inherit;
}

.item-meta {
  display: flex;
  align-items: center;
}

.featured-countdown {
  padding: 9px 14px;
  display: flex;
  align-items: center;
  border: 1px solid var(--border-color);
  border-radius: 30px;
  line-height: 1;
  font-weight: var(--heading-font-weight);
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  top: -20px;
  background: var(--body-background);
  white-space: nowrap;
  cursor: pointer;
}

.featured-countdown .clock svg {
  width: 16px;
  height: 16px;
  fill: url('#cryptoki-svg-gradient') var(--primary-accent-color);
  margin-right: 5px;
}

.featured-countdown:hover .clock svg {
  fill: var(--contrast-text-color);
}

.featured-countdown:hover {
  background: var(--cryptoki-gradient);
  border: 1px solid transparent;
  background-clip: border-box;
  background-origin: border-box;
  color: var(--contrast-text-color);
}

.featured-item .price-caption,
.featured-item .social-caption {
  font-size: 10px;
  letter-spacing: -0.02em;
  margin-bottom: 7px;
  text-transform: uppercase;
}

.featured-item .share-icons svg {
  width: 10px;
  height: 10px;
  margin-right: 10px;
  fill: var(--main-font-color);
}

.featured-item .share-icons a:last-child svg {
  margin-right: 0;
}

.item-meta .avatar {
  position: relative;
  margin-right: 10px;
}

.featured-item-post-content {
  display: flex;
  padding: 24px 30px;
}

.featured-item-post-content>* {
  margin-right: 40px;
  line-height: 1;
}

.featured-item-pricebox .price {
  font-weight: var(--heading-font-weight);
  line-height: 1;
}

.share-icons a:hover svg {
  fill: var(--primary-accent-color);
}

/* ================================
       17.2. Featured items v2
       ================================ */

.featured-item.v2 .featured-item-info .title {
  text-align: center;
}

.featured-item.v2 .avatar {
  margin-right: 12px;
}

.featured-item.v2 .item-meta {
  justify-content: center;
}

.featured-item.v2 .featured-item-content {
  padding: 0;
}

.featured-item.v2 .featured-item-post-content {
  padding: 15px 30px;
  display: flex;
  justify-content: space-between;
}

.featured-item.v2 .featured-item-post-content>* {
  margin-right: 0;
}

.featured-item.v2 .featured-item-info {
  text-align: center;
  padding: 30px 10px;
}

.featured-item.v2 .title {
  font-size: 16px;
  line-height: 1.4;
  font-weight: var(--heading-font-weight);
  margin-bottom: 15px;
  letter-spacing: var(--letter-spacing);
}

.featured-item.v2 .featured-countdown {
  font-size: 12px;
}

.featured-item-pricebox {
  text-align: left;
}

.featured-item.v2 .price-caption,
.featured-item.v2 .social-caption {
  line-height: 1;
}

.featured-item.v2 .fav-counter {
  position: relative;
  right: 0;
  top: 0;
  border: none;
  background: none;
  padding: 0;
}

.featured-item.v2 .fav-counter svg {
  width: 14px;
  height: 14px;
}

.featured-item.v2 .fav-counter:hover>* {
  fill: var(--primary-accent-color);
  color: inherit;
}

.featured-item.v2 .fav-counter .count {
  font-size: 10px;
}

/* ================================
       17.3. Featured items v3
       ================================ */

.featured-item.v3 .featured-item-wrapper {
  position: relative;
}

.featured-item-image-wrapper {
  background: var(--body-background);
  border-radius: 25px 25px 0 0;
  padding: 16px 16px 0;
}

.featured-item.v3 .featured-item-image {
  background: var(--body-background);
  border-radius: 20px;
}

.featured-item.v3 .title {
  margin-bottom: 0;
  padding: 30px;
  border-bottom: 1px solid var(--border-color);
  border-radius: 0 0 25px 25px;
}

.featured-item.v3 .featured-countdown {
  top: auto;
  left: 57px;
  bottom: 300px;
  z-index: 10;
}

.featured-item.v3 .fav-counter {
  top: auto;
  bottom: 25px;
}

.featured-item.v3 .item-meta-wrapper {
  background: var(--body-background);
  position: relative;
}

.featured-item.v3 .item-meta {
  display: block;
  padding: 24px 30px;
  background-color: var(--body-background);
  border-bottom: 1px solid var(--border-color);
  border-radius: 0 0 25px 25px;
}

.featured-item.v3 .featured-item-wrapper .title {
  margin-bottom: 0;
}

.featured-item.v3 .creator-box {
  justify-content: space-between;
  position: relative;
  z-index: 1;
}

.featured-item.v3 .creator-info {
  display: flex;
  align-items: center;
}

.featured-item.v3 .creator-label {
  font-size: 10px;
  margin-bottom: 7px;
}

.featured-item.v3 .creator-meta .creator-name {
  font-weight: var(--heading-font-weight);
}

.featured-item.v3 .featured-item-pricebox {
  line-height: 1;
  flex-basis: 35%;
}

/* ================================
       17.4. Featured items v4
       ================================ */

.featured-item.v4 .featured-item-content>* {
  margin-bottom: 30px;
}

.featured-item.v4 .featured-item-content>div:last-child {
  margin-bottom: 0;
}

.featured-item.v4 .featured-item-image {
  position: relative;
  margin-bottom: 40px;
}

.featured-item.v4 .item-meta {
  font-size: 16px;
  font-weight: var(--heading-font-weight);
  line-height: 1;
}

.featured-item.v4 .featured-item-pricebox .price {
  font-size: 18px;
}

.featured-item.v4 .featured-item-author .title {
  margin-bottom: 5px;
}

.featured-item.v4 .featured-item-author,
.featured-item.v4 .featured-bidding-section {
  padding-left: 14px;
  padding-right: 14px;
}

.featured-item.v4 .avatar {
  border: 6px solid var(--body-background);
  position: absolute;
  bottom: 155px;
  left: 25px;
  margin-bottom: 0;
  width: 54px;
  height: 54px;
}

.featured-item.v4 .featured-bidding-section {
  display: flex;
  justify-content: space-between;
}

.featured-item.v4 .featured-item-post-content {
  justify-content: space-between;
}

.featured-item.v4 .featured-item-post-content>* {
  margin-right: 0;
}

.featured-item.v4 .countdown-box .label {
  font-size: 10px;
  text-transform: uppercase;
  margin-bottom: 7px;
}

.featured-item.v4 .countdown-box .countdown {
  font-weight: var(--heading-font-weight);
}

/* ================================
       17.5. Featured items v5
      ================================ */

.featured-item.v5 .featured-item-content {
  padding: 14px;
}

.featured-item.v5 .title {
  font-size: 16px;
  line-height: 1.4;
  font-weight: var(--heading-font-weight);
  margin-bottom: 10px;
  letter-spacing: var(--letter-spacing);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.featured-item.v5 .fav-counter {
  top: 16px;
}

.featured-item .item-category {
  position: absolute;
  top: -10px;
  left: 20px;
}

.item-category {
  width: auto;
  font-size: 9px;
  line-height: 1;
  text-transform: uppercase;
  font-weight: var(--heading-font-weight);
  padding: 4px 8px;
  border-radius: 20px;
  color: var(--contrast-text-color);
  letter-spacing: var(--letter-spacing);
  white-space: nowrap;
}

.item-category.ui-templates {
  background-color: var(--ui-templates);
  color: var(--main-font-color);
}

.item-category.coded-templates {
  background-color: var(--coded-templates);
}

.item-category.social-graphics {
  background-color: var(--social-graphics);
  color: var(--main-font-color);
}

.item-category.video-tutorials {
  background-color: var(--video-tutorials);
}

.featured-item.v5 .featured-item-post-content {
  justify-content: space-between;
  align-items: center;
  padding: 20px 25px;
}

.featured-item.v5 .featured-item-post-content>* {
  margin-right: 0;
}

.item-rating {
  color: #feda03;
  display: flex;
  align-items: center;
}

.item-rating .software {
  padding: 2px;
  border: 2px solid #ccc;
}

.item-rating .software:hover {
  border-color: var(--primary-accent-color);
}

.item-rating span {
  font-size: 0;
  margin-right: 2px;
}

.item-rating img {
  width: 35px;
  height: 35px;
  border-radius: 50%;
}

.item-rating .filled svg {
  fill: var(--secondary-accent-color);
}

.featured-item .item-price {
  font-size: var(--body-font-size);
  letter-spacing: var(--letter-spacing);
  font-weight: var(--heading-font-weight);
}

/* ================================
       17.6. Featured items v6
      ================================ */

.featured-item.v6 .featured-item-content {
  padding: 0;
}

.featured-item.v6 .featured-item-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 30px 25px;
}

.featured-item.v6 .item-category {
  left: 50%;
  transform: translate(-50%, 0);
}

.featured-item.v6 .title {
  font-size: var(--body-font-size);
  text-align: center;
}

.featured-item.v6 .item-meta {
  font-size: 12px;
  align-items: center;
}

.featured-item.v6 .item-meta .verified {
  margin-left: 5px;
  font-size: 0;
}

.featured-item.v6 .item-meta .verified svg {
  width: 10px;
  height: 10px;
  border: none;
  padding: 1px;
}

.featured-item .item-rating-box {
  display: flex;
  justify-content: center;
  align-items: center;
}

.featured-item.v6 .item-meta+.item-rating-box {
  margin-top: 20px;
}

.featured-item.v6 .fav-counter {
  position: relative;
  right: auto;
  left: 0;
  top: 0;
  border: none;
  padding: 0;
  margin-left: 12px;
}

.featured-item.v6 .fav-counter:hover {
  background: none;
}

.featured-item.v6 .fav-counter:hover>* {
  color: var(--primary-accent-color);
  fill: var(--primary-accent-color);
}

.featured-item.v6 .fav-counter svg {
  width: 12px;
  height: 12px;
}

.featured-item.v6 .fav-counter .count {
  font-size: 10px;
}

.featured-item.v6 .featured-item-post-content {
  justify-content: space-between;
}

.featured-item.v6 .featured-item-post-content>* {
  margin-right: 0;
}

/* ================================
       17.7. Featured items v7
      ================================ */

.featured-item.v7 .featured-item-content {
  padding: 14px;
}

.featured-item.v7 .title {
  font-size: 16px;
}

.featured-item.v7 .item-rating-box {
  flex-direction: column;
  align-items: flex-start;
}

.featured-item.v7 .item-rating-box .rating-label {
  font-size: 10px;
  letter-spacing: -0.02em;
  margin-bottom: 7px;
  text-transform: uppercase;
  font-weight: var(--body-font-weight);
}

.featured-item.v7 .featured-item-info {
  padding-left: 16px;
  padding-right: 16px;
}

/* ================================
       17.8. Featured items v8
      ================================ */

.featured-item.v8 .featured-item-content {
  padding: 0;
}

.featured-item.v8 .featured-item-image {
  padding: 16px 16px 0;
  border-radius: 20px;
  position: relative;
  overflow: visible;
}

.featured-item.v8 .item-category {
  position: relative;
  top: 0;
  left: 0;
}

.featured-item.v8 .avatar {
  border: 3px solid var(--contrast-text-color);
  position: absolute;
  bottom: -15px;
  left: 36px;
  width: 32px;
  height: 32px;
}

.featured-item.v8 .featured-item-info {
  padding: 30px;
  border-bottom: 1px solid var(--border-color);
  border-radius: 25px;
}

.featured-item.v8 .title {
  font-size: 16px;
  font-weight: var(--heading-font-weight);
  letter-spacing: var(--letter-spacing);
}

.featured-item.v8 .description {
  font-size: var(--body-font-size);
}

.featured-item.v8 .additional-info {
  padding: 18px 30px;
}

.featured-item.v8 .featured-item-post-content {
  justify-content: space-between;
}

.featured-item.v8 .featured-item-post-content>* {
  margin-right: 0;
}

.featured-item.v8 .item-rating-box {
  flex-direction: column;
  align-items: flex-start;
}

.featured-item.v8 .social-share-box {
  text-align: right;
}

@media (max-width: 320px) {
  /* .featured-item.v8 .avatar {
        top: 155px;
    } */
}

/* ================================
       18. CREATORS
       ================================ */

/* ================================
       18.1 Creators
       ================================ */

.creator-item {
  background-color: var(--lighter-gray);
  border: 1px solid var(--border-color);
  border-radius: 25px;
}

.creator-item .creator-wrapper {
  background: var(--body-background);
  padding: 0 30px 30px;
  border-bottom: 1px solid var(--border-color);
  border-radius: 0 0 25px 25px;
  border-top-width: 0;
}

.creator-item .creator-cover {
  width: 100%;
  max-height: 98px;
  background-color: var(--lighter-gray);
  border-radius: 25px;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
}

.creator-item .creator-cover img {
  width: 100%;
  border-radius: 25px;
}

.creator-item .avatar.box-64 {
  width: 80px;
  height: 80px;
  border: 8px solid var(--body-background);
}

.creator-info-wrapper {
  margin-top: -45px;
  position: relative;
}

.creator-info-wrapper .follow-me {
  position: absolute;
  top: 25px;
  right: -40px;
  z-index: 1;
}

.creator-item .creator-info {
  margin-bottom: 30px;
}

.creator-item .creator-info .title {
  font-size: 20px;
  font-weight: var(--heading-font-weight);
  line-height: 1.2;
  letter-spacing: var(--letter-spacing);
  margin-bottom: 5px;
}

.creator-item .creator-info .title a:hover {
  color: inherit;
}

.creator-item .creator-info .item-meta {
  font-size: 16px;
  font-weight: var(--heading-font-weight);
  letter-spacing: var(--letter-spacing);
  line-height: 1;
}

.creator-item .creator-info .avatar {
  margin-bottom: 10px;
}

.creator-item .btn-small {
  padding: 10px 20px;
}

.creator-item .social-icons-list {
  margin-bottom: 30px;
}

.creator-item .social-icons-list .social-icon {
  width: 44px;
  height: 34px;
  margin-right: 8px;
}

.creator-item .about-creator {
  margin-bottom: 55px;
}

.creator-item .about-creator p {
  margin: 0;
}

.creator-item .creator-stats {
  padding: 22px 30px;
}

.creator-item .creator-stats .stats-item .label {
  font-size: 10px;
  text-transform: uppercase;
  line-height: 1.4;
}

.creator-item .creator-stats .stats-item .number {
  font-weight: var(--heading-font-weight);
}

.preview-box {
  width: 48px;
  height: 48px;
  margin-left: 0;
  margin-right: 15px;
  border-radius: 15px;
  cursor: pointer;
}

.preview-box:last-child {
  margin-right: 0;
}

.preview-box img {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 15px;
}

.preview-box.box-68 {
  background-color: var(--lighter-gray);
  border: 1px solid var(--border-color);
  border-radius: 20px;
  margin-left: 16px;
  width: 68px;
  height: 68px;
}

.preview-box.box-68 img {
  border-radius: 20px;
}

.more-projects {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--body-background);
  border: 1px solid var(--border-color);
  border-radius: 15px;
}

.more-projects a {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.more-projects a:hover svg {
  fill: var(--primary-accent-color);
}

.more-projects svg {
  width: 10px;
  height: 2px;
  fill: var(--main-font-color);
}

@media (max-width: 1419px) {
  .preview-box.box-68 {
    margin-left: 0;
  }
}

@media (max-width: 600px) {
  .creator-stats .stats-item {
    margin-right: 15px;
  }
}

@media (max-width: 414px) {
  .preview-box {
    width: 42px;
    height: 42px;
  }
}

/* ================================
       18.2 Top creators
       ================================ */

.top-creator-item {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  padding: 30px;
  border: 1px solid var(--border-color);
  border-radius: 20px;
  position: relative;
}

.creator-box {
  display: flex;
  align-items: center;
}

.creator-box .title,
.projects-score .title {
  font-size: 20px;
  font-weight: var(--heading-font-weight);
  line-height: 1.2;
  margin-bottom: 7px;
}

.creator-box .title a:hover {
  color: inherit;
}

.top-creator-item .creator-box .avatar+.creator-meta {
  margin-left: 15px;
}

.creator-box .creator-meta,
.projects-score .label {
  line-height: 1;
}

.creator-number {
  font-size: 12px;
  font-weight: var(--heading-font-weight);
  line-height: 1;
  background-color: var(--body-background);
  border: 1px solid var(--border-color);
  border-radius: 30px;
  padding: 10px 13px;
  position: absolute;
  left: -14px;
  top: 16px;
  z-index: 10;
}

.projects-previews {
  display: flex;
}

@media (max-width: 320px) {
  .top-creator-item .projects-score {
    display: none;
  }
}

/* ================================
       18.3 Featured creators
       ================================ */

.featured-creators-item {
  display: flex;
  flex-wrap: nowrap;
  border: 1px solid var(--border-color);
  border-radius: 30px;
  z-index: 1;
}

.featured-creators-wrapper {
  padding: clamp(1rem, 3vmax, 45px);
}

.featured-creators-wrapper>* {
  margin-bottom: 30px;
}

.featured-creators-wrapper>div:last-child {
  margin-bottom: 0;
}

.featured-creators-info-wrapper {
  display: flex;
  justify-content: space-between;
}

.featured-creators-item>img {
  width: 177px;
  border-radius: 30px 20px 20px 30px;
  object-fit: cover;
  object-position: center bottom;
}

.featured-creators-item .title {
  font-size: 20px;
  line-height: 1.2;
  font-weight: var(--heading-font-weight);
  margin-bottom: 7px;
}

.featured-creators-item .title a:hover {
  color: inherit;
}

.creator-stats {
  display: flex;
}

.creator-stats .stats-item {
  margin-right: 40px;
}

.creator-stats .stats-item:last-child {
  margin-right: 0;
}

.follow-me {
  align-self: flex-start;
}

@media (max-width: 1199px) {
  .featured-creators-item {
    flex-wrap: wrap;
  }

  .featured-creators-item>img {
    width: 100%;
    height: 120px;
    border-radius: 25px;
    object-position: center center;
  }

  .projects-previews {
    margin-top: 20px;
  }
}

@media (max-width: 414px) {
  .featured-creators-item .creator-stats {
    justify-content: space-between;
  }

  .featured-creators-item .creator-stats .stats-item {
    margin-right: 0;
  }
}

@media (max-width: 320px) {
  .featured-creators-item .social-icons-list {
    justify-content: space-between;
  }

  .featured-creators-item .social-icons-list .social-icon {
    margin-right: 0;
  }

  .featured-creators-item .projects-previews {
    justify-content: space-between;
  }

  .featured-creators-item .preview-box {
    margin-right: 0;
  }
}

/* ================================
       18.4. Become a creator
       ================================ */

.become-creator-bc-box {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.become-creator-bc-box .bc-info {
  flex-basis: 40%;
  padding: 0 clamp(1rem, 4vmax, 60px);
}

.become-creator-bc-box .bc-info>* {
  margin-bottom: clamp(1rem, 3vmax, 40px);
}

.become-creator-bc-box .bc-image {
  padding: 0 clamp(1rem, 4vmax, 60px);
}

.bc-heading .bc-heading-subtitle {
  font-weight: var(--heading-font-weight);
  text-transform: uppercase;
}

.bc-heading .bc-heading-title {
  font-size: calc(var(--h1-heading) + 10px);
  font-weight: var(--heading-font-weight);
  line-height: 1;
  letter-spacing: var(--letter-spacing);
}

.bc-button a {
  display: inline-block;
}

.submit-artwork-section .section-title {
  margin-bottom: 60px;
}

@media (max-width: 800px) {
  .become-creator-bc-box {
    flex-direction: column;
  }
}

/* ================================
       19. PRODUCTS
       ================================ */

/* ================================
       19.1. Product v1
       ================================ */

.product-version-1 {
  display: grid;
  grid-template-columns: 52.5% 1fr;
}

.product-version-1 .product-media {
  background-color: var(--lighter-gray);
  padding-top: max(5vw, 85px);
  border-right: 1px solid var(--border-color);
  position: relative;
}

.product-img {
  position: relative;
  text-align: center;
}

.product-img img {
  width: 100%;
  height: auto;
}

.product-media .expand-icon {
  display: inline-flex;
  padding: 13px;
  border: 1px solid var(--border-color);
  border-radius: 50%;
  background: var(--body-background);
  margin-bottom: 15px;
  position: absolute;
  right: 18px;
  top: 18px;
  cursor: pointer;
  z-index: 10;
}

.product-media .expand-icon svg {
  fill: var(--main-font-color);
}

.product-media .expand-icon:hover svg {
  fill: var(--primary-accent-color);
}

.product-info-wrapper {
  padding: clamp(2rem, 5vmax, 80px) clamp(3rem, 6vmax, 100px);
}

.product-info-wrapper>*,
.product-info-wrapper .simplebar-content>* {
  margin-bottom: clamp(2rem, 4vmax, 60px);
}

.product-title {
  margin-bottom: 0;
}

.product-meta-section {
  display: flex;
}

.product-meta-item {
  flex: 0 1 190px;
}

.product-meta-item .label {
  font-size: clamp(0.5rem, 1vmax, 14px);
  text-transform: uppercase;
}

.product-meta-item .value {
  font-size: clamp(1rem, 2vmax, 32px);
  font-weight: var(--heading-font-weight);
  line-height: 1.2;
  letter-spacing: var(--letter-spacing);
}

.product-meta-item .caption {
  font-size: clamp(0.5rem, 1vmax, 14px);
  font-weight: var(--heading-font-weight);
}

.product-meta-item .caption span {
  padding-right: 26px;
}

.product-meta-item .caption span:last-child {
  padding-right: 0;
}

.bidding-section {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.bidding-section>* {
  margin-right: 15px;
}

@media (max-width: 414px) {
  .bidding-section>* {
    margin-bottom: 20px;
  }

  .product-info-wrapper {
    padding-left: 30px;
    padding-right: 30px;
  }
}

.product-fav-counter {
  width: max(4.6vw, 70px);
  height: max(2.12vw, 36px);
  max-height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--border-color);
  border-radius: 30px;
  line-height: 1;
  cursor: pointer;
}

.product-fav-counter svg {
  fill: var(--main-font-color);
}

.product-fav-counter.gradient-background svg {
  fill: var(--contrast-text-color);
}

.product-fav-counter span {
  font-size: var(--body-font-size);
}

.product-fav-counter:hover svg {
  fill: var(--primary-accent-color);
}

.share-icons svg {
  margin-right: 15px;
  fill: var(--main-font-color);
}

@media (max-width: 800px) {
  .product-fav-counter {
    width: 45px;
  }

  .product-fav-counter span {
    display: none;
  }
}

/* ================================
       19.1.1. Countdown JS
       ================================ */

.product-meta-item .js-countdown .countdown__timer {
  display: flex;
}

.product-meta-item .js-countdown .countdown__item {
  display: flex;
  flex-direction: column;
}

.product-meta-item .countdown__item+.countdown__item {
  margin-left: 15px;
}

.product-meta-item .js-countdown .countdown__value {
  font-size: clamp(1rem, 2vmax, 32px);
  font-weight: var(--heading-font-weight);
  line-height: 1.2;
  letter-spacing: var(--letter-spacing);
}

.product-meta-item .js-countdown .countdown__label {
  font-size: clamp(0.5rem, 1vmax, 14px);
  font-weight: var(--heading-font-weight);
}

@media (max-width: 1199px) {
  .product-version-1 {
    grid-template-columns: 1fr;
  }

  .product-version-1 .product-img {
    text-align: center;
  }
}

/* ================================
       19.1.2. Placed bids
       ================================ */

.placed-bid {
  display: flex;
  justify-content: space-between;
  padding: 26px 30px;
  border: 1px solid var(--border-color);
  border-radius: 25px;
  margin-bottom: 20px;
}

.bid-box {
  text-align: right;
}

.placed-bid .creator-meta .creator-name,
.bid-box .crypto-value {
  font-weight: var(--heading-font-weight);
  line-height: 1;
  margin-bottom: 10px;
}

.placed-bid .creator-meta .bid-date,
.bid-box .currency-value {
  font-size: 13px;
  line-height: 1;
}

/* ================================
       19.1.3. Related items
       ================================ */

.product-version-1 .related-section .featured-box {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.product-version-1 .related-section .featured-item {
  flex: 0 0 218px;
  margin-bottom: 20px;
  margin-right: 20px;
}

.product-version-1 .related-section .featured-item:last-child {
  margin-right: 0;
}

@media (max-width: 1199px) {
  .product-version-1 .related-section .featured-item {
    flex: 0 0 30%;
  }
}

@media (max-width: 767px) {
  .product-version-1 .related-section .featured-item {
    flex: 0 0 49%;
  }
}

@media (max-width: 480px) {
  .product-version-1 .related-section .featured-item {
    flex: 0 0 100%;
  }
}

/* ================================
       19.2. Product v2
       ================================ */

.product-version-2 {
  margin-bottom: clamp(2rem, 5vmax, 80px);
}

.product-version-2 .product-media {
  background-color: var(--pure-black);
}

.product-version-2 .product-info {
  background-color: var(--body-background);
  width: 980px;
  max-width: 100%;
  margin: -80px auto 0;
  border-radius: 20px;
  position: relative;
  z-index: 10;
}

.product-version-2 .page-title-section,
.product-version-2 .product-author,
.product-version-2 .product-meta-section,
.product-version-2 .place-bid,
.product-version-2 .bidding-section {
  display: flex;
  justify-content: center;
}

.product-version-2 .product-meta-section .product-meta-item {
  text-align: center;
}

.product-version-2 .place-bid {
  margin-bottom: 40px;
}

.product-version-2 .related-section .section-title-wrapper h3 {
  margin: 0 auto 0.25em;
}

@media (max-width: 1024px) {
  .product-version-2 .product-info {
    margin: -60px auto 0;
  }
}

@media (max-width: 767px) {
  .product-version-2 .product-info {
    margin: 0 auto 0;
  }
}

/* ================================
       19.3. Product v3
       ================================ */

.product-version-3>* {
  margin-bottom: clamp(2rem, 5.6vmax, 90px);
}

.product-version-3 .page-title-section {
  align-items: center;
  margin-bottom: clamp(1rem, 4vmax, 60px);
}

.product-version-3 .product-main-info {
  display: flex;
  justify-content: space-between;
}

.product-version-3 .product-main-info>* {
  flex-basis: 33.3%;
  margin-right: clamp(3rem, 7.5vmax, 120px);
}

.product-version-3 .product-main-info>div:last-child {
  margin-right: 0;
}

.product-version-3 .product-author,
.product-version-3 .product-description {
  margin-bottom: 50px;
}

.product-version-3 .product-description:last-child {
  margin-bottom: 0;
}

.favourite-section {
  display: flex;
}

.favourite-section .product-fav-counter {
  margin-right: 15px;
}

.product-version-3 .product-media {
  position: relative;
}

.product-version-3 .product-media svg {
  fill: var(--contrast-text-color);
}

.product-version-3 .product-media .expand-icon {
  position: absolute;
  background: var(--pure-black);
  opacity: 0.8;
  border: 1px solid var(--pure-black);
  right: 12px;
  top: 12px;
  margin-bottom: 0;
  z-index: 10;
}

.product-version-3 .product-meta-section {
  display: flex;
  flex-direction: column;
}

.product-version-3 .product-meta-item {
  flex-basis: auto;
  margin-bottom: clamp(2rem, 3vmax, 50px);
}

.bids-listing-section {
  display: flex;
  justify-content: space-between;
}

.bids-listing-section .bids-list {
  flex: 0 1 700px;
  margin-right: 20px;
}

.bids-listing-section .bids-list:last-child {
  margin-right: 0;
}

@media (max-width: 1024px) {
  .product-version-3 .product-main-info {
    flex-wrap: wrap;
  }

  .product-version-3 .product-main-info>* {
    flex-basis: 46%;
    margin-right: 0;
    margin-bottom: 60px;
  }

  .product-version-3 .product-main-info>div:last-child {
    margin-bottom: 0;
  }

  .product-version-3 .product-meta-section {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-basis: 100%;
  }
}

@media (max-width: 800px) {
  .product-version-3 .product-meta-section {
    flex-wrap: wrap;
  }

  .bids-listing-section {
    flex-direction: column;
  }

  .bids-listing-section .bids-list {
    flex-basis: auto;
    margin-right: 0;
  }
}

@media (max-width: 600px) {
  .product-version-3 .product-main-info>* {
    flex-basis: 100%;
  }

  .product-version-3 .page-title-section {
    flex-direction: column;
  }

  .product-version-3 .page-title-section>* {
    margin-bottom: 20px;
  }
}

/* ================================
Product detail text of user name
       ================================ */

.bold-user-name {
  /* font-weight: 700; */
  font-size: 14px;
  font-weight: var(--heading-font-weight);
  line-height: 1.4;
  letter-spacing: var(--letter-spacing);
}

/* ================================
       19.4. Product page digital store
       ================================ */

.product-page-ds {
  display: flex;
}

.product-page-ds .main-content-area {
  flex-basis: 68%;
}

.product-page-ds aside {
  flex-basis: 32%;
  margin-left: 20px;
}

.product-ds>* {
  margin-bottom: 30px;
}

.product-title-section .product-subtitle {
  display: flex;
  align-items: center;
}

.product-title-section .product-subtitle>* {
  margin-right: 12px;
}

.product-title-section .product-subtitle>div:last-child {
  margin-right: 0;
}

.product-title-section .product-author {
  display: flex;
  align-items: center;
}

.product-title-section .product-author .avatar {
  margin-right: 8px;
}

.product-ds .product-image img {
  width: 100%;
  object-fit: cover;
  border-radius: 23px;
  margin-top: 0 !important;
}

.product-ds .product-info .product-description {
  padding: clamp(1rem, 3.1vmax, 50px) clamp(1rem, 3.5vmax, 57px) clamp(1rem, 2.5vmax, 40px);
  border: 1px solid var(--border-color);
  border-radius: 25px;
}

.product-ds .product-info .product-description .title {
  margin-bottom: clamp(1rem, 2.5vmax, 40px);
}

.comments-list+.leave-comment,
.reviews-list+.leave-review {
  margin-top: 60px;
}

.leave-comment h5,
.leave-review h5 {
  margin-bottom: clamp(1rem, 3.1vmax, 50px);
}

.review-item {
  padding: 30px;
  border: 1px solid var(--border-color);
  border-radius: 25px;
  margin-bottom: 20px;
}

.review-item>* {
  margin-bottom: 25px;
}

.review-item>div:last-child {
  margin-bottom: 0;
}

.review-title {
  font-size: 16px;
  font-weight: var(--heading-font-weight);
  letter-spacing: var(--letter-spacing);
}

.review-footer {
  display: flex;
}

.review-author {
  display: flex;
  margin-right: 12px;
}

.review-author .avatar {
  margin-right: 8px;
}

.rating-field {
  display: flex;
  margin-bottom: 15px;
}

.rating-field label {
  font-size: var(--body-font-size);
  font-weight: var(--heading-font-weight);
  text-transform: capitalize;
  margin-right: 12px;
}

.product-additional-info {
  padding: clamp(1rem, 3.75vmax, 60px);
  border: 1px solid var(--border-color);
  border-radius: 25px;
  position: relative;
  /* height: 80vh; */
  /* overflow-y: auto; */
  /* overflow-x: hidden; */
}

.product-additional-info::-webkit-scrollbar {
  display: none;
}

.product-additional-info>* {
  margin-bottom: clamp(2rem, 4.3vmax, 45px);
}

.product-additional-info>div:last-child {
  margin-bottom: 0;
}

.product-additional-info .product-fav-counter {
  position: absolute;
  top: 22px;
  right: -12px;
  background: var(--body-background);
  margin-bottom: 0;
}

.product-additional-info2 {
  padding: 25px 0px 5px 40px;
  border: 1px solid var(--border-color);
  border-radius: 25px;
  margin: 13px 23px 0 34px;
}

.pricing-plans {
  margin-bottom: 30px;
}

.pricing-plans>* {
  margin-bottom: 20px;
}

.pricing-plans .plan label+.license-description {
  margin-top: 20px;
}

#purchase-form .product-price {
  display: flex;
  align-items: baseline;
  margin-bottom: clamp(1rem, 3.1vmax, 30px);
}

#purchase-form .product-price .price {
  font-size: var(--h2-heading);
  font-weight: var(--heading-font-weight);
  letter-spacing: var(--letter-spacing);
  line-height: 1;
  margin-right: 8px;
}

#purchase-form .product-price .label {
  font-size: var(--body-background);
  font-weight: var(--body-font-weight);
  letter-spacing: var(--letter-spacing);
  margin-bottom: 0;
}

#purchase-form button[type='submit']+button[type='submit'] {
  margin-top: 20px;
}

.product-score {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.product-score .score-item {
  text-align: center;
  padding: clamp(1rem, 1.87vmax, 30px);
  border: 1px solid var(--border-color);
  border-radius: 25px;
  flex: 0 1 160px;
}

.product-score .value {
  font-size: clamp(1rem, 2vmax, 32px);
  font-weight: var(--heading-font-weight);
  line-height: 1;
  letter-spacing: var(--letter-spacing);
  margin-bottom: 10px;
}

.product-score .label {
  font-size: var(--body-font-size);
  font-weight: var(--body-font-weight);
  line-height: 1;
}

.product-detail-distance {
  margin-bottom: 1.2rem;
}

.product-additional-info .product-details-wrapper {
  display: flex;
  font-size: var(--body-font-size);
}

.product-additional-info .product-details ul {
  flex-basis: 50%;
}

.product-additional-info .product-details ul li {
  margin-bottom: 20px;
}

.product-additional-info .product-details .details-value {
  font-weight: var(--heading-font-weight);
}

.product-additional-info .product-details .details-value .tags-list {
  line-height: 1.2;
}

.product-additional-info .small-title {
  margin-bottom: 30px;
}

.product-additional-info .seller-info {
  flex-direction: column;
  align-items: flex-start;
}

.product-additional-info .seller-box {
  display: flex;
}

.product-additional-info .seller-name .name {
  font-size: 18px;
  font-weight: var(--heading-font-weight);
  letter-spacing: var(--letter-spacing);
  line-height: 1.2;
  margin-bottom: 0;
}

.product-additional-info .seller-name .meta {
  font-size: var(--body-font-size);
  font-weight: var(--body-font-weight);
  display: inline-block;
}

.product-additional-info .seller-meta>* {
  margin-bottom: 25px;
}

.product-additional-info .seller-meta>div:last-child {
  margin-bottom: 0;
}

.product-additional-info .seller-meta .social-icons-list li {
  margin-right: 5px;
}

.product-additional-info .seller-meta .seller-buttons {
  display: flex;
}

.seller-buttons .btn+.btn {
  margin-left: 12px;
}

@media (max-width: 800px) {
  .product-page-ds {
    flex-wrap: wrap;
  }

  .product-page-ds .main-content-area,
  .product-page-ds aside {
    flex-basis: 800px;
    max-width: 100%;
  }

  .product-additional-info {
    margin-top: 50px;
  }

  .product-additional-info .seller-meta .social-icons-list {
    flex-wrap: wrap;
  }
}

@media (max-width: 414px) {
  .product-score .score-item {
    flex-basis: 130px;
  }

  .product-additional-info .seller-meta .social-icons-list li {
    margin-bottom: 5px;
  }

  .btn-small-wide {
    padding: 10px 12px;
  }
}

/* ================================
       20. PROFILE
       ================================ */

.profile-page .profile-page-container {
  padding-top: clamp(2rem, 4vmax, 70px);
  padding-bottom: clamp(2rem, 4vmax, 70px);
}

.profile-header-mobile {
  display: none;
}

.profile-page .tabs-list {
  justify-content: center;
}

@media (max-width: 800px) {
  .profile-page .tabs-list {
    justify-content: flex-start;
  }
}

/* ================================
       20.1. Profile header
       ================================ */

.profile-header-section .cover-image {
  max-height: 320px;
}

.profile-header-section .cover-image img {
  max-height: 320px;
  width: 100%;
  object-fit: cover;
}

.profile-header-user-profile {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -80px;
  position: relative;
}

.profile-header-user-profile .follow-box {
  display: flex;
  align-items: center;
  position: absolute;
  top: 50px;
  right: 0;
  height: 56px;
}

.more-link {
  width: max(3.75vw, 45px);
  height: max(3.12vw, 36px);
  max-height: 50px;
  border: 1px solid var(--border-color);
  border-radius: 30px;
  margin-right: 15px;
  background: var(--body-background);
  cursor: pointer;
}

.more-link a {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.more-link:hover svg {
  fill: var(--primary-accent-color);
}

.author-primary-info {
  display: flex;
  justify-content: space-around;
  align-items: baseline;
  margin-top: 10px;
  margin-bottom: 30px;
}

.author-primary-info .activity-meta,
.author-primary-info .projects-meta {
  display: flex;
  justify-content: space-between;
  flex: 0 1 33.3%;
}

.author-primary-info .activity-meta>* .author-primary-info .projects-meta>* {
  text-align: center;
}

.activity-meta .number,
.projects-meta .number {
  font-size: clamp(16px, calc(1rem + 1vw), 32px);
  font-weight: var(--heading-font-weight);
  line-height: 1;
  margin-bottom: 15px;
}

.activity-meta .label,
.projects-meta .label {
  line-height: 1;
}

.projects-meta .country .flag {
  margin: 0 0 15px;
}

.projects-meta .country .flag img {
  margin: 0 auto;
}

.profile-author-info {
  text-align: center;
}

.profile-author-info a:hover {
  color: inherit;
}

.profile-author-info h3 {
  margin-bottom: 0;
  line-height: 1.2;
}

.profile-author-info .author-meta {
  font-size: 16px;
}

.profile-author-info .author-meta+.download-file {
  margin-top: 24px;
}

.profile-author-info .download-file {
  display: inline-block;
  padding: 8px 12px;
  border: 1px solid var(--border-color);
  border-radius: 30px;
  font-size: 12px;
  line-height: 1;
  font-weight: var(--heading-font-weight);
  white-space: nowrap;
  cursor: pointer;
}

.profile-author-info .download-file svg {
  fill: var(--main-font-color);
  margin-left: 5px;
}

.author-description {
  width: 700px;
  max-width: 100%;
  margin: 0 auto;
  text-align: center;
}

.author-description p {
  margin-bottom: 30px;
}

.author-description .social-icons-list {
  justify-content: center;
}

/* ================================
       20.2. About author
       ================================ */

.about-author-wrapper {
  display: flex;
  justify-content: flex-start;
}

.about-author-wrapper>* {
  flex: 0 1 33%;
  margin-right: clamp(1rem, 5vmax, 100px);
}

.about-author-wrapper>div:last-child {
  margin-right: 0;
}

@media (max-width: 1024px) {
  .about-author-wrapper {
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .about-author-wrapper>* {
    flex: 0 0 48%;
    margin-right: 0;
    margin-bottom: 20px;
  }
}

@media (max-width: 800px) {
  .about-author-wrapper>* {
    flex: 0 0 100%;
  }
}

/* ================================
       20.3. Profile header mobile
       ================================ */

@media (max-width: 800px) {
  .profile-header-section {
    display: none;
  }

  .profile-header-mobile {
    display: block;
    margin-top: 9px;

  }

  .profile-header-mobile .about-author-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: -40px;
    margin-bottom: 30px;
  }

  .profile-header-mobile .about-author-section>* {
    justify-content: center;
  }

  .profile-author-info .download-file {
    display: none;
  }

  .profile-header-mobile .about-author-section .profile-header-user-profile {
    margin-bottom: 30px;
  }

  .profile-header-mobile .follow-container .more-link-wrapper {
    margin-right: 10px;
  }

  .profile-header-mobile .follow-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .more-link {
    width: 48px;
    height: 36px;
  }

  .profile-header-mobile .more-link-wrapper .more-link {
    margin: 0 auto;
  }

  .profile-header-mobile .follow-box button {
    display: block;
    margin: 0 auto;
  }

  .profile-header-mobile .profile-header-user-profile {
    margin-top: 0;
  }

  .profile-header-mobile .profile-header-user-profile .avatar {
    border: 8px solid var(--body-background);
    width: 80px;
    height: 80px;
  }

  .profile-header-mobile .social-icons-list {
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 20px;
  }

  .profile-header-mobile .profile-author-info .author-meta {
    font-size: var(--body-font-size);
    line-height: 1;
  }

  .profile-header-mobile .social-icons-list .social-icon {
    margin-right: 8px;
    margin-bottom: 10px;
  }

  .profile-header-mobile .social-icons-list .social-icon:last-child {
    margin-right: 0;
  }

  .profile-header-mobile .author-primary-info {
    display: flex;
    justify-content: center;
    align-items: baseline;
    margin-bottom: 0;
  }

  .profile-header-mobile .author-primary-info .activity-meta {
    flex-basis: 100%;
    justify-content: space-around;
  }

  .profile-header-mobile .author-primary-info .activity-meta>* {
    text-align: center;
  }
}

/* ================================
      20.4. Profile page digital store
      ================================ */

.profile-page-ds {
  margin-top: 20px;
}

.profile-page-ds .profile-header-section {
  position: relative;
}

.profile-page-ds .follow-box {
  position: absolute;
  right: 32px;
  top: 27px;
  display: flex;
}

.profile-page-ds .profile-header-user-profile {
  margin-top: -70px;
  margin-bottom: 15px;
}

.profile-page-ds .profile-author-info .author-meta {
  display: inline-block;
  margin-bottom: 5px;
  font-weight: var(--heading-font-weight);
}

.profile-page-ds .item-rating {
  justify-content: center;
}

.profile-page-ds .profile-page-container {
  padding-top: clamp(2rem, 4vmax, 70px);
}

@media (max-width: 800px) {
  .profile-page-ds .profile-header-mobile .about-author-section {
    justify-content: center;
    margin-top: 0;
    margin-bottom: 0;
  }

  .profile-page-ds .profile-header-mobile .about-author-section>* {
    flex-basis: 100%;
  }

  .profile-page-ds .profile-header-mobile .profile-header-user-profile {
    margin-top: -30px;
    margin-bottom: 20px;
  }

  .profile-page-ds .profile-header-mobile .profile-header-user-profile .avatar {
    margin-bottom: 10px;
  }

  .profile-page-ds .profile-header-mobile .profile-header-user-profile .profile-author-info {
    margin-bottom: 15px;
  }

  .profile-page-ds .profile-header-mobile .profile-header-user-profile .author-name {
    line-height: 1;
    margin-bottom: 0;
  }

  .profile-page-ds .follow-box {
    position: relative;
    top: auto;
    right: auto;
    justify-content: center;
  }
}

/* ================================
       21. COLLECTORS
       ================================ */

.collectors-filter select:last-child {
  margin-left: 15px;
}

.collectors-filter select {
  flex-basis: 293px;
}

table.content-table {
  width: 100%;
  text-align: left;
  border-collapse: separate;
  border-spacing: 0 20px;
}

.content-table thead .heading-label {
  font-size: 10px;
  text-transform: uppercase;
}

.content-table thead .heading-label:first-child {
  padding-left: 30px;
}

.content-table th {
  padding: 15px 0;
  border-bottom: 1px solid var(--border-color);
}

.content-table tbody>tr {
  padding: 30px;
}

.content-table .collector-info {
  flex-basis: 380px;
}

.content-table td {
  border: solid 1px var(--border-color);
  border-style: solid none;
  padding-top: 27px;
  padding-bottom: 27px;
  background-color: var(--body-background);
}

.content-table td:first-child {
  border-left-style: solid;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  padding-left: 30px;
}

.content-table td:last-child {
  border-right-style: solid;
  border-bottom-right-radius: 25px;
  border-top-right-radius: 25px;
  padding-right: 30px;
}

.collectors-box .stats-item {
  font-weight: var(--heading-font-weight);
}

@media (max-width: 414px) {
  .collectors-filter form {
    flex-direction: column;
    justify-content: flex-start;
  }

  .collectors-filter div.nice-select~div.nice-select {
    margin-left: 0;
    margin-top: 10px;
  }
}

/* ================================
       21.1. Collectors mobile view
       ================================ */

@media (max-width: 800px) {
  table.content-table {
    border: 0;
    border-spacing: 0;
  }

  table.content-table caption {
    font-size: 1.3em;
  }

  table.content-table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  table.content-table tr {
    display: block;
    margin-bottom: 0.625em;
    border-bottom: 2px solid var(--border-color);
  }

  table.content-table td {
    display: block;
    font-size: 0.8em;
    text-align: right;
  }

  table.content-table td::before {
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }

  table.content-table tr:last-child {
    border-bottom: 0;
  }

  .content-table tbody>tr {
    padding: 10px;
  }

  .content-table td {
    border: none;
    padding: 5px 0;
  }

  .content-table td:first-child {
    border: none;
    padding-left: 0;
  }

  .content-table td:last-child {
    border: none;
    padding-right: 0;
  }

  .collectors-filter+.collectors-box table.content-table {
    border-spacing: 0 20px;
  }

  .collector-info {
    flex-basis: 0;
    justify-content: flex-end;
  }

  .collector-info .collector-meta {
    text-align: left;
  }

  .collector-info .small-title {
    margin-bottom: 0;
    font-size: 0.9rem;
  }

  .collector-info .avatar,
  .collector-meta .meta {
    display: none;
  }
}

/* ================================
       22. ARTWORKS
       ================================ */

/* ================================
       22.1. Artworks
       ================================ */

.artworks-carousel {
  margin-bottom: 30px;
}

.artwork-item {
  display: flex;
  border: 1px solid var(--border-color);
  border-radius: 25px;
}

.artwork-item>* {
  flex-basis: 50%;
}

.artwork-item .artwork-media,
.artwork-item .artwork-media img {
  border-radius: 25px;
}

.artwork-item .artwork-details {
  padding: clamp(1rem, 3vmax, 40px) clamp(1rem, 4vmax, 60px);
}

.artwork-item .artwork-details .avatar-block {
  margin-bottom: 30px;
}

.artwork-item .artwork-details .product-meta-section {
  margin-bottom: 30px;
}

.artwork-item .artwork-details .product-description {
  margin-bottom: clamp(1rem, 3.2vmax, 60px);
}

.artwork-item .bidding-section .product-fav-counter {
  color: var(--contrast-text-color);
  fill: var(--contrast-text-color);
  border: transparent;
}

.artwork-item .bidding-section .product-fav-counter:hover svg {
  fill: var(--contrast-text-color);
}

@media (max-width: 1024px) {}

.artwork-item .artwork-details {
  padding-left: clamp(1rem, 2.5vmax, 40px);
  padding-right: clamp(1rem, 2.5vmax, 40px);
}

@media (max-width: 800px) {
  .artwork-item {
    flex-direction: column;
  }

  .artwork-item>* {
    flex-basis: 100%;
  }
}

/* ================================
       22.2. Collections
       ================================ */

.collection-item {
  position: relative;
  border-radius: 28px;
  border: 1px solid var(--border-color);
}

.collection-item .cover-image img {
  width: 100%;
  height: auto;
  border-radius: 25px;
}

.collection-item .collection-info {
  width: 70%;
  color: var(--contrast-text-color);
  position: absolute;
  top: 40px;
  left: 40px;
}

.collection-item .collection-info .title {
  line-height: 1.1;
}

.collection-item .collection-info .number {
  font-size: 16px;
}

/* ================================
       23. ACTIVITY
       ================================ */

.activity-list+.load-more_bars {
  margin-top: 75px;
}

.activity-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 15px 15px 30px;
  border: 1px solid var(--border-color);
  border-radius: 25px;
  margin-bottom: 15px;
  position: relative;
}

.activity-item.bid::before {
  content: '';
  /* background: url(../svg/annotation.svg) no-repeat center; */
  background-size: 12px;
  background-color: #6a52ff;
  width: 30px;
  height: 24px;
  border-radius: 30px;
  position: absolute;
  left: -6px;
  top: 10px;
}

.activity-item.like::before {
  content: '';
  /* background: url(../svg/heart-white.svg) no-repeat center; */
  background-size: 13px;
  background-color: #ff3a4e;
  width: 30px;
  height: 24px;
  border-radius: 30px;
  position: absolute;
  left: -6px;
  top: 10px;
}

.activity-item.follow {
  padding: 30px;
}

.activity-item.follow::before {
  content: '';
  /* background: url(../svg/profile-white.svg) no-repeat center; */
  background-size: 12px;
  background-color: #2371ff;
  width: 30px;
  height: 24px;
  border-radius: 30px;
  position: absolute;
  left: -6px;
  top: 10px;
}

.activity-item.sale::before {
  content: '';
  /* background: url(../svg/dollar-white.svg) no-repeat center; */
  background-size: 12px;
  background-color: #ff813b;
  width: 30px;
  height: 24px;
  border-radius: 30px;
  position: absolute;
  left: -6px;
  top: 10px;
}

.activity-item.purchase::before {
  content: '';
  /* background: url(../svg/wallet-white.svg) no-repeat center; */
  background-size: 12px;
  background-color: #ffc000;
  width: 30px;
  height: 24px;
  border-radius: 30px;
  position: absolute;
  left: -6px;
  top: 10px;
}

.activity-item.listing::before {
  content: '';
  /* background: url(../svg/bookmark-white.svg) no-repeat center; */
  background-size: 10px;
  background-color: #00e7a3;
  width: 30px;
  height: 24px;
  border-radius: 30px;
  position: absolute;
  left: -6px;
  top: 10px;
}

.bid-placer {
  display: flex;
  align-items: center;
}

.bid-placer .avatar+.bid-info {
  margin-left: 10px;
}

.bid-info .bid-title {
  line-height: 1.4;
  margin-bottom: 5px;
  font-weight: var(--heading-font-weight);
}

.bid-info .bid-date {
  font-size: 13px;
  line-height: 1;
}

.bid-product {
  width: 72px;
  height: 72px;
  border: 1px solid var(--border-color);
  border-radius: 18px;
  background: var(--extra-light-gray);
}

.bid-product img {
  min-width: 72px;
  border-radius: 18px;
}

.load-more {
  width: 65px;
  margin: 0 auto;
}

@media (max-width: 414px) {
  .activity-item {
    border-radius: 20px;
  }

  .activity-item.follow {
    padding: 15px 15px 15px 30px;
  }

  .bid-product {
    width: 50px;
    height: 50px;
    margin-left: 10px;
  }

  .bid-product img {
    min-width: 50px;
    border-radius: 12px;
  }
}

/* ================================
      24. USER DASHBOARD
      ================================ */

.user-db-menu {
  width: auto;
  max-width: 100%;
  border: 1px solid var(--border-color);
  border-radius: 25px;
}

.user-db-content-area>* {
  margin-bottom: 50px;
}

.user-db-content-area>div:last-child {
  margin-bottom: 0;
}

/* .user-db-content-area + button {
  margin-top: clamp(1rem, 4.4vmax, 70px);
} */

.user-db-title {
  font-size: 24px;
  line-height: 1.2;
  font-weight: var(--heading-font-weight);
  margin-bottom: 50px;
}

.user-db-cover-image.profile-cover-image {
  height: 100px;
  border-radius: 25px;
}

.user-header-user-profile {
  margin-top: -45px;
  padding: 0 25px 30px;
  border-bottom: 1px solid var(--border-color);
  border-radius: 25px;
}

.user-header-user-profile .avatar {
  width: 80px;
  height: 80px;
  border: 8px solid var(--body-background);
  background-color: var(--body-background);
  margin-bottom: 10px;
}

.user-header-user-profile .title {
  font-size: 20px;
  font-weight: var(--heading-font-weight);
  line-height: 1.2;
  margin-bottom: 7px;
}

.user-header-user-profile .title a:hover {
  color: inherit;
}

.user-header-user-profile .item-meta {
  font-size: 16px;
  font-weight: var(--heading-font-weight);
  line-height: 1;
}

.user-db-body {
  padding: 30px 0;
}

.user-db-body .profile-menu li a {
  font-size: var(--body-font-size);
}

.user-db-body .profile-menu li svg {
  width: 18px;
  height: 18px;
  margin-right: 14px;
}

.user-db-body .profile-menu li:hover {
  background: none;
}

.user-db-body .profile-menu li:hover a {
  color: inherit;
  transform: translateX(0);
}

.upload-photo-box .user-avatar {
  position: relative;
  margin-right: 20px;
  margin-bottom: 30px;
  float: left;
}

.upload-photo-box .user-avatar,
.upload-photo-box .user-avatar img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
}

.upload-photo-box .user-cover-image {
  position: relative;
  float: left;
  margin-bottom: 30px;
}

.upload-photo-box .user-cover-image {
  max-width: 600px;
  width: 100%;
  height: 100px;
  background-color: var(--lighter-gray);
}

.upload-photo-box .user-cover-image img {
  width: 600px;
  height: 100px;
  object-fit: cover;
}

.upload-photo-box .user-avatar::after,
.upload-photo-box .user-cover-image::after {
  /* content:url(../svg/camera.svg); */
  width: 44px;
  height: 34px;
  line-height: 1;
  background-color: var(--body-background);
  background-repeat: no-repeat;
  background-position: center;
  border: 1px solid var(--border-color);
  border-radius: 30px;
  padding: 8px 14px;
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
}

.labelFile {
  cursor: pointer;
  right: 5px;
  top: 5px;
  background-color: #fff;
  border-radius: 50%;
  width: 44px;
  height: 34px;
  line-height: 1;
  border: 1px solid var(--border-color);
  border-radius: 30px;
  padding: 7px 13px;
}

.upload-photo-box .upload-notice {
  clear: both;
}

/* ================================
       24.1. Upload artwork
       ================================ */

.artwork-upload-box .label {
  font-size: var(--body-font-size);
  font-weight: var(--heading-font-weight);
  line-height: 1.5;
  text-transform: capitalize;
  margin-bottom: 15px;
}

.artwork-upload-box .upload-container {
  display: flex;
}

.artwork-upload {
  width: 100%;
  margin-right: 20px;
}

.upload-box {
  width: 100%;
  height: 180px;
  border: 1px dashed var(--border-color);
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.upload-box img {
  width: 100%;
  height: 155px;
  border-radius: 20px;
  margin-top: 30px;
}

.upload-box>* {
  margin-bottom: 15px;
}

.upload-container .upload-box .browse-btn {
  margin-bottom: 0;
}

.upload-container .artwork-preview {
  max-width: 100%;
  width: 220px;
  height: 180px;
}

.artwork-preview .preview-box {
  width: 220px;
  height: 180px;
  border: 1px solid var(--border-color);
  border-radius: 25px;
  display: flex;
}

@media (max-width: 414px) {
  .artwork-upload-box .upload-container {
    flex-direction: column;
  }
}

/* ================================
       24.2. Dashboard tabs
       ================================ */

.db-tabs-list {
  list-style: none;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.db-tabs-list li {
  flex-basis: 33.3%;
  margin-right: 20px;
}

.db-tabs-list .licence-input {
  padding: 0 25px 30px;
}

.db-tabs-list .licence-input input {
  /* padding: 0 25px 30px; */
  width: 100%;
  margin-bottom: 0 !important;
}

.db-tabs-list li:last-child {
  margin-right: 0;
}

.db-tabs-list li:not(.active) {
  border: solid 1px var(--border-color);
  border-radius: 25px;
}

.db-tabs-list li.active {
  border: solid 2px transparent;
  border-radius: 25px;
  background-image: linear-gradient(var(--body-background),
      var(--body-background)),
    linear-gradient(to right,
      var(--primary-gradient-color),
      var(--secondary-gradient-color));
  background-origin: border-box;
  background-clip: content-box, border-box;
}

.db-tabs-list a {
  display: flex;
  align-items: baseline;
  padding: 25px 30px;
}

.db-tabs-list a:hover {
  color: inherit;
}

.purchase-type-symbol {
  margin-right: 10px;
}

.purchase-type-symbol svg {
  fill: var(--main-font-color);
}

.purchase-type-info .purchase-type-title {
  font-size: var(--body-font-size);
  font-weight: var(--heading-font-weight);
  line-height: 1.5;
  letter-spacing: var(--letter-spacing);
  margin: 0 0 0.5em;
}

.purchase-type-info .purchase-type-description {
  line-height: 1.4;
}

@media (max-width: 1024px) {
  .purchase-type-symbol {
    display: none;
  }
}

@media (max-width: 414px) {
  .db-tabs-list {
    flex-direction: column;
  }

  .db-tabs-list li {
    margin-right: 0;
    margin-bottom: 20px;
  }

  .purchase-type-symbol {
    display: block;
  }
}

/* ================================
      25. SELLERS PAGE
      ================================ */

.digital-store .creator-item {
  position: relative;
}

.digital-store .creator-item .follow-me {
  position: absolute;
  right: -10px;
  top: 75px;
}

.digital-store .creator-item .creator-info {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.digital-store .creator-item .social-icons-list:not(.swiper-wrapper) {
  justify-content: center;
}

.digital-store .projects-previews .preview-box {
  width: 82px;
  height: 50px;
}

.digital-store .projects-previews .preview-box.more-projects {
  width: 50px;
  height: 50px;
}

.digital-store .creator-item .creator-stats {
  padding: 0;
  justify-content: center;
  text-align: center;
}

.digital-store .creator-item .projects-previews {
  padding: 22px 30px;
}

/* ================================
       26. SHOPPING CART AND CHECKOUT
       ================================ */

/* ================================
       26.1. Shopping cart page
       ================================ */

.shopping-cart-area.grid-right-sidebar {
  grid-column-gap: 20px;
}

.shopping-cart tbody tr {
  position: relative;
}

.shopping-cart td {
  padding-right: 50px;
}

table td.price {
  font-weight: var(--heading-font-weight);
}

table .product-info {
  display: flex;
  align-items: center;
}

table .product-thumb {
  width: 82px;
  height: auto;
  /* border: 1px solid var(--border-color); */
  border-radius: 12px;
  margin-right: 10px;
}

table .product-thumb img {
  border-radius: 12px;
}

table .product-details .product-name {
  font-size: var(--body-background);
  font-weight: var(--heading-font-weight);
  line-height: 1;
  margin-bottom: 8px;
}

table .product-details .product-meta {
  display: flex;
  align-items: center;
}

table .product-details .product-seller {
  font-size: 12px;
  font-weight: var(--body-font-weight);
  display: flex;
  align-items: center;
  margin-left: 5px;
}

table .product-seller .verified {
  font-size: 0;
}

table .product-seller .verified svg {
  width: 10px;
  height: 10px;
  padding: 2px;
  border: none;
  margin-left: 5px;
}

table .item-price {
  font-size: var(--body-font-size);
  font-weight: var(--heading-font-weight);
}

#discount-form {
  float: right;
}

#discount-form>* {
  margin-left: 15px;
}

#discount-form input {
  padding: max(0.87vw, 12px) max(1.5vw, 18px);
}

#discount-form input::placeholder {
  font-weight: var(--body-font-weight);
}

.cart-total-box {
  border: 1px solid var(--border-color);
  border-radius: 25px;
  padding: 30px;
}

.cart-total-wrapper>* {
  margin-bottom: 50px;
}

.cart-total-wrapper>div:last-child {
  margin-bottom: 0;
}

.cart-total-box .total-price {
  font-size: var(--h2-heading);
  font-weight: var(--heading-font-weight);
  line-height: 1;
  text-align: center;
}

.total-price-details .field {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
}

.total-price-details .field:last-child {
  margin-bottom: 0;
}

.total-price-details .label,
.total-price-details .value {
  line-height: 1;
}

.total-price-details .value {
  font-weight: var(--heading-font-weight);
}

.to-checkout-button a {
  display: block;
}

.to-checkout-button a.gradient-background:hover {
  color: var(--contrast-text-color);
}

/* ================================
      26.1.1 Shopping cart mobile view
      ================================ */

@media (max-width: 1023px) {
  .shopping-cart-area.grid-right-sidebar {
    grid-template-columns: 1fr;
    grid-row-gap: clamp(1rem, 8vw, 140px);
    grid-column-gap: 0;
  }
}

@media (max-width: 800px) {
  .shopping-cart tbody>tr {
    padding: 10px;
  }

  .shopping-cart table.content-table td {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    border: none;
    padding: 5px 0;
    text-align: left;
    margin-bottom: 10px;
  }

  .shopping-cart td:first-child {
    border: none;
    padding-left: 0;
  }

  .shopping-cart td:last-child {
    border: none;
    padding-right: 0;
  }

  .shopping-cart table td::before {
    text-align: left;
  }

  .shopping-cart td:last-child {
    position: absolute;
    right: 0;
    top: -10px;
    transform: translate(0, 50%);
  }
}

@media (max-width: 414px) {

  .shopping-cart .nice-select,
  .shopping-cart .nice-select .nice-select-dropdown {
    width: 100%;
  }
}

/* ================================
      26.2. Checkout page
      ================================ */

.checkout-area .summary-section {
  padding: 30px 30px 40px;
  border: 1px solid var(--border-color);
  border-radius: 25px;
}

.checkout-area .summary-section>* {
  padding-bottom: 40px;
  margin-bottom: 40px;
  border-bottom: 1px solid var(--border-color);
}

.checkout-area .summary-section>div:last-child {
  padding-bottom: 0;
  margin-bottom: 0;
}

.checkout-area .summary-section>div:first-child,
.checkout-area .summary-section>div:last-child {
  padding-bottom: 0;
  border-bottom: none;
}

.checkout-area .summary-section .small-title {
  margin-bottom: 30px;
}

.product-in-cart {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

.product-in-cart:last-child {
  margin-bottom: 0;
}

.product-in-cart .product-info {
  display: flex;
}

.product-in-cart .product-thumb {
  width: 82px;
  height: 50px;
  min-width: 82px;
  border-radius: 10px;
  margin-right: 10px;
}

.product-in-cart .product-thumb img {
  border-radius: 10px;
}

.product-in-cart .product-name,
.product-in-cart .product-price {
  font-size: var(--body-font-size);
  font-weight: var(--heading-font-weight);
  line-height: 1.2;
}

.product-in-cart .license-type {
  font-size: 12px;
  font-weight: var(--body-font-weight);
}

.checkout-area .total-price-section {
  display: flex;
  justify-content: flex-end;
}

.total-price-section .total-price-details {
  flex-basis: 50%;
}

.total-price-section .total-price-details .total-price {
  font-size: 16px;
}

.payment-method+.payment-method {
  margin-top: 30px;
}

.payment-method label {
  font-size: body-fotn-size;
  font-weight: var(--heading-font-weight);
  line-height: 1;
  margin-bottom: 20px;
}

.complete-order-button input[type='submit'] {
  color: var(--contrast-text-color);
  width: 100%;
  margin-bottom: 0;
}

.complete-order-button input[type='submit']:hover {
  color: var(--contrast-text-color);
}

/* ================================
       27. PROFILE DASHBOARD
       ================================ */

/* ================================
       27.1. Dashboard menu
       ================================ */

.dashboard-wrapper>* {
  margin-bottom: 50px;
}

.dashboard-wrapper>div:last-child {
  margin-bottom: 0;
}

.user-db-menu-ds {
  width: 260px;
  padding: 30px;
  border: 1px solid var(--border-color);
  border-radius: 25px;
}

.profile-menu-ds li {
  font-size: var(--body-font-size);
  font-weight: var(--heading-font-weight);
  line-height: 1;
}

.profile-menu-ds li a {
  display: block;
  padding: 15px 0;
}

.profile-menu-ds svg {
  margin-right: 10px;
}

.profile-menu-ds li:hover a {
  color: inherit;
}

.profile-menu-ds li:hover svg {
  fill: url('#cryptoki-svg-gradient') var(--primary-accent-color);
}

/* ================================
       27.2. User stats
       ================================ */

.user-stats-section {
  display: flex;
  justify-content: space-between;
}

.user-stats-section .stat-item {
  flex: 0 1 340px;
  display: flex;
  padding: clamp(1rem, 1.6vmax, 26px) clamp(1.5rem, 2.5vmax, 40px);
  border: 1px solid var(--border-color);
  border-radius: 25px;
  color: var(--contrast-text-color);
  margin-right: 20px;
}

.user-stats-section .stat-item:last-child {
  margin-right: 0;
}

.user-stats-section .stat-icon {
  margin-right: 15px;
}

.user-stats-section .stat-icon svg {
  fill: var(--contrast-text-color);
  width: clamp(1rem, 2vmax, 32px);
  height: clamp(1rem, 2vmax, 32px);
}

.user-stats-section .stat-number {
  font-size: clamp(1rem, 2vmax, 32px);
  font-weight: var(--heading-font-weight);
  line-height: 1;
  margin-bottom: 10px;
}

.earnings-report-section h5 {
  margin-bottom: 30px;
}

/* ================================
       27.3. Top Sellers
       ================================ */

.best-sellers td:last-child {
  padding-right: 0;
}

.best-sellers .product-info {
  display: flex;
  position: relative;
}

.best-sellers .product-thumb {
  width: 82px;
  height: auto;
  min-width: 82px;
  border: 1px solid var(--border-color);
  border-radius: 12px;
  margin-right: 10px;
}

.best-sellers .product-thumb img {
  border-radius: 12px;
}

.best-sellers .product-details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.best-sellers .product-name,
.best-sellers .stats-item {
  font-weight: var(--heading-font-weight);
  line-height: 1;
}

.best-sellers .product-name {
  margin-bottom: 10px;
}

.item-number {
  font-size: 12px;
  font-weight: var(--heading-font-weight);
  line-height: 1;
  background-color: var(--body-background);
  border: 1px solid var(--border-color);
  border-radius: 30px;
  padding: 10px 13px;
  position: absolute;
  left: -46px;
  top: -8px;
  z-index: 10;
}

/* ================================
       27.4. Responsive styles
       ================================ */

@media (max-width: 800px) {
  .grid-small-sidebar-left {
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 0;
    grid-row-gap: 40px;
  }

  .best-sellers tbody>tr {
    padding: 10px;
  }

  .best-sellers table.content-table td {
    display: flex;
    justify-content: space-between;
    border: none;
    padding: 5px 0;
    text-align: left;
    margin-bottom: 10px;
  }

  .best-sellers td:first-child {
    border: none;
    padding-left: 0;
  }

  .best-sellers td:last-child {
    border: none;
    padding-right: 0;
  }

  .best-sellers table td::before {
    text-align: left;
  }

  .item-number {
    display: none;
  }

  .best-sellers .product-details {
    align-items: flex-end;
  }
}

@media (max-width: 414px) {
  .user-stats-section {
    flex-wrap: wrap;
  }

  .user-stats-section .stat-item {
    margin-right: 0;
    margin-bottom: 10px;
  }

  .best-sellers .product-thumb {
    display: none;
  }

  .best-sellers td[data-label='Item details']::before {
    white-space: nowrap;
  }

  table .product-details .product-name {
    text-align: right;
  }
}

/* ================================
    28. PROFILE SALES STATEMENT
    ================================ */

.sales-statement .stat-item {
  color: var(--main-font-color);
  flex-direction: column;
  align-items: center;
  flex-basis: auto;
}

.green {
  color: var(--ds-green);
}

.red {
  color: var(--ds-red);
}

.statement .date,
.statement .stat-value {
  font-weight: var(--heading-font-weight);
}

.statement .item-title {
  font-weight: var(--heading-font-weight);
  line-height: 1;
}

.statement .license-details {
  font-size: 12px;
}

input[type="month"]::-webkit-clear-button {
  display: none !important;
}

@media (max-width: 800px) {
  .statement .license-details {
    font-size: 0.9em;
  }
}

/* ================================
      28.1. Sales statement mobile view
      ================================ */

@media (max-width: 600px) {
  .sales-statement .user-stats-section {
    flex-wrap: wrap;
  }

  .sales-statement .user-stats-section>* {
    flex-basis: 49%;
    margin-right: 0;
    margin-bottom: 10px;
  }
}

/* ================================
    29. PROFILE UPLOAD ITEM
    ================================ */

.upload-row {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-column-gap: 20px;
}

#upload-item-form h5 {
  margin-bottom: 50px;
}

#upload-item-form input[type='submit'] {
  width: 100%;
  color: var(--contrast-text-color);
}

#upload-item-form textarea {
  margin-bottom: 20px;
}

.upload-container {
  margin-bottom: 35px;
}

.upload-container:last-child {
  margin-bottom: 0;
}

.upload-container button.close {
  background: var(--body-background);
  margin-left: 5px;
}

@media (max-width: 1024px) {
  .upload-row {
    grid-template-columns: 1fr;
    grid-column-gap: 0;
    grid-row-gap: 40px;
  }
}

@media (max-width: 320px) {
  .upload-row {
    grid-template-columns: 290px;
  }
}

/* ================================
    30. PROFILE MANAGE ITEMS
    ================================ */

.manage-items button {
  position: absolute;
  right: -12px;
  top: 18px;
  z-index: 1;
}

.manage-items .featured-item.v5 .fav-counter {
  top: 60px;
}

.create-item .create-item-wrapper {
  background-color: var(--lighter-gray);
  border: 1px solid var(--border-color);
  border-radius: 25px;
}

.create-item .create-item-content {
  padding: 14px;
  background: var(--body-background);
  border-bottom: 1px solid var(--border-color);
  border-radius: 25px;
}

.create-item .create-item-image {
  height: 188px;
  overflow: hidden;
  border: 1px solid var(--border-color);
  border-radius: 20px;
  background: var(--lighter-gray);
  display: flex;
  justify-content: center;
  align-items: center;
}

.create-item .create-item-image svg {
  width: 32px;
  height: 32px;
}

.create-item .create-item-info {
  padding: 30px 30px 10px;
  text-align: center;
  background: var(--body-background);
}

.create-item .small-title {
  margin-bottom: 10px;
}

.create-item-post-content {
  height: 50px;
}

/* ================================
    31. PROFILE PAYMENT HISTORY
    ================================ */

.payment-history td {
  font-weight: var(--heading-font-weight);
}

.payment-history td:last-child {
  padding-right: 0;
}

.payment-method-section>* {
  margin-bottom: 20px;
}

.payment-method-section>div:last-child {
  margin-bottom: 0;
}

.payment-method-section h5 {
  margin-bottom: 30px;
}

#payment-method-form,
.confirmed-section {
  padding: 30px;
  border: 1px solid var(--border-color);
  border-radius: 25px;
}

#payment-method-form button {
  width: 100%;
}

.payment-method+button {
  margin-top: 15px;
}

/* ================================
    32. PROFILE PURCHASES
    ================================ */

.purchases-list table td {
  font-weight: var(--heading-font-weight);
}

td[data-label='Date'] {
  white-space: nowrap;
}

.purchases-list button.download {
  width: 52px;
  height: 52px;
  background: var(--btn-dark);
  border-radius: var(--btn-border-radius);
}

.purchases-list button.download:hover {
  background: var(--cryptoki-gradient);
}

.purchases-list button.download svg {
  fill: var(--contrast-text-color);
}

.purchases-list button.item-rating {
  display: inline-flex;
  border: 1px solid var(--border-color);
  padding: 20px;
  border-radius: var(--btn-border-radius);
}

.purchases-list table .product-thumb {
  min-width: 82px;
}

@media (max-width: 1024px) {
  .purchases-list table {
    border: 0;
  }

  .purchases-list table caption {
    font-size: 1.3em;
  }

  .purchases-list table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  .purchases-list table tr {
    display: block;
    margin-bottom: 0.625em;
    border-bottom: 2px solid var(--border-color);
  }

  .purchases-list table td {
    display: block;
    font-size: 0.8em;
    text-align: right;
  }

  .purchases-list table td::before {
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }

  .purchases-list table tr:last-child {
    border-bottom: 0;
  }

  .purchases-list tbody>tr {
    padding: 10px;
  }

  .purchases-list td {
    border: none;
    padding: 5px 0;
  }

  .purchases-list td:first-child {
    border: none;
    padding-left: 0;
  }

  .purchases-list td:last-child {
    border: none;
    padding-right: 0;
  }

  .purchases-list table .product-info {
    justify-content: flex-end;
  }
}

@media (max-width: 414px) {
  .purchases-list .product-info {
    flex-direction: column;
    align-items: flex-end;
  }

  .purchases-list .product-info .product-thumb {
    margin-right: 0;
    margin-bottom: 10px;
  }

  .purchases-list .product-details .product-meta {
    flex-wrap: wrap;
    justify-content: flex-end;
  }
}

/* ================================
    32.1 Cryptoki modal
    ================================ */

.cryptoki-modal {
  position: fixed;
  width: 100vw;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cryptoki-modal.open {
  visibility: visible;
  opacity: 1;
  transition-delay: 0s;
  z-index: 30;
}

.cryptoki-modal .modal-bg {
  position: absolute;
  background: rgb(0 0 0 / 88%);
  width: 100%;
  height: 100%;
}

.cryptoki-modal .modal-container {
  width: 460px;
  background: var(--body-background);
  border: 1px solid var(--border-color);
  border-radius: 25px;
  padding: 30px;
  position: relative;
}

.cryptoki-modal .modal-close {
  position: absolute;
  right: -15px;
  top: -15px;
  outline: none;
  appearance: none;
  color: red;
  background: none;
  border: 0px;
  font-weight: bold;
  cursor: pointer;
}

.cryptoki-modal .modal-close svg {
  width: 12px;
  height: 12px;
  fill: var(--contrast-text-color);
}

.review-form {
  text-align: left;
}

.review-form .review-form-title {
  font-size: var(--body-background);
  font-weight: var(--heading-font-weight);
  margin-bottom: 20px;
}

.review-form .rating-box {
  display: flex;
  margin-bottom: 25px;
}

.review-form .rating-box .title {
  margin-right: 10px;
}

.review-form input[type='submit'] {
  color: var(--contrast-text-color);
  margin-bottom: 0;
}

:root {
  /* ============================
       1.1. Primary template colors
   
    /* ===============================
       1.2. Body background and font colors
       =============================== */
  --body-background: #fff;
  --border-color: #e1e1e1;
  --main-font-color: #252831;
  --unchangable-font-color: #252831;
  --contrast-text-color: #fff;
  --inactive-text-color: #a8a9ad;
  /* ====================================
       1.3. Footer background nand font colors
      ===================================== */
  --footer-background: #14161b;
  --footer-text-color: #fff;
  /* ==============================
       1.4. Additional colors
       ============================== */
  --light-gray: #e3e3e3;
  --lighter-gray: #f3f3f3;
  --extra-light-gray: #f9f9f9;
  --pure-black: #000;
  --fuchsia-color: #ff2654;
  --unrated-color: #c8c7d5;
  --cryptoki-blue: #1852ff;
  --cryptoki-salmon: #ff3d54;
  --ds-purple: #c23fff;
  --ds-yellow: #feda03;
  --cryptoki-box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2),
    0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
  --ds-red: #f32a63;
  --ds-green: #00bf8b;
  --btn-dark: #252831;
  --ui-templates: var(--secondary-accent-color);
  --social-graphics: var(--secondary-gradient-color);
  --coded-templates: var(--primary-gradient-color);
  --video-tutorials: var(--cryptoki-salmon);
  /* ===========================
       1.5. Typography sizes
       ============================*/
  /* ===========================
       1.6. Form authorization colors
       ============================*/
  --toggle-background-color-on: var(--cryptoki-gradient);
  --toggle-background-color-off: var(--main-font-color);
  --toggle-control-color: #fff;
  --toggle-control-border: #343c4a;
  /* ===========================
       1.7. Border-radius
       ============================*/
  --btn-border-radius: 10px;
  --input-border-radius: 10px;
}

/* ===========================
       1.8. Dark Mode
       ============================*/

body.dark-mode {
  --body-background: #121419;
  --main-font-color: #fff;
  --border-color: #353946;
  --light-gray: #353946;
  --lighter-gray: #20232b;
  --extra-light-gray: #20232b;
  --primary-accent-color: #00ffcc;
  --primary-gradient-color: #177afa;
  --secondary-gradient-color: #03f0d1;
  --cryptoki-gradient: linear-gradient(to right,
      var(--primary-gradient-color) 20%,
      var(--secondary-gradient-color));
  --toggle-background-color-on: var(--cryptoki-gradient);
  --cryptoki-box-shadow: 0 3px 5px -1px rgba(255, 255, 255, 0.2),
    0 6px 10px 0 rgba(255, 255, 255, 0.14),
    0 1px 18px 0 rgba(255, 255, 255, 0.12);
}

/* ===========================
       2. DEFAULTS
       ============================*/

*,
*::after,
*::before {
  box-sizing: border-box;
}

::-moz-selection {
  background: var(--secondary-gradient-color);
  color: var(--contrast-text-color);
  text-shadow: none;
}

::selection {
  background: var(--secondary-gradient-color);
  color: var(--contrast-text-color);
  text-shadow: none;
}

.cryptoki-scrollbar::-webkit-scrollbar {
  width: 6px;
}

.cryptoki-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: var(--main-font-color);
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.cryptoki-scrollbar::-webkit-scrollbar-track {
  background: transparent;
}

.cryptoki-scrollbar::-webkit-scrollbar-thumb {
  background-color: var(--light-gray);
  border-radius: 6px;
}

body {
  background-color: var(--body-background);
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

img {
  max-width: 100%;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

textarea {
  resize: vertical;
}

figure {
  margin: 0;
}

a {
  text-decoration: none;
  color: inherit;
}

a:hover,
a:active {
  color: var(--primary-accent-color);
  fill: var(--primary-accent-color);
}

input:focus,
select:focus {
  outline: none;
}

ul,
ol {
  margin: 0;
  padding: 0;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    margin-top: 20px;
  }

  to {
    opacity: 1;
    margin-top: 0;
  }
}

/* ==============================
       3. HELPER CLASSES
       ============================== */

/* =========================
       3.1. General classes
       ========================= */

.hidden,
[hidden] {
  display: none !important;
}

.sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.sr-only.focusable:active,
.sr-only.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  white-space: inherit;
  width: auto;
}

.invisible {
  visibility: hidden;
}

.overflow-hidden {
  overflow: hidden;
}

.clearfix::before,
.clearfix::after {
  content: ' ';
  display: table;
}

.clearfix::after {
  clear: both;
}

.highlighted-item {
  font-size: 10px;
  line-height: 1;
  color: var(--contrast-text-color);
  border-radius: 30px;
  padding: 4px 7px;
  margin-left: 10px;
}

/* =========================
       3.2. Text classes
       ========================= */

.colored {
  color: var(--primary-accent-color);
}

.text-center {
  text-align: center;
}

.bold {
  font-weight: var(--heading-font-weight);
}

.list-line-height {
  line-height: 2.75;
}

.truncate {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

/* =========================
       3.3. Padding classes
       ========================= */

.content-padding {
  padding-top: clamp(2rem, 4.3vmax, 70px);
  padding-bottom: clamp(2rem, 4.3vmax, 70px);
}

.section-padding {
  padding-top: clamp(1rem, 2.8vmax, 45px);
  padding-bottom: clamp(1rem, 2.8vmax, 45px);
}

.content-padding>.section-padding:first-child {
  padding-top: 0;
}

.section-padding-medium {
  padding-top: clamp(2rem, 4.5vmax, 70px);
  padding-bottom: clamp(2rem, 4.5vmax, 70px);
}

.bottom-padding-50 {
  padding-bottom: clamp(2rem, 3.12vmax, 50px);
}

.bottom-padding-70 {
  padding-bottom: clamp(2rem, 4.3vmax, 70px);
}

.no-top-padding {
  padding-top: 0 !important;
}

/* =========================
       3.4. Margin classes
       ========================= */

.margin-bottom {
  margin-bottom: 75px;
}

.negative-margin {
  margin-right: -18px;
  margin-left: -22px;
}

/* =========================
       3.5. Border classes
       ========================= */

.border-bottom {
  border-bottom: 1px solid var(--border-color);
}

.icon-border a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 34px;
  background-color: var(--body-background);
  border: 1px solid var(--border-color);
  border-radius: 30px;
  line-height: 1;
}

.icon-border a svg {
  fill: var(--main-font-color);
}

/* =========================
       3.6. Flex layout classes
       ========================= */

.flex-space-between {
  display: flex;
  justify-content: space-between;
}

.flex-space-around {
  display: flex;
  justify-content: space-around;
}

.flex-center {
  display: flex;
  justify-content: center;
}

.flex-right {
  display: flex;
  justify-content: flex-end;
}

.flex-left {
  display: flex;
  justify-content: flex-start;
}

.flex-wrap {
  flex-wrap: wrap;
}

.align-items-center {
  align-items: center;
}

/* =========================
       3.7. Grid layout classes
       ========================= */

.grid-aside-main-4-col {
  display: grid;
  grid-template-areas: 'aside main-content-area';
  grid-template-columns: 1fr 3fr;
}

.grid-left-sidebar {
  display: grid;
  grid-template-columns: 340px 3fr;
  grid-column-gap: clamp(1rem, 8vw, 140px);
}

.grid-right-sidebar {
  display: grid;
  grid-template-columns: 3fr 340px;
  grid-column-gap: clamp(1rem, 8vw, 140px);
}

.grid-right-sidebar-large {
  display: grid;
  grid-template-columns: 3fr minmax(320px, 1.5fr);
  grid-column-gap: 20px;
}

.grid-2-columns {
  display: grid;
  grid-template-columns: minmax(340px, 1fr) minmax(340px, 1fr);
  grid-gap: 20px;
}

.grid-3-columns {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  grid-gap: 20px;
}

.grid-aside-main-4-col .grid-3-columns {
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
}

.grid-6-columns {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(218px, 1fr));
  grid-gap: 20px;
}

.grid-small-sidebar-left {
  display: grid;
  grid-template-columns: 260px minmax(550px, 3fr);
  grid-gap: clamp(1rem, 6.25vw, 100px);
}

@media (max-width: 1199px) {
  .grid-aside-main-4-col {
    grid-template-columns: 1fr 2fr;
  }
}

@media (max-width: 800px) {
  .grid-2-columns {
    grid-template-columns: 1fr;
    grid-column-gap: 0;
  }

  .grid-aside-main-4-col {
    grid-template-columns: 1fr 1fr;
  }

  .grid-right-sidebar-large {
    grid-template-columns: 1fr;
    grid-column-gap: 0;
  }

  .grid-left-sidebar,
  .grid-right-sidebar {
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: clamp(1rem, 8vw, 140px);
    grid-column-gap: 0;
  }
}

@media (max-width: 600px) {
  .grid-aside-main-4-col {
    grid-template-columns: 1fr;
    grid-template-areas: none;
  }
}

@media (max-width: 414px) {
  .grid-3-columns {
    grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));
    grid-column-gap: 0;
  }
}

@media (max-width: 320px) {
  .grid-aside-main-4-col .grid-3-columns {
    grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));
  }

  .grid-2-columns,
  .grid-left-sidebar,
  .grid-right-sidebar {
    grid-template-columns: 290px;
  }
}

/* ==================
       3.8. Gradients
       =================== */

.gradient-border_white-bg {
  display: inline-flex;
  align-self: center;
  border-radius: var(--btn-border-radius);
  border: solid 2px transparent;
  background-image: linear-gradient(var(--body-background),
      var(--body-background)),
    linear-gradient(to right,
      var(--primary-gradient-color),
      var(--secondary-gradient-color));
  background-origin: border-box;
  background-clip: content-box, border-box;
  position: relative;
}

.gradient-border_white-bg .btn,
.gradient-border_white-bg button,
.gradient-border_white-bg .btn:hover,
.gradient-border_white-bg button:hover {
  color: var(--main-font-color);
}

.gradient-border_white-bg:after {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  background: var(--cryptoki-gradient);
  filter: blur(5px);
  border-radius: var(--btn-border-radius);
  opacity: 0;
  visibility: hidden;
  will-change: opacity;
  transition: opacity 0.3s linear;
}

.gradient-border_white-bg:hover:after {
  opacity: 1;
  visibility: visible;
}

.gradient-border_dark-bg {
  display: inline-flex;
  align-self: center;
  border-radius: var(--btn-border-radius);
  border: solid 2px transparent;
  background-image: linear-gradient(var(--footer-background),
      var(--footer-background)),
    linear-gradient(to right,
      var(--primary-gradient-color),
      var(--secondary-gradient-color));
  background-origin: border-box;
  background-clip: content-box, border-box;
  position: relative;
}

.gradient-border_dark-bg .btn,
.gradient-border_dark-bg button,
.gradient-border_dark-bg .btn:hover,
.gradient-border_dark-bg button:hover {
  color: var(--contrast-text-color);
}

.gradient-border_dark-bg:after {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  background: var(--primary-accent-color);
  filter: blur(0);
  border-radius: var(--btn-border-radius);
  will-change: filter;
  transition: all 0.2s linear;
}

.gradient-border_dark-bg:hover:after {
  filter: blur(5px);
}

.gradient-border-purple-bg {
  display: inline-flex;
  align-self: center;
  border-radius: var(--btn-border-radius);
  border: solid 2px transparent;
  background-image: linear-gradient(#473495, #51399b),
    linear-gradient(to right,
      var(--primary-gradient-color),
      var(--secondary-gradient-color));
  background-origin: border-box;
  background-clip: content-box, border-box;
  position: relative;
}

.gradient-border-purple-bg .btn,
.gradient-border-purple-bg button,
.gradient-border-purple-bg .btn:hover,
.gradient-border-purple-bg button:hover {
  color: var(--contrast-text-color);
}

.gradient-border_purple-bg:after {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  background: var(--primary-accent-color);
  filter: blur(0);
  border-radius: var(--btn-border-radius);
  will-change: filter;
  transition: all 0.2s linear;
}

.gradient-border_purple-bg:hover:after {
  filter: blur(5px);
}

.gradient-text {
  background-color: var(--main-font-color);
  background-image: var(--cryptoki-gradient);
  background-size: 100%;
  background-clip: text;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
}

.gradient-text::selection {
  color: var(--contrast-text-color);
  -webkit-text-fill-color: var(--contrast-text-color);
}

.gradient-background {
  background: var(--cryptoki-gradient);
  color: var(--contrast-text-color);
}

.gradient-background:hover {
  color: var(--contrast-text-color);
}

.orange-gradient {
  background: linear-gradient(to right, #ff7b0c, #ffed01);
}

.turquoise-gradient {
  background: linear-gradient(to right, #38abf6, #06f5b1);
}

.purple-gradient {
  background: linear-gradient(to right, #2628b1, #bc62f6);
}

.green-gradient {
  background: linear-gradient(to right, #0075a2, #00ef68);
}

.turquoise-gradient-diagonal {
  background: linear-gradient(to top right, #00f4cb, #0062fe);
}

.purple-gradient-diagonal {
  background: linear-gradient(to top right, #f137ff, #5447eb);
}

.orange-gradient-diagonal {
  background: linear-gradient(to top right, #ffe022, #ff2853);
}

.blue-gradient-diagonal {
  background: linear-gradient(to top right, #0062fe, #00f4cb);
}

.fuchsia-gradient-diagonal {
  background: linear-gradient(to top right, #5447eb, #f137ff);
}

.yellow-gradient-diagonal {
  background: linear-gradient(to top right, #ff2853, #ffe022);
}

/* =======================
       4. PRINT STYLES
       ======================= */

@media print {

  *,
  *::before,
  *::after {
    background: var(--body-background) !important;
    color: #000 !important;
    /* Black prints faster */
    box-shadow: none !important;
    text-shadow: none !important;
  }

  a,
  a:visited {
    text-decoration: underline;
  }

  a[href]::after {
    content: ' (' attr(href) ')';
  }

  abbr[title]::after {
    content: ' (' attr(title) ')';
  }

  a[href^='#']::after,
  a[href^='javascript:']::after {
    content: '';
  }

  pre {
    white-space: pre-wrap !important;
  }

  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }

  thead {
    display: table-header-group;
  }

  tr,
  img {
    page-break-inside: avoid;
  }

  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
  h3 {
    page-break-after: avoid;
  }
}

/* ==================
       5. TYPOGRAPHY
       =================== */

body {
  font-family: 'Manrope VF', Manrope, -apple-system, BlinkMacSystemFont, Roboto,
    'Helvetica Neue', sans-serif;
  font-size: var(--body-font-size);
  color: var(--main-font-color);
  line-height: 2;
  letter-spacing: var(--letter-spacing);
}

body,
input {
  font-weight: var(--body-font-weight);
  letter-spacing: var(--letter-spacing);
}

/* ==================
       6. BUTTONS
       =================== */

button {
  font-size: var(--body-font-size);
  font-weight: var(--heading-font-weight);
  border: none;
  cursor: pointer;
  background-color: transparent;
  padding: 0;
  line-height: 1;
}

.btn {
  font-size: var(--body-font-size);
  font-weight: var(--heading-font-weight);
  line-height: 1;
  border-radius: var(--btn-border-radius);
  white-space: nowrap;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  will-change: background, color, box-shadow;
  transition-property: background, color, box-shadow;
  transition-duration: 300ms;
}

.btn:hover {
  color: inherit;
}

.digital-store .btn:hover {
  color: var(--main-font-color);
}

.btn.gradient-background:hover {
  color: var(--contrast-text-color);
}

.btn-normal {
  padding: max(0.87vw, 12px) max(2.5vw, 30px);
}

.btn-normal2 {
  padding: max(0.68vw, 0px) max(2.5vw, 30px);
}

.btn-wide {
  padding: max(0.87vw, 12px) max(4vw, 50px);
}

.btn-fullwidth {
  padding: max(0.87vw, 12px) max(2.5vw, 30px);
  width: 100%;
  text-align: center;
}

.btn-small {
  padding: 10px 14px;
  font-size: 12px;
}

.btn-small-wide {
  padding: 12px 30px;
  font-size: 12px;
}

.btn-small-fullwidth {
  padding: 12px 30px;
  font-size: 12px;
  width: 100%;
  display: block;
  text-align: center;
}

.btn-square {
  border-radius: 10px;
}

.btn-follow {
  border-radius: 30px;
}

.btn-dark {
  background-color: var(--btn-dark);
  color: var(--contrast-text-color);
}

.bttn-dark {
  background-color: white;
  color: black;
}

body.dark-mode .btn-dark {
  background: var(--secondary-accent-color) var(--cryptoki-gradient);
  color: var(--contrast-text-color);
}

/* ===========================
     6.1 Buttons Material effect
     ============================ */

.waves-effect {
  position: relative;
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  -webkit-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

.waves-effect .waves-ripple {
  position: absolute;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  margin-top: -50px;
  margin-left: -50px;
  opacity: 0;
  background: rgba(0, 0, 0, 0.2);
  background: radial-gradient(rgba(0, 0, 0, 0.2) 0,
      rgba(0, 0, 0, 0.3) 40%,
      rgba(0, 0, 0, 0.4) 50%,
      rgba(0, 0, 0, 0.5) 60%,
      rgba(255, 255, 255, 0) 70%);
  transition: all 0.5s ease-out;
  transition-property: transform, opacity;
  transform: scale(0) translate(0, 0);
  pointer-events: none;
}

.waves-effect.waves-light .waves-ripple {
  background: rgba(255, 255, 255, 0.4);
  background: radial-gradient(rgba(255, 255, 255, 0.2) 0,
      rgba(255, 255, 255, 0.3) 40%,
      rgba(255, 255, 255, 0.4) 50%,
      rgba(255, 255, 255, 0.5) 60%,
      rgba(255, 255, 255, 0) 70%);
}

.waves-float {
  mask-image: none;
  -webkit-mask-image: none;
}

.waves-float:active {
  box-shadow: var(--cryptoki-box-shadow);
}

.waves-block {
  display: block;
}

/* ==================
    7. SVG ICONS
    =================== */

svg {
  width: 16px;
  height: 16px;
}

.crumina-icon {
  fill: var(--main-font-color);
}

#cryptoki-svg-gradient {
  --color-stop-1: var(--primary-gradient-color);
  --color-stop-2: var(--secondary-gradient-color);
}

/* ==================
       8. GENERAL
       =================== */

/* ==================
       8.1. Body, containers
       =================== */

body::before {
  content: '';
  width: 0;
  height: 0;
  opacity: 0;
  visibility: hidden;
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  transition: visibility 0.3s linear, opacity 0.3s linear;
}

body.body-overlay::before {
  width: 100%;
  height: 100%;
  opacity: 1;
  visibility: visible;
  z-index: 99;
  transition: visibility 0.3s linear, opacity 0.3s linear;
}

.container-1300 {
  width: 1360px;
  padding-left: 30px;
  padding-right: 30px;
  max-width: 100%;
  margin: 0 auto;
}

/* ==================
       8.2. Sections
       =================== */

.section-title-wrapper,
.page-title-section {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: max(2vw, 1rem);
}

.section-medium {
  width: 1000px;
  padding-right: 30px;
  padding-left: 30px;
  max-width: 100%;
  margin: 0 auto;
}

.section-small {
  width: 760px;
  padding-right: 30px;
  padding-left: 30px;
  max-width: 100%;
  margin: 0 auto;
}

.extra-small-section {
  width: 525px;
  padding-right: 30px;
  padding-left: 30px;
  max-width: 100%;
  margin: 0 auto;
}

.section-title {
  font-size: var(--h2-heading);
  font-weight: var(--heading-font-weight);
  line-height: 1.15em;
  letter-spacing: var(--letter-spacing);
  margin: 0 0 0.25em;
}

.page-title {
  margin-bottom: 40px;
}

.small-title {
  font-size: 16px;
  font-weight: var(--heading-font-weight);
  margin-bottom: clamp(1rem, 2vmax, 20px);
  line-height: 1.4;
  letter-spacing: var(--letter-spacing);
}

.display-mode-links svg {
  fill: var(--main-font-color);
}

@media (max-width: 600px) {

  .section-medium,
  .section-small,
  .extra-small-section {
    padding-right: 15px;
    padding-left: 15px;
  }
}

/* ================================
       8.2.1. Sections title navigation
       ================================ */

.title-navigation {
  width: 55px;
  display: flex;
  justify-content: space-between;
  position: relative;
}

.title-navigation svg {
  width: 15px;
  height: 20px;
}

.title-navigation a:hover svg {
  fill: var(--primary-accent-color);
}

.display-mode-links a {
  margin-left: 15px;
}

.display-mode-links a:hover svg {
  fill: var(--primary-accent-color);
}

.all-items-link {
  font-size: 16px;
  font-weight: var(--heading-font-weight);
  line-height: 1.6;
}

/* ================================
       8.3. Avatars
       ================================ */

/* ================================
        8.3.1. Avatar block
       ================================ */

.avatar-block {
  display: flex;
  align-items: center;
}

.avatar-block .avatar-title {
  font-size: 16px;
  font-weight: var(--heading-font-weight);
  line-height: 1.4;
  margin-bottom: 0.25em;
}

.avatar-block .avatar-title a:hover {
  color: inherit;
}

.avatar-block .avatar-meta {
  font-size: 13px;
  line-height: 1;
}

.avatar-block .avatar+.avatar-meta {
  margin-left: 10px;
}

/* ================================
       8.3.2. Avatar sizes
       ================================ */

.avatar {
  position: relative;
}

.avatar,
.avatar img {
  border-radius: 50%;
}

.avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--cryptoki-gradient);
}

.avatar a {
  line-height: 1;
}

.avatar img {
  background: var(--body-background);
}

.box-26 {
  width: 32px;
  height: 32px;
}

.box-26 img {
  width: 28px;
  height: 28px;
  object-fit: cover;
  border: 2px solid var(--body-background);
}

.avatar.box-26 .verified {
  position: absolute;
  bottom: -4px !important;
  right: -4px;
}

.avatar.box-26 .verified .svg {
  width: 20px;
  height: 20px;
}

.box-42 {
  width: 42px;
  height: 42px;
  min-width: 42px;
}

.box-42 img {
  width: 38px;
  height: 38px;
  border: 2px solid var(--body-background);
}

.avatar.box-42 .verified {
  position: absolute;
  bottom: -2px;
  right: -4px;
  line-height: 1;
}

.box-152 {
  width: 152px;
  height: 152px;
  min-width: 152px;
}

.box-152 img {
  width: 112px;
  height: 112px;
  border: 6px solid var(--body-background);
}

.avatar.box-152 {
  border: 14px solid var(--body-background);
  margin-right: 0;
}

.avatar.box-152 .verified {
  position: absolute;
  bottom: -4px;
  right: -4px;
}

.avatar.box-152 svg {
  width: 40px;
  height: 40px;
  border: 6px solid var(--body-background);
  padding: 6px;
}

/* ================================
       8.4. Lists
       ================================ */

/* ================================
       8.4.1. Styled list
       ================================ */

ul {
  list-style: none;
}

ul li .bullet-icon {
  width: 8px;
  height: 8px;
  margin-right: 8px;
}

ol li::marker {
  font-weight: bold;
}

.details-list {
  list-style: none;
}

.details-list li {
  line-height: 2.75;
}

.details-list li .detail-label {
  display: inline-block;
  width: 30%;
  min-width: 105px;
}

/* ================================
       8.4.2. Social styled list
       ================================ */

.social-styled-list {
  list-style: none;
}

.social-styled-list li {
  line-height: 2.75;
}

.social-styled-list li a {
  font-weight: var(--heading-font-weight);
}

.social-styled-list li>svg {
  fill: var(--main-font-color);
}

.social-styled-list .marker {
  margin-right: 10px;
}

.social-styled-list .verified {
  margin-left: 5px;
}

.social-styled-list .verified svg {
  padding: 2px;
}

/* ================================
       9. HEADER
       ================================ */

/* ================================
        9.1. Header General
        ================================ */

.site-header {
  background-color: var(--body-background);
  /* height: 100vh; */
}

.offcanvas {
  height: 100vh;
  width: 85%;
}

.mobile-menu .menu-item .menu-link {
  padding: 8px 5px !important;
}

@media (max-width: 800px) {
  .main:not(.digital-store) .site-header {
    position: sticky;
    top: 0;
    z-index: 100;
    background-color: var(--body-background);
  }
}

/* ================================
        9.2. Topbar
        ================================ */

.digital-store .topbar {
  background: var(--extra-light-gray);
}

.digital-store .topbar .nice-select {
  background-color: transparent;
}

.topbar-wrapper {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.topbar-wrapper>div:last-child {
  margin-right: 0;
}

.topbar-47 {
  height: 47px;
}

.topbar-92 {
  height: 92px;
}

@media (max-width: 1200px) {
  .digital-store .topbar {
    display: none;
  }
}

@media (min-width: 1200px) {
  .topbar-wrapper>* {
    margin-bottom: 0;
  }
}

/* ================================
        9.2.1. Topbar switchers
        ================================ */

.topbar-switchers {
  display: flex;
}

.topbar-switchers>* {
  margin-left: 15px;
}

.topbar-switchers>div:first-child {
  margin-left: 0;
}

/* ================================
        9.3. Logotype
        ================================ */

.logo .logo-link:hover {
  color: inherit;
}

.logo .logo-link,
.collection-box,
.header-user-profile {
  display: flex;
  align-items: center;
}

.logo .logo-img,
.logo .logo-img svg {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.logo .logo-text {
  font-size: 20px;
  font-weight: var(--heading-font-weight);
}

@media (max-width: 1024px) {
  .site-header .logo {
    flex-grow: 1;
  }
}

@media (max-width: 500px) {

  .topbar .logo .logo-text,
  .nav-ds .logo .logo-text {
    display: none;
  }
}

/* ================================
        9.4. Collection box
        ================================ */

.collection-box .collection-title {
  font-weight: var(--heading-font-weight);
  white-space: nowrap;
}

.collection-box>* {
  margin-right: 20px;
}

.collection-box>*:last-child {
  margin-right: 10px;
}

.collection-box .btn {
  padding: 20px 27px;
}

.count {
  margin-left: 5px;
}

@media (max-width: 800px) {
  .collection-box {
    display: none;
  }

  .loginnbtn {
    display: block !important;
  }
}

/* ================================
        9.5. User activity buttons
        ================================ */

.user-activity-buttons {
  display: flex;
}

.user-activity-buttons>* {
  margin-right: 20px;
  position: relative;
}

@media (max-width: 568px) {
  .user-activity-buttons>* {
    position: static;
  }
}

.user-activity-buttons button .counter {
  position: absolute;
  top: -18px;
  right: -12px;
  font-size: 9px;
  line-height: 2;
  width: 18px;
  height: 18px;
  background-color: #0084ff;
  color: var(--contrast-text-color);
  border-radius: 50%;
}

.cart-button svg,
.favourites-button svg,
.notifications-button svg,
#mobile-search svg {
  fill: var(--main-font-color);
}

.cart-button:hover svg,
.favourites-button:hover svg,
.notifications-button:hover svg,
#mobile-search:hover svg {
  fill: var(--primary-accent-color);
}

.nav-ds .user-activity-buttons button .counter {
  color: var(--unchangable-font-color);
}

/* ================================
        9.5.1. Notifications dropdown
        ================================ */

#notifications-dropdown {
  position: absolute;
  top: 40px;
  left: 50%;
  transform: translate(-50%, 0);
  padding: 25px 30px;
  background-color: var(--body-background);
  border: 1px solid var(--border-color);
  color: var(--main-font-color);
  border-radius: 25px;
  z-index: 100;
  max-width: 100vw;
  width: 360px;
}

#notifications-dropdown .title {
  font-size: var(--body-font-size);
  letter-spacing: var(--letter-spacing);
  font-weight: var(--heading-font-weight);
  text-align: left;
  margin-bottom: 30px;
}

.notifications-wrapper {
  margin-bottom: 40px;
  max-height: 250px;
}

.notifications-wrapper .notification {
  display: flex;
  /* justify-content: space-between; */
  font-size: 12px;
  letter-spacing: var(--letter-spacing);
  margin-bottom: 30px;
}

.notification:last-child {
  margin-bottom: 0;
}

.notification .thumb-box {
  width: 50px;
  height: 50px;
  min-width: 50px;
  border: 1px solid var(--border-color);
  border-radius: 15px;
  position: relative;
  margin-right: 10px;
}

.notification .thumb-box span {
  position: absolute;
  bottom: 0;
  right: 0;
  border-radius: 5px;
}

.notification .thumb-box svg {
  font-size: 15px !important;
  width: 22px;
  height: 22px;
  fill: var(--contrast-text-color);
  padding: 3px;
}

.notification .thumb-box img {
  object-fit: cover;
  border-radius: 15px;
}

.bid-type {
  background-color: #6a52ff;
}

.purchase-type {
  background-color: #ffc000;
}

.like-type {
  background-color: #ff3a4e;
}

.review-type {
  background-color: #c23fff;
}

.comment-type {
  background-color: #ff394f;
}

.notification .notification-info {
  text-align: left;
}

.notification .notification-info .message {
  line-height: 1.4;
  margin-bottom: 5px;
}

.cryptoki-notif-target {
  display: none;
  animation: fadeInUp 0.2s;
}

.cryptoki-notif-target.active {
  display: block;
}

.cryptoki-notif-bttn {
  position: relative;
}

.cryptoki-notif-bttn.active>.crumina-icon {
  fill: var(--primary-accent-color);
}

/* ================================
        9.5.1.1. Notifications page
        ================================ */

.widget-filter-panel.notifications {
  max-width: 260px;
}

.notifications-list+.load-more_bars {
  margin-top: 75px;
}

.notifications-list .notification {
  padding: 25px 30px;
  border: 1px solid var(--border-color);
  border-radius: 25px;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  letter-spacing: var(--letter-spacing);
}

.notifications-list .notification-body {
  display: flex;
  align-items: center;
}

.notification-body .notification-info {
  font-size: 14px;
}

.notification-body .publish-date {
  font-size: 12px;
}

.notification-body .thumb-box {
  width: 82px;
  height: auto;
  min-width: 82px;
}

.notification-body .avatar+.notification-info {
  margin-left: 28px;
}

.notification .action-buttons {
  display: flex;
  align-items: center;
}

.notification .action-buttons input[type='checkbox']+label::before {
  width: 16px;
  height: 16px;
  margin-right: 0;
}

.notification .action-buttons input[type='checkbox']:checked+label::after {
  left: 4px;
  top: 4px;
}

.notification .action-buttons .cryptoki-form.checkbox {
  position: relative;
  top: 4px;
}

.notification .action-buttons button.close+.cryptoki-form.checkbox {
  margin-left: 15px;
}

.notifications-list .command-line {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 30px;
}

.notifications-list .command-line>* {
  margin-left: 20px;
  font-size: var(--body-font-size);
  font-weight: var(--heading-font-weight);
  letter-spacing: var(--letter-spacing);
}

.notifications-list .command-line>.command:first-child {
  margin-left: 0;
}

.notifications-list .command-line .command:hover,
.notifications-list .command-line .command:active {
  color: var(--primary-accent-color);
}

@media (max-width: 600px) {
  .widget-filter-panel.notifications {
    max-width: 100%;
  }

  .widget-filter-panel.notifications #filter-form {
    margin-right: 0;
  }

  .widget-filter-panel.notifications #filter-form .form-group {
    flex-direction: row;
    justify-content: flex-start;
  }

  .widget-filter-panel.notifications #filter-form .form-group .small-title {
    flex-basis: 100%;
  }

  .widget-filter-panel.notifications #filter-form .form-group .form-list-elem {
    margin-right: 10px;
  }
}

@media (max-width: 414px) {
  .notification .action-buttons {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin-left: 10px;
  }

  .notification .action-buttons button.close+.cryptoki-form.checkbox {
    margin-left: 0;
    margin-top: 10px;
  }
}

@media (max-width: 320px) {
  .notification-body .thumb-box {
    display: none;
  }
}

/* ================================
        9.5.2. Cart dropdown
        ================================ */

.cart-button {
  position: relative;
}

#cart-dropdown {
  position: absolute;
  top: 40px;
  left: 50%;
  transform: translate(-50%, 0);
  padding: 25px 30px;
  background-color: var(--body-background);
  border: 1px solid var(--border-color);
  color: var(--main-font-color);
  border-radius: 25px;
  z-index: 100;
  width: 360px;
  max-width: 100vw;
}

#cart-dropdown .cart-wrapper {
  border-bottom: 1px solid var(--border-color);
}

#cart-dropdown .title {
  font-size: var(--body-font-size);
  letter-spacing: var(--letter-spacing);
  font-weight: var(--heading-font-weight);
  text-align: left;
  margin-bottom: 30px;
}

.purchase-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.purchase-info .product-info {
  display: flex;
  justify-content: space-between;
  text-align: left;
  margin-bottom: 5px;
}

.product-info .product-title,
.product-info .product-price {
  font-size: 12px;
  line-height: 1.2;
  font-weight: var(--heading-font-weight);
}

.product-info .product-price {
  margin-left: 20px;
}

.purchase-info .product-type {
  font-size: 9px;
  line-height: 1;
  text-transform: uppercase;
  font-weight: var(--heading-font-weight);
  padding: 4px 8px;
  border-radius: 20px;
  color: var(--contrast-text-color);
  letter-spacing: var(--letter-spacing);
}

.product-type.ui-templates {
  background-color: var(--ui-templates);
}

.product-type.social-graphics {
  background-color: var(--social-graphics);
  color: var(--main-font-color);
}

.digital-store .product-type.social-graphics {
  color: var(--main-font-color);
}

.product-type.coded-templates {
  background-color: var(--coded-templates);
}

#cart-dropdown .total-price {
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
  border-bottom: 1px solid var(--border-color);
}

.total-price .heading {
  font-size: 12px;
}

.total-price .price {
  font-size: 12px;
  font-weight: var(--heading-font-weight);
}

.cart-buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 30px;
}

.cart-buttons a {
  line-height: 1;
}

.cart-buttons a+a {
  margin-left: 10px;
}

@media (max-width: 568px) {

  #notifications-dropdown,
  #cart-dropdown {
    top: 90px;
  }
}

@media (max-width: 320px) {

  #notifications-dropdown,
  #cart-dropdown {
    width: 300px;
  }
}

/* ================================
        9.6. Header search
        ================================ */

.searchbox {
  width: 100%;
  flex: 0 3 700px;
  margin-left: 10px;
  margin-right: 20px;
}

.main-search {
  position: relative;
  display: flex;
  justify-content: flex-start;
  border: 1px solid var(--border-color);
  border-radius: var(--input-border-radius);
}

.main-search .search-input,
.main-search .search-params,
.main-search .search-button {
  border: none;
  padding: 18px 20px;
  white-space: nowrap;
}

.main-search .search-button svg {
  fill: var(--main-font-color);
}

.main-search .search-input {
  flex: 0 1 100%;
  background-color: var(--body-background);
  color: var(--main-font-color);
  border-radius: 0;
}

.main-search .search-params {
  border-radius: var(--input-border-radius) 0 0 var(--input-border-radius);
  border-right: 1px solid var(--border-color);
  background-color: var(--extra-light-gray);
  color: var(--main-font-color);
}

.main-search .search-params svg {
  margin-left: 5px;
  fill: var(--main-font-color);
  transition: all ease 0.1s;
}

.main-search .search-dropdown {
  position: absolute;
  background-color: var(--extra-light-gray);
  border: 1px solid var(--border-color);
  top: calc(100% + 10px);
  left: -1px;
  width: 200px;
  z-index: 60;
  padding: 10px 0;
  border-radius: var(--input-border-radius);
  text-align: left;
  display: none;
  animation: fadeInUp 0.25s;
}

.main-search .search-dropdown li {
  white-space: initial;
  padding: 10px 20px;
  font-size: var(--body-font-size);
  line-height: 1.4;
  font-weight: var(--heading-font-weight);
}

.main-search .search-dropdown li:hover {
  background-color: var(--main-font-color);
  background-image: var(--cryptoki-gradient);
  background-size: 100%;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  cursor: pointer;
}

.main-search .search-params.active svg {
  transform: rotate(180deg);
}

.main-search .search-params.active~.search-dropdown {
  display: block;
}

@media (max-width: 1024px) {
  .searchbox {
    display: none;
  }
}

/* ================================
        9.6.1. Mobile search
        ================================ */

#mobile-search {
  display: none;
  position: relative;
}

#mobile-search-block {
  height: 0;
  position: relative;
  transition: height 0.3s linear;
  border-bottom: 0px solid var(--border-color);
}

#mobile-search-block>#mobile-search-form {
  width: 1430px;
  max-width: 100%;
  margin: 0 auto;
  position: relative;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.3s linear, opacity 0s linear;
}

#mobile-search-form input {
  width: 100%;
  outline: none;
  border: none;
  background-color: var(--body-background);
  padding: 15px 40px 15px 20px;
}

#mobile-search-form input::placeholder {
  color: var(--inactive-text-color);
}

#mobile-search-form .search-button {
  position: absolute;
  right: 20px;
  top: 15px;
  visibility: hidden;
  opacity: 0;
  transform: scale(0);
  transition: visibility 0.3s linear, opacity 0.3s linear, transform 0.3s linear;
}

#mobile-search-block.is-visible {
  height: 50px;
  border-bottom-width: 1px;
}

.nav-ds #mobile-search-block.is-visible {
  border-top: 1px solid var(--border-color);
}

#mobile-search-block.is-visible>#mobile-search-form {
  visibility: visible;
  opacity: 1;
  transition: visibility 0.3s linear 0.3s, opacity 0.3s linear 0.3s;
}

#mobile-search-block>#mobile-search-form>.search-button svg {
  fill: var(--main-font-color);
}

#mobile-search-block.is-visible>#mobile-search-form>.search-button {
  visibility: visible;
  opacity: 1;
  transform: scale(1);
  transition: visibility 0.3s linear, opacity 0.3s linear, transform 0.3s linear;
}

#mobile-search svg {
  will-change: transform;
  transform: rotate(0deg);
  transition: transform 0.3s linear;
}

#mobile-search.clicked svg {
  transform: rotate(90deg);
  transition: transform 0.3s linear;
}

@media (max-width: 1024px) {
  #mobile-search {
    display: block;
    margin-right: 20px;
  }
}

/* ================================
        9.7. Header User Profile
        ================================ */

.header-user-profile {
  position: relative;
  cursor: pointer;
}

.header-user-profile .user-meta {
  border-left: 1px solid var(--border-color);
  padding-left: 20px;
  text-align: right;
  white-space: nowrap;
}

.header-user-profile .user_name {
  font-weight: var(--heading-font-weight);
}

.header-user-profile .user_score {
  font-size: 12px;
  font-weight: 600;
  line-height: 1;
}

.header-user-profile .user_score svg {
  color: var(--coded-templates);
  border: 2px solid var(--coded-templates);
  padding: 3px;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  margin-right: 5px;
}

.header-user-profile .avatar {
  margin-left: 10px;
  position: relative;
  transform: scale(1);
  will-change: transform;
  transition: transform 0.3s linear;
}

.digital-store .verified svg {
  fill: var(--unchangable-font-color);
}

@media (max-width: 414px) {
  .header-user-profile .user-meta {
    display: none;
  }
}

/* ================================
        9.7.1. Profile dropdown
        ================================ */

#profile-dropdown {
  width: 240px;
  border-radius: 25px;
  border: 1px solid var(--border-color);
  position: absolute;
  right: -20px;
  top: 60px;
  background-color: var(--extra-light-gray);
  will-change: opacity, visibility;
  z-index: 100;
}

.profile-dropdown-header {
  height: 40px;
}

.profile-cover-image {
  /* background: url(../img/content/profile/profile-cover-1.png) no-repeat 0 0; */
  background-size: cover;
  border-radius: 25px 25px 0 0;
  height: 40px;
}

.profile-dropdown-body {
  padding: 30px 0 5px 0;
  background-color: var(--body-background);
  border-bottom: 1px solid var(--border-color);
  border-radius: 0 0 20px 20px;
}

.profile-heading {
  padding-right: 30px;
  padding-left: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  margin-top: -45px;
}

.profile-heading .profile-avatar {
  border: 3px solid var(--body-background);
  position: relative;
  width: 32px;
  height: 32px;
}

.profile-heading .profile-avatar .verified {
  position: absolute;
  right: -2px;
  top: 8px;
}

.profile-heading .profile-avatar .verified svg {
  width: 14px;
  height: 14px;
  padding: 2px;
  border: 1px solid var(--body-background);
}

.profile-heading .profile-link a {
  font-size: 10px;
}

.profile-heading .profile-link:hover a {
  color: var(--contrast-text-color);
}

.profile-menu li {
  display: flex;
  align-items: center;
}

.profile-menu li:hover {
  background-color: var(--extra-light-gray);
}

.profile-menu li:hover svg {
  fill: url('#cryptoki-svg-gradient') var(--primary-accent-color);
}

.profile-menu li:hover a {
  color: inherit;
  transform: translateX(8px);
}

.profile-dropdown-footer {
  padding: 25px 30px;
}

.mode-toggle {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mode-toggle .mode-title {
  font-size: 13px;
  font-weight: var(--heading-font-weight);
}

.toggle-control {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 12px;
  user-select: none;
}

.toggle-control input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.toggle-control input:checked~.control {
  background: var(--toggle-background-color-on);
}

.toggle-control input:checked~.control::after {
  left: 16px;
}

.toggle-control .control {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 30px;
  border-radius: 30px;
  background-color: var(--toggle-background-color-off);
  transition: background-color 0.15s ease-in;
}

.toggle-control .control::after {
  content: '';
  position: absolute;
  left: 2px;
  top: 2px;
  width: 12px;
  height: 12px;
  border-radius: 30px;
  background-color: var(--toggle-control-color);
  transition: left 0.15s ease-in;
}

/* ================================
        9.7.2. Digital Store User Profile
        ================================ */

.nav-ds .header-user-profile .user_score {
  font-weight: var(--heading-font-weight);
  color: var(--primary-accent-color);
}

/* ================================
        10. PRIMARY NAVIGATION
        ================================ */

.navigation-menu {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: baseline;
  list-style: none;
  position: relative;
}

.navigation-menu>.menu-item {
  margin-right: 30px;
  display: flex;
  align-items: center;
}

.navigation-menu .menu-item:last-child {
  margin-right: 0;
}

.navigation-menu .hidden-menu .menu-link:hover svg {
  fill: var(--primary-accent-color);
}

.menu-item .menu-link {
  display: block;
  font-size: var(--body-font-size);
  font-weight: var(--heading-font-weight);
  line-height: 2.3;
}

.navigation-menu .menu-item .menu-link {
  padding: 25px 0;
}

.navigation-menu .menu-item .menu-link:hover,
.navigation-menu .menu-item .menu-link:active {
  background-color: var(--main-font-color);
  background-image: var(--cryptoki-gradient);
  background-size: 100%;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.navigation-menu .menu-item .menu-link:visited {
  background-color: var(--main-font-color);
  background-image: var(--cryptoki-gradient);
  background-size: 100%;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: var(--main-font-color);
}

.menu-item-has-children {
  position: relative;
}

.menu-item-has-children .menu-link .indicator {
  display: inline-flex;
}

.menu-item-has-children .menu-link .indicator .crumina-icon {
  width: 15px;
  height: 15px;
  margin-left: 5px;
  padding-top: 5px;
}

.navigation-menu .menu-item-has-children:hover>.menu-link,
.navigation-menu .menu-item-has-children:active>.menu-link,
.navigation-menu .menu-item-has-children:visited>.menu-link {
  background-color: var(--main-font-color);
  background-image: var(--cryptoki-gradient);
  background-size: 100%;
  /*noinspection CssInvalidPropertyValue*/
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.menu-item-has-children:hover>.menu-link>.indicator svg {
  fill: var(--primary-accent-color);
}

.navigation-menu>li.menu-item-has-children>.menu-link>.indicator svg {
  transition: transform 0.2s linear;
}

.navigation-menu>li.menu-item-has-children:hover>.menu-link>.indicator svg {
  transform: rotate(180deg);
}

/* ================================
        10.1. Submenu
        ================================ */

.navigation-menu .menu-item-has-children ul.submenu,
.navigation-menu ul.submenu li ul.submenu {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  left: 0;
  top: 80%;
  margin: 0;
  padding: 20px 0;
  min-width: 270px;
  list-style: none;
  background-color: var(--body-background);
  border: 1px solid var(--border-color);
  border-radius: 25px;
  z-index: 100;
  will-change: opacity, visibility;
  transition: visibility 0s linear, opacity 0.3s linear;
}

.navigation-menu ul.submenu li ul.submenu {
  left: 92%;
  top: -16px;
}

.navigation-menu .menu-item-has-children:hover>ul.submenu,
.navigation-menu ul.submenu>.menu-item-has-children:hover>ul.submenu {
  visibility: visible;
  opacity: 1;
}

.navigation-menu .submenu .menu-item .menu-link {
  font-size: var(--body-font-size);
  line-height: 1.4;
  font-weight: var(--heading-font-weight);
  padding: 10px 30px;
}

.navigation-menu ul.submenu>.menu-item-has-children>.menu-link>.indicator svg {
  transition: transform 0.2s linear;
}

.navigation-menu ul.submenu>.menu-item-has-children:hover>.menu-link>.indicator svg {
  transform: translateX(5px);
}

.submenu>.menu-item-has-children .menu-link .indicator {
  opacity: 0;
  transition: opacity 0.3s linear;
}

.submenu>.menu-item-has-children:hover>.menu-link>.indicator {
  opacity: 1;
}

/* ================================
        10.2. Megamenu
        ================================ */

.navigation-menu .megamenu {
  visibility: hidden;
  opacity: 0;
  width: 770px;
  max-width: 80vw;
  position: absolute;
  top: 80%;
  left: auto;
  right: 50%;
  transform: translate(50%, 0);
  z-index: 100;
  will-change: opacity, visibility;
  transition: visibility 0s linear, opacity 0.3s linear;
}

.navigation-menu .menu-item-has-megamenu:hover .megamenu {
  visibility: visible;
  opacity: 1;
}

.navigation-menu .megamenu .megamenu-wrapper {
  display: flex;
  justify-content: space-between;
  background-color: var(--body-background);
  border: 1px solid var(--border-color);
  border-radius: 25px;
  padding: 30px 50px;
}

.navigation-menu .megamenu .megamenu-column {
  display: flex;
  flex-direction: column;
  flex-basis: 50%;
  margin-right: clamp(2rem, 5.6vmax, 90px);
}

.navigation-menu .megamenu .megamenu-column:last-child {
  margin-right: 0;
}

.navigation-menu .megamenu-column .megamenu-title {
  font-size: var(--body-font-size);
  font-weight: var(--heading-font-weight);
  margin-bottom: 20px;
}

.navigation-menu .megamenu .menu-item .menu-link {
  font-size: 13px;
  padding: 10px 0;
}

/* ================================
        10.3. Hidden menu dropdown
        ================================ */

.hidden-menu-dropdown {
  visibility: hidden;
  opacity: 0;
  width: 270px;
  padding: 20px 0;
  background-color: var(--body-background);
  border: 1px solid var(--border-color);
  border-radius: 25px;
  position: absolute;
  z-index: 10;
  right: -200px;
  top: 50px;
  will-change: opacity, visibility;
  transition: visibility 0s linear 0.3s, opacity linear 0.3s;
}

.navigation-menu .hidden-menu:hover .hidden-menu-dropdown {
  visibility: visible;
  opacity: 1;
  transition-delay: 0s;
}

.loading .hidden-menu-dropdown {
  display: none;
}

.hidden-menu-dropdown a {
  font-size: var(--body-font-size);
  font-weight: var(--heading-font-weight);
  display: block;
  padding: 6px 30px;
}

/* ================================
        10.4. Social icons list
        ================================ */

.social-icons-list {
  list-style: none;
  display: flex;
  flex-wrap: nowrap;
}

.social-icons-list .social-icon {
  margin-right: 15px;
}

.social-icons-list .social-icon:last-child {
  margin-right: 0;
}

.social-icons-list .social-icon svg:hover,
.social-icons-list .social-icon svg:active {
  fill: var(--primary-accent-color);
}

/* ================================
        10.5. Mobile menu
        ================================ */

.burger-icon {
  display: none;
  cursor: pointer;
}

.burger-icon:hover svg {
  fill: var(--primary-accent-color);
}

.mobile-nav-panel {
  position: fixed;
  top: 0;
  left: 0;
  transform: translateX(-100%);
  background: var(--body-background);
  min-width: 240px;
  height: 100vh;
  z-index: 100;
  overflow: auto;
  transition: transform 0.3s ease-in;
}

.mobile-nav-panel.open {
  transform: translateX(0);
}

.loading .mobile-nav-panel {
  display: none;
}

.mobile-nav-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 20px;
}

.mobile-nav-header .close-icon {
  visibility: hidden;
  opacity: 0;
  width: 14px;
  height: 14px;
  transform: scale(0);
  transition: transform 0.3s linear 0.3s;
}

.mobile-menu {
  background: var(--body-background);
  position: relative;
  z-index: 10;
}

.mobile-menu-logo img {
  width: 40px;
  height: 40px;
}

.mobile-nav-panel.open .mobile-nav-header .close-icon {
  visibility: visible;
  opacity: 1;
  transform: scale(1);
  transition: transform 0.3s linear 0.3s;
  cursor: pointer;
}

.mobile-nav-panel.open .mobile-nav-header .close-icon:hover {
  fill: var(--primary-accent-color);
}

.mobile-menu .menu-item-has-children>.submenu,
.mobile-menu .menu-item-has-children>.megamenu {
  width: 100%;
  background-color: var(--body-background);
  position: relative;
  max-height: 0;
  opacity: 0;
  z-index: -1;
  transition: opacity 0.2s linear, max-height 0.3s linear 0.1s;
  overflow: hidden;
}

.mobile-menu .menu-item-has-children>.submenu.show,
.mobile-menu .menu-item-has-children>.megamenu.show {
  max-height: 2500px;
  opacity: 1;
  z-index: 100;
  pointer-events: all;
  transition: opacity 0.3s linear, max-height 0.3s linear;
}

.mobile-menu .menu-item .menu-link {
  line-height: 1.4;
  padding: 8px 20px;
}

.mobile-menu .menu-item .menu-link .count {
  color: var(--primary-accent-color);
}

.mobile-menu .menu-item-has-children>ul.submenu {
  padding-left: 10px;
}

.mobile-menu .megamenu-title {
  font-weight: var(--heading-font-weight);
  padding-left: 20px;
}

.mobile-menu .megamenu .menu-item .menu-link {
  font-size: 13px;
  padding-left: 30px;
}

.mobile-nav-footer {
  position: fixed;
  bottom: 0;
  padding: 0px 0px 20px 0px;
}

.mobile-nav-footer .topbar-switchers {
  margin-bottom: 25px;
}

@media (max-width: 800px) {
  nav {
    display: none;
  }

  .burger-icon {
    display: block;
  }
}

/* ================================
        10.6 Digital Store navigation
        ================================ */

.nav-ds .navigation-wrapper {
  height: 93px;
}

.nav-ds .navigation-wrapper>* {
  display: flex;
  align-items: center;
}

.nav-ds .nav-left-hand-part {
  justify-content: flex-start;
  flex-basis: 60%;
}

.nav-ds .logo {
  margin-right: 30px;
}

.nav-ds #mobile-search {
  display: block;
}

.nav-ds #mobile-search:hover svg {
  fill: var(--primary-accent-color);
}

@media (max-width: 1200px) {
  nav.nav-ds {
    display: block;
  }

  .nav-ds .navigation-menu {
    display: none;
  }

  .nav-ds .burger-icon {
    display: block;
    margin-right: 10px;
  }
}

/* ================================
        11. ELEMENTS
        ================================ */

/* ================================
       11.1.Call to action
       ================================ */

.call-to-action-box {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 40px;
  background: linear-gradient(50deg,
      var(--primary-gradient-color),
      var(--secondary-gradient-color));
  border-radius: 25px;
  padding: 66px 0 68px;
  position: relative;
  overflow: hidden;
}

.call-to-action-text {
  color: #fff;
}

.call-to-action-text .h1 {
  line-height: 1;
}

.call-to-action-text .subtitle {
  font-weight: var(--heading-font-weight);
}

.call-to-action-button {
  position: relative;
  z-index: 10;
}

@media (max-width: 600px) {
  .call-to-action-box {
    flex-direction: column;
    text-align: center;
    padding: 36px 0 38px;
  }

  .call-to-action-box .call-to-action-text {
    margin-bottom: 10px;
  }

  .call-to-action-button {
    margin-top: 20px;
  }
}

/* ================================
      11.3. Info Box
      ================================ */

.info-box {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: clamp(1rem, 4vmax, 100px);
}

.info-item .title {
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .info-box {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 414px) {
  .info-box {
    grid-template-columns: 1fr;
    grid-column-gap: 0;
  }
}

/* ================================
       11.4. Tabs
       ================================ */

.tabs-list {
  list-style: none;
  display: flex;
  border-bottom: 1px solid var(--border-color);
}

.tabs-list li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 35px;
  opacity: 0.7;
}

.tabs-list li .count {
  color: var(--primary-accent-color);
  font-weight: var(--heading-font-weight);
}

.tabs-list li a {
  font-size: 16px;
  font-weight: var(--heading-font-weight);
  letter-spacing: var(--letter-spacing);
  display: block;
  padding: 14px 0;
  white-space: nowrap;
  border-bottom: 2px solid transparent;
  cursor: pointer;
  }
  
  .tabs-list li div {
    font-size: 16px;
    font-weight: var(--heading-font-weight);
    letter-spacing: var(--letter-spacing);
    display: block;
    padding: 14px 0;
    white-space: nowrap;
    border-bottom: 2px solid transparent;
}

.tabs-list li.active {
  color: var(--main-font-color);
  opacity: 1;
}

.tabs-list li.active a {
  border-bottom: 2px solid var(--main-font-color);
}
.tabs-list li.active div {
  border-bottom: 2px solid var(--main-font-color);
}

.tab-content {
  padding-top: 40px;
}

.tab {
  display: none;
}

.tab.active {
  display: block;
}

.tabs-block.swiper-container {
  position: relative;
}

.tabs-block li.swiper-slide {
  width: auto;
}

.tabs-block .swiper-nav,
.tabs-block .swiper-nav .swiper-button-next,
.tabs-block .swiper-nav .swiper-button-prev {
  display: none;
}

.tabs-block .swiper-nav .swiper-button-next .next-button,
.tabs-block .swiper-nav .swiper-button-prev .prev-button {
  width: 8px;
  height: 12px;
  fill: var(--main-font-color);
}

.tabs-block .swipper-nav .swiper-button-next:hover svg,
.tabs-block .swipper-nav .swiper-button-prev:hover svg {
  fill: var(--primary-accent-color);
}

.tabs-block .swiper-nav .swiper-button-prev::before,
.tabs-block .swiper-nav .swiper-button-next::before,
.tabs-block .swiper-nav .swiper-button-prev::after,
.tabs-block .swiper-nav .swiper-button-next::after {
  content: '';
}

@media (max-width: 800px) {
  .tabs-block {
    position: relative;
  }

  .tabs-list {
    border-bottom: none;
  }

  .tabs-list li {
    justify-content: center;
    border-bottom: 1px solid var(--border-color);
  }

  .tabs-block.swiper-container .tabs-list li.swiper-slide {
    width: auto;
    opacity: 0;
    visibility: hidden;
    transition: visibility 0s linear 0s, opacity 0.3s linear;
  }

  .tabs-block.swiper-container .tabs-list li.swiper-slide-visible {
    opacity: 1;
    visibility: visible;
  }

  .tabs-block.swiper-container .tabs-list li.swiper-slide {
    margin-right: 0;
  }

  .tab-content {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (max-width: 1024px) {
  .product-version-1 .product-media {
    padding-top: 0;
  }
}

/* ================================
       11.5. Accordion
       ================================ */

.accordion-card {
  padding: clamp(1rem, 2vmax, 30px);
  border: 1px solid var(--border-color);
  border-radius: 25px;
  margin-bottom: 15px;
}

.card-header .card-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.card-header .card-title {
  font-size: 16px;
  font-weight: var(--heading-font-weight);
  line-height: 1.4;
}

.accordion-card .card-info {
  margin-top: 45px;
}

.card-header .icon-box {
  position: relative;
}

.card-header svg {
  width: 20px;
  height: 20px;
  position: absolute;
  top: -5px;
  left: -5px;
  fill: var(--main-font-color);
}

.icon-box .plus-icon.show {
  opacity: 1;
  visibility: visible;
  transform: rotate(90deg);
  transition: transform 0.3s linear;
}

.icon-box .plus-icon {
  transform: rotate(0deg);
  transition: transform 0.3s linear;
}

.icon-box .minus-icon {
  opacity: 1;
  visibility: visible;
  transform: rotate(0deg);
  transition: transform 0.3s linear;
}

.card-body {
  overflow: hidden;
  opacity: 1;
  visibility: hidden;
  line-height: 1.6;
  font-size: 1rem;
  font-weight: 500;
  max-height: 0;
  transition: visibility 0.5s, padding 0.5s, max-height 0.5s;
}

.card-body.open {
  visibility: visible;
}

/* ================================
       11.6. Pagination
       ================================ */

.pagination-section {
  padding-top: clamp(2rem, 3.1vmax, 80px);
  padding-bottom: clamp(1rem, 2.5vmax, 40px);
}

.pagination-list {
  display: flex;
  list-style: none;
  font-weight: var(--heading-font-weight);
}

.pagination-list .page-nav-prev {
  display: none;
  margin-right: 22px;
}

.pagination-list .page-item {
  margin-right: 22px;
}

.pagination-list .page-item:last-child {
  margin-right: 0;
}

/* ================================
       11.7. Before footer
       ================================ */

.before-footer-block {
  display: flex;
  padding-top: clamp(1rem, 4vmax, 100px);
}

.before-footer-image {
  flex: 0 1 240px;
}

.before-footer-image img {
  object-fit: cover;
  width: 100%;
  height: auto;
}

@media (max-width: 767px) {
  .before-footer-block {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  }
}

/* ================================
       11.8. Wallet
       ================================ */

.wallet-wrapper .section-title {
  margin-bottom: 80px;
}

.wallet-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 40px;
  margin-bottom: 40px;
}

.wallet-grid-3 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 40px;
  margin-bottom: 40px;
}

.wallet-item {
  border: 1px solid var(--border-color);
  border-radius: 25px;
  position: relative;
  padding-top: 60px;
}

.wallet-icon {
  position: absolute;
  top: -20px;
  left: 50%;
  transform: translate(-50%, 0);
  width: 90px;
  height: 60px;
  background: var(--body-background);
  border: 1px solid var(--border-color);
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.wallet-item .wallet-info {
  padding: 35px;
  text-align: center;
  background: var(--body-background);
  border-top: 1px solid var(--border-color);
  border-radius: 23px;
}

.wallet-item .wallet-info .title {
  margin-bottom: 10px;
}

.wallet-item .wallet-info a {
  color: var(--primary-accent-color);
}

.notification-text {
  font-size: 16px;
  font-weight: var(--heading-font-weight);
}

.notification-text a {
  color: var(--primary-accent-color);
}

@media (max-width: 820px) {
  .wallet-grid {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 10px;
  }
}

@media (max-width: 567px) {
  .wallet-grid {
    grid-template-columns: 1fr;
    grid-column-gap: 0;
  }

  .wallet-grid-3 {
    grid-template-columns: 1fr;
    grid-column-gap: 0;
  }
}

/* ================================
       11.9. Become seller block
       ================================ */

.become-seller-block {
  display: flex;
  justify-content: center;
  align-items: center;
}

.become-seller-block img {
  margin-right: max(3.75vw, 30px);
  width: 170px;
  height: auto;
}

.become-seller-block .subtitle {
  font-size: var(--body-font-size);
  font-weight: var(--heading-font-weight);
  letter-spacing: var(--letter-spacing);
  text-transform: uppercase;
}

.become-seller-block .title {
  font-size: var(--h2-heading);
  font-weight: var(--heading-font-weight);
  letter-spacing: var(--letter-spacing);
  line-height: 1;
}

@media (max-width: 375px) {
  .become-seller-block {
    flex-direction: column;
  }

  .become-seller-block img {
    margin-right: 0;
    margin-bottom: 20px;
  }
}

/* ================================
       11.10. Fullwidth banner
       ================================ */

.fullwidth-banner {
  background: var(--cryptoki-gradient);
  height: 540px;
  max-height: 70vw;
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 70px;
}


.search-form-with-text {
  z-index: 1;
}

.search-form-with-text .text-content {
  text-align: center;
  margin-bottom: max(3.75vw, 30px);
}

.search-form-with-text {
  padding: 20px;
}

.search-form-with-text .subtitle {
  font-size: var(--body-font-size);
  font-weight: var(--heading-font-weight);
  letter-spacing: var(--letter-spacing);
  color: var(--contrast-text-color);
}

.search-form-with-text .title {
  font-size: clamp(2rem, 3.8vmax, 62px);
  font-weight: var(--heading-font-weight);
  letter-spacing: var(--letter-spacing);
  color: var(--contrast-text-color);
  line-height: 1;
}

.search-form-with-text .bannerTitle {
  font-size: clamp(2rem, 3.8vmax, 62px);
  font-weight: var(--heading-font-weight);
  letter-spacing: var(--letter-spacing);
  color: var(--contrast-text-color);
  line-height: 1.5;
}

.search-form-with-text .main-search {
  border-radius: 10px;
  border: none;
}

.search-form-with-text .main-search .search-params {
  display: flex;
  align-items: center;
  border-radius: 10px 0 0 10px;
}

.search-form-with-text .main-search .search-params .search-dropdown {
  border-radius: 10px;
}

.search-form-with-text .main-search .search-button {
  background-color: var(--main-font-color);
  border-radius: 0 10px 10px 0;
}

body.dark-mode .search-form-with-text .main-search .search-button {
  background-color: var(--btn-dark);
}

.search-form-with-text .main-search .search-button svg {
  fill: var(--contrast-text-color);
}

@media (max-width: 767px) {
  .search-form-with-text .main-search .search-params .button-text {
    display: none;
  }
}

@media (max-width: 320px) {
  .search-form-with-text .main-search .search-input {
    width: 100px;
  }
}

/* ================================
       11.11. Load More Bars
       ================================ */

.load-more_bars {
  display: flex;
  justify-content: center;
  height: 40px;
}

.load-more_bars .load-more_bar {
  width: 4px;
  height: 100%;
  margin-right: 4px;
  border-radius: 200px;
  -webkit-transform: scaleY(0.2);
  transform: scaleY(0.2);
  -webkit-animation: load-more_bars 0.5s infinite alternate ease-in;
  animation: load-more_bars 0.5s infinite alternate ease-in;
}

.load-more_bars .load-more_bar:last-child {
  margin-right: 0;
}

.load-more_bars .load-more_bar:nth-child(1) {
  background-color: #5346eb;
}

.digital-store .load-more_bars .load-more_bar:nth-child(1) {
  background-color: #5346eb;
}

.load-more_bars .load-more_bar:nth-child(2) {
  background-color: #6545ee;
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}

.digital-store .load-more_bars .load-more_bar:nth-child(2) {
  background-color: #6545ee;
}

.load-more_bars .load-more_bar:nth-child(3) {
  background-color: #8242f1;
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

.digital-store .load-more_bars .load-more_bar:nth-child(3) {
  background-color: #8242f1;
}

.load-more_bars .load-more_bar:nth-child(4) {
  background-color: #ac3ef5;
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

.digital-store .load-more_bars .load-more_bar:nth-child(4) {
  background-color: #ac3ef5;
}

.load-more_bars .load-more_bar:nth-child(5) {
  background-color: #b43df7;
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.digital-store .load-more_bars .load-more_bar:nth-child(5) {
  background-color: #b43df7;
}

.load-more_bars .load-more_bar:nth-child(6) {
  background-color: #cd3afa;
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

.digital-store .load-more_bars .load-more_bar:nth-child(6) {
  background-color: #cd3afa;
}

.load-more_bars .load-more_bar:nth-child(7) {
  background-color: #dd39fc;
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

.digital-store .load-more_bars .load-more_bar:nth-child(7) {
  background-color: #dd39fc;
}

.load-more_bars .load-more_bar:nth-child(8) {
  background-color: #f337fe;
  -webkit-animation-delay: 0.7s;
  animation-delay: 0.7s;
}

.digital-store .load-more_bars .load-more_bar:nth-child(8) {
  background-color: #f337fe;
}

@-webkit-keyframes load-more_bars {
  0% {
    -webkit-transform: scaleY(0.2);
    transform: scaleY(0.2);
  }

  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }
}

@keyframes load-more_bars {
  0% {
    -webkit-transform: scaleY(0.2);
    transform: scaleY(0.2);
  }

  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }
}

/* ================================
       12. CRYPTOKI FORM
       ================================ */

.form-title {
  font-size: 24px;
  line-height: 1.2;
  font-weight: var(--heading-font-weight);
  margin-bottom: 20px;
}

.form-list-wrapper {
  display: inline-flex;
  flex-flow: column nowrap;
}

.form-group {
  margin-bottom: 40px;
}

.form-group:last-child {
  margin-bottom: 0;
}

.form-list-elem {
  font-weight: var(--heading-font-weight);
  line-height: 1;
  margin-bottom: 20px;
}

.form-list-elem:last-child {
  margin-bottom: 0;
}

.cryptoki-form input,
.cryptoki-form textarea {
  font-size: var(--body-font-size);
  font-weight: var(--body-font-weight);
  background-color: var(--body-background);
  color: var(--main-font-color);
}

.cryptoki-form input[type='text'],
.cryptoki-form input[type='number'],
.cryptoki-form input[type='email'],
.cryptoki-form input[type='button'],
.cryptoki-form input[type='password'],
.cryptoki-form input[type='url'],
.cryptoki-form input[type='submit'],
.cryptoki-form input[type='reset'],

/* .cryptoki-form select, */

.cryptoki-form .select-nice {
  padding: max(0.87vw, 12px) max(1.5vw, 18px);
  border: 1px solid var(--border-color);
  background-color: var(--body-background);
  color: var(--main-font-color);
  border-radius: var(--input-border-radius);
  margin-bottom: 20px;
  position: relative;
}

.cryptoki-form input[type='button'],
.cryptoki-form input[type='submit'] {
  cursor: pointer;
}

.cryptoki-form input:focus,
.cryptoki-form select:focus,
.cryptoki-form textarea:focus {
  outline: none;
  border: 1px solid var(--primary-accent-color);
}

.cryptoki-form .price-range {
  display: flex;
  justify-content: space-between;
}

.cryptoki-form .price-range input {
  float: left;
  width: 48%;
  margin-right: 15px;
}

.cryptoki-form .price-range input:last-child {
  margin-right: 0;
}

.cryptoki-form input[type='button'],
.cryptoki-form button {
  background-color: var(--main-font-color);
  color: var(--contrast-text-color);
}

.cryptoki-form input[type='reset'] {
  border: none;
  color: var(--inactive-text-color);
  padding: 0;
  margin: 0;
}

/* .cryptoki-form textarea {
  padding: 30px;
  border: 1px solid var(--border-color);
  border-radius: 10pxpx;
} */

.digital-store .cryptoki-form textarea {
  border-radius: 15px;
}

.cryptoki-form .form-group {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin-bottom: 10px;
}

.cryptoki-form .form-field {
  display: flex;
  flex-direction: column;
  /* width: 100%; */
  margin-bottom: 10px;
}

.cryptoki-form .form-group>.form-field {
  margin-bottom: 0;
}

.cryptoki-form .form-group:last-of-type>.form-field input {
  margin-bottom: 0;
}

.cryptoki-form .form-group:last-of-type>.form-field label {
  margin-top: 20px;
}

.cryptoki-form input::placeholder {
  color: var(--inactive-text-color);
}

.form-field:only-child,
.form-field:last-child {
  margin-right: 0;
}

.cryptoki-form .form-field label,
.cryptoki-form .label {
  font-size: var(--body-font-size);
  font-weight: var(--heading-font-weight);
  text-transform: capitalize;
  margin-bottom: 15px;
  line-height: 1;
}

.cryptoki-form .form-field+button,
.cryptoki-form .form-group+button,
.cryptoki-form .form-field+input[type='button'],
.cryptoki-form .form-group+input[type='button'],
.cryptoki-form .form-field+input[type='submit'],
.cryptoki-form .form-group+input[type='submit'] {
  margin-top: 30px;
}

.cryptoki-form .register-notice {
  margin-top: 10px;
}

@media (max-width: 1024px) {
  .is-two-col-element .form-list-wrapper {
    margin-bottom: 20px;
  }

  .cryptoki-form .form-group {
    flex-wrap: wrap;
  }

  .form-group>.form-field {
    margin-right: 0;
  }
}

/* ================================
       12.1. Cryptoki select
       ================================ */

select {
  appearance: none;
  display: inline-block;
  /* background-image: url(../svg/arrow-down.svg); */
  background-position: calc(100% - 20px) center;
  background-repeat: no-repeat;
  background-size: 8px 8px;
  border: 1px solid var(--border-color);
  border-radius: var(--btn-border-radius);
  padding: max(1vw, 12px) max(1.5vw, 40px) max(1vw, 12px) max(1.5vw, 20px);
  font-weight: var(--heading-font-weight);
  line-height: 1.2;
}

select::before {
  /* content: url(../svg/arrow-down.svg); */
  width: 8px;
  height: 8px;
}

.cryptoki-form select {
  position: relative;
  width: 100%;
  cursor: pointer;
  font-weight: var(--heading-font-weight);
}

/* ================================
       12.2. Cryptoki checkbox
       ================================ */

.cryptoki-form input[type='checkbox'] {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.cryptoki-form input[type='checkbox']+label {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  position: relative;
}

.cryptoki-form input[type='checkbox']+label::before {
  content: '';
  width: 1em;
  height: 1em;
  flex-grow: 0;
  flex-shrink: 0;
  background-color: var(--lighter-gray);
  border: 1px solid var(--border-color);
  border-radius: 0.4em;
  margin-right: 0.5em;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
}

.cryptoki-form input[type='checkbox']:checked+label::before {
  background: var(--cryptoki-gradient);
  border: none;
}

.cryptoki-form input[type='checkbox']:checked+label::after {
  content: '';
  width: 8px;
  height: 8px;
  position: absolute;
  left: 3px;
  top: 3px;
  /* background-image: url(../svg/cross.svg); */
}

/* ================================
       12.3. Cryptoki radio
       ================================ */

.cryptoki-form input[type='radio'] {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.cryptoki-form input[type='radio']+label {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  position: relative;
}

.cryptoki-form input[type='radio']+label::before {
  content: '';
  width: 14px;
  height: 14px;
  flex-grow: 0;
  flex-shrink: 0;
  background-color: var(--lighter-gray);
  border: 1px solid var(--border-color);
  border-radius: 1em;
  margin-right: 0.5em;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
}

.cryptoki-form input[type='radio']:checked+label::before {
  background: var(--cryptoki-gradient);
  border: none;
}

.cryptoki-form input[type='radio']:checked+label::after {
  content: '';
  width: 6px;
  height: 6px;
  position: absolute;
  left: 4px;
  /* background-image: url(../svg/dot.svg); */
}

/* ================================
       12.4. Cryptoki input file
       ================================ */

.cryptoki-form .file-uploader {
  width: 100%;
  height: 180px;
  border: 1px dashed var(--border-color);
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.cryptoki-form .file-uploader>* {
  margin-bottom: 15px;
}

.cryptoki-form .file-uploader label {
  font-size: var(--body-font-size);
  font-weight: var(--heading-font-weight);
  line-height: 1;
  border-radius: var(--input-border-radius);
  padding: max(0.87vw, 12px) max(2.5vw, 30px);
  background-color: var(--main-font-color);
  color: var(--contrast-text-color);
  cursor: pointer;
}

.digital-store .file-uploader label:hover {
  color: var(--contrast-text-color);
}

.cryptoki-form #upload-media {
  position: absolute;
  opacity: 0;
  z-index: -1;
}

/* ================================
       12.5. Comment form
       ================================ */

#comment-form .btn {
  float: right;
}

/* ================================
       12.6. Contact form
       ================================ */

#contact-form .btn {
  padding: max(0.87vw, 12px) max(4.3vw, 50px);
  float: right;
}

/* Hide recaptcha v3 Bage */

.grecaptcha-badge {
  visibility: hidden;
}

/* ================================
       12.7. Filter forms
       ================================ */

#filter-form {
  padding: 30px;
  border: 1px solid var(--border-color);
  border-radius: 20px;
  margin-right: 20px;
}

#filter-form input[type='submit'],
#filter-form input[type='reset'] {
  width: 100%;
  font-weight: var(--heading-font-weight);
}

#filter-form input[type='submit'] {
  background: var(--main-font-color);
  color: var(--contrast-text-color);
  margin-top: 0;
}

#filter-form input[type='submit']:active,
#filter-form input[type='submit']:visited,
#filter-form input[type='submit']:focus {
  border: none;
}

body.dark-mode #filter-form input[type='submit'] {
  background: var(--cryptoki-gradient);
  color: var(--contrast-text-color);
}

#filter-form .form-group {
  flex-direction: column;
  margin-bottom: 40px;
}

#filter-form input[type='number'] {
  margin-bottom: 0;
}

@media (max-width: 1024px) {
  #filter-form .is-two-col-element {
    flex-wrap: wrap;
  }

  #filter-form .form-group {
    margin-bottom: 20px;
  }
}

/* ================================
       12.7.1. Creators form
       ================================ */

#creators-filter-form {
  display: flex;
  justify-content: space-between;
}

#creators-filter-form .filter-options>* {
  margin-right: 15px;
  margin-left: 0;
}

#creators-filter-form .filter-options>div:last-child {
  margin-right: 0;
}

@media (max-width: 800px) {
  #creators-filter-form {
    flex-direction: column;
  }

  #creators-filter-form .filter-options>* {
    margin-bottom: 10px;
  }
}

/* ================================
       12.7.2. Artworks form
       ================================ */

#artworks-filter-form {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media (max-width: 1024px) {
  #artworks-filter-form {
    justify-content: flex-start;
  }

  #artworks-filter-form .filter-item {
    margin-bottom: 10px;
  }
}

/* ================================
       13. SLIDERS
       ================================ */

/* ================================
       13.1. Home slider
       ================================ */

.swiper {
  width: 100%;
  height: 100%;
}

#homeSlider {
  max-height: 640px;
  margin-bottom: 20px;
}

#homeSlider .dark-overlay {
  width: 100%;
  height: 100%;
  /* background: url(../img/main-slider_dark-gradient.png) no-repeat 0 100%; */
  border-radius: 30px;
  position: absolute;
}

#homeSlider .slide-content .slide-title {
  font-size: clamp(2rem, 3.8vmax, 62px);
  line-height: 1;
  font-weight: var(--heading-font-weight);
  color: var(--contrast-text-color);
  letter-spacing: var(--letter-spacing);
}

#homeSlider .slide-content .slide-subtitle {
  font-size: var(--body-font-size);
  text-transform: uppercase;
  font-weight: var(--heading-font-weight);
  color: var(--contrast-text-color);
  letter-spacing: var(--letter-spacing);
}

#homeSlider .slide-content .slide-title+button,
#homeSlider .slide-content .slide-title+.slide-button {
  margin-top: 30px;
}

#homeSlider .gradient-border_white-bg.slide-button:hover,
#homeSlider .gradient-border_dark-bg.slide-button:hover,
#homeSlider .gradient-border-purple-bg.slide-button:hover {
  background: var(--cryptoki-gradient);
  background-origin: border-box;
}

#homeSlider .gradient-border_white-bg.slide-button:hover a {
  color: var(--contrast-text-color);
}

#homeSlider .swiper-slide {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
}

#homeSlider .swiper-slide>img {
  position: relative;
}

#homeSlider .swiper-slide .slide-content {
  position: absolute;
  /* bottom: 0; */
  /* left: 0; */
  padding: clamp(2rem, 4.3vmax, 70px);
  width: 100%;
  height: auto;
  z-index: 99999999999999;
  display: flex;
  justify-content: center;
  top: 35%;
}

#homeSlider .swiper-slide .item-bid-section {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  color: var(--contrast-text-color);
  margin-top: 20px;
}

#homeSlider .swiper-slide .item-bid-section>* {
  margin-right: clamp(1rem, 2.5vmax, 40px);
}

#homeSlider .swiper-slide .item-bid-section>div:last-child {
  margin-right: 0;
}

.item-bid-section .value {
  font-size: clamp(1rem, 2vmax, 32px);
  font-weight: var(--heading-font-weight);
  line-height: 1.2;
  letter-spacing: var(--letter-spacing);
}

.item-bid-section .label {
  text-transform: uppercase;
}

.item-info .item-meta {
  display: inline-flex;
  background-color: var(--body-background);
  padding: 3px 6px;
  border-radius: 30px;
  margin-bottom: 20px;
}

.homeslider-nav {
  width: 940px;
  height: 50px;
  max-width: 100vw;
  position: absolute;
  left: 50%;
  bottom: 50%;
  transform: translate(-50%, 50%);
  z-index: 10;
}

.homeslider-nav .swiper-button-next,
.homeslider-nav .swiper-button-prev {
  background-color: var(--body-background);
  width: 46px;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--border-color);
  border-radius: var(--btn-border-radius);
  cursor: pointer;
  position: absolute;
  top: 50%;
}

.homeslider-nav .swiper-button-prev {
  left: -12px;
  right: auto;
}

.homeslider-nav .swiper-button-next {
  right: -12px;
  left: auto;
}

.homeslider-nav .swiper-button-next::after,
.homeslider-nav .swiper-button-prev::after {
  content: '';
}

.homeslider-nav .swiper-button-next .next-button,
.homeslider-nav .swiper-button-prev .prev-button {
  width: 4px;
  height: 8px;
}

.homeslider-nav .swiper-button-next svg,
.homeslider-nav .swiper-button-prev svg {
  fill: var(--main-font-color);
}

.homeslider-nav .swiper-button-next:hover svg,
.homeslider-nav .swiper-button-prev:hover svg {
  fill: var(--primary-accent-color);
}

@media (max-width: 996px) {
  .homeslider-nav .swiper-button-prev {
    left: 10px;
    right: auto;
  }

  .homeslider-nav .swiper-button-next {
    right: 10px;
    left: auto;
  }
}

@media (max-width: 600px) {
  #homeSlider .homeslider-nav {
    display: none;
  }
}

@media (max-width: 414px) {
  #homeSlider .slide-content-align-right .slide-content {
    width: 100%;
  }

  #homeSlider .swiper-slide .item-bid-section>div:last-child {
    margin-top: 10px;
  }

  #homeSlider .swiper-slide .item-bid-section {
    margin-top: 10px;
  }
}

@media (max-width: 320px) {
  #homeSlider .item-info .item-meta {
    display: none;
  }
}

/* ================================
       13.2. Featured items carousel
       ================================ */

.title-navigation .swiper-button-prev {
  left: auto;
  right: 40px;
  top: -10px;
}

.title-navigation .swiper-button-next {
  right: 0;
  left: auto;
  top: -10px;
}

.title-navigation .swiper-button-prev:hover svg,
.title-navigation .swiper-button-next:hover svg {
  fill: var(--primary-accent-color);
}

.title-navigation .swiper-button-prev::after,
.title-navigation .swiper-button-next::after {
  content: '';
}

/* ================================
       13.3. Categories bar carousel
       ================================ */

.categories-nav .swiper-button-prev::after,
.categories-nav .swiper-button-next::after {
  content: '';
}

.categories-nav .swiper-button-prev {
  left: 0;
}

.categories-nav .swiper-button-next {
  right: 0;
}

.categories-nav .arrow {
  display: flex;
  align-items: center;
  width: 10px;
  height: 42px;
  background: var(--body-background);
  z-index: 10;
  fill: var(--main-font-color);
}

.categories-nav .arrow:hover svg {
  fill: var(--primary-accent-color);
}

.categories-nav .arrow-left {
  justify-content: flex-start;
  box-shadow: var(--body-background) 5px 0px 10px 15px;
}

.categories-nav .arrow-right {
  justify-content: flex-end;
  box-shadow: var(--body-background) -5px 0px 10px 15px;
}

.categories-nav svg {
  width: 7px;
  height: 12px;
}

.categories-bar {
  position: relative;
  overflow: hidden;
  margin-bottom: 30px;
}

.category-item {
  display: inline-flex;
  font-size: 13px;
  font-weight: var(--heading-font-weight);
  border: 1px solid var(--border-color);
  border-radius: var(--btn-border-radius);
  margin-right: 14px;
  white-space: nowrap;
  flex-basis: 160px;
}

.category-item a {
  display: flex;
  align-items: center;
  padding: 9px 16px;
}

.category-item svg {
  margin-right: 7px;
}

.category-item:hover {
  display: inline-flex;
  align-self: center;
  border-radius: var(--btn-border-radius);
  border: solid 1px transparent;
  background-image: linear-gradient(var(--body-background),
      var(--body-background)),
    linear-gradient(to right,
      var(--primary-gradient-color),
      var(--secondary-gradient-color));
  background-origin: border-box;
  background-clip: content-box, border-box;
}

.category-item:hover a {
  color: inherit;
}

/* ================================
       13.4. Gallery carousel
       ================================ */

.gallery-carousel {
  overflow: hidden;
}

.gallery-carousel .swiper-button-prev {
  left: 0;
  right: auto;
}

.gallery-carousel .swiper-button-next {
  right: 0;
  left: auto;
}

.gallery-carousel .swiper-button-prev:hover svg,
.gallery-carousel .swiper-button-next:hover svg {
  fill: var(--primary-accent-color);
}

.gallery-carousel .swiper-button-prev::after,
.gallery-carousel .swiper-button-next::after {
  content: '';
}

/* ================================
       13.5. Social icons carousel
       ================================ */

.social-icons-carousel {
  position: relative;
  overflow: hidden;
}

.social-icons-carousel .swiper-wrapper {
  margin: 0 auto 30px;
}

.social-icons-carousel .swiper-slide {
  width: auto;
  opacity: 0;
  visibility: hidden;
  transition: visibility 0s linear 0s, opacity 0.3s linear;
}

.social-icons-carousel .swiper-slide-visible {
  opacity: 1;
  visibility: visible;
}

.social-swiper-nav {
  position: relative;
}

.social-swiper-nav .swiper-button-prev svg,
.social-swiper-nav .swiper-button-next svg {
  width: 4px;
  height: 8px;
}

.social-swiper-nav .swiper-button-prev:hover svg,
.social-swiper-nav .swiper-button-next:hover svg {
  fill: var(--primary-accent-color);
}

.social-swiper-nav .swiper-button-prev::after,
.social-swiper-nav .swiper-button-next::after {
  content: '';
}

.social-swiper-nav .swiper-button-next {
  right: -26px;
  left: auto;
  top: 16px;
}

.social-swiper-nav .swiper-button-prev {
  right: auto;
  left: -28px;
  top: 16px;
}

/* ================================
       13.6. Items Categories carousel
       ================================ */

/* ================================
       13.8. Featured Sellers slider
       ================================ */

.seller-info {
  display: flex;
  align-items: center;
}

.seller-info .avatar {
  margin-right: 20px;
}

.seller-info .title,
.seller-score .title {
  font-size: 20px;
  font-weight: var(--heading-font-weight);
  letter-spacing: var(--letter-spacing);
  line-height: 1;
}

.seller-info .meta,
.seller-score label {
  font-size: var(--body-font-size);
  font-weight: var(--body-font-weight);
  letter-spacing: var(--letter-spacing);
}

.seller-stats {
  display: flex;
  align-items: center;
}

.seller-stats>* {
  margin-left: clamp(10px, 3.5vmax, 50px);
}

.seller-stats>div:first-child {
  margin-left: 0;
}

.seller-score {
  text-align: right;
  white-space: nowrap;
}

@media (max-width: 800px) {
  .seller-info {
    margin-bottom: 20px;
  }

  .products-previews {
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .products-previews .preview-box {
    margin-top: 10px;
  }
}

@media (max-width: 320px) {
  .products-previews .preview-box {
    width: 60px;
    height: auto;
    border-radius: 12px;
  }

  .products-previews .preview-box img {
    border-radius: 12px;
  }

  .seller-info .seller-box .avatar {
    display: none;
  }

  .product-additional-info .product-score .score-item {
    flex-basis: 105px;
  }
}

/* ================================
       14. ASIDE
       ================================ */

.aside-wrapper {
  padding-top: 15px;
}

.widget {
  margin-bottom: 50px;
}

.widget:last-child {
  margin-bottom: 0;
}

.widget-title {
  margin-bottom: 20px;
}

.widget-contact .email {
  font-size: 16px;
  font-weight: var(--heading-font-weight);
}

.widget-2 {
  padding: 30px;
  border: 1px solid var(--border-color);
  border-radius: 25px;
  margin-bottom: 20px;
}

.widget-2 .widget-title.h5 {
  font-size: 16px;
  font-weight: var(--heading-font-weight);
  margin-bottom: 30px;
}

@media (max-width: 600px) {
  aside {
    margin-bottom: 30px;
  }
}

@media (max-width: 414px) {

  .widget-2,
  .news-item-classic .news-content {
    padding: 15px;
  }
}

/* ================================
       14.1. Recent posts widget
       ================================ */

.recent-posts-widget-item {
  display: flex;
  margin-bottom: 20px;
}

.recent-posts-widget-item:last-child {
  margin-bottom: 0;
}

.recent-posts-widget-item-img {
  width: 80px;
  height: 80px;
  min-width: 80px;
  border-radius: 20px;
  border: 1px solid var(--border-color);
  margin-right: 15px;
}

.recent-posts-widget-item-img img {
  width: 100%;
  height: auto;
  border-radius: 20px;
  object-fit: cover;
}

.recent-posts-widget-item-tags {
  margin-bottom: 8px;
}

.recent-posts-widget-item-tags .tag-item {
  font-size: 10px;
  line-height: 1;
  font-weight: var(--heading-font-weight);
  padding: 5px 8px;
  border: 1px solid var(--border-color);
  border-radius: 30px;
}

.recent-posts-widget-item-tags .tag-item+.tag-item {
  margin-left: 4px;
}

.recent-posts-widget-item-title {
  font-size: var(--body-font-size);
  font-weight: var(--heading-font-weight);
  line-height: 1.4;
  margin-bottom: 10px;
}

.recent-posts-widget-item-title a:hover {
  color: inherit;
}

.recent-posts-widget-item-meta {
  font-size: 12px;
  font-weight: var(--body-font-weight);
  line-height: 1;
}

.recent-posts-widget-item-meta a {
  color: var(--primary-accent-color);
}

/* ================================
       14.2. Widget Search
       ================================ */

.widget-search .search {
  position: relative;
  border: 1px solid var(--border-color);
  border-radius: var(--input-border-radius);
  display: flex;
}

.widget-search .search-input,
.widget-search .search-button {
  border: none;
  padding: 18px 20px;
  white-space: nowrap;
}

.widget-search .search-input {
  flex: 0 1 100%;
  background-color: var(--body-background);
  color: var(--main-font-color);
  border-radius: var(--input-border-radius);
}

@media (max-width: 320px) {

  .widget-search .search-input,
  .widget-search .search-button {
    padding: 15px 17px;
  }
}

/* ================================
       14.2. Categories Widget
       ================================ */

.categories-widget-item {
  display: inline-block;
  font-size: 12px;
  font-weight: var(--heading-font-weight);
  line-height: 1;
  padding: 10px 14px;
  border: 1px solid var(--border-color);
  border-radius: var(--btn-border-radius);
  margin-right: 5px;
  margin-bottom: 10px;
}

.categories-widget-item:hover {
  color: var(--contrast-text-color);
  background: var(--cryptoki-gradient);
  border: none;
  padding: 11px 15px;
}

/* ================================
       14.3. Archives Widget
       ================================ */

.archives-widget-list .archive-item {
  font-size: var(--body-font-size);
  font-weight: var(--heading-font-weight);
  line-height: 2.75;
}

.archives-widget-list .archive-item a+span {
  margin-left: 4px;
}

/* ================================
       14.4. Latest Comments Widget
       ================================ */

.latest-comments-widget-list .comment-item {
  font-size: var(--body-font-size);
  font-weight: var(--body-font-weight);
  line-height: 1.4;
  padding: 0;
  border: none;
  border-radius: 0;
  margin-bottom: 20px;
}

.latest-comments-widget-list .comment-link {
  font-weight: var(--heading-font-weight);
}

.latest-comments-widget-list .comment-link:hover {
  color: inherit;
}

/* ================================
       14.5. Calendar Widget
       ================================ */

.calendar-table {
  border-spacing: 10px;
  width: 100%;
}

.calendar-table caption {
  font-size: 16px;
  font-weight: var(--heading-font-weight);
}

.calendar-table th {
  font-size: 10px;
  font-weight: var(--heading-font-weight);
  text-transform: uppercase;
}

.calendar-table td {
  font-size: var(--body-font-size);
  font-weight: var(--heading-font-weight);
  line-height: 1;
  background: var(--body-background);
  border: 1px solid var(--border-color);
  border-radius: var(--btn-border-radius);
  text-align: center;
}

.calendar-table td a {
  display: block;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 14px 13px;
}

.calendar-table td:hover {
  border: solid 1px transparent;
  background-image: linear-gradient(var(--body-background),
      var(--body-background)),
    linear-gradient(to right,
      var(--primary-gradient-color),
      var(--secondary-gradient-color));
  background-origin: border-box;
  background-clip: content-box, border-box;
  position: relative;
}

.calendar-table td.inactive a {
  color: var(--inactive-text-color);
  border-color: var(--inactive-text-color);
}

.calendar-table td.inactive:hover {
  color: inherit;
  border-color: var(--inactive-text-color);
  background-image: none;
}

@media (max-width: 1200px) {
  .calendar-table {
    border-spacing: 5px;
  }

  .calendar-table td {
    font-size: 12px;
  }

  .calendar-table td a {
    padding: 8px 7px;
  }
}

@media (max-width: 800px) {
  .calendar-table td a {
    padding: 10px 9px;
  }
}

@media (max-width: 320px) {
  .calendar-table td {
    font-size: 12px;
  }

  .calendar-table td a {
    padding: 8px 7px;
  }
}

/* ================================
       15. FOOTER
       ================================ */

.header-strap {
  margin-top: auto;
  font-size: 13px;
  background: var(--footer-background);
  position: relative;
  color: var(--footer-text-color);
}

.header-strap::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 8px;
  z-index: 1;
  background: var(--cryptoki-gradient);
}

.header-strap-margin {
  margin-top: clamp(0rem, 6.25vmax, 8px);
}

/* ================================
       15.4. Subscribe widget
       ================================ */

.subscribe-form-content {
  display: flex;
  width: max(250px, 20vw);
  max-width: 100%;
  border: solid 2px transparent;
  border-radius: var(--input-border-radius);
  background-image: linear-gradient(var(--footer-background),
      var(--footer-background)),
    linear-gradient(to right,
      var(--primary-gradient-color),
      var(--secondary-gradient-color));
  background-origin: border-box;
  background-clip: content-box, border-box;
  position: relative;
}

.subscribe-form .subscribe-input {
  flex: 0 1 100%;
  padding: 20px;
  background: transparent;
  border-radius: var(--input-border-radius);
  border: none;
  font-size: var(--body-font-size);
  font-weight: var(--body-font-weight);
}

.subscribe-widget .subscribe-submit {
  position: absolute;
  width: 24px;
  height: 24px;
  top: calc(50% - 12px);
  right: 20px;
}

.subscribe-widget .subscribe-submit svg {
  fill: var(--footer-text-color);
}

.subscribe-form .subscribe-form-message {
  margin: 5px 0 0 5px;
}

/* ================================
      15.5. Subscribe widget v2
      ================================ */

.footer .subscribe-widget-v2 {
  position: relative;
  margin-top: -60px;
}

.subscribe-widget-v2 {
  position: absolute;
  top: -100px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  /* margin-top: 40px !important; */
  background: linear-gradient(50deg,
      var(--primary-gradient-color),
      var(--secondary-gradient-color));
  border-radius: 25px;
  padding: clamp(1.8rem, 4vmax, 64px) 0 clamp(2rem, 4.1vmax, 66px);
  position: relative;
}

.subscribe-widget-v2 .animated-img {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: auto;
  background: url(../Image/footerbg.png) no-repeat 0 0;
  background-size: cover;
  border-radius: 25px;
}

.subscribe-text {
  color: #fff;
  z-index: 1;
}

.subscribe-text .h2 {
  line-height: 1;
}

.subscribe-text .subtitle {
  font-weight: var(--heading-font-weight);
}

.subscribe-form-v2 form {
  position: relative;
  width: 500px;
  max-width: 100%;
}

.subscribe-form-v2 input {
  padding: 18px;
  width: 100%;
  border-radius: 10px;
  border: 0;
  color: var(--main-font-color);
  font-size: var(--body-font-size);
}

.subscribe-form-v2 input::placeholder {
  font-size: var(--body-font-size);
  color: var(--inactive-text-color);
}

.subscribe-form-v2 .subscribe-submit {
  height: 100%;
  padding: 18px 34px;
  position: absolute;
  right: 0;
  top: 0;
  border-radius: 0 10px 10px 0;
}

.subscribe-form-v2 .subscribe-submit #send {
  display: none;
}

@media (max-width: 1024px) {
  .subscribe-widget-v2 {
    flex-direction: column;
    text-align: center;
  }

  .subscribe-widget-v2 .subscribe-text {
    margin-bottom: 20px;
    padding: 20px;
  }
}

@media (max-width: 600px) {
  .footer .subscribe-widget-v2 {
    margin-top: -30px;
  }

  .subscribe-form-v2 form {
    width: 300px;
  }

  .subscribe-form-v2 .subscribe-submit {
    padding: 18px;
  }

  .subscribe-form-v2 .subscribe-submit #send {
    display: block;
    fill: var(--contrast-text-color);
  }

  .subscribe-form-v2 .subscribe-submit .button-text {
    display: none;
  }
}

@media (max-width: 320px) {
  .subscribe-form-v2 form {
    width: 260px;
  }
}

/* ================================
       16. BLOG
       ================================ */

/* ================================
       16.1. News feed
       ================================ */

.news-item {
  border: 1px solid var(--border-color);
  border-radius: 25px;
  transition: transform 0.3s ease-in-out;
}

.news-item:hover {
  transform: translateY(-10px);
}

.news-thumb {
  border-radius: 25px;
}

.news-thumb img {
  width: 100%;
  height: auto;
  border-radius: 25px;
}

.news-content {
  padding: 30px;
  position: relative;
}

.news-content>* {
  margin-bottom: 20px;
}

.news-content>div:last-child {
  margin-bottom: 0;
}

.news-meta>a {
  color: var(--primary-accent-color);
}

/* .news-tags {
    position: absolute;
    top: -15px;
    left: 25px;
    z-index: 10;
} */

.no-thumb .news-tags {
  position: relative;
  top: 0;
  left: 0;
  margin-bottom: 20px;
}

.news-tags .tag-item {
  padding: 9px 14px;
  background-color: var(--body-background);
  border: 1px solid var(--border-color);
  border-radius: 30px;
  margin-right: 5px;
  font-size: 12px;
  font-weight: var(--heading-font-weight);
  cursor: pointer;
}

.news-tags .tag-item:last-child {
  margin-right: 0;
}

.news-title.h5 {
  margin-bottom: 20px;
}

.news-title.h5 a:hover {
  color: inherit;
}

.read-more-link {
  display: inline-block;
  vertical-align: middle;
}

.read-more-link a {
  font-weight: var(--heading-font-weight);
}

.read-more-link a:hover {
  background-color: var(--main-font-color);
  background-image: var(--cryptoki-gradient);
  background-size: 100%;
  background-clip: text;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
}

.read-more-link a:hover svg {
  fill: var(--primary-gradient-color);
}

@media (max-width: 320px) {
  .news-item .news-content {
    padding: 20px 15px;
  }
}

/* ================================
       16.2. Post formats
       ================================ */

.video-post-format,
.gallery-post-format,
.audio-post-format {
  position: relative;
}

.video-post-format .post-format-icon,
.audio-post-format .post-format-icon {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: var(--cryptoki-gradient);
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}

.video-post-format .post-format-icon svg {
  width: 20px;
  height: 22px;
  fill: #fff;
  position: relative;
  top: calc(50% - 15px);
  left: calc(50% - 8px);
}

.audio-post-format .post-format-icon svg {
  width: 18px;
  height: 22px;
  fill: #fff;
  position: relative;
  top: calc(50% - 15px);
  left: calc(50% - 8px);
}

.gallery-post-format .post-format-icon {
  width: 55px;
  height: 34px;
  z-index: 10;
  background: var(--body-background);
  border: 1px solid var(--border-color);
  border-radius: 30px;
  position: absolute;
  top: 18px;
  right: -14px;
}

.gallery-post-format .post-format-icon svg {
  width: 4px;
  height: 8px;
}

.gallery-post-format .post-format-icon a {
  padding: 4px 7px;
}

.gallery-post-format .post-format-icon a:hover {
  fill: var(--primary-accent-color);
}

/* ================================
       16.3. Single post
       ================================ */

.single-post.medium-section {
  width: 940px;
  max-width: 100%;
  margin: 0 auto;
}

.post-featured-image {
  margin-bottom: clamp(2rem, 4.5vmax, 70px);
}

.single-post .post-content {
  font-size: var(--article-font-size);
}

.single-post .post-content,
.single-post .comments-section {
  width: 700px;
  max-width: 100%;
  margin: 0 auto clamp(1rem, 6vmax, 100px);
}

.single-post .post-content>* {
  margin-bottom: clamp(2rem, 4.3vmax, 70px);
}

.single-post .post-heading {
  text-align: center;
  margin-bottom: clamp(2rem, 4.5vmax, 70px);
}

.single-post .post-heading h1 {
  margin-bottom: 40px;
}

.single-post .news-meta {
  display: flex;
  justify-content: center;
  align-items: center;
}

.post-author a {
  color: var(--primary-accent-color);
}

.single-post .news-meta .social-icons-list {
  margin-left: 28px;
}

.single-post .news-tags {
  position: relative;
  top: 0;
  left: 0;
  margin-bottom: 30px;
}

.single-post .content-heading {
  margin-bottom: 1.5em;
}

.single-post .list-block {
  display: flex;
  justify-content: flex-start;
}

.single-post .list-block>* {
  flex-basis: 50%;
}

.single-post .list-block ol {
  padding-left: 1rem;
}

@media (max-width: 414px) {
  .single-post .list-block {
    flex-wrap: wrap;
  }

  .single-post .list-block>* {
    flex-basis: 100%;
  }
}

/* ================================
       16.3.1. Image caption
       ================================ */

figure figcaption {
  font-size: var(--body-font-size);
  text-align: center;
  margin-top: 10px;
}

figure figcaption a {
  color: var(--primary-accent-color);
}

/* ================================
       16.3.2. Quote
       ================================ */

blockquote {
  text-align: center;
  background: var(--extra-light-gray);
  border: 1px solid var(--border-color);
  border-radius: 25px;
  margin-left: 0;
  margin-right: 0;
  padding: clamp(1rem, 2vmax, 35px) clamp(2rem, 4vmax, 65px) clamp(1.5rem, 3vmax, 45px);
}

blockquote p {
  font-size: var(--h4-heading);
  letter-spacing: var(--letter-spacing);
  line-height: 1.3;
  margin: 20px;
}

/* ================================
       16.3.3. Post author box
       ================================ */

.post-author-box {
  padding: 30px;
  background: var(--extra-light-gray);
  border-radius: 25px;
  border: 1px solid var(--border-color);
}

.post-author-box .author-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.post-author-box p {
  font-size: var(--body-font-size);
}

@media (max-width: 414px) {
  .post-author-box .author-heading {
    flex-wrap: wrap;
  }

  .post-author-box .avatar-block {
    margin-bottom: 20px;
  }
}

/* ================================
       16.3.4. Comments
       ================================ */

.comments-section .comments-list {
  margin-bottom: clamp(1rem, 4vmax, 100px);
}

.comment-item {
  margin-bottom: 20px;
}

.comment-item .comment-item-wrapper {
  padding: 30px;
  border: 1px solid var(--border-color);
  border-radius: 25px;
}

.comment-item .avatar-block {
  margin-bottom: 30px;
}

.comment-item .comment-body {
  font-size: var(--body-font-size);
  margin-bottom: 30px;
}

.comment-item .comment-meta {
  display: flex;
  font-size: 12px;
}

.comment-item .comment-meta .publish-date {
  margin-right: 15px;
}

.comment-item .comment-meta .reply a {
  font-weight: var(--heading-font-weight);
  color: inherit;
}

.comment-item.has-children>.children {
  margin-top: 20px;
}

/* ================================
     16.4. Blog Classic
    ================================ */

.news-item-classic {
  display: flex;
  border: 1px solid var(--border-color);
  border-radius: 25px;
  margin-bottom: 20px;
}

.news-item-classic:last-child {
  margin-bottom: 0;
}

.news-item-classic .news-thumb {
  display: flex;
  border-radius: 25px 0 0 25px;
  position: relative;
  flex: 0 0 45%;
}

.news-item-classic .news-thumb a:not(.post-format-icon) {
  display: flex;
}

.news-item-classic .news-thumb img {
  border-radius: 25px 0 0 25px;
  object-fit: cover;
  flex: 1;
}

.news-item-classic .news-content {
  flex: 0 0 55%;
}

.news-item-classic .news-tags {
  position: relative;
  top: auto;
  left: auto;
  z-index: 1;
}

.news-item-classic.no-thumb .news-content {
  flex-basis: 100%;
}

@media (max-width: 800px) {
  .blog-classic-area.grid-right-sidebar-large {
    grid-template-columns: 1fr;
    grid-column-gap: 0;
    grid-row-gap: 40px;
  }
}

@media (max-width: 414px) {
  .news-item-classic {
    flex-direction: column;
  }

  .news-item-classic .news-thumb,
  .news-item-classic .news-thumb img {
    border-radius: 25px 25px 0 0;
  }
}

/* ================================
     16.5. Single post sidebar
    ================================ */

.single-post-sidebar.grid-right-sidebar-large {
  grid-template-columns: 2.5fr minmax(320px, 1.5fr);
  grid-column-gap: clamp(1rem, 8vw, 140px);
}

.single-post-sidebar .post-heading {
  text-align: left;
}

.single-post-sidebar .post-heading h1 {
  margin-bottom: 30px;
}

.single-post-sidebar .news-meta {
  justify-content: flex-start;
}

.single-post-sidebar .post-content,
.single-post-sidebar .comments-section {
  width: 100%;
  max-width: 100%;
  margin: 0;
}

@media (max-width: 800px) {
  .single-post-sidebar.grid-right-sidebar-large {
    grid-template-columns: 1fr;
    grid-column-gap: 0;
    grid-row-gap: 40px;
  }
}

@media (max-width: 320px) {
  .single-post-sidebar.grid-right-sidebar-large {
    grid-template-columns: 290px;
  }
}

/* ================================
       17. FEATURED ITEMS
       ================================ */

/* ================================
       17.1. Featured items v1
       ================================ */

.featured-box-wrapper {
  margin-bottom: clamp(2rem, 3.75vmax, 60px);
}

.featured-item .featured-item-wrapper {
  background-color: var(--extra-light-gray);
  border: 1px solid var(--border-color);
  border-radius: 25px;
}

.featured-item .featured-item-content {
  background-color: var(--body-background);
  border-bottom: 1px solid var(--border-color);
  border-radius: 25px;
  padding: 16px;
  position: relative;
  z-index: 1;
}

.fav-counter {
  display: flex;
  align-items: center;
  background: var(--body-background);
  border: 1px solid var(--border-color);
  border-radius: 30px;
  padding: 9px 14px;
  line-height: 1;
  position: absolute;
  right: -12px;
  top: 30px;
  z-index: 1;
  cursor: pointer;
}

.fav-counter svg {
  fill: var(--main-font-color);
}

.fav-counter .count {
  font-size: 12px;
  font-weight: var(--heading-font-weight);
}

.fav-counter:hover {
  background: var(--cryptoki-gradient);
  background-origin: border-box;
  background-clip: padding-box, border-box;
  border: 1px solid transparent;
}

.fav-counter:hover>* {
  color: var(--contrast-text-color);
  fill: var(--contrast-text-color);
}

.featured-item-image {
  max-height: 340px;
  overflow: hidden;
  border-radius: 20px;
}

.featured-item-image img {
  object-fit: cover;
}

.featured-item-image>a>img {
  border-radius: 20px;
  width: 100%;
  height: 197px;
}

.featured-item-info {
  padding: 26px 10px 10px;
  position: relative;
}

.featured-item .featured-item-info .title,
.featured-item.v3 .featured-item-wrapper .title,
.featured-item.v4 .featured-item-author .title {
  font-size: 20px;
  font-weight: var(--heading-font-weight);
  line-height: 1.2;
  margin-bottom: 10px;
  letter-spacing: var(--letter-spacing);
}

.featured-item .featured-item-info .title a:hover {
  color: inherit;
}

.item-meta {
  display: flex;
  align-items: center;
}

.featured-countdown {
  padding: 9px 14px;
  display: flex;
  align-items: center;
  border: 1px solid var(--border-color);
  border-radius: 30px;
  line-height: 1;
  font-weight: var(--heading-font-weight);
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  top: -20px;
  background: var(--body-background);
  white-space: nowrap;
  cursor: pointer;
}

.featured-countdown .clock svg {
  width: 16px;
  height: 16px;
  fill: url('#cryptoki-svg-gradient') var(--primary-accent-color);
  margin-right: 5px;
}

.featured-countdown:hover .clock svg {
  fill: var(--contrast-text-color);
}

.featured-countdown:hover {
  background: var(--cryptoki-gradient);
  border: 1px solid transparent;
  background-clip: border-box;
  background-origin: border-box;
  color: var(--contrast-text-color);
}

.featured-item .price-caption,
.featured-item .social-caption {
  font-size: 10px;
  letter-spacing: -0.02em;
  margin-bottom: 7px;
  text-transform: uppercase;
}

.featured-item .share-icons svg {
  width: 10px;
  height: 10px;
  margin-right: 10px;
  fill: var(--main-font-color);
}

.featured-item .share-icons a:last-child svg {
  margin-right: 0;
}

.item-meta .avatar {
  position: relative;
  margin-right: 10px;
}

.featured-item-post-content {
  display: flex;
  padding: 24px 30px;
}

.featured-item-post-content>* {
  margin-right: 40px;
  line-height: 1;
}

.featured-item-pricebox .price {
  font-weight: var(--heading-font-weight);
  line-height: 1;
}

.share-icons a:hover svg {
  fill: var(--primary-accent-color);
}

/* ================================
       17.2. Featured items v2
       ================================ */

.featured-item.v2 .featured-item-info .title {
  text-align: center;
}

.featured-item.v2 .avatar {
  margin-right: 12px;
}

.featured-item.v2 .item-meta {
  justify-content: center;
}

.featured-item.v2 .featured-item-content {
  padding: 0;
}

.featured-item.v2 .featured-item-post-content {
  padding: 15px 30px;
  display: flex;
  justify-content: space-between;
}

.featured-item.v2 .featured-item-post-content>* {
  margin-right: 0;
}

.featured-item.v2 .featured-item-info {
  text-align: center;
  padding: 30px 10px;
}

.featured-item.v2 .title {
  font-size: 16px;
  line-height: 1.4;
  font-weight: var(--heading-font-weight);
  margin-bottom: 15px;
  letter-spacing: var(--letter-spacing);
}

.featured-item.v2 .featured-countdown {
  font-size: 12px;
}

.featured-item-pricebox {
  text-align: left;
}

.featured-item.v2 .price-caption,
.featured-item.v2 .social-caption {
  line-height: 1;
}

.featured-item.v2 .fav-counter {
  position: relative;
  right: 0;
  top: 0;
  border: none;
  background: none;
  padding: 0;
}

.featured-item.v2 .fav-counter svg {
  width: 14px;
  height: 14px;
}

.featured-item.v2 .fav-counter:hover>* {
  fill: var(--primary-accent-color);
  color: inherit;
}

.featured-item.v2 .fav-counter .count {
  font-size: 10px;
}

/* ================================
       17.3. Featured items v3
       ================================ */

.featured-item.v3 .featured-item-wrapper {
  position: relative;
}

.featured-item-image-wrapper {
  background: var(--body-background);
  border-radius: 25px 25px 0 0;
  padding: 16px 16px 0;
}

.featured-item.v3 .featured-item-image {
  background: var(--body-background);
  border-radius: 20px;
}

.featured-item.v3 .title {
  margin-bottom: 0;
  padding: 30px;
  border-bottom: 1px solid var(--border-color);
  border-radius: 0 0 25px 25px;
}

.featured-item.v3 .featured-countdown {
  top: auto;
  left: 57px;
  bottom: 300px;
  z-index: 10;
}

.featured-item.v3 .fav-counter {
  top: auto;
  bottom: 25px;
}

.featured-item.v3 .item-meta-wrapper {
  background: var(--body-background);
  position: relative;
}

.featured-item.v3 .item-meta {
  display: block;
  padding: 24px 30px;
  background-color: var(--body-background);
  border-bottom: 1px solid var(--border-color);
  border-radius: 0 0 25px 25px;
}

.featured-item.v3 .featured-item-wrapper .title {
  margin-bottom: 0;
}

.featured-item.v3 .creator-box {
  justify-content: space-between;
  position: relative;
  z-index: 1;
}

.featured-item.v3 .creator-info {
  display: flex;
  align-items: center;
}

.featured-item.v3 .creator-label {
  font-size: 10px;
  margin-bottom: 7px;
}

.featured-item.v3 .creator-meta .creator-name {
  font-weight: var(--heading-font-weight);
}

.featured-item.v3 .featured-item-pricebox {
  line-height: 1;
  flex-basis: 35%;
}

/* ================================
       17.4. Featured items v4
       ================================ */

.featured-item.v4 .featured-item-content>* {
  margin-bottom: 30px;
}

.featured-item.v4 .featured-item-content>div:last-child {
  margin-bottom: 0;
}

.featured-item.v4 .featured-item-image {
  position: relative;
  margin-bottom: 40px;
}

.featured-item.v4 .item-meta {
  font-size: 16px;
  font-weight: var(--heading-font-weight);
  line-height: 1;
}

.featured-item.v4 .featured-item-pricebox .price {
  font-size: 18px;
}

.featured-item.v4 .featured-item-author .title {
  margin-bottom: 5px;
}

.featured-item.v4 .featured-item-author,
.featured-item.v4 .featured-bidding-section {
  padding-left: 14px;
  padding-right: 14px;
}

.featured-item.v4 .avatar {
  border: 6px solid var(--body-background);
  position: absolute;
  bottom: 155px;
  left: 25px;
  margin-bottom: 0;
  width: 54px;
  height: 54px;
}

.featured-item.v4 .featured-bidding-section {
  display: flex;
  justify-content: space-between;
}

.featured-item.v4 .featured-item-post-content {
  justify-content: space-between;
}

.featured-item.v4 .featured-item-post-content>* {
  margin-right: 0;
}

.featured-item.v4 .countdown-box .label {
  font-size: 10px;
  text-transform: uppercase;
  margin-bottom: 7px;
}

.featured-item.v4 .countdown-box .countdown {
  font-weight: var(--heading-font-weight);
}

/* ================================
       17.5. Featured items v5
      ================================ */

.featured-item.v5 .featured-item-content {
  padding: 14px;
}

.featured-item.v5 .title {
  font-size: 16px;
  line-height: 1.4;
  font-weight: var(--heading-font-weight);
  margin-bottom: 10px;
  letter-spacing: var(--letter-spacing);
}

.featured-item.v5 .fav-counter {
  top: 16px;
}

.featured-item .item-category {
  position: absolute;
  top: -10px;
  left: 10px;
}

.item-category {
  width: auto;
  font-size: 9px;
  line-height: 1;
  text-transform: uppercase;
  font-weight: var(--heading-font-weight);
  padding: 4px 8px;
  border-radius: 20px;
  color: var(--contrast-text-color);
  letter-spacing: var(--letter-spacing);
  white-space: nowrap;
}

.item-category.coded-templates {
  background-color: var(--coded-templates);
}

.item-category.social-graphics {
  background-color: var(--social-graphics);
  color: var(--main-font-color);
}

.item-category.video-tutorials {
  background-color: var(--video-tutorials);
}

.featured-item.v5 .featured-item-post-content {
  justify-content: space-between;
  align-items: center;
  padding: 20px 25px;
}

.featured-item.v5 .featured-item-post-content>* {
  margin-right: 0;
}

.item-rating {
  display: flex;
  align-items: center;
}

.item-rating span {
  font-size: 0;
  margin-right: 2px;
}

.item-rating .filled svg {
  fill: var(--secondary-accent-color);
}

.featured-item .item-price {
  font-size: var(--body-font-size);
  letter-spacing: var(--letter-spacing);
  font-weight: var(--heading-font-weight);
}

/* ================================
       17.6. Featured items v6
      ================================ */

.featured-item.v6 .featured-item-content {
  padding: 0;
}

.featured-item.v6 .featured-item-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 30px 25px;
}

.featured-item.v6 .item-category {
  left: 50%;
  transform: translate(-50%, 0);
}

.featured-item.v6 .title {
  font-size: var(--body-font-size);
  text-align: center;
}

.featured-item.v6 .item-meta {
  font-size: 12px;
  align-items: center;
}

.featured-item.v6 .item-meta .verified {
  margin-left: 5px;
  font-size: 0;
}

.featured-item.v6 .item-meta .verified svg {
  width: 10px;
  height: 10px;
  border: none;
  padding: 1px;
}

.featured-item .item-rating-box {
  display: flex;
  justify-content: center;
  align-items: center;
}

.featured-item.v6 .item-meta+.item-rating-box {
  margin-top: 20px;
}

.featured-item.v6 .fav-counter {
  position: relative;
  right: auto;
  left: 0;
  top: 0;
  border: none;
  padding: 0;
  margin-left: 12px;
}

.featured-item.v6 .fav-counter:hover {
  background: none;
}

.featured-item.v6 .fav-counter:hover>* {
  color: var(--primary-accent-color);
  fill: var(--primary-accent-color);
}

.featured-item.v6 .fav-counter svg {
  width: 12px;
  height: 12px;
}

.featured-item.v6 .fav-counter .count {
  font-size: 10px;
}

.featured-item.v6 .featured-item-post-content {
  justify-content: space-between;
}

.featured-item.v6 .featured-item-post-content>* {
  margin-right: 0;
}

/* ================================
       17.7. Featured items v7
      ================================ */

.featured-item.v7 .featured-item-content {
  padding: 14px;
}

.featured-item.v7 .title {
  font-size: 16px;
}

.featured-item.v7 .item-rating-box {
  flex-direction: column;
  align-items: flex-start;
}

.featured-item.v7 .item-rating-box .rating-label {
  font-size: 10px;
  letter-spacing: -0.02em;
  margin-bottom: 7px;
  text-transform: uppercase;
  font-weight: var(--body-font-weight);
}

.featured-item.v7 .featured-item-info {
  padding-left: 16px;
  padding-right: 16px;
}

/* ================================
       17.8. Featured items v8
      ================================ */

.featured-item.v8 .featured-item-content {
  padding: 0;
}

.featured-item.v8 .featured-item-image {
  padding: 16px 16px 0;
  border-radius: 20px;
  position: relative;
  overflow: visible;
}

.featured-item.v8 .item-category {
  position: relative;
  top: 0;
  left: 0;
}

.featured-item.v8 .avatar {
  border: 3px solid var(--contrast-text-color);
  position: absolute;
  bottom: -15px;
  left: 36px;
  width: 32px;
  height: 32px;
}

.featured-item.v8 .featured-item-info {
  padding: 30px;
  border-bottom: 1px solid var(--border-color);
  border-radius: 25px;
}

.featured-item.v8 .title {
  font-size: 16px;
  font-weight: var(--heading-font-weight);
  letter-spacing: var(--letter-spacing);
}

.featured-item.v8 .description {
  font-size: var(--body-font-size);
}

.featured-item.v8 .additional-info {
  padding: 18px 30px;
}

.featured-item.v8 .featured-item-post-content {
  justify-content: space-between;
}

.featured-item.v8 .featured-item-post-content>* {
  margin-right: 0;
}

.featured-item.v8 .item-rating-box {
  flex-direction: column;
  align-items: flex-start;
}

.featured-item.v8 .social-share-box {
  text-align: right;
}

@media (max-width: 320px) {
  /* .featured-item.v8 .avatar {
        top: 155px;
    } */
}

/* ================================
       18. CREATORS
       ================================ */

/* ================================
       18.1 Creators
       ================================ */

.creator-item {
  background-color: var(--lighter-gray);
  border: 1px solid var(--border-color);
  border-radius: 25px;
}

.creator-item .creator-wrapper {
  background: var(--body-background);
  padding: 0 30px 30px;
  border-bottom: 1px solid var(--border-color);
  border-radius: 0 0 25px 25px;
  border-top-width: 0;
}

.creator-item .creator-cover {
  width: 100%;
  max-height: 98px;
  background-color: var(--lighter-gray);
  border-radius: 25px;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
}

.creator-item .creator-cover img {
  width: 100%;
  border-radius: 25px;
}

.creator-info-wrapper {
  margin-top: -45px;
  position: relative;
}

.creator-info-wrapper .follow-me {
  position: absolute;
  top: 25px;
  right: -40px;
  z-index: 1;
}

.creator-item .creator-info {
  margin-bottom: 30px;
}

.creator-item .creator-info .title {
  font-size: 20px;
  font-weight: var(--heading-font-weight);
  line-height: 1.2;
  letter-spacing: var(--letter-spacing);
  margin-bottom: 5px;
}

.creator-item .creator-info .title a:hover {
  color: inherit;
}

.creator-item .creator-info .item-meta {
  font-size: 16px;
  font-weight: var(--heading-font-weight);
  letter-spacing: var(--letter-spacing);
  line-height: 1;
}

.creator-item .creator-info .avatar {
  margin-bottom: 10px;
}

.creator-item .btn-small {
  padding: 10px 20px;
}

.creator-item .social-icons-list {
  margin-bottom: 30px;
}

.creator-item .social-icons-list .social-icon {
  width: 44px;
  height: 34px;
  margin-right: 8px;
}

.creator-item .about-creator {
  margin-bottom: 55px;
}

.creator-item .about-creator p {
  margin: 0;
}

.creator-item .creator-stats {
  padding: 22px 30px;
}

.creator-item .creator-stats .stats-item .label {
  font-size: 10px;
  text-transform: uppercase;
  line-height: 1.4;
}

.creator-item .creator-stats .stats-item .number {
  font-weight: var(--heading-font-weight);
}

.preview-box {
  width: 48px;
  height: 48px;
  margin-left: 0;
  margin-right: 15px;
  border-radius: 15px;
  cursor: pointer;
}

.preview-box:last-child {
  margin-right: 0;
}

.preview-box img {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 15px;
}

.preview-box.box-68 {
  background-color: var(--lighter-gray);
  border: 1px solid var(--border-color);
  border-radius: 20px;
  margin-left: 16px;
  width: 68px;
  height: 68px;
}

.preview-box.box-68 img {
  border-radius: 20px;
}

.more-projects {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--body-background);
  border: 1px solid var(--border-color);
  border-radius: 15px;
}

.more-projects a {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.more-projects a:hover svg {
  fill: var(--primary-accent-color);
}

.more-projects svg {
  width: 10px;
  height: 2px;
  fill: var(--main-font-color);
}

@media (max-width: 1419px) {
  .preview-box.box-68 {
    margin-left: 0;
  }
}

@media (max-width: 600px) {
  .creator-stats .stats-item {
    margin-right: 15px;
  }
}

@media (max-width: 414px) {
  .preview-box {
    width: 42px;
    height: 42px;
  }
}

/* ================================
       18.2 Top creators
       ================================ */

.top-creator-item {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  padding: 30px;
  border: 1px solid var(--border-color);
  border-radius: 20px;
  position: relative;
}

.creator-box {
  display: flex;
  align-items: center;
}

.creator-box .title,
.projects-score .title {
  font-size: 20px;
  font-weight: var(--heading-font-weight);
  line-height: 1.2;
  margin-bottom: 7px;
}

.creator-box .title a:hover {
  color: inherit;
}

.top-creator-item .creator-box .avatar+.creator-meta {
  margin-left: 15px;
}

.creator-box .creator-meta,
.projects-score .label {
  line-height: 1;
}

.creator-number {
  font-size: 12px;
  font-weight: var(--heading-font-weight);
  line-height: 1;
  background-color: var(--body-background);
  border: 1px solid var(--border-color);
  border-radius: 30px;
  padding: 10px 13px;
  position: absolute;
  left: -14px;
  top: 16px;
  z-index: 10;
}

.projects-previews {
  display: flex;
}

@media (max-width: 320px) {
  .top-creator-item .projects-score {
    display: none;
  }
}

/* ================================
       18.3 Featured creators
       ================================ */

.featured-creators-item {
  display: flex;
  flex-wrap: nowrap;
  border: 1px solid var(--border-color);
  border-radius: 30px;
  z-index: 1;
}

.featured-creators-wrapper {
  padding: clamp(1rem, 3vmax, 45px);
}

.featured-creators-wrapper>* {
  margin-bottom: 30px;
}

.featured-creators-wrapper>div:last-child {
  margin-bottom: 0;
}

.featured-creators-info-wrapper {
  display: flex;
  justify-content: space-between;
}

.featured-creators-item>img {
  width: 177px;
  border-radius: 30px 20px 20px 30px;
  object-fit: cover;
  object-position: center bottom;
}

.featured-creators-item .title {
  font-size: 20px;
  line-height: 1.2;
  font-weight: var(--heading-font-weight);
  margin-bottom: 7px;
}

.featured-creators-item .title a:hover {
  color: inherit;
}

.creator-stats {
  display: flex;
}

.creator-stats .stats-item {
  margin-right: 40px;
}

.creator-stats .stats-item:last-child {
  margin-right: 0;
}

.follow-me {
  align-self: flex-start;
}

@media (max-width: 1199px) {
  .featured-creators-item {
    flex-wrap: wrap;
  }

  .featured-creators-item>img {
    width: 100%;
    height: 120px;
    border-radius: 25px;
    object-position: center center;
  }

  .projects-previews {
    margin-top: 20px;
  }
}

@media (max-width: 414px) {
  .featured-creators-item .creator-stats {
    justify-content: space-between;
  }

  .featured-creators-item .creator-stats .stats-item {
    margin-right: 0;
  }
}

@media (max-width: 320px) {
  .featured-creators-item .social-icons-list {
    justify-content: space-between;
  }

  .featured-creators-item .social-icons-list .social-icon {
    margin-right: 0;
  }

  .featured-creators-item .projects-previews {
    justify-content: space-between;
  }

  .featured-creators-item .preview-box {
    margin-right: 0;
  }
}

/* ================================
       18.4. Become a creator
       ================================ */

.become-creator-bc-box {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.become-creator-bc-box .bc-info {
  flex-basis: 40%;
  padding: 0 clamp(1rem, 4vmax, 60px);
}

.become-creator-bc-box .bc-info>* {
  margin-bottom: clamp(1rem, 3vmax, 40px);
}

.become-creator-bc-box .bc-image {
  padding: 0 clamp(1rem, 4vmax, 60px);
}

.bc-heading .bc-heading-subtitle {
  font-weight: var(--heading-font-weight);
  text-transform: uppercase;
}

.bc-heading .bc-heading-title {
  font-size: calc(var(--h1-heading) + 10px);
  font-weight: var(--heading-font-weight);
  line-height: 1;
  letter-spacing: var(--letter-spacing);
}

.bc-button a {
  display: inline-block;
}

.submit-artwork-section .section-title {
  margin-bottom: 60px;
}

@media (max-width: 800px) {
  .become-creator-bc-box {
    flex-direction: column;
  }
}

/* ================================
       19. PRODUCTS
       ================================ */

/* ================================
       19.1. Product v1
       ================================ */

.product-version-1 {
  display: grid;
  grid-template-columns: 52.5% 1fr;
}

.product-version-1 .product-media {
  background-color: var(--lighter-gray);
  padding-top: max(5vw, 85px);
  border-right: 1px solid var(--border-color);
  position: relative;
}

.product-img {
  position: relative;
  text-align: center;
}

.product-img img {
  width: 100%;
  height: auto;
}

.product-media .expand-icon {
  display: inline-flex;
  padding: 13px;
  border: 1px solid var(--border-color);
  border-radius: 50%;
  background: var(--body-background);
  margin-bottom: 15px;
  position: absolute;
  right: 18px;
  top: 18px;
  cursor: pointer;
  z-index: 10;
}

.product-media .expand-icon svg {
  fill: var(--main-font-color);
}

.product-media .expand-icon:hover svg {
  fill: var(--primary-accent-color);
}

.product-info-wrapper {
  padding: clamp(2rem, 5vmax, 80px) clamp(3rem, 6vmax, 100px);
}

.product-info-wrapper>*,
.product-info-wrapper .simplebar-content>* {
  margin-bottom: clamp(2rem, 4vmax, 60px);
}

.product-title {
  margin-bottom: 0;
}

.product-meta-section {
  display: flex;
}

.product-meta-item {
  flex: 0 1 190px;
}

.product-meta-item .label {
  font-size: clamp(0.5rem, 1vmax, 14px);
  text-transform: uppercase;
}

.product-meta-item .value {
  font-size: clamp(1rem, 2vmax, 32px);
  font-weight: var(--heading-font-weight);
  line-height: 1.2;
  letter-spacing: var(--letter-spacing);
}

.product-meta-item .caption {
  font-size: clamp(0.5rem, 1vmax, 14px);
  font-weight: var(--heading-font-weight);
}

.product-meta-item .caption span {
  padding-right: 26px;
}

.product-meta-item .caption span:last-child {
  padding-right: 0;
}

.bidding-section {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.bidding-section>* {
  margin-right: 15px;
}

@media (max-width: 414px) {
  .bidding-section>* {
    margin-bottom: 20px;
  }

  .product-info-wrapper {
    padding-left: 30px;
    padding-right: 30px;
  }
}

.product-fav-counter {
  width: max(5.6vw, 70px);
  height: max(3.12vw, 36px);
  max-height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--border-color);
  border-radius: 30px;
  line-height: 1;
  cursor: pointer;
}

.product-fav-counter svg {
  fill: var(--main-font-color);
}

.product-fav-counter.gradient-background svg {
  fill: var(--contrast-text-color);
}

.product-fav-counter span {
  font-size: var(--body-font-size);
}

.product-fav-counter:hover svg {
  fill: var(--primary-accent-color);
}

.share-icons svg {
  margin-right: 15px;
  fill: var(--main-font-color);
}

@media (max-width: 800px) {
  .product-fav-counter {
    width: 45px;
  }

  .product-fav-counter span {
    display: none;
  }
}

/* ================================
       19.1.1. Countdown JS
       ================================ */

.product-meta-item .js-countdown .countdown__timer {
  display: flex;
}

.product-meta-item .js-countdown .countdown__item {
  display: flex;
  flex-direction: column;
}

.product-meta-item .countdown__item+.countdown__item {
  margin-left: 15px;
}

.product-meta-item .js-countdown .countdown__value {
  font-size: clamp(1rem, 2vmax, 32px);
  font-weight: var(--heading-font-weight);
  line-height: 1.2;
  letter-spacing: var(--letter-spacing);
}

.product-meta-item .js-countdown .countdown__label {
  font-size: clamp(0.5rem, 1vmax, 14px);
  font-weight: var(--heading-font-weight);
}

@media (max-width: 1199px) {
  .product-version-1 {
    grid-template-columns: 1fr;
  }

  .product-version-1 .product-img {
    text-align: center;
  }
}

/* ================================
       19.1.2. Placed bids
       ================================ */

.placed-bid {
  display: flex;
  justify-content: space-between;
  padding: 26px 30px;
  border: 1px solid var(--border-color);
  border-radius: 25px;
  margin-bottom: 20px;
}

.bid-box {
  text-align: right;
}

.placed-bid .creator-meta .creator-name,
.bid-box .crypto-value {
  font-weight: var(--heading-font-weight);
  line-height: 1;
  margin-bottom: 10px;
}

.placed-bid .creator-meta .bid-date,
.bid-box .currency-value {
  font-size: 13px;
  line-height: 1;
}

/* ================================
       19.1.3. Related items
       ================================ */

.product-version-1 .related-section .featured-box {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.product-version-1 .related-section .featured-item {
  flex: 0 0 218px;
  margin-bottom: 20px;
  margin-right: 20px;
}

.product-version-1 .related-section .featured-item:last-child {
  margin-right: 0;
}

@media (max-width: 1199px) {
  .product-version-1 .related-section .featured-item {
    flex: 0 0 30%;
  }
}

@media (max-width: 767px) {
  .product-version-1 .related-section .featured-item {
    flex: 0 0 49%;
  }
}

@media (max-width: 480px) {
  .product-version-1 .related-section .featured-item {
    flex: 0 0 100%;
  }
}

/* ================================
       19.2. Product v2
       ================================ */

.product-version-2 {
  margin-bottom: clamp(2rem, 5vmax, 80px);
}

.product-version-2 .product-media {
  background-color: var(--pure-black);
}

.product-version-2 .product-info {
  background-color: var(--body-background);
  width: 980px;
  max-width: 100%;
  margin: -80px auto 0;
  border-radius: 20px;
  position: relative;
  z-index: 10;
}

.product-version-2 .page-title-section,
.product-version-2 .product-author,
.product-version-2 .product-meta-section,
.product-version-2 .place-bid,
.product-version-2 .bidding-section {
  display: flex;
  justify-content: center;
}

.product-version-2 .product-meta-section .product-meta-item {
  text-align: center;
}

.product-version-2 .place-bid {
  margin-bottom: 40px;
}

.product-version-2 .related-section .section-title-wrapper h3 {
  margin: 0 auto 0.25em;
}

@media (max-width: 1024px) {
  .product-version-2 .product-info {
    margin: -60px auto 0;
  }
}

@media (max-width: 767px) {
  .product-version-2 .product-info {
    margin: 0 auto 0;
  }
}

/* ================================
       19.3. Product v3
       ================================ */

.product-version-3>* {
  margin-bottom: clamp(2rem, 5.6vmax, 90px);
}

.product-version-3 .page-title-section {
  align-items: center;
  margin-bottom: clamp(1rem, 4vmax, 60px);
}

.product-version-3 .product-main-info {
  display: flex;
  justify-content: space-between;
}

.product-version-3 .product-main-info>* {
  flex-basis: 33.3%;
  margin-right: clamp(3rem, 7.5vmax, 120px);
}

.product-version-3 .product-main-info>div:last-child {
  margin-right: 0;
}

.product-version-3 .product-author,
.product-version-3 .product-description {
  margin-bottom: 50px;
}

.product-version-3 .product-description:last-child {
  margin-bottom: 0;
}

.favourite-section {
  display: flex;
}

.favourite-section .product-fav-counter {
  margin-right: 15px;
}

.product-version-3 .product-media {
  position: relative;
}

.product-version-3 .product-media svg {
  fill: var(--contrast-text-color);
}

.product-version-3 .product-media .expand-icon {
  position: absolute;
  background: var(--pure-black);
  opacity: 0.8;
  border: 1px solid var(--pure-black);
  right: 12px;
  top: 12px;
  margin-bottom: 0;
  z-index: 10;
}

.product-version-3 .product-meta-section {
  display: flex;
  flex-direction: column;
}

.product-version-3 .product-meta-item {
  flex-basis: auto;
  margin-bottom: clamp(2rem, 3vmax, 50px);
}

.bids-listing-section {
  display: flex;
  justify-content: space-between;
}

.bids-listing-section .bids-list {
  flex: 0 1 700px;
  margin-right: 20px;
}

.bids-listing-section .bids-list:last-child {
  margin-right: 0;
}

@media (max-width: 1024px) {
  .product-version-3 .product-main-info {
    flex-wrap: wrap;
  }

  .product-version-3 .product-main-info>* {
    flex-basis: 46%;
    margin-right: 0;
    margin-bottom: 60px;
  }

  .product-version-3 .product-main-info>div:last-child {
    margin-bottom: 0;
  }

  .product-version-3 .product-meta-section {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-basis: 100%;
  }
}

@media (max-width: 800px) {
  .product-version-3 .product-meta-section {
    flex-wrap: wrap;
  }

  .bids-listing-section {
    flex-direction: column;
  }

  .bids-listing-section .bids-list {
    flex-basis: auto;
    margin-right: 0;
  }
}

@media (max-width: 600px) {
  .product-version-3 .product-main-info>* {
    flex-basis: 100%;
  }

  .product-version-3 .page-title-section {
    flex-direction: column;
  }

  .product-version-3 .page-title-section>* {
    margin-bottom: 20px;
  }
}

/* ================================
       19.4. Product page digital store
       ================================ */

.product-page-ds {
  display: flex;
}

.product-page-ds .main-content-area {
  flex-basis: 68%;
}

.product-page-ds aside {
  flex-basis: 32%;
  margin-left: 20px;
}

.product-ds>* {
  margin-bottom: 30px;
}

.product-title-section .product-subtitle {
  display: flex;
  align-items: center;
}

.product-title-section .product-subtitle>* {
  margin-right: 12px;
}

.product-title-section .product-subtitle>div:last-child {
  margin-right: 0;
}

.product-ds .product-info .product-description {
  padding: clamp(1rem, 3.1vmax, 50px) clamp(1rem, 3.5vmax, 57px) clamp(1rem, 2.5vmax, 40px);
  border: 1px solid var(--border-color);
  border-radius: 25px;
}

.product-ds .product-info .product-description .title {
  margin-bottom: clamp(1rem, 2.5vmax, 40px);
}

.comments-list+.leave-comment,
.reviews-list+.leave-review {
  margin-top: 60px;
}

.leave-comment h5,
.leave-review h5 {
  margin-bottom: clamp(1rem, 3.1vmax, 50px);
}

.review-item {
  padding: 30px;
  border: 1px solid var(--border-color);
  border-radius: 25px;
  margin-bottom: 20px;
}

.review-item>* {
  margin-bottom: 25px;
}

.review-item>div:last-child {
  margin-bottom: 0;
}

.review-title {
  font-size: 16px;
  font-weight: var(--heading-font-weight);
  letter-spacing: var(--letter-spacing);
}

.review-footer {
  display: flex;
}

.review-author {
  display: flex;
  margin-right: 12px;
}

.review-author .avatar {
  margin-right: 8px;
}

.rating-field {
  display: flex;
  margin-bottom: 15px;
}

.rating-field label {
  font-size: var(--body-font-size);
  font-weight: var(--heading-font-weight);
  text-transform: capitalize;
  margin-right: 12px;
}

.product-additional-info {
  padding: clamp(1rem, 3.75vmax, 60px);
  border: 1px solid var(--border-color);
  border-radius: 25px;
  position: relative;
}

.product-additional-info>div:last-child {
  margin-bottom: 0;
}

.product-additional-info .product-fav-counter {
  position: absolute;
  top: 22px;
  right: -12px;
  background: var(--body-background);
  margin-bottom: 0;
}

.pricing-plans {
  margin-bottom: 30px;
}

.pricing-plans>* {
  margin-bottom: 20px;
}

.pricing-plans .plan label+.license-description {
  margin-top: 20px;
}

#purchase-form .product-price .price {
  font-size: var(--h2-heading);
  font-weight: var(--heading-font-weight);
  letter-spacing: var(--letter-spacing);
  line-height: 1;
  margin-right: 8px;
}

#purchase-form .product-price .label {
  font-size: var(--body-background);
  font-weight: var(--body-font-weight);
  letter-spacing: var(--letter-spacing);
  margin-bottom: 0;
}

#purchase-form button[type='submit']+button[type='submit'] {
  margin-top: 20px;
}

.product-score {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.product-score .score-item {
  text-align: center;
  padding: clamp(1rem, 1.87vmax, 30px);
  border: 1px solid var(--border-color);
  border-radius: 25px;
  flex: 0 1 160px;
}

.product-score .value {
  font-size: clamp(1rem, 2vmax, 32px);
  font-weight: var(--heading-font-weight);
  line-height: 1;
  letter-spacing: var(--letter-spacing);
  margin-bottom: 10px;
}

.product-score .label {
  font-size: var(--body-font-size);
  font-weight: var(--body-font-weight);
  line-height: 1;
}

.product-additional-info .product-details-wrapper {
  display: flex;
  font-size: var(--body-font-size);
}

.product-additional-info .product-details ul {
  flex-basis: 50%;
}

.product-additional-info .product-details ul li {
  margin-bottom: 20px;
}

.product-additional-info .product-details .details-value {
  font-weight: var(--heading-font-weight);
}

.product-additional-info .product-details .details-value .tags-list {
  line-height: 1.2;
}

.product-additional-info .small-title {
  margin-bottom: 30px;
}

.product-additional-info .seller-info {
  flex-direction: column;
  align-items: flex-start;
}

.product-additional-info .seller-box {
  display: flex;
}

.product-additional-info .seller-name .name {
  font-size: 18px;
  font-weight: var(--heading-font-weight);
  letter-spacing: var(--letter-spacing);
  line-height: 1.2;
  margin-bottom: 0;
}

.product-additional-info .seller-name .meta {
  font-size: var(--body-font-size);
  font-weight: var(--body-font-weight);
  /* line-height: 1; */
  display: inline-block;
}

.product-additional-info .seller-meta>* {
  margin-bottom: 25px;
}

.product-additional-info .seller-meta>div:last-child {
  margin-bottom: 0;
}

.product-additional-info .seller-meta .social-icons-list li {
  margin-right: 5px;
}

.product-additional-info .seller-meta .seller-buttons {
  display: flex;
}

.seller-buttons .btn+.btn {
  margin-left: 12px;
}

@media (max-width: 800px) {
  .product-page-ds {
    flex-wrap: wrap;
  }

  .product-page-ds .main-content-area,
  .product-page-ds aside {
    flex-basis: 800px;
    max-width: 100%;
  }

  .product-additional-info {
    margin-top: 50px;
  }

  .product-additional-info .seller-meta .social-icons-list {
    flex-wrap: wrap;
  }
}

@media (max-width: 414px) {
  .product-score .score-item {
    flex-basis: 130px;
  }

  .product-additional-info .seller-meta .social-icons-list li {
    margin-bottom: 5px;
  }

  .btn-small-wide {
    padding: 10px 12px;
  }
}

/* ================================
       20. PROFILE
       ================================ */

.profile-page .profile-page-container {
  padding-top: clamp(2rem, 4vmax, 70px);
  padding-bottom: clamp(2rem, 4vmax, 70px);
}

.profile-header-mobile {
  display: none;
}

.profile-page .tabs-list {
  justify-content: center;
}

@media (max-width: 800px) {
  .profile-page .tabs-list {
    justify-content: flex-start;
  }
}

/* ================================
       20.2. About author
       ================================ */

.about-author-wrapper {
  display: flex;
  justify-content: flex-start;
}

.about-author-wrapper>* {
  flex: 0 1 33%;
  margin-right: clamp(1rem, 5vmax, 100px);
}

.about-author-wrapper>div:last-child {
  margin-right: 0;
}

@media (max-width: 1024px) {
  .about-author-wrapper {
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .about-author-wrapper>* {
    flex: 0 0 48%;
    margin-right: 0;
    margin-bottom: 20px;
  }
}

@media (max-width: 800px) {
  .about-author-wrapper>* {
    flex: 0 0 100%;
  }
}

/* ================================
       20.3. Profile header mobile
       ================================ */

@media (max-width: 800px) {
  .profile-header-section {
    display: none;
  }

  .profile-header-mobile {
    display: block;
  }

  .profile-header-mobile .about-author-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: -40px;
    margin-bottom: 30px;
  }

  .profile-header-mobile .about-author-section>* {
    justify-content: center;
  }

  .profile-author-info .download-file {
    display: none;
  }

  .profile-header-mobile .about-author-section .profile-header-user-profile {
    margin-bottom: 30px;
  }

  .profile-header-mobile .follow-container .more-link-wrapper {
    margin-right: 10px;
  }

  .profile-header-mobile .follow-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .more-link {
    width: 48px;
    height: 36px;
  }

  .profile-header-mobile .more-link-wrapper .more-link {
    margin: 0 auto;
  }

  .profile-header-mobile .follow-box button {
    display: block;
    margin: 0 auto;
  }

  .profile-header-mobile .profile-header-user-profile {
    margin-top: 0;
  }

  .profile-header-mobile .profile-header-user-profile .avatar {
    border: 8px solid var(--body-background);
    width: 80px;
    height: 80px;
  }

  .profile-header-mobile .social-icons-list {
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 20px;
  }

  .profile-header-mobile .profile-author-info .author-meta {
    font-size: var(--body-font-size);
    line-height: 1;
  }

  .profile-header-mobile .social-icons-list .social-icon {
    margin-right: 8px;
    margin-bottom: 10px;
  }

  .profile-header-mobile .social-icons-list .social-icon:last-child {
    margin-right: 0;
  }

  .profile-header-mobile .author-primary-info {
    display: flex;
    justify-content: center;
    align-items: baseline;
    margin-bottom: 0;
  }

  .profile-header-mobile .author-primary-info .activity-meta {
    flex-basis: 100%;
    justify-content: space-around;
  }

  .profile-header-mobile .author-primary-info .activity-meta>* {
    text-align: center;
  }
}

/* ================================
      20.4. Profile page digital store
      ================================ */

.profile-page-ds {
  margin-top: 20px;
}

.profile-page-ds .profile-header-section {
  position: relative;
}

.profile-page-ds .follow-box {
  position: absolute;
  right: 32px;
  top: 27px;
  display: flex;
}

.profile-page-ds .profile-header-user-profile {
  margin-top: -70px;
  margin-bottom: 15px;
}

.profile-page-ds .profile-author-info .author-meta {
  display: inline-block;
  margin-bottom: 5px;
  font-weight: var(--heading-font-weight);
}

.profile-page-ds .item-rating {
  justify-content: center;
}

.profile-page-ds .profile-page-container {
  padding-top: clamp(2rem, 4vmax, 70px);
}

@media (max-width: 800px) {
  .profile-page-ds .profile-header-mobile .about-author-section {
    justify-content: center;
    margin-top: 0;
    margin-bottom: 0;
  }

  .profile-page-ds .profile-header-mobile .about-author-section>* {
    flex-basis: 100%;
  }

  .profile-page-ds .profile-header-mobile .profile-header-user-profile {
    margin-top: -30px;
    margin-bottom: 20px;
  }

  .profile-page-ds .profile-header-mobile .profile-header-user-profile .avatar {
    margin-bottom: 10px;
  }

  .profile-page-ds .profile-header-mobile .profile-header-user-profile .profile-author-info {
    margin-bottom: 15px;
  }

  .profile-page-ds .profile-header-mobile .profile-header-user-profile .author-name {
    line-height: 1;
    margin-bottom: 0;
  }

  .profile-page-ds .follow-box {
    position: relative;
    top: auto;
    right: auto;
    justify-content: center;
  }
}

/* ================================
       21.1. Collectors mobile view
       ================================ */

@media (max-width: 800px) {
  table.content-table {
    border: 0;
    border-spacing: 0;
  }

  table.content-table caption {
    font-size: 1.3em;
  }

  table.content-table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  table.content-table tr {
    display: block;
    margin-bottom: 0.625em;
    border-bottom: 2px solid var(--border-color);
  }

  table.content-table td {
    display: block;
    font-size: 0.8em;
    text-align: right;
  }

  table.content-table td::before {
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }

  table.content-table tr:last-child {
    border-bottom: 0;
  }

  .content-table tbody>tr {
    padding: 10px;
  }

  .content-table td {
    border: none;
    padding: 5px 0;
  }

  .content-table td:first-child {
    border: none;
    padding-left: 0;
  }

  .content-table td:last-child {
    border: none;
    padding-right: 0;
  }

  .collectors-filter+.collectors-box table.content-table {
    border-spacing: 0 20px;
  }

  .collector-info {
    flex-basis: 0;
    justify-content: flex-end;
  }

  .collector-info .collector-meta {
    text-align: left;
  }

  .collector-info .small-title {
    margin-bottom: 0;
    font-size: 0.9rem;
  }

  .collector-info .avatar,
  .collector-meta .meta {
    display: none;
  }
}

/* ================================
       22. ARTWORKS
       ================================ */

/* ================================
       22.1. Artworks
       ================================ */

.artworks-carousel {
  margin-bottom: 30px;
}

.artwork-item {
  display: flex;
  border: 1px solid var(--border-color);
  border-radius: 25px;
}

.artwork-item>* {
  flex-basis: 50%;
}

.artwork-item .artwork-media,
.artwork-item .artwork-media img {
  border-radius: 25px;
}

.artwork-item .artwork-details {
  padding: clamp(1rem, 3vmax, 40px) clamp(1rem, 4vmax, 60px);
}

.artwork-item .artwork-details .avatar-block {
  margin-bottom: 30px;
}

.artwork-item .artwork-details .product-meta-section {
  margin-bottom: 30px;
}

.artwork-item .artwork-details .product-description {
  margin-bottom: clamp(1rem, 3.2vmax, 60px);
}

.artwork-item .bidding-section .product-fav-counter {
  color: var(--contrast-text-color);
  fill: var(--contrast-text-color);
  border: transparent;
}

.artwork-item .bidding-section .product-fav-counter:hover svg {
  fill: var(--contrast-text-color);
}

@media (max-width: 1024px) {}

.artwork-item .artwork-details {
  padding-left: clamp(1rem, 2.5vmax, 40px);
  padding-right: clamp(1rem, 2.5vmax, 40px);
}

@media (max-width: 800px) {
  .artwork-item {
    flex-direction: column;
  }

  .artwork-item>* {
    flex-basis: 100%;
  }
}

/* ================================
       22.2. Collections
       ================================ */

.collection-item {
  position: relative;
  border-radius: 28px;
  border: 1px solid var(--border-color);
}

.collection-item .cover-image img {
  width: 100%;
  height: auto;
  border-radius: 25px;
}

.collection-item .collection-info {
  width: 70%;
  color: var(--contrast-text-color);
  position: absolute;
  top: 40px;
  left: 40px;
}

.collection-item .collection-info .title {
  line-height: 1.1;
}

.collection-item .collection-info .number {
  font-size: 16px;
}

/* ================================
       23. ACTIVITY
       ================================ */

.activity-list+.load-more_bars {
  margin-top: 75px;
}

.activity-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 15px 15px 30px;
  border: 1px solid var(--border-color);
  border-radius: 25px;
  margin-bottom: 15px;
  position: relative;
}

.activity-item.bid::before {
  content: '';
  /* background: url(../svg/annotation.svg) no-repeat center; */
  background-size: 12px;
  background-color: #6a52ff;
  width: 30px;
  height: 24px;
  border-radius: 30px;
  position: absolute;
  left: -6px;
  top: 10px;
}

.activity-item.like::before {
  content: '';
  /* background: url(../svg/heart-white.svg) no-repeat center; */
  background-size: 13px;
  background-color: #ff3a4e;
  width: 30px;
  height: 24px;
  border-radius: 30px;
  position: absolute;
  left: -6px;
  top: 10px;
}

.activity-item.follow {
  padding: 30px;
}

.activity-item.follow::before {
  content: '';
  /* background: url(../svg/profile-white.svg) no-repeat center; */
  background-size: 12px;
  background-color: #2371ff;
  width: 30px;
  height: 24px;
  border-radius: 30px;
  position: absolute;
  left: -6px;
  top: 10px;
}

.activity-item.sale::before {
  content: '';
  /* background: url(../svg/dollar-white.svg) no-repeat center; */
  background-size: 12px;
  background-color: #ff813b;
  width: 30px;
  height: 24px;
  border-radius: 30px;
  position: absolute;
  left: -6px;
  top: 10px;
}

.activity-item.purchase::before {
  content: '';
  /* background: url(../svg/wallet-white.svg) no-repeat center; */
  background-size: 12px;
  background-color: #ffc000;
  width: 30px;
  height: 24px;
  border-radius: 30px;
  position: absolute;
  left: -6px;
  top: 10px;
}

.activity-item.listing::before {
  content: '';
  /* background: url(../svg/bookmark-white.svg) no-repeat center; */
  background-size: 10px;
  background-color: #00e7a3;
  width: 30px;
  height: 24px;
  border-radius: 30px;
  position: absolute;
  left: -6px;
  top: 10px;
}

.bid-placer {
  display: flex;
  align-items: center;
}

.bid-placer .avatar+.bid-info {
  margin-left: 10px;
}

.bid-info .bid-title {
  line-height: 1.4;
  margin-bottom: 5px;
  font-weight: var(--heading-font-weight);
}

.bid-info .bid-date {
  font-size: 13px;
  line-height: 1;
}

.bid-product {
  width: 72px;
  height: 72px;
  border: 1px solid var(--border-color);
  border-radius: 18px;
  background: var(--extra-light-gray);
}

.bid-product img {
  min-width: 72px;
  border-radius: 18px;
}

.load-more {
  width: 65px;
  margin: 0 auto;
}

@media (max-width: 414px) {
  .activity-item {
    border-radius: 20px;
  }

  .activity-item.follow {
    padding: 15px 15px 15px 30px;
  }

  .bid-product {
    width: 50px;
    height: 50px;
    margin-left: 10px;
  }

  .bid-product img {
    min-width: 50px;
    border-radius: 12px;
  }
}

/* ================================
      24. USER DASHBOARD
      ================================ */

.user-db-menu {
  width: auto;
  max-width: 100%;
  border: 1px solid var(--border-color);
  border-radius: 25px;
}

.user-db-content-area>* {
  margin-bottom: 50px;
}

.user-db-cover-image.profile-cover-image {
  height: 100px;
  border-radius: 25px;
}

.user-header-user-profile {
  margin-top: -45px;
  padding: 0 25px 30px;
  border-bottom: 1px solid var(--border-color);
  border-radius: 25px;
}

.user-header-user-profile .avatar {
  width: 80px;
  height: 80px;
  border: 8px solid var(--body-background);
  background-color: var(--body-background);
  margin-bottom: 10px;
}

.user-header-user-profile .title {
  font-size: 20px;
  font-weight: var(--heading-font-weight);
  line-height: 1.2;
  margin-bottom: 7px;
}

.user-header-user-profile .title a:hover {
  color: inherit;
}

.user-header-user-profile .item-meta {
  font-size: 16px;
  font-weight: var(--heading-font-weight);
  line-height: 1;
}

.user-db-body {
  padding: 30px 0;
}

.user-db-body .profile-menu li a {
  font-size: var(--body-font-size);
}

.user-db-body .profile-menu li:hover {
  background: none;
}

.user-db-body .profile-menu li:hover a {
  color: inherit;
  transform: translateX(0);
}

.upload-photo-box .user-avatar {
  position: relative;
  margin-right: 20px;
  margin-bottom: 30px;
  float: left;
}

.upload-photo-box .user-avatar,
.upload-photo-box .user-avatar img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
}

.upload-photo-box .user-cover-image {
  position: relative;
  float: left;
  margin-bottom: 30px;
}

.upload-photo-box .user-cover-image {
  max-width: 600px;
  width: 100%;
  height: 100px;
  border-radius: 20px;
  background-color: var(--lighter-gray);
}

.upload-photo-box .user-cover-image img {
  width: 600px;
  height: 100px;
  object-fit: cover;
  border-radius: 20px;
}

.upload-photo-box .user-avatar::after,
.upload-photo-box .user-cover-image::after {
  /* content: url(camera.svg); */
  width: 44px;
  height: 34px;
  line-height: 1;
  background-color: var(--body-background);
  background-repeat: no-repeat;
  background-position: center;
  border: 1px solid var(--border-color);
  border-radius: 30px;
  padding: 8px 14px;
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
}

.upload-photo-box .upload-notice {
  clear: both;
}

/* ================================
       24.1. Upload artwork
       ================================ */

.artwork-upload-box .label {
  font-size: var(--body-font-size);
  font-weight: var(--heading-font-weight);
  line-height: 1.5;
  text-transform: capitalize;
  margin-bottom: 15px;
}

.artwork-upload-box .upload-container {
  display: flex;
}

.artwork-upload {
  width: 100%;
  margin-right: 20px;
}

.upload-box {
  width: 100%;
  height: 180px;
  border: 1px dashed var(--border-color);
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.upload-box>* {
  margin-bottom: 15px;
}

.upload-container .upload-box .browse-btn {
  margin-bottom: 0;
}

.upload-container .artwork-preview {
  max-width: 100%;
  width: 220px;
  height: 180px;
}

.artwork-preview .preview-box {
  width: 220px;
  height: 180px;
  border: 1px solid var(--border-color);
  border-radius: 25px;
  display: flex;
}

@media (max-width: 414px) {
  .artwork-upload-box .upload-container {
    flex-direction: column;
  }
}

/* ================================
       24.2. Dashboard tabs
       ================================ */

.db-tabs-list {
  list-style: none;
  display: flex;
  justify-content: space-between;
}

.db-tabs-list li {
  flex-basis: 33.3%;
  margin-right: 20px;
}

.db-tabs-list li:last-child {
  margin-right: 0;
}

.db-tabs-list li:not(.active) {
  border: solid 1px var(--border-color);
  border-radius: 25px;
}

.db-tabs-list li.active {
  border: solid 2px transparent;
  border-radius: 25px;
  background-image: linear-gradient(var(--body-background),
      var(--body-background)),
    linear-gradient(to right,
      var(--primary-gradient-color),
      var(--secondary-gradient-color));
  background-origin: border-box;
  background-clip: content-box, border-box;
}

.db-tabs-list a {
  display: flex;
  align-items: baseline;
  padding: 25px 30px;
}

.db-tabs-list a:hover {
  color: inherit;
}

.purchase-type-symbol {
  margin-right: 10px;
}

.purchase-type-symbol svg {
  fill: var(--main-font-color);
}

.purchase-type-info .purchase-type-title {
  font-size: var(--body-font-size);
  font-weight: var(--heading-font-weight);
  line-height: 1.5;
  letter-spacing: var(--letter-spacing);
  margin: 0 0 0.5em;
}

.purchase-type-info .purchase-type-description {
  line-height: 1.4;
}

@media (max-width: 1024px) {
  .purchase-type-symbol {
    display: none;
  }
}

@media (max-width: 414px) {
  .db-tabs-list {
    flex-direction: column;
  }

  .db-tabs-list li {
    margin-right: 0;
    margin-bottom: 20px;
  }

  .purchase-type-symbol {
    display: block;
  }
}

/* ================================
      25. SELLERS PAGE
      ================================ */

.digital-store .creator-item {
  position: relative;
}

.digital-store .creator-item .follow-me {
  position: absolute;
  right: -10px;
  top: 75px;
}

.digital-store .creator-item .creator-info {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.digital-store .creator-item .social-icons-list:not(.swiper-wrapper) {
  justify-content: center;
}

.digital-store .projects-previews .preview-box {
  width: 82px;
  height: 50px;
}

.digital-store .projects-previews .preview-box.more-projects {
  width: 50px;
  height: 50px;
}

.digital-store .creator-item .creator-stats {
  padding: 0;
  justify-content: center;
  text-align: center;
}

.digital-store .creator-item .projects-previews {
  padding: 22px 30px;
}

/* ================================
       26. SHOPPING CART AND CHECKOUT
       ================================ */

/* ================================
       26.1. Shopping cart page
       ================================ */

.shopping-cart-area.grid-right-sidebar {
  grid-column-gap: 20px;
}

.shopping-cart tbody tr {
  position: relative;
}

.shopping-cart td {
  padding-right: 50px;
}

table td.price {
  font-weight: var(--heading-font-weight);
}

table .product-info {
  display: flex;
  align-items: center;
}

table .product-thumb {
  width: 82px;
  height: auto;
  /* border: 1px solid var(--border-color); */
  border-radius: 12px;
  margin-right: 10px;
}

table .product-thumb img {
  border-radius: 12px;
}

table .product-details .product-name {
  font-size: var(--body-background);
  font-weight: var(--heading-font-weight);
  line-height: 1;
  margin-bottom: 8px;
}

table .product-details .product-meta {
  display: flex;
  align-items: center;
}

table .product-details .product-seller {
  font-size: 12px;
  font-weight: var(--body-font-weight);
  display: flex;
  align-items: center;
  margin-left: 5px;
}

table .product-seller .verified {
  font-size: 0;
}

table .product-seller .verified svg {
  width: 10px;
  height: 10px;
  padding: 2px;
  border: none;
  margin-left: 5px;
}

table .item-price {
  font-size: var(--body-font-size);
  font-weight: var(--heading-font-weight);
}

#discount-form {
  float: right;
}

#discount-form>* {
  margin-left: 15px;
}

#discount-form input {
  padding: max(0.87vw, 12px) max(1.5vw, 18px);
}

#discount-form input::placeholder {
  font-weight: var(--body-font-weight);
}

.cart-total-box {
  border: 1px solid var(--border-color);
  border-radius: 25px;
  padding: 30px;
}

.cart-total-wrapper>* {
  margin-bottom: 50px;
}

.cart-total-wrapper>div:last-child {
  margin-bottom: 0;
}

.cart-total-box .total-price {
  font-size: var(--h2-heading);
  font-weight: var(--heading-font-weight);
  line-height: 1;
  text-align: center;
}

.total-price-details .field {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
}

.total-price-details .field:last-child {
  margin-bottom: 0;
}

.total-price-details .label,
.total-price-details .value {
  line-height: 1;
}

.total-price-details .value {
  font-weight: var(--heading-font-weight);
}

.to-checkout-button a {
  display: block;
}

.to-checkout-button a.gradient-background:hover {
  color: var(--contrast-text-color);
}

/* ================================
      26.1.1 Shopping cart mobile view
      ================================ */

@media (max-width: 1023px) {
  .shopping-cart-area.grid-right-sidebar {
    grid-template-columns: 1fr;
    grid-row-gap: clamp(1rem, 8vw, 140px);
    grid-column-gap: 0;
  }
}

@media (max-width: 800px) {
  .shopping-cart tbody>tr {
    padding: 10px;
  }

  .shopping-cart table.content-table td {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    border: none;
    padding: 5px 0;
    text-align: left;
    margin-bottom: 10px;
  }

  .shopping-cart td:first-child {
    border: none;
    padding-left: 0;
  }

  .shopping-cart td:last-child {
    border: none;
    padding-right: 0;
  }

  .shopping-cart table td::before {
    text-align: left;
  }

  .shopping-cart td:last-child {
    position: absolute;
    right: 0;
    top: -10px;
    transform: translate(0, 50%);
  }
}

@media (max-width: 414px) {

  .shopping-cart .nice-select,
  .shopping-cart .nice-select .nice-select-dropdown {
    width: 100%;
  }
}

/* ================================
      26.2. Checkout page
      ================================ */

.checkout-area .summary-section {
  padding: 30px 30px 40px;
  border: 1px solid var(--border-color);
  border-radius: 25px;
}

.checkout-area .summary-section>* {
  padding-bottom: 40px;
  margin-bottom: 40px;
  border-bottom: 1px solid var(--border-color);
}

.checkout-area .summary-section>div:last-child {
  padding-bottom: 0;
  margin-bottom: 0;
}

.checkout-area .summary-section>div:first-child,
.checkout-area .summary-section>div:last-child {
  padding-bottom: 0;
  border-bottom: none;
}

.checkout-area .summary-section .small-title {
  margin-bottom: 30px;
}

.product-in-cart {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

.product-in-cart:last-child {
  margin-bottom: 0;
}

.product-in-cart .product-info {
  display: flex;
}

.product-in-cart .product-thumb {
  width: 82px;
  height: 50px;
  min-width: 82px;
  border-radius: 10px;
  margin-right: 10px;
}

.product-in-cart .product-thumb img {
  border-radius: 10px;
}

.product-in-cart .product-name,
.product-in-cart .product-price {
  font-size: var(--body-font-size);
  font-weight: var(--heading-font-weight);
  line-height: 1.2;
}

.product-in-cart .license-type {
  font-size: 12px;
  font-weight: var(--body-font-weight);
}

.checkout-area .total-price-section {
  display: flex;
  justify-content: flex-end;
}

.total-price-section .total-price-details {
  flex-basis: 50%;
}

.total-price-section .total-price-details .total-price {
  font-size: 16px;
}

.payment-method+.payment-method {
  margin-top: 30px;
}

.payment-method label {
  font-size: body-fotn-size;
  font-weight: var(--heading-font-weight);
  line-height: 1;
  margin-bottom: 20px;
}

.complete-order-button input[type='submit'] {
  color: var(--contrast-text-color);
  width: 100%;
  margin-bottom: 0;
}

.complete-order-button input[type='submit']:hover {
  color: var(--contrast-text-color);
}

/* ================================
       27. PROFILE DASHBOARD
       ================================ */

/* ================================
       27.1. Dashboard menu
       ================================ */

.dashboard-wrapper>* {
  margin-bottom: 50px;
}

.dashboard-wrapper>div:last-child {
  margin-bottom: 0;
}

.user-db-menu-ds {
  width: 260px;
  padding: 30px;
  border: 1px solid var(--border-color);
  border-radius: 25px;
}

.profile-menu-ds li {
  font-size: var(--body-font-size);
  font-weight: var(--heading-font-weight);
  line-height: 1;
}

.profile-menu-ds li a {
  display: block;
  padding: 15px 0;
}

.profile-menu-ds svg {
  margin-right: 10px;
}

.profile-menu-ds li:hover a {
  color: inherit;
}

.profile-menu-ds li:hover svg {
  fill: url('#cryptoki-svg-gradient') var(--primary-accent-color);
}

/* ================================
       27.2. User stats
       ================================ */

.user-stats-section {
  display: flex;
  justify-content: space-between;
}

.user-stats-section .stat-item {
  flex: 0 1 340px;
  display: flex;
  padding: clamp(1rem, 1.6vmax, 26px) clamp(1.5rem, 2.5vmax, 40px);
  border: 1px solid var(--border-color);
  border-radius: 25px;
  color: var(--contrast-text-color);
  margin-right: 20px;
}

.user-stats-section .stat-item:last-child {
  margin-right: 0;
}

.user-stats-section .stat-icon {
  margin-right: 15px;
}

.user-stats-section .stat-icon svg {
  fill: var(--contrast-text-color);
  width: clamp(1rem, 2vmax, 32px);
  height: clamp(1rem, 2vmax, 32px);
}

.user-stats-section .stat-number {
  font-size: clamp(1rem, 2vmax, 32px);
  font-weight: var(--heading-font-weight);
  line-height: 1;
  margin-bottom: 10px;
}

.earnings-report-section h5 {
  margin-bottom: 30px;
}

/* ================================
       27.3. Top Sellers
       ================================ */

.best-sellers td:last-child {
  padding-right: 0;
}

.best-sellers .product-info {
  display: flex;
  position: relative;
}

.best-sellers .product-thumb {
  width: 82px;
  height: auto;
  min-width: 82px;
  border: 1px solid var(--border-color);
  border-radius: 12px;
  margin-right: 10px;
}

.best-sellers .product-thumb img {
  border-radius: 12px;
}

.best-sellers .product-details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.best-sellers .product-name,
.best-sellers .stats-item {
  font-weight: var(--heading-font-weight);
  line-height: 1;
}

.best-sellers .product-name {
  margin-bottom: 10px;
}

.item-number {
  font-size: 12px;
  font-weight: var(--heading-font-weight);
  line-height: 1;
  background-color: var(--body-background);
  border: 1px solid var(--border-color);
  border-radius: 30px;
  padding: 10px 13px;
  position: absolute;
  left: -46px;
  top: -8px;
  z-index: 10;
}

/* ================================
       27.4. Responsive styles
       ================================ */

@media (max-width: 800px) {
  .grid-small-sidebar-left {
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 0;
    grid-row-gap: 40px;
  }

  .best-sellers tbody>tr {
    padding: 10px;
  }

  .best-sellers table.content-table td {
    display: flex;
    justify-content: space-between;
    border: none;
    padding: 5px 0;
    text-align: left;
    margin-bottom: 10px;
  }

  .best-sellers td:first-child {
    border: none;
    padding-left: 0;
  }

  .best-sellers td:last-child {
    border: none;
    padding-right: 0;
  }

  .best-sellers table td::before {
    text-align: left;
  }

  .item-number {
    display: none;
  }

  .best-sellers .product-details {
    align-items: flex-end;
  }
}

@media (max-width: 414px) {
  .user-stats-section {
    flex-wrap: wrap;
  }

  .user-stats-section .stat-item {
    margin-right: 0;
    margin-bottom: 10px;
  }

  .best-sellers .product-thumb {
    display: none;
  }

  .best-sellers td[data-label='Item details']::before {
    white-space: nowrap;
  }

  table .product-details .product-name {
    text-align: right;
  }
}

/* ================================
    28. PROFILE SALES STATEMENT
    ================================ */

.sales-statement .stat-item {
  color: var(--main-font-color);
  flex-direction: column;
  align-items: center;
  flex-basis: auto;
}

.green {
  color: var(--ds-green);
}

.red {
  color: var(--ds-red);
}

.statement .date,
.statement .stat-value {
  font-weight: var(--heading-font-weight);
}

.statement .item-title {
  font-weight: var(--heading-font-weight);
  line-height: 1;
}

.statement .license-details {
  font-size: 12px;
}

@media (max-width: 800px) {
  .statement .license-details {
    font-size: 0.9em;
  }
}

/* ================================
      28.1. Sales statement mobile view
      ================================ */

@media (max-width: 600px) {
  .sales-statement .user-stats-section {
    flex-wrap: wrap;
  }

  .sales-statement .user-stats-section>* {
    flex-basis: 49%;
    margin-right: 0;
    margin-bottom: 10px;
  }
}

/* ================================
    29. PROFILE UPLOAD ITEM
    ================================ */

.upload-row {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-column-gap: 20px;
}

#upload-item-form h5 {
  margin-bottom: 50px;
}

#upload-item-form input[type='submit'] {
  width: 100%;
  color: var(--contrast-text-color);
}

#upload-item-form textarea {
  margin-bottom: 20px;
}

.upload-container {
  margin-bottom: 35px;
}

.upload-container:last-child {
  margin-bottom: 0;
}

.upload-container button.close {
  background: var(--body-background);
  margin-left: 5px;
}

@media (max-width: 1024px) {
  .upload-row {
    grid-template-columns: 1fr;
    grid-column-gap: 0;
    grid-row-gap: 40px;
  }
}

@media (max-width: 320px) {
  .upload-row {
    grid-template-columns: 290px;
  }
}

/* ================================
    30. PROFILE MANAGE ITEMS
    ================================ */

.manage-items button {
  position: absolute;
  right: -12px;
  top: 18px;
  z-index: 1;
}

.manage-items .featured-item.v5 .fav-counter {
  top: 60px;
}

.create-item .create-item-wrapper {
  background-color: var(--lighter-gray);
  border: 1px solid var(--border-color);
  border-radius: 25px;
}

.create-item .create-item-content {
  padding: 14px;
  background: var(--body-background);
  border-bottom: 1px solid var(--border-color);
  border-radius: 25px;
}

.create-item .create-item-image {
  height: 206px;
  overflow: hidden;
  border: 1px solid var(--border-color);
  border-radius: 20px;
  background: var(--lighter-gray);
  display: flex;
  justify-content: center;
  align-items: center;
}

.create-item .create-item-image svg {
  width: 32px;
  height: 32px;
}

.create-item .create-item-info {
  padding: 30px 30px 10px;
  text-align: center;
  background: var(--body-background);
}

.create-item .small-title {
  margin-bottom: 10px;
}

.create-item-post-content {
  height: 55px;
}

/* ================================
    31. PROFILE PAYMENT HISTORY
    ================================ */

.payment-history td {
  font-weight: var(--heading-font-weight);
}

.payment-history td:last-child {
  padding-right: 0;
}

.payment-method-section>* {
  margin-bottom: 20px;
}

.payment-method-section>div:last-child {
  margin-bottom: 0;
}

.payment-method-section h5 {
  margin-bottom: 30px;
}

#payment-method-form,
.confirmed-section {
  padding: 30px;
  border: 1px solid var(--border-color);
  border-radius: 25px;
}

#payment-method-form button {
  width: 100%;
}

.payment-method+button {
  margin-top: 15px;
}

/* ================================
    32. PROFILE PURCHASES
    ================================ */

.purchases-list table td {
  font-weight: var(--heading-font-weight);
}

td[data-label='Date'] {
  white-space: nowrap;
}

.purchases-list button.download {
  width: 52px;
  height: 52px;
  background: var(--btn-dark);
  border-radius: var(--btn-border-radius);
}

.purchases-list button.download:hover {
  background: var(--cryptoki-gradient);
}

.purchases-list button.download svg {
  fill: var(--contrast-text-color);
}

.purchases-list button.item-rating {
  display: inline-flex;
  border: 1px solid var(--border-color);
  padding: 20px;
  border-radius: var(--btn-border-radius);
}

.purchases-list table .product-thumb {
  min-width: 82px;
}

@media (max-width: 1024px) {
  .purchases-list table {
    border: 0;
  }

  .purchases-list table caption {
    font-size: 1.3em;
  }

  .purchases-list table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  .purchases-list table tr {
    display: block;
    margin-bottom: 0.625em;
    border-bottom: 2px solid var(--border-color);
  }

  .purchases-list table td {
    display: block;
    font-size: 0.8em;
    text-align: right;
  }

  .purchases-list table td::before {
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }

  .purchases-list table tr:last-child {
    border-bottom: 0;
  }

  .purchases-list tbody>tr {
    padding: 10px;
  }

  .purchases-list td {
    border: none;
    padding: 5px 0;
  }

  .purchases-list td:first-child {
    border: none;
    padding-left: 0;
  }

  .purchases-list td:last-child {
    border: none;
    padding-right: 0;
  }

  .purchases-list table .product-info {
    justify-content: flex-end;
  }
}

@media (max-width: 414px) {
  .purchases-list .product-info {
    flex-direction: column;
    align-items: flex-end;
  }

  .purchases-list .product-info .product-thumb {
    margin-right: 0;
    margin-bottom: 10px;
  }

  .purchases-list .product-details .product-meta {
    flex-wrap: wrap;
    justify-content: flex-end;
  }
}

/* ================================
    32.1 Cryptoki modal
    ================================ */

.cryptoki-modal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cryptoki-modal.open {
  visibility: visible;
  opacity: 1;
  transition-delay: 0s;
  z-index: 30;
}

.cryptoki-modal .modal-bg {
  position: absolute;
  background: rgb(0 0 0 / 88%);
  width: 100%;
  height: 100%;
}

.cryptoki-modal .modal-container {
  width: 460px;
  background: var(--body-background);
  border: 1px solid var(--border-color);
  border-radius: 25px;
  padding: 30px;
  position: relative;
}

.cryptoki-modal .modal-close {
  position: absolute;
  right: -15px;
  top: -15px;
  outline: none;
  appearance: none;
  color: red;
  background: none;
  border: 0px;
  font-weight: bold;
  cursor: pointer;
}

.cryptoki-modal .modal-close svg {
  width: 12px;
  height: 12px;
  fill: var(--contrast-text-color);
}

.review-form {
  text-align: left;
}

.review-form .review-form-title {
  font-size: var(--body-background);
  font-weight: var(--heading-font-weight);
  margin-bottom: 20px;
}

.review-form .rating-box {
  display: flex;
  margin-bottom: 25px;
}

.review-form .rating-box .title {
  margin-right: 10px;
}

.review-form input[type='submit'] {
  color: var(--contrast-text-color);
  margin-bottom: 0;
}

.ReactTags__tags {
  position: relative;
}

/* Styles for the React tag nput */

.ReactTags__tagInput {
  /* width: 200px;
    border-radius: 2px; */
  display: inline-block;
  width: 100%;
}

.ReactTags__tags {
  border: 1px solid var(--border-color) !important;
  padding: 1px;
  border-radius: 8px;
}

.ReactTags__tagInputField {
  border: none !important;
  margin-bottom: 0px !important;
  width: 100%;
}

/*
.ReactTags__tagInput input.ReactTags__tagInputField,
.ReactTags__tagInput input.ReactTags__tagInputField:focus {
    height: 31px;
    margin: 0;
    font-size: 12px;
    width: 100%;
    border: 1px solid #eee;
} */

/* Styles for selected tags */

div.ReactTags__tags {
  position: relative;
}

.ReactTags__selected span.ReactTags__tag {
  background: black;
  color: white;
  /* font-size: 12px; */
  font-size: 12px;
  font-weight: 700;
  /* font-weight: var(--body-font-weight); */
  /* display: inline-block; */
  padding: 5px 3px 6px 11px;
  margin: 0 5px;
  border-radius: 6px;
}

.ReactTags__selected a.ReactTags__remove {
  color: #aaa;
  margin-left: 5px;
  cursor: pointer;
}

/* Styles for suggestions */

.ReactTags__suggestions {
  position: absolute;
}

.ReactTags__suggestions ul {
  list-style-type: none;
  box-shadow: 0.05em 0.01em 0.5em rgba(0, 0, 0, 0.2);
  background: white;
  width: 200px;
}

.ReactTags__suggestions li {
  border-bottom: 1px solid #ddd;
  padding: 5px 10px;
  margin: 0;
}

.ReactTags__suggestions li mark {
  text-decoration: underline;
  background: none;
  font-weight: 600;
}

.ReactTags__suggestions ul li.ReactTags__activeSuggestion {
  background: #b7cfe0;
  cursor: pointer;
}

.ReactTags__remove {
  vertical-align: middle;
  border: none;
  cursor: pointer;
  /* margin-left: 6px; */
  font-size: 20px;
  padding: 8px 8px 8px 8px;
  background: transparent !important;
  /* color: black !important; */
  border-radius: 6px;
}

/*Styles for Drop Down*/

.css-qc6sy-singleValue {
  font-size: var(--body-font-size) !important;
  font-weight: var(--body-font-weight) !important;
}

.css-319lph-ValueContainer {
  font-size: var(--body-font-size) !important;
  font-weight: var(--body-font-weight) !important;
}

.react-select__placeholder {
  color: brown !important;
}

.css-319lph-ValueContainer select::placeholder {
  color: var(--inactive-text-color) !important;
}

.react-select-placeholder-text {
  color: var(--inactive-text-color);
}

.answerBody {
  line-height: 1.6;
  font-size: 1rem;
  font-weight: 500;
  transition: visibility 0.5s, padding 0.5s, max-height 0.5s;
  padding: 0px 10px;
}

.question {
  padding: clamp(1rem, 2vmax, 25px);
  padding-bottom: 15px;
  padding-top: 25px;
}

.question .answerInfo {
  margin-top: 12px;
}

.questionHeader {
  background: none !important;
  border-bottom: 0;
}

.collapsing {
  transition: visibility 0.5s, padding 0.5s, max-height 0.5s;
}

.fixheight {
  height: 300px;
}

.scrollble {
  overflow-y: scroll;
}

.scrollble::-webkit-scrollbar {
  width: 3px;
  color: #0062fe;
}

/***********Plan card css********************/

.wallet-icon1 {
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translate(-50%, 0);
  width: 117px;
  height: 55px;
  background: var(--cryptoki-gradient);
  color: var(--contrast-text-color);
  border: 1px solid var(--border-color);
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.wallet-item1 .title {
  font-size: 16px;
  font-weight: 800;
  letter-spacing: 0.05px;
}

.wallet-inner {
  font-size: 16px;
  font-weight: 700;
  word-spacing: 1px;
  letter-spacing: 0.1px;
  line-height: 25px;
}

.wallet-inner span {
  color: #13C1E9;
}

.blackCard {
  /* background-color: #252831 !important; */
  background-image: url('../../../public/Image/Rectangle\ 19.png');
  background-position-x: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.grayCard {
  /* background-color: #252831 !important; */
  background-image: url('../../../public/Image/Rectangle\ 20.png');
  background-position-x: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.wallet-grid-31 {
  display: flex;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 40px;
  margin-bottom: 40px;
  color: white;
}

.grDiamond>* {
  stroke: #e0b219 !important;
}

.grDiamond {
  width: 50px !important;
  height: 50px !important;
  transform: rotateZ(350deg);
}

.RiCheckboxCircleFill>* {
  color: #00bf8b !important;
}

.wallet-item1 {
  border-radius: 25px;
  position: relative;
  padding-top: 35px;
  width:100%
}

.fixheight1 {
  width: 100%;
  height: 270px;
  margin: 0 35px;
}

.wallet-grid-31:last-child {
  padding-bottom: 40px;
}

@media (max-width: 992px) {
  .wallet-grid-31 {
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
  }

  .pb-50 {
    padding-bottom: 50px;
    margin-top: 35px;
  }
}

@media (max-width: 567px) {
  .wallet-grid-31 {
    grid-template-columns: 1fr;
    grid-column-gap: 0;
  }

  .pb2-50 {
    padding-bottom: 50px;
    margin-top: 35px;
  }
}

@media (max-width: 429px) {
  .wallet-icon1 {
    width: 90px;
    height: 42px;
    top: -20px;
  }
}

/**************************************************************/
.social-icons-list {
  list-style: none;
  display: flex;
  flex-wrap: wrap !important;
  row-gap: 7px !important;
  margin-bottom: 13px !important;
}

.details-title li,
.details-value li {
  margin-bottom: 7px;
}

.form-field.software button {
  padding: max(0.87vw, 12px) max(1.5vw, 18px) !important
}

@media all and (max-width:1500px) {
  .form-field.software button {
    padding: max(0.87vw, 12px) max(1.5vw, 18px) !important
  }
}

/* description richbox css*/
.ButtonGroup__root___3lEAn:nth-child(2),
.ButtonGroup__root___3lEAn:nth-child(4),
.ButtonGroup__root___3lEAn:nth-child(5),
.ButtonGroup__root___3lEAn:nth-child(6),
.ButtonGroup__root___3lEAn:nth-child(7),
.ButtonGroup__root___3lEAn:nth-child(3) .ButtonWrap__root___1EO_R:last-child {
  display: none;
}

.ButtonGroup__root___3lEAn {
  /* margin: 5px 0px 0px 13px !important; */
}

.RichTextEditor__root___2QXK- {
  border-radius: 25px !important;
}

.EditorToolbar__root___3_Aqz {
  margin: 15px 15px 0px 15px !important;
  padding: 0 !important;
}

.public-DraftEditorPlaceholder-root,
.public-DraftEditor-content {
  padding-left: 20px !important;
}

.Button__root___1gz0c {
  border: 1px solid transparent !important;
  background: none !important;
}

button.IconButton__root___3tqZW.IconButton__isActive___2Ey8p.Button__root___1gz0c {
  backdrop-filter: brightness(90%);
}

.public-DraftEditorPlaceholder-inner,
.public-DraftStyleDefault-block.public-DraftStyleDefault-ltr {
  font-family: 'Manrope VF', Manrope, -apple-system, BlinkMacSystemFont, Roboto, 'Helvetica Neue', sans-serif;
}

.DraftEditor-editorContainer {
  height: 100px;
  overflow-y: scroll;
}

.DraftEditor-editorContainer::-webkit-scrollbar {
  display: none;
}

.DraftEditor-editorContainer ::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}

.DraftEditor-editorContainer ::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
  background-color: #c1c1c1;
}

.DraftEditor-editorContainer ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
  border-radius: 10px;
  background-color: #f5f5f5;
}

.RichTextEditor__block___2Vs_D.RichTextEditor__paragraph___3NTf9 {
  margin: 0 !important;
}

/*/////////////////// Licencs //////////////////*/
.linces-description {
  font-weight: 800;
  font-size: 16px;
  line-height: 32px;
}

.linces-fulldescription {
  word-spacing: 1px
}

.text-theme {
  color: #9D9D9D;
}

.personal-license {
  font-size: 19px;
  font-weight: 600;
}

.license-text {
  font-size: 12px;
}

.long-text {
  font-size: 14px;
  word-spacing: 1px;
}

.Commercial-license {
  color: #5347EB;
  font-size: 19px;
  font-weight: 600
}

.Extended-license {
  color: #12B0FA;
  font-size: 19px;
  font-weight: 600
}

.unlimited-time {
  font-weight: 800;
  font-size: 19px;
  word-spacing: 1px;
}

.word-space {
  word-spacing: 1px;
}

.table-main-text {
  font-size: 19px;
  font-weight: 800;
}

.list {
  font-size: 14px;
  font-weight: 500;
  word-spacing: 1px;
  margin-top: 15px;
}

.border-gradient {
  border-bottom: 2px solid transparent;
  border-image: linear-gradient(to right, var(--primary-gradient-color) 20%, var(--secondary-gradient-color));
  border-image-slice: 1;
  width: fit-content;
}

.borderFull-gradient {
  border: 2px solid transparent;
  border-image: linear-gradient(to right, var(--primary-gradient-color) 20%, var(--secondary-gradient-color));
  border-image-slice: 1;
  width: fit-content;
}

.text-color {
  color: #00C106;
}

/* /////////////////////Privacy////////////////// */
.data-type {
  font-size: 17px;
  font-weight: 700;
}

.icon-degree {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 8px;
  font-size: 18px;
  color: white;
  background: var(--cryptoki-gradient);
}

.subTitle {
  font-size: 16px;
  line-height: 24px;
  color: #707070;
  margin: 0
}

.text-high {
  font-size: 22px;
  line-height: 30px;
  font-weight: 600;
}

.title-seller {
  font-size: 30px;
  line-height: 40px;
  font-weight: 700;
  font-style: normal;
  text-align: left;
  color: #000;
  margin-bottom: 16px;
}

.desc-seller {
  font-size: 24px;
  line-height: 36px;
  font-style: normal;
  text-align: left;
  color: #000;
  margin: 0;
  margin-bottom: 28px;
}

.buttons {
  flex-direction: row;
  justify-content: flex-start;
  padding: 0;
  display: flex;
  width: 100%
}

.seller-grediant {
  margin-bottom: 0;
  margin-right: 10px;
  font-size: 16px;
  padding: 10px 22px;
  border-radius: 3px;
  font-weight: 700;
  box-sizing: border-box;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  transition: .25s;
  border-width: 2px;
  border-style: solid;

  background: var(--cryptoki-gradient);
  color: #fff;
}

.seller-without {
  margin-bottom: 0;
  margin-right: 10px;
  font-size: 16px;
  padding: 10px 22px;
  font-weight: 700;
  box-sizing: border-box;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  transition: .25s;
}

.footerIcon {
  display: inline-block;
  height: 25px;
  width: 25px;
  margin-right: 10px;
}

.footer-text-pera {
  font-size: 16px;
  word-spacing: 1px;
}

.incres-font {
  font-size: 16px;
}

.custom-btn {
  width: 130px;
  height: 40px;
  padding: 10px 25px;
  font-family: 'Lato', sans-serif;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
}

.btn-14 {
  position: relative;
  border: none;
  z-index: 2;
}

.btn-14:before,
.btn-14:after {
  position: absolute;
  content: "";
  width: 20%;
  height: 20%;
  border: 1px solid;

  z-index: -1;
  transition: all 0.3s ease;
}

.btn-14:before {
  top: 0;
  left: 0;
  border-bottom-color: transparent;
  border-right-color: transparent;
  border-top-color: #e139fd;
  border-left-color: #e139fd;
}

.btn-14:after {
  bottom: 0;
  right: 0;
  border-top-color: transparent;
  border-left-color: transparent;
  border-bottom-color: #e139fd;
  border-right-color: #e139fd;
}

.btn-14:hover:before,
.btn-14:hover:after {
  border-color: #00aced;
  height: 100%;
  width: 100%;
  box-shadow: 0 0 5px #e139fd, 0 0 5px #e139fd inset;
}

.join-community {
  padding: 60px 100px;
  font-weight: 700;
}

.lock-in {
  width: 1200px !important;
}

.titleeee {
  width: 70%;
}

.titleName {
  width: 20%;
}

.link {
  width: 60%;
}

.description {
  width: 70%;
}

.description1 {
  width: 20%;
}

.shimmer {
  padding: 5px;
  width: 95%;
  height: 120px;
  margin: 10px auto;
  background: #ffffff;
}

.shimmer1 {
  padding: 5px;
  width: 95%;
  height: 300px;
  margin: 10px auto;
  background: #ffffff;
}

.shimmer3 {
  padding: 5px;
  width: 95%;
  height: 200px;
  margin: 10px auto;
  background: #ffffff;
}

.shimmer2 {
  padding: 5px;
  width: 95%;
  height: 600px;
  margin: 10px auto;
  background: #ffffff;
}

.shimmer .image-card {
  height: 90px;
  width: 90px;
  float: right;
  border-radius: 8px;
}

.banner-image {
  height: 540px;
  width: 100%;
  border-radius: 8px;
}

.browser-image {
  height: 197px;
  width: 100%;
  border-radius: 8px;
}

.image-card1 {
  height: 202px;
  width: 100%;
  /* float: right; */
  border-radius: 8px;
}

.circle {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  margin-top: 10px;
}

.stroke {
  height: 15px;
  background: #777;
  margin-top: 10px;
}

.wrapper {
  width: 0px;
  animation: fullView 0.5s forwards linear;
}

@keyframes fullView {
  100% {
    width: 100%;
  }
}

.animate {
  animation: shimmer 3s;
  animation-iteration-count: infinite;
  background: linear-gradient(to right, #e6e6e6 5%, #cccccc 25%, #e6e6e6 35%);
  background-size: 1000px 100%;
}

@keyframes shimmer {
  from {
    background-position: -1000px 0;
  }

  to {
    background-position: 1000px 0;
  }
}

/* /////////////////////Privacy////////////////// */
.full-logo {
  display: block;
}

.short-logo {
  display: none;
}

@media (max-width: 1199px) {
  .short-logo {
    display: block;
  }

  .full-logo {
    display: none;
  }

  .new-logo {
    width: 40px !important;
  }
  .new-logo1 {
    width: 150px !important;
  }
}
.ReactTags__selected span.ReactTags__tag{
  white-space: nowrap !important;
}